import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as firebase from 'firebase';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  nickname = '';
  ref = firebase.database().ref('users/');
  matcher = new MyErrorStateMatcher();

  constructor(private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (localStorage.getItem('nickname')) {
      this.router.navigate(['/roomlist']);
    }
    this.loginForm = this.formBuilder.group({
      'nickname' : [null, Validators.required],
      'code_secret' : [null, Validators.required]
    });
  }

  titleOptions = [
  {local: 'BOKINGI Julian'},{local: 'BUDIMBU Soraya'},{local: 'COTON Terry Jason'},{local: 'DOS ANJOS Astrid'},{local: 'ENDUNDO FERREIRA Sandro'},{local: 'GONDA DI MATINA Chloé'},{local: 'GROBELNY Joseph'},{local: 'ILUNGA Alexandre Placide'},
  {local: 'KABUIKA Dahlia'},{local: 'KANYIMBU Brayan'},{local: 'KASONGA NDUNGA'},{local: 'KAYEMBE MODWARIZA Muriel'},{local: 'KAYUMBA Daniel'},{local: 'KISIMBA Eden'},{local: 'KOMBO Victoria'},{local: 'LEBENI Tanaël'},
  {local: 'LEMAIRE Leana'},{local: 'LOKALE Emmanuella'},{local: 'LUSINDE Yohali'},{local: 'MONGA ILUNGA Adriel Roger'},{local: 'NGOY MOEMBO Maryssa'},{local: 'NLANDU Shamah'},{local: 'OKITOSOMBA Darren'},{local: 'PALMY Auréole'},
  
  {local: 'AISSI Pierre'},{local: 'ANDRIANTAVY Laure-Aina'},{local: 'CIBALONZA Junior'},{local: 'EFAMBE Eliot'},{local: 'INEZA Guy-Auguin'},{local: 'JACOBS Benjamin'},{local: 'KAWAMA Ketsia'},{local: 'KAWARA Allégria'},
  {local: 'KINYOKI Atéa'},{local: 'LOKOTO Sancini'},{local: 'LUHAKA Gloria'},{local: 'LWANGO Victoria'},{local: 'MABUNDA Michel-Ange'},{local: 'MACHARIA Lusemo'},{local: 'MANGULU Gracia'},{local: 'MUMBEMBELE Emmanuella'},{local: 'MWANA MAY Olive'},
  {local: 'N_DUA Dayana'},{local: 'NGANDU Yaëlle'},{local: 'NTUMBA Bolide'},{local: 'ONEZYME Gloria'},{local: 'SINGA Léonie'},{local: 'THALIA WATCHIBA'},{local: 'UMBA Délices'},

  
  {local: 'BINJA KABOYI Melissa'},{local: 'BULAYA Heidi'},{local: 'HU Hanbing'},{local: 'IBRAHIM Mohamed'},{local: 'KIBWE Moise'},{local: 'LUKEMBA Chloé Hellemans'},{local: 'LUYEYE TADI MFUMU Délice'},{local: 'MAHY Pol'},{local: 'MAKUNDA Yanis'},
  {local: 'MASUDI Jean-Gedeon'},{local: 'MBANGU Emy'},{local: 'MULAND Nicolas'},{local: 'MULENDA Ruphin'},{local: 'MUNDEKE Grace'},{local: 'MUNDHU Benjamin'},{local: 'MUSANGU Benjamin'},{local: 'NGOYI Samuella'},{local: 'NTALAJA Winner'},
  {local: 'NZOLANTIMA Adrien'},{local: 'NZOLANTIMA Etia'},{local: 'TSHIKUDI LUPUMBE'},{local: 'YAKA Ephraïm'},{local: 'YAV Angela'},{local: 'ZIELE Raphaël'},

  
  {local: 'ANDEMA KABOYI Muriel'},{local: 'BAHWERE NDOVA Jemima'},{local: 'BERTHELOT Medhi Laurent'},{local: 'BILEK Chiara Genevière'},{local: 'BILOMBA Trish Agreyta'},{local: 'BULU LASSASS Jacques'},
  {local: 'FOTA YOMBWE Joachim'},{local: 'ILOFO MBOYO Daniel'},{local: 'IMAMA ILEBO Catherine'},{local: 'KAHASHA CHILAGANE Raïa'},{local: 'KOULTOUMI Juhani Roland-Marcel'},{local: 'LANCELOT Thalia Grâce'},{local: 'LIKIN Tristan'},{local: 'LUNDI Eunice'},
  {local: 'MATATA YOHALI Archange'},{local: 'MAYEMBA Andrea'},{local: 'MBAYA NGALULA Marie-Catherine'},{local: 'MUTINGA KATUBALONDI Evajolie'},{local: 'MWEHU Charles Mayundo'},{local: 'NEEMA VUNABANDI Doxa'},{local: 'PONGOMBO ONUSUMBA Henri'},{local: 'ZHOU Muyan'},

  
  {local: 'HU Hanbing Charly'},{local: 'KABALA KAPINGA Grâce'},{local: 'KABUYA-LUMUNA CHEUSSI Christelle'},{local: 'KANI KANI OPINE Nathan'},{local: 'KASONGO NGEMBA Merci'},{local: 'KATIKIA AZANGA Malan'},
  {local: 'KAVUO MATHE Grace'},{local: 'KOSO LONGAYO Pierrette'},{local: 'LIZEBI SOFI Prince'},{local: 'LUYEYE NTINDU Dodie'},{local: 'MAKIASHI Sem'},{local: 'MASIALA BINDA Veronica Grâce'},{local: 'MBIYE MULOMBA Betina'},{local: 'MWANA-DEO GRATIAS Gloria'},
  {local: 'MWEPU MAKASA TSHIANGALA Océane'},{local: 'NGARAMBE ISHARA Dan'},{local: 'NGENYI MULOWAYI Xavier Samu'},{local: 'NSEKA LANDA Elliot Kiese'},{local: 'OKITOSOMBA OPENGE Albert Theia'},{local: 'TCHUMA MILONGA Jonathan'},{local: 'ZHOU Zheng Enock'},

  
  {local: 'BUZENGI MAFUTA Annabelle Joyce'},{local: 'CHOVU Issa Christian'},{local: 'KAGE MWANZITA Marie Florent'},{local: 'KAYEMBE MINZUNDU Noé'},{local: 'LEZIN Charlotte'},{local: 'LUFUMA SONY Carl-Anthony'},
  {local: 'MANKONDO LEMA Jonathan Thomas'},{local: 'MATONDO TSUMBU Yohan'},{local: 'MULOKO MWAMBA Gaspy'},{local: 'MUSAMPA MWABA Daniel'},{local: 'MUSOGA TAMBWE Bénédicte Grâce'},{local: 'OKONGE MODESTE Junior Daniel'},{local: 'OTONGA NGWENDE Vincianne'},{local: 'PANDA Annie-Gabrielle'},
  {local: 'PHAMBU Henry-Michel'},{local: 'SANGES Matteo'},{local: 'SEKE Vanecia Kibaba'},{local: 'SETI Alexandre'},{local: 'SINGA KUPA MADUGALA François'},{local: 'TSHIPU KALEV Hélène'},

  
  {local: 'BADIBANGA Hiram'},{local: 'DOLLY WANDALA Ebed'},{local: 'KAWAMA MWADI Roseline'},{local: 'KISIMBA NGOY MUAMBA Jordan-Samuel'},{local: 'MUKENDI YEZE Karel Yace'},{local: 'NGANDU NGOYI Daniella'},
  {local: 'TANGI Cassandra Grâce Mathilde'},{local: 'TSHIOTA TSHIBOLA Marie Annaelle'},

  
  {local: 'ADOMBE KETOPE Daniella'},{local: 'BADIBANGA Kestia-Cristale'},{local: 'BECQUEVORT Harmony Kabagema'},{local: 'BISIMWA LUEMBA Charles-Henri'},{local: 'DE ROECK Anthony Cédric Christian'},{local: 'ELONGA LIBOKE Geoffrey'},
  {local: 'ITAMA GIKAHOYA Ferdyl'},{local: 'KALONJI TAMBAYI Stéphanie'},{local: 'LEZIN Brenda Béatrice'},{local: 'LUHAKA MUSAFIRI Nelson'},{local: 'MADAIL TANDUNDU AUGUSTO Marie-Julia'},{local: 'NGONGO MANGAZA Christenvie'},{local: 'NTUMBA NGOY Abi Béatrice'},{local: 'OKITOSOMBA EDUMBE Alphine'},
  {local: 'PAMI KIESSE Benie'},{local: 'SHAMPA Barchiel'},{local: 'STAVELOT Lucas'},{local: 'TSHIYOYO NGALAMULUME Paul Henri'},

  
  {local: 'DIBATHIA NKETANI Jade Méline'},{local: 'FERREIRA JUNIOR Malaïka Louise'},{local: 'IMAMA BOKANGA Pierre'},{local: 'KAMANDA TSHAKAYA Asael'},{local: 'KITENGE MUNKINDJI Johan'},{local: 'LI Weiyu Stella'},
  {local: 'LIANAVWA MUMBERE Philippe'},{local: 'MAKOSSO Cyril David'},{local: 'MBAKU MANGAMFU Reiel'},{local: 'MOKONDA MA BATULE Marc Henri'},{local: 'MONGA Danielle'},{local: 'MUHIYA FALIALA Gad'},{local: 'MWILWA TAMBWE Francis'},{local: 'NLANDU NEHEMA Claire'},
  {local: 'SANGWA Daniel'},{local: 'SETI Jean-Yanny'},{local: 'VANCUTSEM Alain Martin'},{local: 'WATCHIBA SAIDI Samuel'},{local: 'ZHAO Zihan'},

  
  {local: 'BADU AKETI PAIZANOS Alex'},{local: 'BOSHAB BESHAL MONYO Emmanuel'},{local: 'BULU OWEN Junior'},{local: 'DJONGA Prince Aaron'},{local: 'JACQUERYE Christian'},{local: 'KABEMBA Chiara Maëlle'},
  {local: 'KANYONGA KABEYA FLORIAN'},{local: 'LUHONGE SIFA Chloé Alexia'},{local: 'MOKOLI NGOY Camille Elie'},{local: 'MULENDA SANGEWA Emérite'},{local: 'MUSUMBA TSHIBOLA Estelle Astrid'},{local: 'MVULAZANA KILUBU Daisy Véronique'},{local: 'MWEHU Henri Baruani'},{local: 'NZIGIRE Kimberley'},
  {local: 'PAY-PAY VAGHENI Kiyana'},{local: 'SAFUNIA KIAMBA Anne-Sophie'},{local: 'SIMENE KILICHO Yasmine'},{local: 'ZOUNDI Alena Athanaïs W'},

  
  {local: 'BANGUDI MFUANA Léa Marie-Annie'},{local: 'BOYELA ILEMBWA Donel'},{local: 'GRACE MARIE-CLAIRE Shukrani'},{local: 'HUSSEIN BARAKA Hussein'},{local: 'JABER Hussein'},{local: 'KAJ KANYIMBU Chloé'},
  {local: 'KAMBERE Kayla'},{local: 'KASONGO LWANZA Princesse'},{local: 'KASUKU SIMADA David-Cornelis'},{local: 'KAYEMBE NSAMINUE Marie Splendid'},{local: 'MANKONDO MUPAPA Jocelyne Diersi'},{local: 'MOLAMBA LOWA Louise-Odette Sese'},{local: 'MPULU KANKIESA Grâce-Félicienne'},{local: 'MUDINA WA MUDINA Bernoully'},
  {local: 'N_SA LOKWA Bradley'},{local: 'NYAKABASA IRAGI Mina Solène'},{local: 'NZILA TADULU Tracy'},{local: 'PALMY Lise'},{local: 'PHEMBA Léa'},

  
  {local: 'AMOURI NZALI Elam'},{local: 'ANDRIANTAVY Yanis'},{local: 'AUTSAI AYIKORU Joy'},{local: 'BANGUDI BASUNGA Ethan Kayamba'},{local: 'de LANNOY Nyssia Léa Jacquie'},{local: 'DEMEESTER Joséphine Séverine'},
  {local: 'FOTA KONGOLO KAJIKU Deo Gracia'},{local: 'ILUNGA MBENGI Christelle'},{local: 'KABASELE Christian'},{local: 'KAMANZI Nyota'},{local: 'KIBAMBE NKONGOLO Triomphe'},{local: 'KISHIMBA WA DIESE Eben Ezer'},{local: 'MAISHA MUKUNA Maël'},{local: 'MBALA Gabriella Sarah'},
  {local: 'MBELOLA Neema'},{local: 'MUNGWA Violette'},{local: 'PANZA IFELO Géorgina'},{local: 'SAKOMBI MOLENDO Amélie Monique'},

  
  {local: 'BALINGIBISO Esther'},{local: 'ELONGA IMANI Andréa'},{local: 'HATTA KETAMU Cynthia Golden'},{local: 'KAWAMA BANZA Daniella'},{local: 'KOMBO LISASI Christ'},{local: 'LEBENI Ilian Gabriel Célestin'},
  {local: 'LUMBU Joëlle'},{local: 'LUYEYE MANTETO Mignon'},{local: 'LWELA BINDA KADEFI Emmanuel'},{local: 'MASHAKO MAMBA Bénédict Nathan'},{local: 'MPUTU Julien'},{local: 'MUAMBA Ryan'},{local: 'MULUMBA KATCHY Althéa Tshibuyi'},{local: 'NDONGALA KET Keline Rachel'},
  {local: 'NEMOYATO ENKOTOSIYA Maguy'},{local: 'NSEKA LANDA Danaëlle'},{local: 'OTONGA LUTSHINDA Dorcas'},{local: 'PLESSERS Maxime Ibrahim'},{local: 'TCHELO MAZOMBO Ariel Christopher'},{local: 'VIKA KANKIEZA Jean-Vincent'},{local: 'WAN NGIMBI Cyntiche'},{local: 'YAMBA KABANGO Prince David'},

  
  {local: 'ADOMBE GABRIELLA'},{local: 'BAHWERE PALUKU Trésor'},{local: 'DURIEUX Gaëlle'},{local: 'ENDUNDO FERREIRA Joachim Grégo'},{local: 'ENGULU BOYEKA LOMIAKA Loïle Ma'},{local: 'GIRINGANJI DON Aymard'},
  {local: 'HAMAD Hind'},{local: 'HIJAZI Amani'},{local: 'KAMUN TSHIKALA Glory'},{local: 'KAPINGA MUBANGA Mauricette'},{local: 'KIMBEMBE CHRISMAS'},{local: 'LOKALE CHLOE'},{local: 'MATUNGUL NOBANA Dorcas Marie B'},{local: 'MOBUTU Ilaniya Ladawa'},
  {local: 'MUSAMPA KAPINGA Erica'},{local: 'MUSANGU Priscillia'},{local: 'TEIXEIRA Emmanuela Shanaya'},{local: 'TSHISEKEDI TSHIMIYI Christopher'},{local: 'TSHIVUADI KALOMBO TAMBWE'},{local: 'TSHOVU Mukendi Naomi'},

  
  {local: 'BILOMBA WA BILOMBA Priscilla'},{local: 'HENDRICK Kluivert Ric'},{local: 'LUBANDJI CHARLES'},{local: 'MARTINS LUIS Helder David'},{local: 'MBIYE MULOMBA Isaac'},{local: 'M_PETI BOPONGE David'},
  {local: 'MUNDHU JOSEPH EPHRAIM Beni'},{local: 'PHEMBA Roland'},{local: 'VIRA MATHE Esther'},

  
  {local: 'BAHATI KETSIA'},{local: 'BULAYA Camille Mangaza'},{local: 'CHEN Rui'},{local: 'GUSU-WO AMBZUDA Daniel'},{local: 'IVOUBA Charles-Alexandre'},{local: 'KASUNKA Mwanza Elliott'},
  {local: 'KISIMBA NGOY Ndalewe Lilian'},{local: 'KUMBA Patient'},{local: 'MALAMAS Lars'},{local: 'MASSAMBA ELOHEEKA'},{local: 'MBUYU MUKOLONGO Keren'},{local: 'MUNDHU FETTY Battyston'},{local: 'MWEHU Chloé'},{local: 'NTUMBA NGOY Eunice Kapinga'},
  {local: 'NYAKABASA Ntale David'},{local: 'SAFI MUTABUSHA MUYEMBE Aurélie'},{local: 'SOLA FURAHA Madeline'},{local: 'TOTO Zika Samantha'},{local: 'TULUKA N_SOMPI MARIE-ELIANE'},{local: 'YAV MUJING NACATSHAK Claudia'},

  
  {local: 'AÏSSI MBAIASIMA Pearl'},{local: 'BOKINGI Claude'},{local: 'BOLODJWA EALE W_AMENGE Yohan'},{local: 'BONDO Priscila'},{local: 'BUPILA FURAISHA Léa'},{local: 'de LANNOY Maëlle'},
  {local: 'HENRY Saniya'},{local: 'KALUME POLYDOR'},{local: 'KAMANDA BAGANDA Oren'},{local: 'KANI KANI LUAMBA Nodin'},{local: 'LEHANI RASHIDI Jonathan'},{local: 'LUONGWE KIHANZULA Pitchou'},{local: 'MBUYU ILUNGA NKASSA Samuel'},{local: 'M_BWANKIEM NIAROLIEM Jonathan'},
  {local: 'MUNGWA Harmonie'},{local: 'NZILA Pongo Jaëlle'},{local: 'NZOLANTIMA NKEMBO'},{local: 'UMBA Yann'},{local: 'VANDEWALLE Jennifer'},

  
  {local: 'AMBOHEKI LONGAYO Véronique'},{local: 'BILENGE MUNKINDJI Clémence'},{local: 'KAPANGA NGOYI Nathan'},{local: 'KATIKIA AZANGA Lance'},{local: 'KUSIKUMALA DUDUNA Cynthia'},{local: 'LUTALA ALLEGRESSE'},
  {local: 'MAKIASHI Junias'},{local: 'M_BARIKO Presilia'},{local: 'MORIAME Ryan'},{local: 'MUKUNA KANKU Elie'},{local: 'NGARAMBE CUBAYIRO Nathan'},

  
  {local: 'BAGALE BULIGO Loïs'},{local: 'BAGBENI Adeito Perla'},{local: 'BIGOSHI Gloria Magnificat'},{local: 'BILA MINLANGU ZOLE Ana-Caroline'},{local: 'BOPE MABINTSH Keren'},{local: 'BRAUN Emile'},
  {local: 'JOIRIS Gabriel'},{local: 'KABASELE Anthony'},{local: 'KANINDA Thalia'},{local: 'MAYANGA Gloria-Anne'},{local: 'MPOYI Aggee'},{local: 'MPUEKELA TSHISUAKA (Keren)'},{local: 'M_SIRI KYULU Anaïs'},{local: 'MUGABO OLAME Elam'},
  {local: 'MUKALAY ASSUMINI Laurie'},{local: 'MUKUKU Gabriel'},{local: 'MUZEU-MULLER Lys'},{local: 'MWAMINI KASEREKA Josette'},{local: 'MWINYI MUHEMEDI NASIBU Danny'},{local: 'NZOMONO BAFWANGA Jenovie'},{local: 'PALMY Kelly Ande Kalgi'},{local: 'SAADA HAMULI Pascaline'},{local: 'SAKOMBI MOLENDO Anthony'},{local: 'TSHISEKEDI MULUMBA Christian Samuel'},{local: 'TSHIVUADI KALOMBO TAMBWE Maïcha'},{local: 'TSHOVU Joséphine Benijah'},

  
  {local: 'AMULI TAGIRAWABO Nathan'},{local: 'BODI-MATONDO Jeovana'},{local: 'FUNGULA MAYOYO Marguerite'},{local: 'KALEV-MUTONDO Daniel'},{local: 'KANI KANI MPIRI Nehemie'},{local: 'KAWAMA EmmanueL'},
  {local: 'KELE Paul-Elie'},{local: 'KIKUNDA Yomi Ekila'},{local: 'KITOTO MUNKINDJI Marcia'},{local: 'LEZIN Kimberley'},{local: 'LUYEYE Watoma Emmanuelle'},{local: 'MANKONDO Maboko Joseph'},{local: 'MOSIKWA AMANA Maxim'},{local: 'MPIDI Marie-Rose'},
  {local: 'MWANA MAY DOSITHE Fils'},{local: 'NGARAMBE NGAMIJE Loïc'},{local: 'NZOLANTIMA DIVANA Yona'},{local: 'ODENT Morgann'},{local: 'OKONGE Joy'},{local: 'PELESA Marceline-Prunelle'},{local: 'PORSPERGER Tomiya'},{local: 'SOLA Marilyn Omoy'},{local: 'TSHIBINGU NDIBU Lionel'},{local: 'TSHIMANGA Samuel'},

  
  {local: 'CHALUPA Kyara'},{local: 'INKUNE MBOYO Joseph'},{local: 'ITAMA ASIKALINE Darly Germaine'},{local: 'KAMBERE Yohann'},{local: 'KOULTOUMI Kedijah'},{local: 'MAKONGA NTUMBA MULOPWE Raïssa'},
  {local: 'MULUMBA KALAMBAY Benjamin'},{local: 'MUNONGO Kevin Godefroid'},{local: 'PELESA Sarah-Persévérance'},{local: 'SHULUNGU MUYUMBA Israël'},{local: 'SHULUNGU OMBA Kerene'},{local: 'SITA PEMBELE NKANKU Mami'},{local: 'VANDEWALLE Jessica'},
 
];

  onFormSubmit(form: any) {
    const login = form;
    this.ref.orderByChild('nickname').equalTo(login.nickname).once('value', snapshot => {
      if (snapshot.exists()) {
        localStorage.setItem('nickname', login.nickname);
        this.router.navigate(['/roomlist']);
      } else {
        const newUser = firebase.database().ref('users/').push();
        newUser.set(login);
        localStorage.setItem('nickname', login.nickname);
        this.router.navigate(['/roomlist']);
      }
    });
  }

}
