
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/paques5.png');" >
  <p> </p>
  <p> </p>

  <ul class="breadcrumb">
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site des évaluations en ligne'Quiziniere'"  href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a></li>
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site de Visio-conférence'jit-si'"  href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a></li>

    <li><a  href="#" data-toggle="popover" title="Rédirection vers la boite de messagérie de l'école"  href="https://login.one.com/mail" Target="_blank" > Webmail </a></li>
    <li><a  href="#" data-toggle="popover" title="Méssagérie instantanée privée de l'école. Permet de participer à un groupe dédié de CHAT"  [routerLink]="'/login'" routerLinkActive="active" > Chat-Room </a></li>   
    <li><a  href="#" data-toggle="popover" title="Fermeture de la session en cours"  [routerLink]="'auth/signin'" routerLinkActive="active"  > Déconnexion </a></li>     
  </ul>
 

  <h2>Vos leçons</h2>
  <p> </p>
  <p> </p>
  <div class="jumbotron" style="background-color:lavenderblush" >
            
        <button href="#" data-toggle="popover" title="Pour composer une nouvelle leçon vous serez appélé à renseigner le code générique pour enseignant" class="btn btn-primary" (click)="onNewBook()">Nouvelle leçon</button>
        <button href="#" data-toggle="popover" title="Pour accéder aux anciènnes leçons mises en archive" class="btn btn-default" (click)="onArchive()" >Archives</button>           
      
        
        <form [formGroup]="filterForm">
        <h5>Filtrer par :</h5>

          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label href="#" data-toggle="popover" title="Pour filtrer les leçons en fonction du titre!" for="title-filter">Titre</label>
                <input type="text" id="title-filter" formControlName="titleFilter">
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label href="#" data-toggle="popover" title="Pour filtrer les leçons en fonction de l'enseignant!" for="author-filter">Auteur</label>
                <input type="text" id="author-filter" formControlName="authorFilter">
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label href="#" data-toggle="popover" title="Pour filtrer les leçons en fonction de la date de publication!" for="date-filter">Date</label>
                <input type="date" id="date-filter" formControlName="dateFilter">
              </div>
            </div>   
            <div class="col-sm-3">
              <div class="form-group">
                  <select href="#" data-toggle="popover" title="Veuillez sélectionner la classe cible!" id="class-filter" formControlName="classFilter">
                  <option *ngFor="let classe of (classList$ | async)" [value]="classe.value" placeholder="Classe">{{ classe.text }}</option>
                </select>
              </div>       
  
            </div>

          </div>

          <div class="form-group">
            <button class="btn-default btn-sm" (click)="onResetFilters()">Réinitialiser filtres</button>
          </div>

          <div class="form-group" hidden>
            <label href="#" data-toggle="popover" title="Pour filtrer les leçons en fonction du devoir du prof!" for="author-devoir-filter">AuteurDevoir</label>
            <input type="text" id="author-devoir-filter" formControlName="authordevoirfilter">
          </div>

          <div class="form-group" hidden>
            <label for="author-delete">Code Unique pour supprimer une leçon</label>
            <input type="text" id="author-delete" (input)="detectIdAuthor($event.target.value)" formControlName="authorDelete">
          </div>
          <p class="text-danger">{{ errorMessage }}</p>

        </form>
    </div>

    <div class="row">
      <div class="col-xs-12">

        <div class="list-group">
          <button  [ngClass]="{ snapped: book.title === 'DEVOIR' }"
            class="list-group-item"
            *ngFor="let book of (books$ | async); let i = index"

            (click)="onViewBook(book.id)" >
            <h3 class="list-group-item-heading">

              {{ book.listedechoix | uppercase }}
              <p></p>
            -<span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert">Attention</span></span> 
            
              <p> </p>
              <b>{{ book.dateJour | date:"dd/MM/yyyy" }}</b>
              <p> </p>

              <b>{{ book.author | uppercase}}</b>
            

            <button class="btn btn-default pull-right" >      
                <span class="glyphicon glyphicon-remove" ></span>
            </button>              

            </h3>
            <p class="list-group-item-text">{{ book.title | uppercase  }}</p>
            <p *ngIf = "book.title == 'DEVOIR'" class="list-group-item-text"><b>Devoir à domicile de : {{ book.author | uppercase  }}</b></p>
            <p class="list-group-item-text">{{ book.id }}</p>

          </button>
        </div>

      </div>
    </div>
  </div>
