import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooksService } from '../services/books.service';
import { Book } from '../models/Book.model';
import { Router } from '@angular/router';
import { ConstantsService } from '../services/constants.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith,} from 'rxjs/operators';
import { Classe } from '../models/Classe.model';
import { ClassesService } from '../services/classes.service';



@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit, OnDestroy {

  books$: Observable<Book[]>;
  classList$: Observable<Classe[]>;
  distFolderLocation: boolean;
  filterForm: FormGroup;
  destroy$: Subject<boolean>;

  authorEmail: string ="";
  idDelete: string = "";
  idDeleteConfirm: boolean = false;
  verifIdAuthor: string;
  errorMessage: string;

  mail_enseignant: string;


  constructor(private booksService: BooksService,
    private router: Router,
    private _constant: ConstantsService,
    private formBuilder: FormBuilder,
    private classes: ClassesService
    ) {
    this.distFolderLocation = this._constant.distLocation;
    console.log(this.router.getCurrentNavigation().extras.state.example);
    this.mail_enseignant = this.router.getCurrentNavigation().extras.state.example; // la valeur du nom de l'enseignant
  }
  ngOnInit() {
    this.classList$ = this.classes.getClasses();
    this.destroy$ = new Subject();
    this.filterForm = this.formBuilder.group({
      titleFilter: [null],
      authorFilter: [null],
      dateFilter: [null],
      classFilter: [null],
      authordevoirfilter: [null],
      authorDelete: [null] // ajout champs pour la suppression des lecçons
    });
    this.books$ = combineLatest([
      this.booksService.bookSubject,
      this.filterForm.valueChanges.pipe(
        startWith({
          titleFilter: null,
          authorFilter: null,  // Rédirection vers l'espace dedié de l'enseignat
          dateFilter: null,
          authordevoirfilter: this.mail_enseignant,
          classFilter: null
        })
      )]).pipe(
        debounceTime(300),
        map(([books, valueChanges]) => {
          if (valueChanges.titleFilter) {
            books = books.filter(book => book.title.toLowerCase().includes(valueChanges.titleFilter.toLowerCase()));
          }
          if (valueChanges.authorFilter) {
            books = books.filter(book => book.author.toLowerCase().includes(valueChanges.authorFilter.toLowerCase()));
          }
          if (valueChanges.dateFilter) {
            books = books.filter(book =>
              new Date(book.dateJour).getDate() === new Date(valueChanges.dateFilter).getDate() &&
              new Date(book.dateJour).getMonth() === new Date(valueChanges.dateFilter).getMonth()
            );          }
          if (valueChanges.classFilter) {
            books = books.filter(book => book.listedechoix.toString().toLowerCase() === valueChanges.classFilter);
          }
          if (valueChanges.authordevoirfilter) {
            books = books.filter(book => book.authorId.toLowerCase().includes(valueChanges.authordevoirfilter.toLowerCase()));  // Adresse mail du prof
          }
          return books;
        }),
        map(books => books.sort(
          (a: Book, b: Book) => (new Date(b.dateJour).getTime() - new Date(a.dateJour).getTime()) < 0 ? -1 : 1))
    );
    this.booksService.emitBooks();
  }


  
  detectIdAuthor(event) {
    this.verifIdAuthor = this.filterForm.get('authorDelete').value;
    console.log(this.verifIdAuthor); 
    
     this.rechercheEmailAuthor();
    
  }


  rechercheEmailAuthor() {

    switch (this.verifIdAuthor) {
      case '150978':
        this.idDelete = 'HERVE MBENGA LIKITA';
        this.authorEmail = 'hml@hml-solution.com';
        this.idDeleteConfirm = true;
        break;
        case 'E41':
          this.idDelete = 'ADARVE ESPINOSA SANDRA';
          this.authorEmail = "sandra.adarve@lpl-rdc.com";
          this.idDeleteConfirm = true;
        break;

        case 'GY33':
          this.idDelete = 'SERGINE LEROSSIGNOL';
          this.authorEmail = "sergine.lerossignol@lpl-rdc.com";
          this.idDeleteConfirm = true;
          break;
          case 'F48':
            this.idDelete = 'KENYA DOSSOU';
            this.authorEmail = "kenya.dossou@lpl-rdc.com";
            this.idDeleteConfirm = true;
            break;
            case 'A435':
              this.idDelete = 'EVELYNE DEROOVER';
              this.authorEmail = "evelyne.deroover@lpl-rdc.com";
              this.idDeleteConfirm = true;
              break;
              case 'A337':
                this.idDelete = 'LAURENCE DEBRY';
                this.authorEmail = "laurence.debry@lpl-rdc.com";
                this.idDeleteConfirm = true;
                break;
                case 'F211':
                  this.idDelete = 'RAPHAEL WANZIO';
                  this.authorEmail = "raphael.wanzio@lpl-rdc.com";
                  this.idDeleteConfirm = true;
                  break;
                  case 'B2012':
                    this.idDelete = 'LEOPOLD TANGANAGBA';
                    this.authorEmail = "leopold.tanganagba@lpl-rdc.com";
                    this.idDeleteConfirm = true;
                    break;
                    case 'G4837':
                      this.idDelete = 'MANON NARDELLOTTO';
                      this.authorEmail = "sandra.adarve@lpl-rdc.com";
                      this.idDeleteConfirm = true;
                      break;
                      case 'A139':
                        this.idDelete = 'OLIVIER COYETTE';
                        this.authorEmail = "olivier.coyette@lpl-rdc.com";
                        this.idDeleteConfirm = true;
                        break;
                        case 'G4640':
                          this.idDelete = 'ALEXANDRE LATKOWSKI';
                          this.authorEmail = "alexandre.latkowski@lpl-rdc.com";
                          this.idDeleteConfirm = true;
                          break;
                          case 'G4441':
                            this.idDelete = 'RAYHANA SURAY';
                            this.authorEmail = "rayhana.suray@lpl-rdc.com";
                            this.idDeleteConfirm = true;
                            break;
                            case 'B1053':
                              this.idDelete = 'SOPHIE MAHY';
                              this.authorEmail = "sophie.mahy@lpl-rdc.com";
                              this.idDeleteConfirm = true;
                              break;

      case 'B182':
        this.idDelete = 'BEYA SIMON';
        this.authorEmail = 'simon.beya@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C63':
        this.idDelete = 'BORGUGNONS CASSANDRA';
        this.authorEmail ='cassandra.borgugnons@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E14':
        this.idDelete = 'DE ROSEN MELISSA';
        this.authorEmail ='melisssa.derosen@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'GY5':
        this.idDelete = 'DELAET CEDRIC';
        this.authorEmail = 'cedric.delaet@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F56':
        this.idDelete = 'DILU CHRISTELLE';
        this.authorEmail = 'christelle.dilu@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F47':
        this.idDelete = 'FAWAZ SORAYA';
        this.authorEmail = 'soraya.fawaz@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G68':
        this.idDelete = 'GREGOIRE CLEMENT';
        this.authorEmail = 'clement.gregoire@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C19':
        this.idDelete = 'HANSON AMELIE';
        this.authorEmail = 'amelie.hanson@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F210':
        this.idDelete = 'ILUNGA SUAREZ MIREILLE';
        this.authorEmail = 'mireille.ilunga@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B2011':
        this.idDelete = 'KAKUDJI RACHEL';
        this.authorEmail = 'rachel.kakudji@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C312':
        this.idDelete = 'KELE BENOIT';
        this.authorEmail = 'benoit.kele@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C213':
        this.idDelete = 'KIADI KATIA';
        this.authorEmail = 'katia.kiadi@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E314':
        this.idDelete = 'LECOQ QUENTIN';
        this.authorEmail = 'quentin.lecoq@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E615':
        this.idDelete = 'MAES DAMIEN';
        this.authorEmail = 'damien.maes@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1916':
        this.idDelete = 'MARION LAURENCE';
        this.authorEmail = 'laurence.marion@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G417':
        this.idDelete = 'MATETA PAGUY';
        this.authorEmail = 'paguy.mateta@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G218':
        this.idDelete = 'MAURER CHAMMAS ALEXANDRA';
        this.authorEmail = 'alexandra.maurer@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C419':
        this.idDelete = 'MERLE KATY';
        this.authorEmail = 'katy.merle@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'MO20':
        this.idDelete = 'MEYERS MARIE';
        this.authorEmail = 'marie.meyers@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E221':
        this.idDelete = 'MISTIAEN CHRISTOPHER';
        this.authorEmail = 'christopher.mistiaen@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E722':
        this.idDelete = 'MWEPU BROS CHARLENE';
        this.authorEmail = 'charlene.mwepu@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G3423':
        this.idDelete = 'NOTERMAN ANNIE';
        this.authorEmail = 'annie.noterman@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F624':
        this.idDelete = 'NZUZI YVON';
        this.authorEmail = 'yvon.nzuzi@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C525':
        this.idDelete = 'OLIVA ANNABELLE';
        this.authorEmail = 'annabelle.oliva@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'E826':
        this.idDelete = 'PEDUZY ALICE';
        this.authorEmail = 'alice.peduzy@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F127':
        this.idDelete = 'PETTEAU STEEVE';
        this.authorEmail ='steeve.petteau@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'E928':
        this.idDelete = 'RICHARD ROMAIN';
        this.authorEmail = 'romain.richard@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G829':
        this.idDelete = 'SUMAILI ARTHUR';
        this.authorEmail = 'arthur.sumaili@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'F330':
        this.idDelete = 'THORP WILLIAM';
        this.authorEmail = 'william.thorp@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'C731':
        this.idDelete = 'TOMBU CHANTAL';
        this.authorEmail = 'chantal.tombu@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'GY32':
        this.idDelete = 'ZANGERLE GILLES';
        this.authorEmail = 'gilles.zangerle@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G4033':
        this.idDelete = 'COLSON SARAH';
        this.authorEmail = 'sarah.colson@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G40331':
        this.idDelete = 'ILYINE CHARLOTTE';
        this.authorEmail = 'charlotte.ilyine@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'A434':
        this.idDelete = 'DETHY LAURE';
        this.authorEmail = 'laure.dethy@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'A235':
        this.idDelete = 'SIMOES ISABEL';
        this.authorEmail = 'isabelle.simoes@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'A336':
        this.idDelete = 'PARDAENS RITA';
        this.authorEmail = 'ritha.pardaens@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'G4837':
        this.idDelete = 'CASTELA CERRO DELPHINE';
        this.authorEmail = 'delphine.castela@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'A138':
        this.idDelete = 'BOSCO CHRISTINA';
        this.authorEmail = 'christina.bosco@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G4639':
        this.idDelete = 'CHAUDRON MAÏTE';
        this.authorEmail = 'maite.chaudron@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G4440':
        this.idDelete = 'BOURSAS NADIA';
        this.authorEmail = 'nadia.boursas@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G4241':
        this.idDelete = 'IKETE JESSICA';
        this.authorEmail = 'jessica.ikete@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B542':
        this.idDelete = 'MATHY ASTRID ';
        this.authorEmail = 'astrid.mathy@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B643':
        this.idDelete = 'MUNGA MURIEL';
        this.authorEmail = 'muriel.munga@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B744':
        this.idDelete = 'THUMAS ANNOUCK';
        this.authorEmail ='annouck.thumas@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B845':
        this.idDelete = 'BOUX SOPHIE';
        this.authorEmail = 'sophie.boux@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B146':
        this.idDelete = 'RAHIER VERONIQUE';
        this.authorEmail = 'veronique.rahier@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B447':
        this.idDelete = 'DEBIESME ALEXANDRA';
        this.authorEmail = 'alexandra.debiesme@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B348':
        this.idDelete = 'RIGUELLE SARAH';
        this.authorEmail = 'sarah.riguelle@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B249':
        this.idDelete = 'LIVRIZZI MARIA';
        this.authorEmail = 'maria.livrizzi@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1150':
        this.idDelete = 'ANDREANI ANNIE';
        this.authorEmail = 'annie.andreani@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B951':
        this.idDelete = 'LAMBERT MAXIME';
        this.authorEmail = 'maxime.lambert@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1052':
        this.idDelete = 'CHARLIER SOPHIE';
        this.authorEmail = 'sophie.charlier@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G1853':
        this.idDelete = 'DURIEZ ALEXANDRE';
        this.authorEmail = 'alexandre.duriez@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G2254':
        this.idDelete = 'BORON KILLIAN';
        this.authorEmail = 'kilian.boron@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G2055':
        this.idDelete = 'LEBEAU MARIE-PAULE';
        this.authorEmail ='marie-paule.lebeau@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'G3056':
        this.idDelete = 'PREGARDIEN QUENTIN';
        this.authorEmail = 'quentin.pregardien@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G2657':
        this.idDelete = 'BOUZOUITA LAETITIA';
        this.authorEmail = 'laeticia.bouzouita@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G2458':
        this.idDelete = 'NDRIN NAOMI';
        this.authorEmail = 'naomie.ndrin@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B1559':
        this.idDelete = 'AGNEESSENS SUAD';
        this.authorEmail = 'suad.agnessens@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1460':
        this.idDelete = 'BOUVIER FREDERIC';
        this.authorEmail = 'frederic.bouvier@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1661':
        this.idDelete = 'MOUSSEBOIS VIRGINIE';
        this.authorEmail = 'virginie.moussebois@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'G3462':
        this.idDelete = 'NOTTERMAN ANNIE';
        this.authorEmail ='annie.noterman@lpl-rdc.com	';
        this.idDeleteConfirm = true;
        break;
      case 'B1363':
        this.idDelete = 'UMBA YVETTE';
        this.authorEmail = 'yvette.umba@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'B1764':
        this.idDelete = 'KABONGO GINETTE';
        this.authorEmail = 'ginette.kabongo@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'G3865':
        this.idDelete = 'LEBENI ILLA';
        this.authorEmail = 'illa.lebeni@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'GY66':
        this.idDelete = 'VERBRAEKEN AMELIE';
        this.authorEmail ='amelie.verbraeken@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'GY67':
        this.idDelete = 'KABANGU KANDA';
        this.authorEmail = 'kanda.kabangu@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
      case 'P68':
        this.idDelete = 'CHAMI KARIN';
        this.authorEmail = 'karin.chami@lpl-rdc.com';
        this.idDeleteConfirm = true;
        break;
        case 'DP69':
          this.idDelete = 'DEVOS OLIVIER';
          this.authorEmail = 'direction.primaire@lpl-rdc.com';
          this.idDeleteConfirm = true;
        break;
        case 'P661':
          this.idDelete = 'FLORIANE MAROY';
          this.authorEmail = 'floriane.maroy@lpl-rdc.com';
          this.idDeleteConfirm = true;
        break;
        case 'PY17':
          this.idDelete = 'JOANNE FLEURVIL';
          this.authorEmail = 'floriane.maroy@lpl-rdc.com';
          this.idDeleteConfirm = true;
        
        break;
          case '150978':
          this.idDelete = 'HERVE MBENGA LIKITA';
          this.authorEmail = 'hml@hml-solution.com';
          this.idDeleteConfirm = true;
          break;
          case 'AVIS PARENTS SECONDAIRE': // TODO: REMOVE FOR PRODUCTION
          this.idDelete = 'DIRECTION PEDAGOGIQUE SECONDAIRE';
          this.authorEmail = 'direction.secondaire@lpl-rdc.com';
          this.idDeleteConfirm = true;
          break;
        case 'AVIS PARENTS FONDAMENTALE': // TODO: REMOVE FOR PRODUCTION
          this.idDelete = 'DIRECTION PEDAGOGIQUE FONDAMENTALE';
          this.authorEmail = 'direction-pedagogique-fondamentale@lpl-rdc.com';
          this.idDeleteConfirm = true;
        break;
      case 'TEST': // TODO: REMOVE FOR PRODUCTION
        this.idDelete = 'TEST';
        this.authorEmail = 'TEST';
        this.idDeleteConfirm = true;
        break;
      default:
        this.authorEmail = '';
    }
    //console.log('Mon ID : ' + this.authorEmail);

  }

  onNewBook() {
    // this.router.navigate(['/books', 'new']);
    this.router.navigate(['/authsupp'], { state: { example: this.mail_enseignant } });
    //this.router.navigate(['/authsupp']);
  }

  onArchive() {
    // this.router.navigate(['/books', 'new']);
    this.router.navigate(['/booksStudentM1I']);
  }

  onDeleteBook(book: Book) {
    console.log('Valeur du mail leçon: ' + book.authorId);
      console.log('Valeur du mail saisi: ' + this.authorEmail);

    if( book.authorId == this.authorEmail){
      //if( this.authorEmail == '150978'){
      
      this.booksService.removeBook(book);

    }else{
      console.log("Attention cette leçon n'est pas la votre!!!!");
          }
  }

  onViewBook(id: number) {

    console.log("La valeur de l'ID : " + id);
    this.router.navigate(['/books', 'view', id], { state: { example: this.mail_enseignant } });
    //this.router.navigate(['/books', 'view', id]);
    
  }

  onResetFilters() {
    this.filterForm.reset();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}