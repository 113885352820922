<div class="container-fluid">
    <div class="row">

        <h3>---------------------------------------------------------------------------------------------------------------------------------------------------ADMINISTRATION-----------------------------------------------------------------------------------------------------------------------------</h3>
        <h3>------------------------------------------------------------------------------------------------------------------------------------------------<b>NOUVEL UTILISATEUR</b>---------------------------------------------------------------------------------------------------------------------------------</h3>
      <div class="col-12 col-lg-12 col-md-12 sol-sm-12">
        <form [formGroup]="productFormFinance" (ngSubmit)="updateProduct_admin(productFormFinance.value)">

  
         <div class="form-group">
            <label>Nom de l'utilisateur</label>
            <input  type="text" class="form-control" placeholder="Nom'" formControlName="NomUtilisateur" required>
         </div>


          <div class="form-group">
            <label>Adresse E-mail</label>
            <input  type="text" class="form-control" placeholder="E-mail'" formControlName="Adressemail" required>
         </div>

          <div class="form-group">
             <label>Code d'identification unique(Max: 6 caractères alphanumérique)</label>
             <input   type="text" class="form-control" placeholder="Identifiant unique" formControlName="IdentUnique" required>            
          </div>

          <div class="form-group">
            <label>A accès à Class-Room?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Class-Room?" formControlName="ClassRoom" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['ClassRoom'].valid && (productFormFinance.controls['ClassRoom'].touched)">
              <div [hidden]="!productFormFinance.controls['ClassRoom'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>A accès à Chat-Room?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Chat-Room?" formControlName="ChatRoom" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['ChatRoom'].valid && (productFormFinance.controls['ChatRoom'].touched)">
              <div [hidden]="!productFormFinance.controls['ChatRoom'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>A accès à Finance?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Finance?" formControlName="Finance" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['Finance'].valid && (productFormFinance.controls['Finance'].touched)">
              <div [hidden]="!productFormFinance.controls['Finance'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>A accès à Secretariat?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Secretariat?" formControlName="Secretariat" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['Secretariat'].valid && (productFormFinance.controls['Secretariat'].touched)">
              <div [hidden]="!productFormFinance.controls['Secretariat'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>A accès à Communication?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Communication?" formControlName="Communication" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['Communication'].valid && (productFormFinance.controls['Communication'].touched)">
              <div [hidden]="!productFormFinance.controls['Communication'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>A accès à Administration?(NON / OUI)</label>
            <input  type="text" class="form-control" placeholder="Administration?" formControlName="Administration" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['Administration'].valid && (productFormFinance.controls['Administration'].touched)">
              <div [hidden]="!productFormFinance.controls['Administration'].errors.required">
                La valeur NON ou OUI est obligatoire.
              </div>
            </div>
          </div>


          

          <p class="text-danger">{{ errorMessage }}</p>

          <button type="submit" class="btn btn-primary" [disabled]="!productFormFinance.valid">Soumettre</button>
          <button class="btn btn-secondary" (click)="getNavigation('admin', '')">retour</button>
          
  
        </form>

        
      </div>
    </div>
  </div>