import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {
  StudentCode: any;
  Ordre: boolean;
  errorMessage: string;

  constructor() { }

  

  ngOnInit(): void {
  }

  onSaveBook() {

  switch (this.StudentCode) {

    case '215':
      this.StudentCode = 'ADARVE ESPINOSA SANDRA';
      this.Ordre =true;
      break;
    case '610':
      this.StudentCode = 'BEYA SIMON';
      this.Ordre =true;
      break;
    case '617':
      this.StudentCode = 'BORGUGNONS CASSANDRA';
      this.Ordre =true;
      break;
    case '632':
      this.StudentCode = 'DE ROSEN MELISSA';
      this.Ordre =true;
      break;
    case '647':
      this.StudentCode = 'DELAET CEDRIC';
      this.Ordre =true;
      break;
    case '679':
      this.StudentCode = 'DILU CHRISTELLE';
      this.Ordre =true;
      break;
    case '683':
      this.StudentCode = 'FAWAZ SORAYA';
      this.Ordre =true;
      break;
    case '760':
      this.StudentCode = 'GREGOIRE CLEMENT';
      this.Ordre =true;
    case '831':
      this.StudentCode = 'HANSON AMELIE';
      this.Ordre =true;
      break;
    case '842':
      this.StudentCode = 'ILUNGA SUAREZ MIREILLE';
      this.Ordre =true;
      break;
    case '857':
      this.StudentCode = 'KAKUDJI RACHEL';
      this.Ordre =true;
      break;
    case '859':
      this.StudentCode = 'KELE BENOIT';
      this.Ordre =true;
      break;
    case '884':
      this.StudentCode = 'KIADI KATIA';
      this.Ordre =true;
      break;
    case '946':
      this.StudentCode = 'LECOQ QUENTIN';
      this.Ordre =true;
      break;
    case '957':
      this.StudentCode = 'MAES DAMIEN';
      this.Ordre =true;
      break;
    case '1011':
      this.StudentCode = 'MARION LAURENCE';
      this.Ordre =false;
      break;
    case '1022':
      this.StudentCode = 'MATETA PAGUY';
      this.Ordre =true;
      break;
    case '1027':
      this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
      this.Ordre =true;
      break;
    case '1033':
      this.StudentCode = 'MERLE KATY';
      this.Ordre =true;
      break;
    case '1039':
      this.StudentCode = 'MEYERS MARIE';
      this.Ordre =true;
      break;
    case '1042':
      this.StudentCode = 'MISTIAEN CHRISTOPHER';
      this.Ordre =true;
      break;
    case '1053':
      this.StudentCode= 'MWEPU BROS CHARLENE';
      this.Ordre =true;
      break;
    case '1059':
      this.StudentCode = 'NOTERMAN ANNIE';
      this.Ordre =true;
      break;
    case '1068':
      this.StudentCode = 'NZUZI YVON';
      this.Ordre =true;
      break;
    case '1083':
      this.StudentCode = 'OLIVA ANNABELLE';
      this.Ordre =true;
      break;
    case '1102':
      this.StudentCode = 'PEDUZY ALICE';
      this.Ordre =true;
      break;
    case '1105':
      this.StudentCode = 'PETTEAU STEEVE';
      this.Ordre =true;
      break;
    case '1106':
      this.StudentCode = 'RICHARD ROMAIN';
      this.Ordre =true;
      break;
    case '1141':
      this.StudentCode = 'SUMAILI ARTHUR';
      this.Ordre =true;
      break;
    case '1148':
      this.StudentCode = 'THORP WILLIAM';
      this.Ordre =true;
      break;
    case '1154':
      this.StudentCode = 'TOMBU CHANTAL';
      this.Ordre =true;
      break;
    case '1166':
      this.StudentCode = 'ZANGERLE GILLES';
      this.Ordre =true;
      break;
    case '1175':
      this.StudentCode = 'COLSON SARAH';
      this.Ordre =true;
      break;
    case '1218':
      this.StudentCode = 'ILYNE CHARLOTTE';
      this.Ordre =true;
      break;
    case '1220':
      this.StudentCode = 'DETHY LAURE';
      this.Ordre =true;
      break;
    case '1234':
      this.StudentCode = 'SIMOES ISABEL';
      this.Ordre =true;
      break;
    case '1253':
      this.StudentCode = 'PARDAENS RITA';
      this.Ordre =true;
      break;
    case '1267':
      this.StudentCode = 'CASTELA CERRO DELPHINE';
      this.Ordre =true;
      break;
    case '1273':
      this.StudentCode = 'BOSCO CHRISTINA';
      this.Ordre =true;
      break;
    case '1278':
      this.StudentCode = 'CHAUDRON MAÏTE';
      this.Ordre =true;
      break;
    case '1291':
      this.StudentCode = 'BOURSAS NADIA';
      this.Ordre =true;
     break;
    case '1303':
      this.StudentCode = 'IKETE JESSICA';
      this.Ordre =true;
      break;
    case '1306':
      this.StudentCode = 'MATHY ASTRID ';
      this.Ordre =true;
      break;
    case '1313':
      this.StudentCode = 'MUNGA MURIEL';
      this.Ordre =true;
      break;
    case '1321':
      this.StudentCode = 'THUMAS ANNOUCK';
      this.Ordre =true;
      break;
    case '1324':
      this. StudentCode = 'BOUX SOPHIE';
      this.Ordre =true;
      break;
    case '1325':
      this.StudentCode = 'RAHIER VERONIQUE';
      this.Ordre =true;
      break;
    case '1332':
      this.StudentCode = 'DEBIESME ALEXANDRA';
      this.Ordre =true;
      break;
    case '1338':
      this.StudentCode = 'RIGUELLE SARAH';
      this.Ordre =true;
      break;
    case '1349':
      this.StudentCode = 'LIVRIZZI MARIA';
      this.Ordre =true;
      break;
    case '1354':
      this.StudentCode = 'ANDREANI ANIE';
      this.Ordre =true;
      break;
    case '1374':
      this.StudentCode = 'LAMBERT MAXIME';
      this.Ordre =true;
      break;
    case '1376':
      this.StudentCode = 'CHARLIER SOPHIE';
      this.Ordre =true;
      break;
    case '1377':
      this.StudentCode = 'DURIEZ ALEXANDRE';
      this.Ordre =true;
      break;
    case '1380':
      this.StudentCode = 'BORON KILLIAN';
      this.Ordre =false;
      break;
    case '1383':
      this.StudentCode = 'LEBEAU MARIE-PAULE';
      this.Ordre =true;
      break;
    case '1388':
      this.StudentCode = 'PREGARDIEN QUENTIN';
      this.Ordre =true;
      break;
    case '1392':
      this.StudentCode = 'BOUZOUITA LAETITIA';
      this.Ordre =true;
      break;
    case '1396':
      this.StudentCode = 'NDRIN NAOMI';
      this.Ordre =true;
      break;
    case '1397':
      this.StudentCode = 'AGNEESSENS SUAD';
      this.Ordre =true;
      break;
    case '1406':
      this.StudentCode = 'BOUVIER FREDERIC';
      this.Ordre =true;
      break;
    case '1413':
      this. StudentCode = 'MOUSSEBOIS VIRGINIE';
      this.Ordre =true;
      break;
    case '1430':
      this.StudentCode = 'NOTTERMAN ANNIE';
      this.Ordre =true;
      break;
    case '1472':
      this.StudentCode = 'UMBA YVETTE';
      this.Ordre =true;
      break;
    case '1480':
      this.StudentCode = 'KABONGO GINETTE';
      this.Ordre =true;
      break;
    case '1482':
      this.StudentCode = 'LEBENI ILLA';
      this.Ordre =true;
      break;
    case '1487':
      this.StudentCode = 'VERBRAEKEN AMELIE';
      this.Ordre =true;
      break;
    case '1488':
      this.StudentCode = 'KABANGU KANDA';
      this.Ordre =true;
      break;
    case '1489':
      this.StudentCode = 'CHAMI KARIN';
      this.Ordre =true;
      break;
    case '1493':
      this.StudentCode = 'DEVOS OLIVIER';
      this.Ordre =true;
      break;
    case '1494':
      this.StudentCode = 'HERVE MBENGA LIKITA';
      this.Ordre =true;
      break;
    case '1509':
      this.StudentCode = 'IKETE JESSICA';
      this.Ordre =true;
      break;
    case '1511':
      this.StudentCode = 'MATHY ASTRID ';
      this.Ordre =true;
      break;
    case '1515':
      this.StudentCode = 'MUNGA MURIEL';
      this.Ordre =true;
      break;
    case '1518':
      this.StudentCode = 'THUMAS ANNOUCK';
      this.Ordre =true;
      break;
    case '1524':
      this.StudentCode = 'BOUX SOPHIE';
      this.Ordre =false;
      break;
    case '1528':
      this.StudentCode = 'RAHIER VERONIQUE';
      this.Ordre =true;
      break;
    case '1529':
      this.StudentCode = 'DEBIESME ALEXANDRA';
      this.Ordre =true;
      break;
    case '1532':
      this.StudentCode = 'RIGUELLE SARAH';
      this.Ordre =true;
      break;
    case '1534':
      this.StudentCode = 'LIVRIZZI MARIA';
      this.Ordre =true;
      break;
    case '1541':
      this.StudentCode = 'ANDREANI ANIE';
      this.Ordre =true;
      break;
    case '1546':
      this.StudentCode = 'LAMBERT MAXIME';
      this.Ordre =true;
      break;
    case '1547':
      this.StudentCode = 'CHARLIER SOPHIE';
      this.Ordre =false;
      break;
    case '1560':
      this.StudentCode = 'DURIEZ ALEXANDRE';
      this.Ordre =true;
      break;
    case '1563':
      this.StudentCode = 'BORON KILLIAN';
      this.Ordre =true;
      break;
    case '1568':
      this.StudentCode = 'LEBEAU MARIE-PAULE';
      this.Ordre =true;
      break;
    case '1573':
      this.StudentCode = 'PREGARDIEN QUENTIN';
      this.Ordre =false;
      break;
    case '1578':
      this.StudentCode = 'BOUZOUITA LAETITIA';
      this.Ordre =true;
      break;
    case '1581':
      this. StudentCode = 'NDRIN NAOMI';
      this.Ordre =true;
      break;
    case '1583':
      this. StudentCode = 'AGNEESSENS SUAD';
      this.Ordre =true;
      break;
    case '1596':
      this.StudentCode = 'BOUVIER FREDERIC';
      this.Ordre =true;
      break;
    case '1601':
      this.StudentCode = 'MOUSSEBOIS VIRGINIE';
      this.Ordre =true;
      break;
    case '1603':
      this.StudentCode = 'NOTTERMAN ANNIE';
      this.Ordre =true;
      break;
    case '1607':
      this.StudentCode = 'UMBA YVETTE';
      this.Ordre =true;
      break;
    case '1612':
      this.StudentCode = 'KABONGO GINETTE';
      this.Ordre =true;
      break;
    case '1614':
      this.StudentCode = 'LEBENI ILLA';
      this.Ordre =true;
      break;
    case '1618':
      this. StudentCode = 'VERBRAEKEN AMELIE';
      this.Ordre =true;
      break;
    case '1624':
      this.StudentCode = 'KABANGU KANDA';
      this.Ordre =true;
      break;
    case '1629':
      this.StudentCode = 'CHAMI KARIN';
      this.Ordre =true;
      break;
    case '1630':
      this.StudentCode = 'DEVOS OLIVIER';
      this.Ordre =true;
      break;
    case '1631':
      this. StudentCode = 'HERVE MBENGA LIKITA';
      this.Ordre =true;
      break;
      case '1634':
        this.StudentCode = 'BEYA SIMON';
        this.Ordre =true;
        break;
      case '1635':
        this.StudentCode = 'BORGUGNONS CASSANDRA';
        this.Ordre =true;
        break;
      case '1641':
        this.StudentCode = 'DE ROSEN MELISSA';
        this.Ordre =false;
        break;
      case '1644':
        this.StudentCode = 'DELAET CEDRIC';
        this.Ordre =true;
        break;
      case '1645':
        this.StudentCode = 'DILU CHRISTELLE';
        this.Ordre =true;
        break;
      case '1646':
        this.StudentCode = 'FAWAZ SORAYA';
        this.Ordre =true;
        break;
      case '1648':
        this.StudentCode = 'GREGOIRE CLEMENT';
        this.Ordre =true;
      case '1654':
        this.StudentCode = 'HANSON AMELIE';
        this.Ordre =true;
        break;
      case '1659':
        this.StudentCode = 'ILUNGA SUAREZ MIREILLE';
        this.Ordre =false;
        break;
      case '1661':
        this.StudentCode = 'KAKUDJI RACHEL';
        this.Ordre =true;
        break;
      case '1671':
        this.StudentCode = 'KELE BENOIT';
        this.Ordre =true;
        break;
      case '1674':
        this.StudentCode = 'KIADI KATIA';
        this.Ordre =true;
        break;
      case '1675':
        this.StudentCode = 'LECOQ QUENTIN';
        this.Ordre =true;
        break;
      case '1676':
        this.StudentCode = 'MAES DAMIEN';
        this.Ordre =true;
        break;
      case '1677':
        this.StudentCode = 'MARION LAURENCE';
        this.Ordre =true;
        break;
      case '1680':
        this.StudentCode = 'MATETA PAGUY';
        this.Ordre =true;
        break;
      case '1682':
        this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
        this.Ordre =true;
        break;
      case '1691':
        this.StudentCode = 'MERLE KATY';
        this.Ordre =true;
        break;
      case '1694':
        this.StudentCode = 'MEYERS MARIE';
        this.Ordre =true;
        break;
      case '1704':
        this.StudentCode = 'MISTIAEN CHRISTOPHER';
        this.Ordre =false;
        break;
      case '1708':
        this.StudentCode= 'MWEPU BROS CHARLENE';
        this.Ordre =false;
        break;
      case '1712':
        this.StudentCode = 'NOTERMAN ANNIE';
        this.Ordre =true;
        break;
      case '1714':
        this.StudentCode = 'NZUZI YVON';
        this.Ordre =true;
        break;
      case '1715':
        this.StudentCode = 'OLIVA ANNABELLE';
        this.Ordre =true;
        break;
      case '1719':
        this.StudentCode = 'PEDUZY ALICE';
        this.Ordre =true;
        break;
      case '1723':
        this.StudentCode = 'PETTEAU STEEVE';
        this.Ordre =true;
        break;
      case '1724':
        this.StudentCode = 'RICHARD ROMAIN';
        this.Ordre =true;
        break;
      case '1734':
        this.StudentCode = 'SUMAILI ARTHUR';
        this.Ordre =true;
        break;
      case '1737':
        this.StudentCode = 'THORP WILLIAM';
        this.Ordre =true;
        break;
      case '1740':
        this.StudentCode = 'TOMBU CHANTAL';
        this.Ordre =true;
        break;
      case '1743':
        this.StudentCode = 'ZANGERLE GILLES';
        this.Ordre =true;
        break;
      case '1746':
        this.StudentCode = 'COLSON SARAH';
        this.Ordre =true;
        break;
      case '1752':
        this. StudentCode = 'ILYNE CHARLOTTE';
        this.Ordre =true;
        break;
      case '1753':
        this.StudentCode = 'DETHY LAURE';
        this.Ordre =true;
        break;
      case '1755':
        this.StudentCode = 'SIMOES ISABEL';
        this.Ordre =true;
        break;
      case '1757':
        this. StudentCode = 'PARDAENS RITA';
        this.Ordre =true;
        break;
      case '1758':
        this.StudentCode = 'CASTELA CERRO DELPHINE';
        this.Ordre =false;
        break;
      case '1759':
        this. StudentCode = 'BOSCO CHRISTINA';
        this.Ordre =false;
        break;
      case '1764':
        this.StudentCode = 'CHAUDRON MAÏTE';
        this.Ordre =true;
        break;
      case '1770':
        this. StudentCode = 'BOURSAS NADIA';
        this.Ordre =true;
       break;
      case '1771':
        this.StudentCode = 'IKETE JESSICA';
        this.Ordre =true;
        break;
      case '1778':
        this. StudentCode = 'MATHY ASTRID ';
        this.Ordre =true;
        break;
      case '1781':
        this. StudentCode = 'MUNGA MURIEL';
        this.Ordre =true;
        break;
      case '1792':
        this. StudentCode = 'THUMAS ANNOUCK';
        this.Ordre =true;
        break;
      case '1801':
        this.StudentCode = 'BOUX SOPHIE';
        this.Ordre =true;
        break;
      case '1803':
        this.StudentCode = 'RAHIER VERONIQUE';
        this.Ordre =true;
        break;
      case '1819':
        this.StudentCode = 'DEBIESME ALEXANDRA';
        this.Ordre =true;
        break;
      case '1820':
        this.StudentCode = 'RIGUELLE SARAH';
        this.Ordre =true;
        break;
      case '1823':
        this.StudentCode = 'LIVRIZZI MARIA';
        this.Ordre =true;
        break;
      case '1829':
        this.StudentCode = 'ANDREANI ANIE';
        this.Ordre =true;
        break;
      case '1832':
        this.StudentCode = 'LAMBERT MAXIME';
        this.Ordre =true;
        break;
      case '1844':
        this.StudentCode = 'CHARLIER SOPHIE';
        this.Ordre =true;
        break;
      case '1851':
        this.StudentCode = 'DURIEZ ALEXANDRE';
        this.Ordre =true;
        break;
      case '1856':
        this.StudentCode = 'BORON KILLIAN';
        this.Ordre =true;
        break;
      case '1857':
        this.StudentCode = 'LEBEAU MARIE-PAULE';
        this.Ordre =true;
        break;
      case '1859':
        this.StudentCode = 'PREGARDIEN QUENTIN';
        this.Ordre =true;
        break;
      case '1860':
        this.StudentCode = 'BOUZOUITA LAETITIA';
        this.Ordre =true;
        break;
      case '1862':
        this.StudentCode = 'NDRIN NAOMI';
        this.Ordre =true;
        break;
      case '1866':
        this.StudentCode = 'AGNEESSENS SUAD';
        this.Ordre =true;
        break;
      case '1867':
        this.StudentCode = 'BOUVIER FREDERIC';
        this.Ordre =true;
        break;
      case '1869':
        this.StudentCode = 'MOUSSEBOIS VIRGINIE';
        this.Ordre =true;
        break;
      case '1870':
        this.StudentCode = 'NOTTERMAN ANNIE';
        this.Ordre =true;
        break;
      case '1873':
        this.StudentCode = 'UMBA YVETTE';
        this.Ordre =true;
        break;
      case '1875':
        this.StudentCode = 'KABONGO GINETTE';
        this.Ordre =true;
        break;
      case '1876':
        this. StudentCode = 'LEBENI ILLA';
        this.Ordre =true;
        break;
      case '1877':
        this. StudentCode = 'VERBRAEKEN AMELIE';
        this.Ordre =true;
        break;
      case '1882':
        this.StudentCode = 'KABANGU KANDA';
        this.Ordre =true;
        break;
      case '1889':
        this.StudentCode = 'CHAMI KARIN';
        this.Ordre =true;
        break;
      case '1890':
        this. StudentCode = 'DEVOS OLIVIER';
        this.Ordre =true;
        break;
      case '1893':
        this.StudentCode = 'HERVE MBENGA LIKITA';
        this.Ordre =true;
        break;
      case '1897':
        this.StudentCode = 'IKETE JESSICA';
        this.Ordre =true;
        break;
      case '1903':
        this.StudentCode = 'MATHY ASTRID ';
        this.Ordre =true;
        break;
      case '1907':
        this.StudentCode = 'MUNGA MURIEL';
        this.Ordre =false;
        break;
      case '1909':
        this.StudentCode = 'THUMAS ANNOUCK';
        this.Ordre =true;
        break;
      case '1912':
        this.StudentCode = 'BOUX SOPHIE';
        this.Ordre =true;
        break;
      case '1916':
        this.StudentCode = 'RAHIER VERONIQUE';
        this.Ordre =true;
        break;
      case '1917':
        this.StudentCode = 'DEBIESME ALEXANDRA';
        this.Ordre =false;
        break;
      case '1918':
        this.StudentCode = 'RIGUELLE SARAH';
        this.Ordre =true;
        break;
      case '1925':
        this.StudentCode = 'LIVRIZZI MARIA';
        this.Ordre =true;
        break;
      case '1927':
        this.StudentCode = 'ANDREANI ANIE';
        this.Ordre =true;
        break;
      case '1937':
        this.StudentCode = 'LAMBERT MAXIME';
        this.Ordre =true;
        break;
      case '1938':
        this. StudentCode = 'CHARLIER SOPHIE';
        this.Ordre =true;
        break;
      case '1939':
        this.StudentCode = 'DURIEZ ALEXANDRE';
        this.Ordre =true;
        break;
      case '1944':
        this.StudentCode = 'BORON KILLIAN';
        this.Ordre =true;
        break;
      case '1950':
        this.StudentCode = 'LEBEAU MARIE-PAULE';
        this.Ordre =true;
        break;
      case '1955':
        this.StudentCode = 'PREGARDIEN QUENTIN';
        this.Ordre =true;
        break;
      case '1956':
        this.StudentCode = 'BOUZOUITA LAETITIA';
        this.Ordre =true;
        break;
      case '1957':
        this. StudentCode = 'NDRIN NAOMI';
        this.Ordre =true;
        break;
      case '1964':
        this.StudentCode = 'AGNEESSENS SUAD';
        this.Ordre =true;
        break;
      case '1966':
        this.StudentCode = 'BOUVIER FREDERIC';
        this.Ordre =false;
        break;
      case '1977':
        this. StudentCode = 'MOUSSEBOIS VIRGINIE';
        this.Ordre =true;
        break;
      case '1979':
        this. StudentCode = 'NOTTERMAN ANNIE';
        this.Ordre =false;
        break;
      case '1982':
        this. StudentCode = 'UMBA YVETTE';
        this.Ordre =true;
        break;
      case '1986':
        this.StudentCode = 'KABONGO GINETTE';
        this.Ordre =true;
        break;
      case '1987':
        this.StudentCode = 'LEBENI ILLA';
        this.Ordre =true;
        break;
      case '1997':
        this.StudentCode = 'VERBRAEKEN AMELIE';
        this.Ordre =true;
        break;
      case '1998':
        this. StudentCode = 'KABANGU KANDA';
        this.Ordre =true;
        break;
      case '2001':
        this.StudentCode = 'CHAMI KARIN';
        this.Ordre =true;
        break;
      case '2006':
        this.StudentCode = 'DEVOS OLIVIER';
        this.Ordre =true;
        break;
      case '2007':
        this.StudentCode = 'HERVE MBENGA LIKITA';
        this.Ordre =true;
        break;
        case '2009':
          this. StudentCode = 'BEYA SIMON';
          this.Ordre =true;
          break;
        case '2010':
          this. StudentCode = 'BORGUGNONS CASSANDRA';
          this.Ordre =true;
          break;
        case '2016':
          this. StudentCode = 'DE ROSEN MELISSA';
          this.Ordre =true;
          break;
        case '2017':
          this.StudentCode = 'DELAET CEDRIC';
          this.Ordre =true;
          break;
        case '2022':
          this. StudentCode = 'DILU CHRISTELLE';
          this.Ordre =true;
          break;
        case '2023':
          this.StudentCode = 'FAWAZ SORAYA';
          this.Ordre =true;
          break;
        case '2027':
          this.StudentCode = 'GREGOIRE CLEMENT';
          this.Ordre =false;
        case '2028':
          this.StudentCode = 'HANSON AMELIE';
          this.Ordre =true;
          break;
        case '2030':
          this. StudentCode = 'ILUNGA SUAREZ MIREILLE';
          this.Ordre =true;
          break;
        case '2031':
          this.StudentCode = 'KAKUDJI RACHEL';
          this.Ordre =true;
          break;
        case '2038':
          this.StudentCode = 'KELE BENOIT';
          this.Ordre =true;
          break;
        case '2040':
          this.StudentCode = 'KIADI KATIA';
          this.Ordre =false;
          break;
        case '2041':
          this.StudentCode = 'LECOQ QUENTIN';
          this.Ordre =true;
          break;
        case '2042':
          this.StudentCode = 'MAES DAMIEN';
          this.Ordre =false;
          break;
        case '2049':
          this.StudentCode = 'MARION LAURENCE';
          this.Ordre =true;
          break;
        case '2051':
          this.StudentCode = 'MATETA PAGUY';
          this.Ordre =true;
          break;
        case '2061':
          this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
          this.Ordre =true;
          break;
        case '2064':
          this. StudentCode = 'MERLE KATY';
          this.Ordre =true;
          break;
        case '2065':
          this.StudentCode = 'MEYERS MARIE';
          this.Ordre =true;
          break;
        case '2068':
          this.StudentCode = 'MISTIAEN CHRISTOPHER';
          this.Ordre =true;
          break;
        case '2069':
          this.StudentCode= 'MWEPU BROS CHARLENE';
          this.Ordre =true;
          break;
        case '2071':
          this.StudentCode = 'NOTERMAN ANNIE';
          this.Ordre =true;
          break;
        case '2072':
          this. StudentCode = 'NZUZI YVON';
          this.Ordre =true;
          break;
        case '2075':
          this.StudentCode = 'OLIVA ANNABELLE';
          this.Ordre =true;
          break;
        case '2077':
          this.StudentCode = 'PEDUZY ALICE';
          this.Ordre =true;
          break;
        case '2081':
          this.StudentCode = 'PETTEAU STEEVE';
          this.Ordre =true;
          break;
        case '2089':
          this.StudentCode = 'RICHARD ROMAIN';
          this.Ordre =true;
          break;
        case '2095':
          this.StudentCode = 'SUMAILI ARTHUR';
          this.Ordre =false;
          break;
        case '2098':
          this.StudentCode = 'THORP WILLIAM';
          this.Ordre =true;
          break;
        case '2103':
          this.StudentCode = 'TOMBU CHANTAL';
          this.Ordre =true;
          break;
        case '2105':
          this. StudentCode = 'ZANGERLE GILLES';
          this.Ordre =true;
          break;
        case '2106':
          this. StudentCode = 'COLSON SARAH';
          this.Ordre =true;
          break;
        case '2107':
          this.StudentCode = 'ILYNE CHARLOTTE';
          this.Ordre =true;
          break;
        case '2108':
          this.StudentCode = 'DETHY LAURE';
          this.Ordre =false;
          break;
        case '2109':
          this.StudentCode = 'SIMOES ISABEL';
          this.Ordre =true;
          break;
        case '2114':
          this. StudentCode = 'PARDAENS RITA';
          this.Ordre =true;
          break;
        case '2115':
          this. StudentCode = 'CASTELA CERRO DELPHINE';
          this.Ordre =true;
          break;
        case '2117':
          this.StudentCode = 'BOSCO CHRISTINA';
          this.Ordre =false;
          break;
        case '2118':
          this. StudentCode = 'CHAUDRON MAÏTE';
          this.Ordre =true;
          break;
        case '2120':
          this. StudentCode = 'BOURSAS NADIA';
          this.Ordre =true;
         break;
        case '2121':
          this.StudentCode = 'IKETE JESSICA';
          this.Ordre =true;
          break;
        case '2124':
          this.StudentCode = 'MATHY ASTRID ';
          this.Ordre =true;
          break;
        case '2125':
          this.StudentCode = 'MUNGA MURIEL';
          this.Ordre =true;
          break;
        case '2127':
          this.StudentCode = 'THUMAS ANNOUCK';
          this.Ordre =true;
          break;
        case '2129':
          this. StudentCode = 'BOUX SOPHIE';
          this.Ordre =true;
          break;
        case '2131':
          this.StudentCode = 'RAHIER VERONIQUE';
          this.Ordre =true;
          break;
        case '2134':
          this.StudentCode = 'DEBIESME ALEXANDRA';
          this.Ordre =true;
          break;
        case '2135':
          this.StudentCode = 'RIGUELLE SARAH';
          this.Ordre =true;
          break;
        case '2136':
          this.StudentCode = 'LIVRIZZI MARIA';
          this.Ordre =true;
          break;
        case '2138':
          this.StudentCode = 'ANDREANI ANIE';
          this.Ordre =false;
          break;
        case '2139':
          this. StudentCode = 'LAMBERT MAXIME';
          this.Ordre =true;
          break;
        case '2142':
          this.StudentCode = 'CHARLIER SOPHIE';
          this.Ordre =true;
          break;
        case '2145':
          this. StudentCode = 'DURIEZ ALEXANDRE';
          this.Ordre =true;
          break;
        case '2146':
          this.StudentCode = 'BORON KILLIAN';
          this.Ordre =true;
          break;
        case '2147':
          this.StudentCode = 'LEBEAU MARIE-PAULE';
          this.Ordre =true;
          break;
        case '2148':
          this.StudentCode = 'PREGARDIEN QUENTIN';
          this.Ordre =true;
          break;
        case '2149':
          this. StudentCode = 'BOUZOUITA LAETITIA';
          this.Ordre =true;
          break;
        case '2150':
          this.StudentCode = 'NDRIN NAOMI';
          this.Ordre =true;
          break;
        case '2155':
          this. StudentCode = 'AGNEESSENS SUAD';
          this.Ordre =true;
          break;
        case '2158':
          this. StudentCode = 'BOUVIER FREDERIC';
          this.Ordre =true;
          break;
        case '2159':
          this.StudentCode = 'MOUSSEBOIS VIRGINIE';
          this.Ordre =true;
          break;
        case '2160':
          this.StudentCode = 'NOTTERMAN ANNIE';
          this.Ordre =true;
          break;
        case '2162':
          this. StudentCode = 'UMBA YVETTE';
          this.Ordre =true;
          break;
        case '2166':
          this. StudentCode = 'KABONGO GINETTE';
          this.Ordre =true;
          break;
        case '2167':
          this. StudentCode = 'LEBENI ILLA';
          this.Ordre =false;
          break;
        case '2170':
          this.StudentCode = 'VERBRAEKEN AMELIE';
          this.Ordre =true;
          break;
        case '2176':
          this.StudentCode = 'KABANGU KANDA';
          this.Ordre =false;
          break;
        case '2177':
          this.StudentCode = 'CHAMI KARIN';
          this.Ordre =true;
          break;
        case '2178':
          this.StudentCode = 'DEVOS OLIVIER';
          this.Ordre =true;
          break;
        case '2183':
          this. StudentCode = 'HERVE MBENGA LIKITA';
          this.Ordre =true;
          case '2184':
            this.StudentCode = 'THUMAS ANNOUCK';
            this.Ordre =true;
            break;
          case '2185':
            this. StudentCode = 'BOUX SOPHIE';
            this.Ordre =true;
            break;
          case '2187':
            this.StudentCode = 'RAHIER VERONIQUE';
            this.Ordre =true;
            break;
          case '2188':
            this.StudentCode = 'DEBIESME ALEXANDRA';
            this.Ordre =true;
            break;
          case '2192':
            this.StudentCode = 'RIGUELLE SARAH';
            this.Ordre =true;
            break;
          case '2193':
            this.StudentCode = 'LIVRIZZI MARIA';
            this.Ordre =true;
            break;
          case '2200':
            this.StudentCode = 'ANDREANI ANIE';
            this.Ordre =true;
            break;
          case '2210':
            this. StudentCode = 'LAMBERT MAXIME';
            this.Ordre =true;
            break;
          case '2214':
            this.StudentCode = 'CHARLIER SOPHIE';
            this.Ordre =false;
            break;
          case '2216':
            this. StudentCode = 'DURIEZ ALEXANDRE';
            this.Ordre =true;
            break;
          case '2217':
            this.StudentCode = 'BORON KILLIAN';
            this.Ordre =false;
            break;
          case '2223':
            this.StudentCode = 'LEBEAU MARIE-PAULE';
            this.Ordre =true;
            break;
          case '2224':
            this.StudentCode = 'PREGARDIEN QUENTIN';
            this.Ordre =true;
            break;
          case '2225':
            this. StudentCode = 'BOUZOUITA LAETITIA';
            this.Ordre =true;
            break;
          case '2229':
            this.StudentCode = 'NDRIN NAOMI';
            this.Ordre =true;
            break;
          case '2237':
            this. StudentCode = 'AGNEESSENS SUAD';
            this.Ordre =true;
            break;
          case '2238':
            this. StudentCode = 'BOUVIER FREDERIC';
            this.Ordre =true;
            break;
          case '2239':
            this.StudentCode = 'MOUSSEBOIS VIRGINIE';
            this.Ordre =true;
            break;
          case '2240':
            this.StudentCode = 'NOTTERMAN ANNIE';
            this.Ordre =true;
            break;
          case '2244':
            this. StudentCode = 'UMBA YVETTE';
            this.Ordre =true;
            break;
          case '2245':
            this. StudentCode = 'KABONGO GINETTE';
            this.Ordre =true;
            break;
          case '2246':
            this. StudentCode = 'LEBENI ILLA';
            this.Ordre =true;
            break;
          case '2248':
            this.StudentCode = 'VERBRAEKEN AMELIE';
            this.Ordre =true;
            break;
          case '2249':
            this.StudentCode = 'KABANGU KANDA';
            this.Ordre =true;
            break;
          case '2250':
            this.StudentCode = 'CHAMI KARIN';
            this.Ordre =true;
            break;
          case '2252':
            this.StudentCode = 'DEVOS OLIVIER';
            this.Ordre =true;
            break;
          case '2254':
            this. StudentCode = 'HERVE MBENGA LIKITA';
            this.Ordre =true;
            break;
            case '2255':
              this.StudentCode = 'BEYA SIMON';
              this.Ordre =true;
              break;
            case '2256':
              this.StudentCode = 'BORGUGNONS CASSANDRA';
              this.Ordre =true;
              break;
            case '2258':
              this.StudentCode = 'DE ROSEN MELISSA';
              this.Ordre =true;
              break;
            case '2260':
              this.StudentCode = 'DELAET CEDRIC';
              this.Ordre =true;
              break;
            case '2261':
              this.StudentCode = 'DILU CHRISTELLE';
              this.Ordre =true;
              break;
            case '2262':
              this.StudentCode = 'FAWAZ SORAYA';
              this.Ordre =true;
              break;
            case '2264':
              this.StudentCode = 'GREGOIRE CLEMENT';
              this.Ordre =false;
            case '2265':
              this.StudentCode = 'HANSON AMELIE';
              this.Ordre =true;
              break;
            case '2267':
              this.StudentCode = 'ILUNGA SUAREZ MIREILLE';
              this.Ordre =true;
              break;
            case '2268':
              this.StudentCode = 'KAKUDJI RACHEL';
              this.Ordre =true;
              break;
            case '2271':
              this.StudentCode = 'KELE BENOIT';
              this.Ordre =true;
              break;
            case '2272':
              this.StudentCode = 'KIADI KATIA';
              this.Ordre =false;
              break;
            case '2273':
              this.StudentCode = 'LECOQ QUENTIN';
              this.Ordre =true;
              break;
            case '2275':
              this.StudentCode = 'MAES DAMIEN';
              this.Ordre =true;
              break;
            case '2276':
              this.StudentCode = 'MARION LAURENCE';
              this.Ordre =true;
              break;
            case '2277':
              this.StudentCode = 'MATETA PAGUY';
              this.Ordre =false;
              break;
            case '2279':
              this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
              this.Ordre =true;
              break;
            case '2281':
              this.StudentCode = 'MERLE KATY';
              this.Ordre =true;
              break;
            case '2282':
              this.StudentCode = 'MEYERS MARIE';
              this.Ordre =true;
              break;
            case '2284':
              this.StudentCode = 'MISTIAEN CHRISTOPHER';
              this.Ordre =true;
              break;
            case '2285':
              this.StudentCode= 'MWEPU BROS CHARLENE';
              this.Ordre =true;
              break;
            case '2286':
              this.StudentCode = 'NOTERMAN ANNIE';
              this.Ordre =true;
              break;
            case '2287':
              this.StudentCode = 'NZUZI YVON';
              this.Ordre =true;
              break;
            case '2288':
              this.StudentCode = 'OLIVA ANNABELLE';
              this.Ordre =true;
              break;
            case '2289':
              this.StudentCode = 'PEDUZY ALICE';
              this.Ordre =false;
              break;
            case '2290':
              this.StudentCode = 'PETTEAU STEEVE';
              this.Ordre =true;
              break;
            case '2291':
              this.StudentCode = 'RICHARD ROMAIN';
              this.Ordre =true;
              break;
            case '2292':
              this.StudentCode = 'SUMAILI ARTHUR';
              this.Ordre =true;
              break;
            case '2294':
              this.StudentCode = 'THORP WILLIAM';
              this.Ordre =true;
              break;
            case '2295':
              this.StudentCode = 'TOMBU CHANTAL';
              this.Ordre =false;
              break;
            case '2297':
              this.StudentCode = 'ZANGERLE GILLES';
              this.Ordre =true;
              break;
            case '2300':
              this.StudentCode = 'COLSON SARAH';
              this.Ordre =true;
              break;
            case '2302':
              this.StudentCode = 'ILYNE CHARLOTTE';
              this.Ordre =true;
              break;
            case '2305':
              this.StudentCode = 'DETHY LAURE';
              this.Ordre =true;
              break;
            case '2306':
              this.StudentCode = 'SIMOES ISABEL';
              this.Ordre =true;
              break;
            case '2307':
              this.StudentCode = 'PARDAENS RITA';
              this.Ordre =false;
              break;
            case '2309':
              this.StudentCode = 'CASTELA CERRO DELPHINE';
              this.Ordre =false;
              break;
            case '2310':
              this.StudentCode = 'BOSCO CHRISTINA';
              this.Ordre =true;
              break;
            case '2313':
              this.StudentCode = 'CHAUDRON MAÏTE';
              this.Ordre =true;
              break;
            case '2314':
              this.StudentCode = 'BOURSAS NADIA';
              this.Ordre =true;
             break;
            case '2315':
              this.StudentCode = 'IKETE JESSICA';
              this.Ordre =false;
              break;
            case '2317':
              this.StudentCode = 'MATHY ASTRID ';
              this.Ordre =true;
              break;
            case '2321':
              this.StudentCode = 'MUNGA MURIEL';
              this.Ordre =true;
              break;
            case '2327':
              this.StudentCode = 'THUMAS ANNOUCK';
              this.Ordre =true;
              break;
            case '2331':
              this. StudentCode = 'BOUX SOPHIE';
              this.Ordre =true;
              break;
            case '2336':
              this.StudentCode = 'RAHIER VERONIQUE';
              this.Ordre =true;
              break;
            case '2342':
              this.StudentCode = 'DEBIESME ALEXANDRA';
              this.Ordre =true;
              break;
            case '2344':
              this.StudentCode = 'RIGUELLE SARAH';
              this.Ordre =true;
              break;
            case '2346':
              this.StudentCode = 'LIVRIZZI MARIA';
              this.Ordre =false;
              break;
            case '2347':
              this.StudentCode = 'ANDREANI ANIE';
              this.Ordre =true;
              break;
            case '2349':
              this.StudentCode = 'LAMBERT MAXIME';
              this.Ordre =true;
              break;
            case '2350':
              this.StudentCode = 'CHARLIER SOPHIE';
              this.Ordre =true;
              break;
            case '2353':
              this.StudentCode = 'DURIEZ ALEXANDRE';
              this.Ordre =true;
              break;
            case '2355':
              this.StudentCode = 'BORON KILLIAN';
              this.Ordre =true;
              break;
            case '2356':
              this.StudentCode = 'LEBEAU MARIE-PAULE';
              this.Ordre =true;
              break;
            case '2357':
              this.StudentCode = 'PREGARDIEN QUENTIN';
              this.Ordre =true;
              break;
            case '2359':
              this.StudentCode = 'BOUZOUITA LAETITIA';
              this.Ordre =true;
              break;
            case '2362':
              this.StudentCode = 'NDRIN NAOMI';
              this.Ordre =true;
              break;
            case '2363':
              this.StudentCode = 'AGNEESSENS SUAD';
              this.Ordre =true;
              break;
            case '2364':
              this.StudentCode = 'BOUVIER FREDERIC';
              this.Ordre =true;
              break;
            case '2366':
              this. StudentCode = 'MOUSSEBOIS VIRGINIE';
              this.Ordre =true;
              break;
            case '2368':
              this.StudentCode = 'NOTTERMAN ANNIE';
              this.Ordre =true;
              break;
            case '2369':
              this.StudentCode = 'UMBA YVETTE';
              this.Ordre =true;
              break;
            case '2370':
              this.StudentCode = 'KABONGO GINETTE';
              this.Ordre =true;
              break;
            case '2371':
              this.StudentCode = 'LEBENI ILLA';
              this.Ordre =true;
              break;
            case '2372':
              this.StudentCode = 'VERBRAEKEN AMELIE';
              this.Ordre =true;
              break;
            case '2373':
              this.StudentCode = 'KABANGU KANDA';
              this.Ordre =true;
              break;
            case '2374':
              this.StudentCode = 'CHAMI KARIN';
              this.Ordre =true;
              break;
            case '2375':
              this.StudentCode = 'DEVOS OLIVIER';
              this.Ordre =true;
              break;
            case '2379':
              this.StudentCode = 'HERVE MBENGA LIKITA';
              this.Ordre =true;
              break;
            case '2380':
              this.StudentCode = 'IKETE JESSICA';
              this.Ordre =true;
              break;
            case '2382':
              this.StudentCode = 'MATHY ASTRID ';
              this.Ordre =true;
              break;
            case '2384':
              this.StudentCode = 'MUNGA MURIEL';
              this.Ordre =true;
              break;
            case '2385':
              this.StudentCode = 'THUMAS ANNOUCK';
              this.Ordre =true;
              break;
            case '2386':
              this.StudentCode = 'BOUX SOPHIE';
              this.Ordre =true;
              break;
            case '2387':
              this.StudentCode = 'RAHIER VERONIQUE';
              this.Ordre =true;
              break;
            case '2389':
              this.StudentCode = 'DEBIESME ALEXANDRA';
              this.Ordre =true;
              break;
            case '2391':
              this.StudentCode = 'RIGUELLE SARAH';
              this.Ordre =true;
              break;
            case '2394':
              this.StudentCode = 'LIVRIZZI MARIA';
              this.Ordre =true;
              break;
            case '2395':
              this.StudentCode = 'ANDREANI ANIE';
              this.Ordre =true;
              break;
            case '2396':
              this.StudentCode = 'LAMBERT MAXIME';
              this.Ordre =true;
              break;
            case '2398':
              this.StudentCode = 'CHARLIER SOPHIE';
              this.Ordre =true;
              break;
            case '2401':
              this.StudentCode = 'DURIEZ ALEXANDRE';
              this.Ordre =true;
              break;
            case '2405':
              this.StudentCode = 'BORON KILLIAN';
              this.Ordre =true;
              break;
            case '2406':
              this.StudentCode = 'LEBEAU MARIE-PAULE';
              this.Ordre =true;
              break;
            case '2409':
              this.StudentCode = 'PREGARDIEN QUENTIN';
              this.Ordre =false;
              break;
            case '2410':
              this.StudentCode = 'BOUZOUITA LAETITIA';
              this.Ordre =true;
              break;
            case '2411':
              this. StudentCode = 'NDRIN NAOMI';
              this.Ordre =true;
              break;
            case '2412':
              this. StudentCode = 'AGNEESSENS SUAD';
              this.Ordre =true;
              break;
            case '2415':
              this.StudentCode = 'BOUVIER FREDERIC';
              this.Ordre =true;
              break;
            case '2418':
              this.StudentCode = 'MOUSSEBOIS VIRGINIE';
              this.Ordre =true;
              break;
            case '2420':
              this.StudentCode = 'NOTTERMAN ANNIE';
              this.Ordre =true;
              break;
            case '2421':
              this.StudentCode = 'UMBA YVETTE';
              this.Ordre =true;
              break;
            case '2423':
              this.StudentCode = 'KABONGO GINETTE';
              this.Ordre =false;
              break;
            case '2427':
              this.StudentCode = 'LEBENI ILLA';
              this.Ordre =true;
              break;
            case '2431':
              this. StudentCode = 'VERBRAEKEN AMELIE';
              this.Ordre =true;
              break;
            case '2433':
              this.StudentCode = 'KABANGU KANDA';
              this.Ordre =true;
              break;
            case '2434':
              this.StudentCode = 'CHAMI KARIN';
              this.Ordre =true;
              break;
            case '2437':
              this.StudentCode = 'DEVOS OLIVIER';
              this.Ordre =true;
              break;
            case '2439':
              this. StudentCode = 'HERVE MBENGA LIKITA';
              this.Ordre =true;
              break;
              case '2441':
                this.StudentCode = 'BEYA SIMON';
                this.Ordre =true;
                break;
              case '2442':
                this.StudentCode = 'BORGUGNONS CASSANDRA';
                this.Ordre =true;
                break;
              case '2444':
                this.StudentCode = 'DE ROSEN MELISSA';
                this.Ordre =true;
                break;
              case '2445':
                this.StudentCode = 'DELAET CEDRIC';
                this.Ordre =true;
                break;
              case '2446':
                this.StudentCode = 'DILU CHRISTELLE';
                this.Ordre =true;
                break;
              case '2452':
                this.StudentCode = 'FAWAZ SORAYA';
                this.Ordre =true;
                break;
              case '2453':
                this.StudentCode = 'GREGOIRE CLEMENT';
                this.Ordre =true;
              case '2455':
                this.StudentCode = 'HANSON AMELIE';
                this.Ordre =true;
                break;
              case '2456':
                this.StudentCode = 'ILUNGA SUAREZ MIREILLE';
                this.Ordre =true;
                break;
              case '2457':
                this.StudentCode = 'KAKUDJI RACHEL';
                this.Ordre =true;
                break;
              case '2459':
                this.StudentCode = 'KELE BENOIT';
                this.Ordre =true;
                break;
              case '2460':
                this.StudentCode = 'KIADI KATIA';
                this.Ordre =true;
                break;
              case '2461':
                this.StudentCode = 'LECOQ QUENTIN';
                this.Ordre =true;
                break;
              case '2463':
                this.StudentCode = 'MAES DAMIEN';
                this.Ordre =true;
                break;
              case '2464':
                this.StudentCode = 'MARION LAURENCE';
                this.Ordre =true;
                break;
              case '2465':
                this.StudentCode = 'MATETA PAGUY';
                this.Ordre =true;
                break;
              case '2466':
                this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
                this.Ordre =true;
                break;
              case '2467':
                this.StudentCode = 'MERLE KATY';
                this.Ordre =true;
                break;
              case '2468':
                this.StudentCode = 'MEYERS MARIE';
                this.Ordre =true;
                break;
              case '2469':
                this.StudentCode = 'MISTIAEN CHRISTOPHER';
                this.Ordre =true;
                break;
              case '2471':
                this.StudentCode= 'MWEPU BROS CHARLENE';
                this.Ordre =true;
                break;
              case '2472':
                this.StudentCode = 'NOTERMAN ANNIE';
                this.Ordre =false;
                break;
              case '2473':
                this.StudentCode = 'NZUZI YVON';
                this.Ordre =true;
                break;
              case '2474':
                this.StudentCode = 'OLIVA ANNABELLE';
                this.Ordre =true;
                break;
              case '2476':
                this.StudentCode = 'PEDUZY ALICE';
                this.Ordre =true;
                break;
              case '2477':
                this.StudentCode = 'PETTEAU STEEVE';
                this.Ordre =true;
                break;
              case '2478':
                this.StudentCode = 'RICHARD ROMAIN';
                this.Ordre =true;
                break;
              case '2480':
                this.StudentCode = 'SUMAILI ARTHUR';
                this.Ordre =true;
                break;
              case '2481':
                this.StudentCode = 'THORP WILLIAM';
                this.Ordre =false;
                break;
              case '2483':
                this.StudentCode = 'TOMBU CHANTAL';
                this.Ordre =true;
                break;
              case '2484':
                this.StudentCode = 'ZANGERLE GILLES';
                this.Ordre =true;
                break;
              case '2485':
                this.StudentCode = 'COLSON SARAH';
                this.Ordre =true;
                break;
              case '2486':
                this. StudentCode = 'ILYNE CHARLOTTE';
                this.Ordre =true;
                break;
              case '2488':
                this.StudentCode = 'DETHY LAURE';
                this.Ordre =true;
                break;
              case '2489':
                this.StudentCode = 'SIMOES ISABEL';
                this.Ordre =true;
                break;
              case '2491':
                this. StudentCode = 'PARDAENS RITA';
                this.Ordre =true;
                break;
              case '2492':
                this.StudentCode = 'CASTELA CERRO DELPHINE';
                this.Ordre =false;
                break;
              case '2493':
                this. StudentCode = 'BOSCO CHRISTINA';
                this.Ordre =true;
                break;
              case '2494':
                this.StudentCode = 'CHAUDRON MAÏTE';
                this.Ordre =true;
                break;
              case '2496':
                this. StudentCode = 'BOURSAS NADIA';
                this.Ordre =true;
               break;
              case '2497':
                this.StudentCode = 'IKETE JESSICA';
                this.Ordre =true;
                break;
              case '2498':
                this. StudentCode = 'MATHY ASTRID ';
                this.Ordre =true;
                break;
              case '2499':
                this. StudentCode = 'MUNGA MURIEL';
                this.Ordre =true;
                break;
              case '2500':
                this. StudentCode = 'THUMAS ANNOUCK';
                this.Ordre =true;
                break;
              case '2501':
                this.StudentCode = 'BOUX SOPHIE';
                this.Ordre =true;
                break;
              case '2502':
                this.StudentCode = 'RAHIER VERONIQUE';
                this.Ordre =true;
                break;
              case '2503':
                this.StudentCode = 'DEBIESME ALEXANDRA';
                this.Ordre =true;
                break;
              case '2505':
                this.StudentCode = 'RIGUELLE SARAH';
                this.Ordre =true;
                break;
              case '2506':
                this.StudentCode = 'LIVRIZZI MARIA';
                this.Ordre =true;
                break;
              case '2507':
                this.StudentCode = 'ANDREANI ANIE';
                this.Ordre =true;
                break;
              case '2508':
                this.StudentCode = 'LAMBERT MAXIME';
                this.Ordre =true;
                break;
              case '2510':
                this.StudentCode = 'CHARLIER SOPHIE';
                this.Ordre =true;
                break;
              case '2512':
                this.StudentCode = 'DURIEZ ALEXANDRE';
                this.Ordre =false;
                break;
              case '2514':
                this.StudentCode = 'BORON KILLIAN';
                this.Ordre =true;
                break;
              case '2515':
                this.StudentCode = 'LEBEAU MARIE-PAULE';
                this.Ordre =false;
                break;
              case '2516':
                this.StudentCode = 'PREGARDIEN QUENTIN';
                this.Ordre =true;
                break;
              case '2517':
                this.StudentCode = 'BOUZOUITA LAETITIA';
                this.Ordre =true;
                break;
              case '2519':
                this.StudentCode = 'NDRIN NAOMI';
                this.Ordre =true;
                break;
              case '2522':
                this.StudentCode = 'AGNEESSENS SUAD';
                this.Ordre =true;
                break;
              case '2524':
                this.StudentCode = 'BOUVIER FREDERIC';
                this.Ordre =true;
                break;
              case '2525':
                this.StudentCode = 'MOUSSEBOIS VIRGINIE';
                this.Ordre =true;
                break;
              case '2526':
                this.StudentCode = 'NOTTERMAN ANNIE';
                this.Ordre =true;
                break;
              case '2527':
                this.StudentCode = 'UMBA YVETTE';
                this.Ordre =true;
                break;
              case '2530':
                this.StudentCode = 'KABONGO GINETTE';
                this.Ordre =true;
                break;
              case '2533':
                this. StudentCode = 'LEBENI ILLA';
                this.Ordre =true;
                break;
              case '2536':
                this. StudentCode = 'VERBRAEKEN AMELIE';
                this.Ordre =true;
                break;
              case '2537':
                this.StudentCode = 'KABANGU KANDA';
                this.Ordre =true;
                break;
              case '2538':
                this.StudentCode = 'CHAMI KARIN';
                this.Ordre =true;
                break;
              case '2539':
                this. StudentCode = 'DEVOS OLIVIER';
                this.Ordre =true;
                break;
              case '2543':
                this.StudentCode = 'HERVE MBENGA LIKITA';
                this.Ordre =true;
                break;
              case '2544':
                this.StudentCode = 'IKETE JESSICA';
                this.Ordre =true;
                break;
              case '2547':
                this.StudentCode = 'MATHY ASTRID ';
                this.Ordre =true;
                break;
              case '2548':
                this.StudentCode = 'MUNGA MURIEL';
                this.Ordre =true;
                break;
              case '2550':
                this.StudentCode = 'THUMAS ANNOUCK';
                this.Ordre =true;
                break;
              case '2552':
                this.StudentCode = 'BOUX SOPHIE';
                this.Ordre =true;
                break;
              case '2553':
                this.StudentCode = 'RAHIER VERONIQUE';
                this.Ordre =false;
                break;
              case '2554':
                this.StudentCode = 'DEBIESME ALEXANDRA';
                this.Ordre =true;
                break;
              case '2555':
                this.StudentCode = 'RIGUELLE SARAH';
                this.Ordre =true;
                break;
              case '2558':
                this.StudentCode = 'LIVRIZZI MARIA';
                this.Ordre =true;
                break;
              case '2561':
                this.StudentCode = 'ANDREANI ANIE';
                this.Ordre =true;
                break;
              case '2562':
                this.StudentCode = 'LAMBERT MAXIME';
                this.Ordre =true;
                break;
              case '2563':
                this. StudentCode = 'CHARLIER SOPHIE';
                this.Ordre =true;
                break;
              case '2565':
                this.StudentCode = 'DURIEZ ALEXANDRE';
                this.Ordre =true;
                break;
              case '2567':
                this.StudentCode = 'BORON KILLIAN';
                this.Ordre =true;
                break;
              case '2568':
                this.StudentCode = 'LEBEAU MARIE-PAULE';
                this.Ordre =true;
                break;
              case '2570':
                this.StudentCode = 'PREGARDIEN QUENTIN';
                this.Ordre =false;
                break;
              case '2571':
                this.StudentCode = 'BOUZOUITA LAETITIA';
                this.Ordre =true;
                break;
              case '2572':
                this. StudentCode = 'NDRIN NAOMI';
                this.Ordre =true;
                break;
              case '2573':
                this.StudentCode = 'AGNEESSENS SUAD';
                this.Ordre =true;
                break;
              case '2574':
                this.StudentCode = 'BOUVIER FREDERIC';
                this.Ordre =false;
                break;
              case '2575':
                this. StudentCode = 'MOUSSEBOIS VIRGINIE';
                this.Ordre =true;
                break;
              case '2576':
                this. StudentCode = 'NOTTERMAN ANNIE';
                this.Ordre =true;
                break;
              case '2577':
                this. StudentCode = 'UMBA YVETTE';
                this.Ordre =true;
                break;
              case '2578':
                this.StudentCode = 'KABONGO GINETTE';
                this.Ordre =true;
                break;
              case '2581':
                this.StudentCode = 'LEBENI ILLA';
                this.Ordre =true;
                break;
              case '2583':
                this.StudentCode = 'VERBRAEKEN AMELIE';
                this.Ordre =true;
                break;
              case '2584':
                this. StudentCode = 'KABANGU KANDA';
                this.Ordre =true;
                break;
              case '2587':
                this.StudentCode = 'CHAMI KARIN';
                this.Ordre =true;
                break;
              case '2588':
                this.StudentCode = 'DEVOS OLIVIER';
                this.Ordre =true;
                break;
              case '2589':
                this.StudentCode = 'HERVE MBENGA LIKITA';
                this.Ordre =true;
                break;
                case '2590':
                  this. StudentCode = 'BEYA SIMON';
                  this.Ordre =true;
                  break;
                case '2591':
                  this. StudentCode = 'BORGUGNONS CASSANDRA';
                  this.Ordre =true;
                  break;
                case '2592':
                  this. StudentCode = 'DE ROSEN MELISSA';
                  this.Ordre =true;
                  break;
                case '2595':
                  this.StudentCode = 'DELAET CEDRIC';
                  this.Ordre =true;
                  break;
                case '2596':
                  this. StudentCode = 'DILU CHRISTELLE';
                  this.Ordre =true;
                  break;
                case '2597':
                  this.StudentCode = 'FAWAZ SORAYA';
                  this.Ordre =true;
                  break;
                case '2598':
                  this.StudentCode = 'GREGOIRE CLEMENT';
                  this.Ordre =true;
                case '2599':
                  this.StudentCode = 'HANSON AMELIE';
                  this.Ordre =true;
                  break;
                case '2602':
                  this. StudentCode = 'ILUNGA SUAREZ MIREILLE';
                  this.Ordre =true;
                  break;
                case '2604':
                  this.StudentCode = 'KAKUDJI RACHEL';
                  this.Ordre =true;
                  break;
                case '2605':
                  this.StudentCode = 'KELE BENOIT';
                  this.Ordre =true;
                  break;
                case '2606':
                  this.StudentCode = 'KIADI KATIA';
                  this.Ordre =true;
                  break;
                case '2607':
                  this.StudentCode = 'LECOQ QUENTIN';
                  this.Ordre =true;
                  break;
                case '2608':
                  this.StudentCode = 'MAES DAMIEN';
                  this.Ordre =true;
                  break;
                case '2609':
                  this.StudentCode = 'MARION LAURENCE';
                  this.Ordre =true;
                  break;
                case '2610':
                  this.StudentCode = 'MATETA PAGUY';
                  this.Ordre =true;
                  break;
                case '2611':
                  this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
                  this.Ordre =true;
                  break;
                case '2613':
                  this. StudentCode = 'MERLE KATY';
                  this.Ordre =true;
                  break;
                case '2614':
                  this.StudentCode = 'MEYERS MARIE';
                  this.Ordre =true;
                  break;
                case '2615':
                  this.StudentCode = 'MISTIAEN CHRISTOPHER';
                  this.Ordre =true;
                  break;
                case '2616':
                  this.StudentCode= 'MWEPU BROS CHARLENE';
                  this.Ordre =true;
                  break;
                case '2617':
                  this.StudentCode = 'NOTERMAN ANNIE';
                  this.Ordre =true;
                  break;
                case '2619':
                  this. StudentCode = 'NZUZI YVON';
                  this.Ordre =true;
                  break;
                case '2621':
                  this.StudentCode = 'OLIVA ANNABELLE';
                  this.Ordre =true;
                  break;
                case '2622':
                  this.StudentCode = 'PEDUZY ALICE';
                  this.Ordre =true;
                  break;
                case '2626':
                  this.StudentCode = 'PETTEAU STEEVE';
                  this.Ordre =true;
                  break;
                case '2627':
                  this.StudentCode = 'RICHARD ROMAIN';
                  this.Ordre =true;
                  break;
                case '2631':
                  this.StudentCode = 'SUMAILI ARTHUR';
                  this.Ordre =true;
                  break;
                case '2632':
                  this.StudentCode = 'THORP WILLIAM';
                  this.Ordre =true;
                  break;
                case '2634':
                  this.StudentCode = 'TOMBU CHANTAL';
                  this.Ordre =true;
                  break;
                case '2636':
                  this. StudentCode = 'ZANGERLE GILLES';
                  this.Ordre =true;
                  break;
                case '2637':
                  this. StudentCode = 'COLSON SARAH';
                  this.Ordre =true;
                  break;
                case '2638':
                  this.StudentCode = 'ILYNE CHARLOTTE';
                  this.Ordre =true;
                  break;
                case '2639':
                  this.StudentCode = 'DETHY LAURE';
                  this.Ordre =true;
                  break;
                case '2641':
                  this.StudentCode = 'SIMOES ISABEL';
                  this.Ordre =true;
                  break;
                case '2644':
                  this. StudentCode = 'PARDAENS RITA';
                  this.Ordre =true;
                  break;
                case '2645':
                  this. StudentCode = 'CASTELA CERRO DELPHINE';
                  this.Ordre =true;
                  break;
                case '2646':
                  this.StudentCode = 'BOSCO CHRISTINA';
                  this.Ordre =false;
                  break;
                case '2648':
                  this. StudentCode = 'CHAUDRON MAÏTE';
                  this.Ordre =true;
                  break;
                case '2649':
                  this. StudentCode = 'BOURSAS NADIA';
                  this.Ordre =true;
                 break;
                case '2650':
                  this.StudentCode = 'IKETE JESSICA';
                  this.Ordre =true;
                  break;
                case '2651':
                  this.StudentCode = 'MATHY ASTRID ';
                  this.Ordre =true;
                  break;
                case '2652':
                  this.StudentCode = 'MUNGA MURIEL';
                  this.Ordre =true;
                  break;
                case '2653':
                  this.StudentCode = 'THUMAS ANNOUCK';
                  this.Ordre =true;
                  break;
                case '2654':
                  this. StudentCode = 'BOUX SOPHIE';
                  this.Ordre =true;
                  break;
                case '2656':
                  this.StudentCode = 'RAHIER VERONIQUE';
                  this.Ordre =true;
                  break;
                case '2658':
                  this.StudentCode = 'DEBIESME ALEXANDRA';
                  this.Ordre =true;
                  break;
                case '2661':
                  this.StudentCode = 'RIGUELLE SARAH';
                  this.Ordre =true;
                  break;
                case '2663':
                  this.StudentCode = 'LIVRIZZI MARIA';
                  this.Ordre =true;
                  break;
                case '2664':
                  this.StudentCode = 'ANDREANI ANIE';
                  this.Ordre =true;
                  break;
                case '2665':
                  this. StudentCode = 'LAMBERT MAXIME';
                  this.Ordre =true;
                  break;
                case '2666':
                  this.StudentCode = 'CHARLIER SOPHIE';
                  this.Ordre =true;
                  break;
                case '2667':
                  this. StudentCode = 'DURIEZ ALEXANDRE';
                  this.Ordre =true;
                  break;
                case '2668':
                  this.StudentCode = 'BORON KILLIAN';
                  this.Ordre =true;
                  break;
                case '2669':
                  this.StudentCode = 'LEBEAU MARIE-PAULE';
                  this.Ordre =true;
                  break;
                case '2670':
                  this.StudentCode = 'PREGARDIEN QUENTIN';
                  this.Ordre =true;
                  break;
                case '2671':
                  this. StudentCode = 'BOUZOUITA LAETITIA';
                  this.Ordre =true;
                  break;
                case '2672':
                  this.StudentCode = 'NDRIN NAOMI';
                  this.Ordre =true;
                  break;
                case '2674':
                  this. StudentCode = 'AGNEESSENS SUAD';
                  this.Ordre =true;
                  break;
                case '2675':
                  this. StudentCode = 'BOUVIER FREDERIC';
                  this.Ordre =true;
                  break;
                case '2676':
                  this.StudentCode = 'MOUSSEBOIS VIRGINIE';
                  this.Ordre =true;
                  break;
                case '2679':
                  this.StudentCode = 'NOTTERMAN ANNIE';
                  this.Ordre =true;
                  break;
                case '2680':
                  this. StudentCode = 'UMBA YVETTE';
                  this.Ordre =true;               

         
                      }
                      console.log('Mon ID : ' + this.Ordre);
                      
                      if (!this.Ordre) {
                        
                  
                      } else {
                        this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le service de comptabilité pour régulariser votre compte';
                  
                      }
                  


  }
















}
