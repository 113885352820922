
<div class="row" data-ng-repeat="row in imageDataUrls" >
  <div class="col-sm-1" data-ng-repeat="imageDataUrl in row" >
      <img alt="img" class="img-responsive" src="assets/img/logo.png" width="300"/>
  </div>
  


    <div class="row">

      <div class="col-sm-4"><h3>Lycée Prince de Liège<b>(Digital-School)</b></h3>
        <h5>Kinshasa</h5></div>
      <div class="col-sm-4"></div>
         
      

      <div class="col-sm-6"  >
        
      </div>

    </div>

 
 

  


 
    <app-header></app-header>

    <div class="col-sm-1"  >
    
      <router-outlet></router-outlet>

    </div>
  

  <footer>
    <a href="https://www.hml-solution.com/" Target="_blank">&copy; 2023 - www.hml-solution.com</a>
  </footer>