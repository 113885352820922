import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './auth/signup/signup.component';
import { SigninComponent } from './auth/signin/signin.component';
import { BookListComponent } from './book-list/book-list.component';
import { SingleBookComponent } from './book-list/single-book/single-book.component';
import { BookFormComponent } from './book-list/book-form/book-form.component';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './services/auth.service';
import { BooksService } from './services/books.service';
import { AuthGuardService } from './services/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import * as firebase from 'firebase';
import { BookListStudentComponent } from './book-list-student/book-list-student.component';
import { SingleBookStudentComponent } from './book-list-student/single-book-student/single-book-student.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProfesseursService } from './services/professeurs.service'
import { ConstantsService } from './services/constants.service';
import { AuthsuppComponent } from './authsupp/authsupp.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { FlyingAnimalPipe } from './flying-animal.pipe';
import { FlyingAnimalNewPipe } from './flying-animal-new.pipe';
import { BookListStudentM1IComponent } from './book-list-student-m1-i/book-list-student-m1-i.component';
import { BookListStudentM1IIComponent } from './book-list-student-m1-ii/book-list-student-m1-ii.component';
import { FlyingAnimalStudentM1IPipe } from './flying-animal-student-m1-i.pipe';
import { FlyingAnimalStudentM1IIPipe } from './flying-animal-student-m1-ii.pipe';
import { BookListStudentM12Component } from './book-list-student-m12/book-list-student-m12.component';
import { BookListStudentM2IIComponent } from './book-list-student-m2-ii/book-list-student-m2-ii.component';
import { BookListStudentM3IComponent } from './book-list-student-m3-i/book-list-student-m3-i.component';
import { BookListStudentM3IIComponent } from './book-list-student-m3-ii/book-list-student-m3-ii.component';
import { BookListStudentM3IIIComponent } from './book-list-student-m3-iii/book-list-student-m3-iii.component';
import { BookListStudentP1AComponent } from './book-list-student-p1-a/book-list-student-p1-a.component';
import { BookListStudentP1BComponent } from './book-list-student-p1-b/book-list-student-p1-b.component';
import { BookListStudentP1CComponent } from './book-list-student-p1-c/book-list-student-p1-c.component';
import { BookListStudentP1DComponent } from './book-list-student-p1-d/book-list-student-p1-d.component';
import { BookListStudentP2AComponent } from './book-list-student-p2-a/book-list-student-p2-a.component';
import { BookListStudentP2BComponent } from './book-list-student-p2-b/book-list-student-p2-b.component';
import { BookListStudentP2CComponent } from './book-list-student-p2-c/book-list-student-p2-c.component';
import { BookListStudentP2DComponent } from './book-list-student-p2-d/book-list-student-p2-d.component';
import { BookListStudentP3AComponent } from './book-list-student-p3-a/book-list-student-p3-a.component';
import { BookListStudentP3BComponent } from './book-list-student-p3-b/book-list-student-p3-b.component';
import { BookListStudentP3CComponent } from './book-list-student-p3-c/book-list-student-p3-c.component';
import { BookListStudentP4AComponent } from './book-list-student-p4-a/book-list-student-p4-a.component';
import { BookListStudentP4BComponent } from './book-list-student-p4-b/book-list-student-p4-b.component';
import { BookListStudentP4CComponent } from './book-list-student-p4-c/book-list-student-p4-c.component';
import { BookListStudentP5AComponent } from './book-list-student-p5-a/book-list-student-p5-a.component';
import { BookListStudentP5BComponent } from './book-list-student-p5-b/book-list-student-p5-b.component';
import { BookListStudentP5CComponent } from './book-list-student-p5-c/book-list-student-p5-c.component';
import { BookListStudentP6AComponent } from './book-list-student-p6-a/book-list-student-p6-a.component';
import { BookListStudentP6BComponent } from './book-list-student-p6-b/book-list-student-p6-b.component';
import { BookListStudentP6CComponent } from './book-list-student-p6-c/book-list-student-p6-c.component';
import { BookListStudentS1IComponent } from './book-list-student-s1-i/book-list-student-s1-i.component';
import { BookListStudentS1IIComponent } from './book-list-student-s1-ii/book-list-student-s1-ii.component';
import { BookListStudentS1IIIComponent } from './book-list-student-s1-iii/book-list-student-s1-iii.component';
import { BookListStudentS2IComponent } from './book-list-student-s2-i/book-list-student-s2-i.component';
import { BookListStudentS2IIComponent } from './book-list-student-s2-ii/book-list-student-s2-ii.component';
import { BookListStudentS2IIIComponent } from './book-list-student-s2-iii/book-list-student-s2-iii.component';
import { BookListStudentS2IVComponent } from './book-list-student-s2-iv/book-list-student-s2-iv.component';
import { BookListStudentS3ESComponent } from './book-list-student-s3-es/book-list-student-s3-es.component';
import { BookListStudentS3ECOComponent } from './book-list-student-s3-eco/book-list-student-s3-eco.component';
import { BookListStudentS3SCComponent } from './book-list-student-s3-sc/book-list-student-s3-sc.component';
import { BookListStudentS3TQComponent } from './book-list-student-s3-tq/book-list-student-s3-tq.component';
import { BookListStudentS4ECOComponent } from './book-list-student-s4-eco/book-list-student-s4-eco.component';
import { BookListStudentS4SCComponent } from './book-list-student-s4-sc/book-list-student-s4-sc.component';
import { BookListStudentS4TQComponent } from './book-list-student-s4-tq/book-list-student-s4-tq.component';
import { BookListStudentS5ESComponent } from './book-list-student-s5-es/book-list-student-s5-es.component';
import { BookListStudentS5MSComponent } from './book-list-student-s5-ms/book-list-student-s5-ms.component';
import { BookListStudentS5TQComponent } from './book-list-student-s5-tq/book-list-student-s5-tq.component';
import { BookListStudentS6ESComponent } from './book-list-student-s6-es/book-list-student-s6-es.component';
import { BookListStudentS6MSComponent } from './book-list-student-s6-ms/book-list-student-s6-ms.component';
import { BookListStudentS6TQComponent } from './book-list-student-s6-tq/book-list-student-s6-tq.component';
import { FlyingAnimalStudentM12Pipe } from './flying-animal-student-m12.pipe';
import { FlyingAnimalStudentM2IIPipe } from './flying-animal-student-m2-ii.pipe';
import { FlyingAnimalStudentM3IPipe } from './flying-animal-student-m3-i.pipe';
import { FlyingAnimalStudentM3IIPipe } from './flying-animal-student-m3-ii.pipe';
import { FlyingAnimalStudentM3IIIPipe } from './flying-animal-student-m3-iii.pipe';
import { FlyingAnimalStudentP1APipe } from './flying-animal-student-p1-a.pipe';
import { FlyingAnimalStudentP1BPipe } from './flying-animal-student-p1-b.pipe';
import { FlyingAnimalStudentP1CPipe } from './flying-animal-student-p1-c.pipe';
import { FlyingAnimalStudentP1DPipe } from './flying-animal-student-p1-d.pipe';
import { FlyingAnimalStudentP2APipe } from './flying-animal-student-p2-a.pipe';
import { FlyingAnimalStudentP2BPipe } from './flying-animal-student-p2-b.pipe';
import { FlyingAnimalStudentP2CPipe } from './flying-animal-student-p2-c.pipe';
import { FlyingAnimalStudentP2DPipe } from './flying-animal-student-p2-d.pipe';
import { FlyingAnimalStudentP3APipe } from './flying-animal-student-p3-a.pipe';
import { FlyingAnimalStudentP3BPipe } from './flying-animal-student-p3-b.pipe';
import { FlyingAnimalStudentP3CPipe } from './flying-animal-student-p3-c.pipe';
import { FlyingAnimalStudentP4APipe } from './flying-animal-student-p4-a.pipe';
import { FlyingAnimalStudentP4BPipe } from './flying-animal-student-p4-b.pipe';
import { FlyingAnimalStudentP4CPipe } from './flying-animal-student-p4-c.pipe';
import { FlyingAnimalStudentP5APipe } from './flying-animal-student-p5-a.pipe';
import { FlyingAnimalStudentP5BPipe } from './flying-animal-student-p5-b.pipe';
import { FlyingAnimalStudentP5CPipe } from './flying-animal-student-p5-c.pipe';
import { FlyingAnimalStudentP6APipe } from './flying-animal-student-p6-a.pipe';
import { FlyingAnimalStudentP6BPipe } from './flying-animal-student-p6-b.pipe';
import { FlyingAnimalStudentP6CPipe } from './flying-animal-student-p6-c.pipe';
import { FlyingAnimalStudentS1IPipe } from './flying-animal-student-s1-i.pipe';
import { FlyingAnimalStudentS1IIPipe } from './flying-animal-student-s1-ii.pipe';
import { FlyingAnimalStudentS1IIIPipe } from './flying-animal-student-s1-iii.pipe';
import { FlyingAnimalStudentS2IPipe } from './flying-animal-student-s2-i.pipe';
import { FlyingAnimalStudentS2IIPipe } from './flying-animal-student-s2-ii.pipe';
import { FlyingAnimalStudentS2IIIPipe } from './flying-animal-student-s2-iii.pipe';
import { FlyingAnimalStudentS2IVPipe } from './flying-animal-student-s2-iv.pipe';
import { FlyingAnimalStudentS3ESPipe } from './flying-animal-student-s3-es.pipe';
import { FlyingAnimalStudentS3ECOPipe } from './flying-animal-student-s3-eco.pipe';
import { FlyingAnimalStudentS3SCPipe } from './flying-animal-student-s3-sc.pipe';
import { FlyingAnimalStudentS3TQPipe } from './flying-animal-student-s3-tq.pipe';
import { FlyingAnimalStudentS4ECOPipe } from './flying-animal-student-s4-eco.pipe';
import { FlyingAnimalStudentS4SCPipe } from './flying-animal-student-s4-sc.pipe';
import { FlyingAnimalStudentS4TQPipe } from './flying-animal-student-s4-tq.pipe';
import { FlyingAnimalStudentS5ESPipe } from './flying-animal-student-s5-es.pipe';
import { FlyingAnimalStudentS5MSPipe } from './flying-animal-student-s5-ms.pipe';
import { FlyingAnimalStudentS5TQPipe } from './flying-animal-student-s5-tq.pipe';
import { FlyingAnimalStudentS6ESPipe } from './flying-animal-student-s6-es.pipe';
import { FlyingAnimalStudentS6MSPipe } from './flying-animal-student-s6-ms.pipe';
import { FlyingAnimalStudentS6TQPipe } from './flying-animal-student-s6-tq.pipe';
import { AuthsuppbulletinComponent } from './authsuppbulletin/authsuppbulletin.component';
import { AuthsuppfactureComponent } from './authsuppfacture/authsuppfacture.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule} from '@syncfusion/ej2-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import {  MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

import { MatListModule } from '@angular/material/list';
import { SingleBookArchiveComponent } from './book-list/single-book-archive/single-book-archive.component';
import { SingleBookStudentArchiveComponent } from './book-list/single-book-student-archive/single-book-student-archive.component';

import {DayPilotModule} from "daypilot-pro-angular";
import { CalendarComponent } from './calendar/calendar/calendar.component';
import { SchedulerComponent } from './scheduler/scheduler/scheduler.component';
import {SchedulerModule} from "./scheduler/scheduler.module";
import { LoginComponent } from './login/login.component';
import { RoomlistComponent } from './roomlist/roomlist.component';
import { AddroomComponent } from './addroom/addroom.component';
import { ChatroomComponent } from './chatroom/chatroom.component';

import { FlexLayoutModule } from '@angular/flex-layout';

import {MatDialogModule } from '@angular/material/dialog';
import { DialogExampleComponent } from './dialog-example/dialog-example.component';
import { AvisComponent } from './avis/avis.component';
import { FinanceComponent } from './finance/finance.component';
import { CreateComponent } from './finance/create/create.component';
import { UpdateComponent } from './finance/update/update.component';
import { SecretariatComponent } from './secretariat/secretariat.component';
import { CreatesecretariatComponent } from './secretariat/createsecretariat/createsecretariat.component';
import { UpdatesecretariatComponent } from './secretariat/updatesecretariat/updatesecretariat.component';
import { AdminComponent } from './admin/admin.component';
import { CreateadminComponent } from './admin/createadmin/createadmin.component';
import { UpdateadminComponent } from './admin/updateadmin/updateadmin.component';
import { DialogChoixComponent } from './dialog-choix/dialog-choix.component';
import { VisioconfComponent } from './visioconf/visioconf.component';






const appRoutes: Routes = [
  { path: 'auth/signup', component: SignupComponent },
  { path: 'auth/signin', component: SigninComponent },
  { path: 'books', component: BookListComponent },
  { path: 'booksStudent', component: BookListStudentComponent },  
  { path: 'booksStudentM1I', component: BookListStudentM1IComponent },
  { path: 'booksStudentM1II', component: BookListStudentM1IIComponent },
  { path: 'booksStudentM12', canActivate: [AuthGuardService], component: BookListStudentM12Component },
  { path: 'booksStudentM2II', canActivate: [AuthGuardService], component: BookListStudentM2IIComponent },
  { path: 'booksStudentM3I', canActivate: [AuthGuardService], component: BookListStudentM3IComponent },
  { path: 'booksStudentM3II', canActivate: [AuthGuardService], component: BookListStudentM3IIComponent },
  { path: 'booksStudentM3III', canActivate: [AuthGuardService], component: BookListStudentM3IIIComponent },
  { path: 'booksStudentP1A', component: BookListStudentP1AComponent },
  { path: 'booksStudentP1B', canActivate: [AuthGuardService], component: BookListStudentP1BComponent },
  { path: 'booksStudentP1C', canActivate: [AuthGuardService], component: BookListStudentP1CComponent },
  { path: 'booksStudentP1D', canActivate: [AuthGuardService], component: BookListStudentP1DComponent },
  { path: 'booksStudentP2A', canActivate: [AuthGuardService], component: BookListStudentP2AComponent },
  { path: 'booksStudentP2B', canActivate: [AuthGuardService], component: BookListStudentP2BComponent },
  { path: 'booksStudentP2C', canActivate: [AuthGuardService], component: BookListStudentP2CComponent },
  { path: 'booksStudentP2D', canActivate: [AuthGuardService], component: BookListStudentP2DComponent },
  { path: 'booksStudentP3A', canActivate: [AuthGuardService], component: BookListStudentP3AComponent },
  { path: 'booksStudentP3B', canActivate: [AuthGuardService], component: BookListStudentP3BComponent },
  { path: 'booksStudentP3C', canActivate: [AuthGuardService], component: BookListStudentP3CComponent },
  { path: 'booksStudentP4A', canActivate: [AuthGuardService], component: BookListStudentP4AComponent },
  { path: 'booksStudentP4B', canActivate: [AuthGuardService], component: BookListStudentP4BComponent },
  { path: 'booksStudentP4C', canActivate: [AuthGuardService], component: BookListStudentP4CComponent },
  { path: 'booksStudentP5A', canActivate: [AuthGuardService], component: BookListStudentP5AComponent },
  { path: 'booksStudentP5B', canActivate: [AuthGuardService], component: BookListStudentP5BComponent },
  { path: 'booksStudentP5C', canActivate: [AuthGuardService], component: BookListStudentP5CComponent },
  { path: 'booksStudentP6A', canActivate: [AuthGuardService], component: BookListStudentP6AComponent },
  { path: 'booksStudentP6B', canActivate: [AuthGuardService], component: BookListStudentP6BComponent },
  { path: 'booksStudentP6C', canActivate: [AuthGuardService], component: BookListStudentP6CComponent },
  { path: 'booksStudentS1I', canActivate: [AuthGuardService], component: BookListStudentS1IComponent },
  { path: 'booksStudentS1II', canActivate: [AuthGuardService], component: BookListStudentS1IIComponent },
  { path: 'booksStudentS1III', canActivate: [AuthGuardService], component: BookListStudentS1IIIComponent },
  { path: 'booksStudentS2I', canActivate: [AuthGuardService], component: BookListStudentS2IComponent },
  { path: 'booksStudentS2II', canActivate: [AuthGuardService], component: BookListStudentS2IIComponent },
  { path: 'booksStudentS2III', canActivate: [AuthGuardService], component: BookListStudentS2IIIComponent },
  { path: 'booksStudentS2IV', canActivate: [AuthGuardService], component: BookListStudentS2IVComponent },
  { path: 'booksStudentS3ES', canActivate: [AuthGuardService], component: BookListStudentS3ESComponent },
  { path: 'booksStudentS3ECO', canActivate: [AuthGuardService], component: BookListStudentS3ECOComponent },
  { path: 'booksStudentS3SC', canActivate: [AuthGuardService], component: BookListStudentS3SCComponent },
  { path: 'booksStudentS3TQ', canActivate: [AuthGuardService], component: BookListStudentS3TQComponent },
  { path: 'booksStudentS4ECO', canActivate: [AuthGuardService], component: BookListStudentS4ECOComponent },
  { path: 'booksStudentS4SC', canActivate: [AuthGuardService], component: BookListStudentS4SCComponent },
  { path: 'booksStudentS4TQ', canActivate: [AuthGuardService], component: BookListStudentS4TQComponent },
  { path: 'booksStudentS5ES', canActivate: [AuthGuardService], component: BookListStudentS5ESComponent },
  { path: 'booksStudentS5MS', canActivate: [AuthGuardService], component: BookListStudentS5MSComponent },
  { path: 'booksStudentS5TQ', canActivate: [AuthGuardService], component: BookListStudentS5TQComponent },
  { path: 'booksStudentS6ES', canActivate: [AuthGuardService], component: BookListStudentS6ESComponent },
  { path: 'booksStudentS6MS', canActivate: [AuthGuardService], component: BookListStudentS6MSComponent },
  { path: 'booksStudentS6TQ', canActivate: [AuthGuardService], component: BookListStudentS6TQComponent },
  
  



  { path: 'books/new', component: BookFormComponent },
  { path: 'books/view/:id', component: SingleBookComponent },

  { path: 'booksArchive/view/:id', component: SingleBookArchiveComponent },
  { path: 'booksStudentArchive/view/:id', component: SingleBookStudentArchiveComponent },

  { path: 'booksStudent/view/:id', component: SingleBookStudentComponent },
  { path: 'authsupp', component: AuthsuppComponent },
  { path: 'authsuppbulletin', component: AuthsuppbulletinComponent },
  { path: 'authsuppfacture', component: AuthsuppfactureComponent },

  { path: 'calendar', component: CalendarComponent },

  { path: 'login', component: LoginComponent },
  { path: 'roomlist', component: RoomlistComponent },
  { path: 'addroom', component: AddroomComponent },
  { path: 'chatroom/:roomname', component: ChatroomComponent },

  { path: 'avis', component: AvisComponent },

  { path: 'finance', component: FinanceComponent },
  { path: 'update/:id', component: UpdateComponent },

  { path: 'secretariat', component: SecretariatComponent },
  { path: 'secretariatcreate', component: CreatesecretariatComponent },
  { path: 'secretariatupdate/:id', component: UpdatesecretariatComponent },

  { path: 'admin', component: AdminComponent },
  { path: 'admincreate', component: CreateadminComponent },
  { path: 'adminupdate/:id', component: UpdateadminComponent },
  { path: 'visioconf', component: VisioconfComponent },

  { path: '', redirectTo: 'auth/signin', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/signin' }
];




@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    BookListComponent,
    SingleBookComponent,
    BookFormComponent,
    HeaderComponent,
    BookListStudentComponent,
    SingleBookStudentComponent,
    AuthsuppComponent,
    RedirectionComponent,
    FlyingAnimalNewPipe,
    BookListStudentM1IComponent,
    BookListStudentM1IIComponent,
    FlyingAnimalStudentM1IPipe,
    FlyingAnimalStudentM1IIPipe,
    BookListStudentM12Component,
    BookListStudentM2IIComponent,
    BookListStudentM3IComponent,
    BookListStudentM3IIComponent,
    BookListStudentM3IIIComponent,
    BookListStudentP1AComponent,
    BookListStudentP1BComponent,
    BookListStudentP1CComponent,
    BookListStudentP1DComponent,
    BookListStudentP2AComponent,
    BookListStudentP2BComponent,
    BookListStudentP2CComponent,
    BookListStudentP2DComponent,
    BookListStudentP3AComponent,
    BookListStudentP3BComponent,
    BookListStudentP3CComponent,
    BookListStudentP4AComponent,
    BookListStudentP4BComponent,
    BookListStudentP4CComponent,
    BookListStudentP5AComponent,
    BookListStudentP5BComponent,
    BookListStudentP5CComponent,
    BookListStudentP6AComponent,
    BookListStudentP6BComponent,
    BookListStudentP6CComponent,
    BookListStudentS1IComponent,
    BookListStudentS1IIComponent,
    BookListStudentS1IIIComponent,
    BookListStudentS2IComponent,
    BookListStudentS2IIComponent,
    BookListStudentS2IIIComponent,
    BookListStudentS2IVComponent,
    BookListStudentS3ESComponent,
    BookListStudentS3ECOComponent,
    BookListStudentS3SCComponent,
    BookListStudentS3TQComponent,
    BookListStudentS4ECOComponent,
    BookListStudentS4SCComponent,
    BookListStudentS4TQComponent,
    BookListStudentS5ESComponent,
    BookListStudentS5MSComponent,
    BookListStudentS5TQComponent,
    BookListStudentS6ESComponent,
    BookListStudentS6MSComponent,
    BookListStudentS6TQComponent,
    FlyingAnimalStudentM12Pipe,
    FlyingAnimalStudentM2IIPipe,
    FlyingAnimalStudentM3IPipe,
    FlyingAnimalStudentM3IIPipe,
    FlyingAnimalStudentM3IIIPipe,
    FlyingAnimalStudentP1APipe,
    FlyingAnimalStudentP1BPipe,
    FlyingAnimalStudentP1CPipe,
    FlyingAnimalStudentP1DPipe,
    FlyingAnimalStudentP2APipe,
    FlyingAnimalStudentP2BPipe,
    FlyingAnimalStudentP2CPipe,
    FlyingAnimalStudentP2DPipe,
    FlyingAnimalStudentP3APipe,
    FlyingAnimalStudentP3BPipe,
    FlyingAnimalStudentP3CPipe,
    FlyingAnimalStudentP4APipe,
    FlyingAnimalStudentP4BPipe,
    FlyingAnimalStudentP4CPipe,
    FlyingAnimalStudentP5APipe,
    FlyingAnimalStudentP5BPipe,
    FlyingAnimalStudentP5CPipe,
    FlyingAnimalStudentP6APipe,
    FlyingAnimalStudentP6BPipe,
    FlyingAnimalStudentP6CPipe,
    FlyingAnimalStudentS1IPipe,
    FlyingAnimalStudentS1IIPipe,
    FlyingAnimalStudentS1IIIPipe,
    FlyingAnimalStudentS2IPipe,
    FlyingAnimalStudentS2IIPipe,
    FlyingAnimalStudentS2IIIPipe,
    FlyingAnimalStudentS2IVPipe,
    FlyingAnimalStudentS3ESPipe,
    FlyingAnimalStudentS3ECOPipe,
    FlyingAnimalStudentS3SCPipe,
    FlyingAnimalStudentS3TQPipe,
    FlyingAnimalStudentS4ECOPipe,
    FlyingAnimalStudentS4SCPipe,
    FlyingAnimalStudentS4TQPipe,
    FlyingAnimalStudentS5ESPipe,
    FlyingAnimalStudentS5MSPipe,
    FlyingAnimalStudentS5TQPipe,
    FlyingAnimalStudentS6ESPipe,
    FlyingAnimalStudentS6MSPipe,
    FlyingAnimalStudentS6TQPipe,
    AuthsuppbulletinComponent,
    AuthsuppfactureComponent,
    SingleBookArchiveComponent,
    SingleBookStudentArchiveComponent,
    CalendarComponent,
    SchedulerComponent,
    LoginComponent,
    RoomlistComponent,
    AddroomComponent,
    ChatroomComponent,
    DialogExampleComponent,
    AvisComponent,
    FinanceComponent,
    CreateComponent,
    UpdateComponent,
    SecretariatComponent,
    CreatesecretariatComponent,
    UpdatesecretariatComponent,
    AdminComponent,
    CreateadminComponent,
    UpdateadminComponent,
    DialogChoixComponent,
    VisioconfComponent
  ],
  entryComponents : [ DialogExampleComponent ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DayPilotModule,
    SchedulerModule,
    RouterModule.forRoot(appRoutes),
    CKEditorModule,
    MultiSelectModule,
    DropDownListModule,
    ButtonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,        
    MatIconModule,  
    MatButtonModule,  
    MatCardModule,  
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatSnackBarModule,
    MatSidenavModule,    
    FlexLayoutModule,
    MatDialogModule,
    
  ],
  providers: [
    AuthService,
    BooksService,
    AuthGuardService,
    ProfesseursService,
    ConstantsService,
    FlyingAnimalPipe,
    FlyingAnimalNewPipe,
    FlyingAnimalStudentM1IPipe,
    FlyingAnimalStudentM1IIPipe,
    FlyingAnimalStudentM12Pipe,
    FlyingAnimalStudentM2IIPipe,
    FlyingAnimalStudentM3IPipe,
    FlyingAnimalStudentM3IIPipe,
    FlyingAnimalStudentM3IIIPipe,
    FlyingAnimalStudentP1APipe,
    FlyingAnimalStudentP1BPipe,
    FlyingAnimalStudentP1CPipe,
    FlyingAnimalStudentP1DPipe,
    FlyingAnimalStudentP2APipe,
    FlyingAnimalStudentP2BPipe,
    FlyingAnimalStudentP2CPipe,
    FlyingAnimalStudentP2DPipe,
    FlyingAnimalStudentP3APipe,
    FlyingAnimalStudentP3BPipe,
    FlyingAnimalStudentP3CPipe,
    FlyingAnimalStudentP4APipe,
    FlyingAnimalStudentP4BPipe,
    FlyingAnimalStudentP4CPipe,
    FlyingAnimalStudentP5APipe,
    FlyingAnimalStudentP5BPipe,
    FlyingAnimalStudentP5CPipe,
    FlyingAnimalStudentP6APipe,
    FlyingAnimalStudentP6BPipe,
    FlyingAnimalStudentP6CPipe,
    FlyingAnimalStudentS1IPipe,
    FlyingAnimalStudentS1IIPipe,
    FlyingAnimalStudentS1IIIPipe,
    FlyingAnimalStudentS2IPipe,
    FlyingAnimalStudentS2IIPipe,
    FlyingAnimalStudentS2IIIPipe,
    FlyingAnimalStudentS2IVPipe,
    FlyingAnimalStudentS3ESPipe,
    FlyingAnimalStudentS3ECOPipe,
    FlyingAnimalStudentS3SCPipe,
    FlyingAnimalStudentS3TQPipe,
    FlyingAnimalStudentS4ECOPipe,
    FlyingAnimalStudentS4SCPipe,
    FlyingAnimalStudentS4TQPipe,
    FlyingAnimalStudentS5ESPipe,
    FlyingAnimalStudentS5MSPipe,
    FlyingAnimalStudentS5TQPipe,
    FlyingAnimalStudentS6ESPipe,
    FlyingAnimalStudentS6MSPipe,
    FlyingAnimalStudentS6TQPipe,
    DatePipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
