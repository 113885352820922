export class Book1 {
    photo: string;
    files: {
      name: string,
      url: string
    }[];
    tempId?: number;
  
    constructor(public title: string,
                public listedechoix: string,
                public author: string,
                public authorId: string,
                public synopsis: string,
                public alerte: string,
                public liens: string,
                public dateJour: string,
               ) {
      this.files = [];
    }
  }