<div class="row">
    <div class="col-xs-12">
      <h2>Vos leçons</h2> 
     
      <p> </p>
      <div class="list-group">
        <button
          class="list-group-item"
          *ngFor="let book of books| flyingAnimalStudentP6B; let i = index "  
          >
        
        
          <h3 class="list-group-item-heading">
          
            <h2>{{ book.listedechoix | uppercase }}</h2>
            <span class="badge badge-light"><span class="glyphicon glyphicon-alert"><h4>{{ book.alerte }}</h4></span></span> 
            <p> </p>
                       
            <h3><b>{{ book.author | uppercase}}</b></h3>
            <h3>{{ book.authorId }}</h3>
            <p></p>
            <h4>{{ book.dateJour | date:"dd/MM/yyyy" }}</h4>
            <p> </p>
  
          
          </h3>
          <h3><b><p class="list-group-item-text">{{ book.title | uppercase  }}</p></b></h3>
         
        
          <div class="col-xs-12">
            {{ book.synopsis }}
          </div>
  
        <div class="col-xs-12">
          
          <textarea name="liens" id="" cols="30" rows="3"> {{ book.liens }}</textarea>
  
          </div>
  
        <div *ngIf="book.files && book.files.length">
          <h4><i>Le(s) Fichier(s) à télécharger en bleu(s)</i></h4>
          <a *ngFor="let file of book.files" [href]="file.url" target="_blank">{{ file.name }}</a>
        </div>
          
        </button>
        <p> </p>
        <p><button class="btn btn-default" (click)="onBackStudent()">Retour</button></p>
  
        
      </div>
      
    </div>