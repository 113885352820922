<div class="container-fluid">
    <div class="row">

        <h3>-------------------------------------------------------------------------------------------------------------------------------SECRETARIAT(Fiche signaletique)-------------------------------------------------------------------------------------------------------------------------------</h3>
        <h3>------------------------------------------------------------------------------------------------------------------------------------------------<b>MISE A JOUR</b>-----------------------------------------------------------------------------------------------------------------------------------------------</h3>
      <div class="col-12 col-lg-12 col-md-12 sol-sm-12">
        <form [formGroup]="productFormFinance" (ngSubmit)="updateProductData_finance(productFormFinance.value)">

  
          <div class="form-group">
            <label>nom de Famille</label>
            <input  type="text" class="form-control" placeholder="Entré le nom de la famille" formControlName="NomFamille" required>
         </div>

          <div class="form-group">
             <label>numéro de Famille</label>
             <input  type="text" class="form-control" placeholder="Entré le numéro de la famille" formControlName="NumeroFamille" required>
          </div>

          <div class="form-group">
             <label>Nombre d'enfants</label>
             <input   type="text" class="form-control" placeholder="Nombre d'enfants" formControlName="NumeroEleve" required>            
          </div>

          <div class="form-group">
             <label>Observation</label>
             <input readonly type="text" class="form-control" placeholder="saisir une observation(pas obligatoire)" formControlName="Observations" required>            
          </div>

          <button class="btn btn-success"  >Status</button>

          <div class="form-group">
            <label>En Ordre?(0 = non / 1 = oui)</label>
            <input readonly type="text" class="form-control" placeholder="OrdrePyt" formControlName="OrdrePyt" required>
            <div class="alert-danger" *ngIf="!productFormFinance.controls['OrdrePyt'].valid && (productFormFinance.controls['OrdrePyt'].touched)">
              <div [hidden]="!productFormFinance.controls['OrdrePyt'].errors.required">
                La valeur 0 ou 1 est obligatoire.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="listedechoix">Uploader le Bulletin scolaire</label>
            <input type="file" name="avatar1" />
          </div>

          <p class="text-danger">{{ errorMessage }}</p>

          <button type="submit" class="btn btn-primary" [disabled]="!productFormFinance.valid">Soumettre</button>
          <button class="btn btn-secondary" (click)="getNavigation('secretariat', '')">retour</button>
          
  
        </form>

        
      </div>
    </div>
  </div>