import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'flyingAnimals',
  pure: false
})
export class FlyingAnimalPipe implements PipeTransform {

  valeurClasse: string

  upsearchClasse(value: string) {
   this.valeurClasse = value
   console.log('valeurClasse:'+ this.valeurClasse);
  }
  transform(allAnimals: any[]) {
    return allAnimals.filter(book => book.listedechoix === this.valeurClasse);
  }


}
