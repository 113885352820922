import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  readonly baseAppUrl: boolean ;
  readonly distLocation: boolean ;

  constructor() { }
  
}
