<h2 mat-dialog-title >INFORMATION IMPORTANTE DE LA DIRECTION {{data.listedechoix}}</h2>
<mat-dialog-content >{{data.title}}</mat-dialog-content>

<div *ngIf="data.file1link">             
    <a  [href]="data.file1link" target="_blank">- CLIQUEZ ICI POUR VOIR LA PIECE JOINTE -</a>            
  </div>

<mat-dialog-actions >
    <button mat-button mat-dialog-close mat-dialog-close='True'> Fermer</button>
    
</mat-dialog-actions>
