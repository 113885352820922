import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authsuppbulletin',
  templateUrl: './authsuppbulletin.component.html',
  styleUrls: ['./authsuppbulletin.component.scss']
})
export class AuthsuppbulletinComponent implements OnInit {

  signInFormSuppbulletin: FormGroup;
  errorMessage: string;
  idService: string;

  studentId: string;
  isStudent: boolean;
  result: string;

  StudentCodeId: string;
  StudentCodeSecret: string;
  StudentCode: string;
  urlCode : string;
  StudentCode1: string;
  urlCode1 : string;
  StudentCode2: string;
  urlCode2 : string;
  StudentCode3: string;
  urlCode3 : string;
  StudentCode4: string;
  urlCode4 : string;
  StudentCode5: string;
  urlCode5 : string;
  StudentCode6: string;
  urlCode6 : string;
  StudentCode7: string;
  urlCode7 : string;
  StudentCode8: string;
  urlCode8 : string;
  StudentCode9: string;
  urlCode9 : string;
  StudentCode10: string;
  urlCode10 : string;

  Ordre:boolean = true;
  champVide : boolean = true;
  

  constructor(private formBuilder: FormBuilder,  
    private router: Router) { }

    ngOnInit() {
      this.initFormSupp();
      
    }

    initFormSupp() {
      this.signInFormSuppbulletin = this.formBuilder.group({
        text: [''],
        textsecret: ['']
        
      });  
      
  
    }

    onVerifFrais(){

      if (!this.Ordre) {

        this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';

        setTimeout(
          () => {
            //this.isAuth = true;
            this.router.navigate(['/auth', 'signin']);
          }, 15000
        );
        
  
      }  else{

      }
    }

    onSubmit() {
      this.StudentCodeId = this.signInFormSuppbulletin.get('text').value;
      this.StudentCodeSecret = this.signInFormSuppbulletin.get('textsecret').value;
      console.log('numéro de famille: ' + this.StudentCodeId);
      console.log('code secret: ' + this.StudentCodeSecret);

      if( this.StudentCodeId != ''){     
        this.champVide = false;
        console.log('Valeur de la boolean' + this.champVide);

         this.StudentCode ='';
         this.urlCode ='';
         this.StudentCode1 ='';
         this.urlCode1 ='';
         this.StudentCode2 ='';
         this.urlCode2 ='';
         this.StudentCode3 ='';
         this.urlCode3 ='';
         this.StudentCode4 ='';
         this.urlCode4 ='';
         this.StudentCode5 ='';
         this.urlCode5 ='';
         this.StudentCode6 ='';
         this.urlCode6 ='';
         this.StudentCode7 ='';
         this.urlCode7 ='';
         this.StudentCode8 ='';
         this.urlCode8 ='';
         this.StudentCode9 ='';
         this.urlCode9 ='';
         this.StudentCode10 ='';
         this.urlCode10 ='';
         
       
        
        switch (this.StudentCodeId) {

          case '215':
            if( this.StudentCodeSecret == '5'){


              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
        
              }

              //this.StudentCode = 'SOENEN Christopher';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1i/Soenen_BulS1I.pdf';
            

            //this.StudentCode1 = 'Bulletin1';
            //this.urlCode1 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

            //this.StudentCode2 = 'Bulletin2';
            //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

            //this.StudentCode3 = 'Bulletin3';
            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

            //this.StudentCode4 = 'Bulletin4';
            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

            //this.StudentCode5 = 'Bulletin5';
            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
            //this.Ordre =true;
            //}else{
              //this.errorMessage = "Code secret ou numéro de famille non valide "
            }

            //console.log('valeur url: ' + this.urlCode);
            //console.log('message erreur: ' + this.errorMessage);
            break;
          case '610':
            if( this.StudentCodeSecret == '7'){
              //this.StudentCode = 'PIEDBOEUF Mickaël';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Piedboeuf%20_Bul6TQ.pdf';
  
              //this.StudentCode1 = 'Bulletin1';
              //this.urlCode1 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
              break;
          case '617':
            if( this.StudentCodeSecret == '9'){
              this.StudentCode = 'MARTINS LUIS Helder David              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Helder_David_Martins_Luis.pdf';
  
              //this.StudentCode1 = 'Bulletin1';
              //this.urlCode1 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '632':
            if( this.StudentCodeSecret == '11'){
              this.StudentCode = 'NYAKABASA IRAGI Mina Solène              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mina_Solène_Nyakabasa_Iragi.pdf';
  
              this.StudentCode1 = 'NYAKABASA Ntale David              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ntale_David_Nyakabasa.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '647':
            if( this.StudentCodeSecret == '13'){
              this.StudentCode = 'KAMANZI Nyota              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nyota_Kamanzi.pdf';
  
              //this.StudentCode1 = 'KAMANZI Aziza Agnes              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kamanzi_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '679':
            if( this.StudentCodeSecret == '15'){
              this.StudentCode = 'VANCUTSEM Alain Martin              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Alain_Martin_Vancutsem.pdf';
  
              //this.StudentCode1 = 'KAMANZI Aziza Agnes              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kamanzi_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '683':
            if( this.StudentCodeSecret == '565'){
              this.Ordre = false;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
        
           
              //this.StudentCode = 'PLACHTA Anaïs Diane              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Plachta_Anais.pdf';
            }
  
              //this.StudentCode1 = 'KAMANZI Aziza Agnes              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kamanzi_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
           
            break;
          case '760':
            if( this.StudentCodeSecret == '17'){
              this.StudentCode = 'MUAMBA Ryan              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ryan_Muamba.pdf';
  
              //this.StudentCode1 = 'MUAMBA Christopher Richard              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Muamba_Bul6SE.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
              break;
          case '831':
            if( this.StudentCodeSecret == '19'){
              this.StudentCode = 'LANCELOT Thalia Grâce              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Thalia_Grâce_Lancelot.pdf';
  
              //this.StudentCode1 = 'MUAMBA Christopher Richard              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Muamba_Bul6SE.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '842':
            if( this.StudentCodeSecret == '21'){
              this.StudentCode = 'BADU AKETI PAIZANOS Alex              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Alex_Badu_Aketi_Paizanos.pdf';
  
              //this.StudentCode1 = 'BADU AKETI PAIZANOS Paul-Oscar              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BADU_PAUL_OSCAR.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '857':
            if( this.StudentCodeSecret == '23'){
              this.StudentCode = 'MBALA Gabriella Sarah              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gabriella_Sarah_Mbala.pdf';
  
              //this.StudentCode1 = 'MUAMBA Christopher Richard              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Muamba_Bul6SE.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '859':
            if( this.StudentCodeSecret == '25'){
              //this.StudentCode = 'MBONGI DAKHLALLAH Ahmed              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Mbong_Dak_Bul6TQ.pdf';
  
              //this.StudentCode1 = 'MUAMBA Christopher Richard              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Muamba_Bul6SE.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '884':
            if( this.StudentCodeSecret == '27'){
              this.StudentCode = 'VIRA MATHE Esther              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Esther_Vira_Mathe.pdf';
  
              this.StudentCode1 = 'KAVUO MATHE Grace              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Grace_Kavuo_Mathe.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '946':
            if( this.StudentCodeSecret == '567'){
              //this.StudentCode = 'DIMBAMBU Lucas-David              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dimbambu_Luca_David_%20P5A.pdf';
  
              //this.StudentCode1 = 'BADU AKETI PAIZANOS Paul-Oscar              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BADU_PAUL_OSCAR.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '957':
            if( this.StudentCodeSecret == '569'){
              this.StudentCode = 'NGANDU MUKEBA Yaëlle Emmanuelle              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yaëlle_Emmanuelle_Ngandu_Mukeba.pdf';
  
              //this.StudentCode1 = 'BADU AKETI PAIZANOS Paul-Oscar              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BADU_PAUL_OSCAR.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1011':
            if( this.StudentCodeSecret == '29'){
              //this.StudentCode = 'VIANA SANTOS Maria Joao              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Viana_Bul6TQ.pdf';
  
              //this.StudentCode1 = 'KAVUO MATHE Grace              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Kavuo_BulS1III.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1022':
            if( this.StudentCodeSecret == '571'){
              this.StudentCode = 'IBRAHIM Mohamad              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mohamad_Ibrahim.pdf';
  
              //this.StudentCode1 = 'BADU AKETI PAIZANOS Paul-Oscar              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BADU_PAUL_OSCAR.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1027':
            if( this.StudentCodeSecret == '31'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'PONGOMBO ONUSUMBA Henri              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Henri_Pongombo_Onusumba.pdf';
        
              }
              

  
              //this.StudentCode1 = 'KAVUO MATHE Grace              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Kavuo_BulS1III.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1033':
            if( this.StudentCodeSecret == '33'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MWINYI MUHEMEDI NASIBU Danny     ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Danny_Mwinyi_Muhemedi_Nasibu.pdf';
        
              }
              
  
              //this.StudentCode1 = 'KAVUO MATHE Grace              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Kavuo_BulS1III.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1039':
            if( this.StudentCodeSecret == '35'){
              this.StudentCode = 'NGENYI MULOWAYI Xavier Samu              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Xavier_Samu_Ngenyi_Mulowayi.pdf';
  
              //this.StudentCode1 = 'NGENYI MULOWAYI William Ngondo              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Ngenyi_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1042':
            if( this.StudentCodeSecret == '37'){
              //this.StudentCode = 'RUBUYE MUGOLI Soraya              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Rubuye_BulS1III.pdf';
  
              //this.StudentCode1 = 'NGENYI MULOWAYI William Ngondo              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Ngenyi_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1053':
            if( this.StudentCodeSecret == '39'){
              this.StudentCode = 'KAMBERE Kayla              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kayla_Kambere.pdf';
  
              this.StudentCode1 = 'KAMBERE Yohann              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Yohann_Kambere.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1059':
            if( this.StudentCodeSecret == '41'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'NZOMONO BAFWANGA Jenovie              ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jenovie_Nzomono_Bafwanga.pdf';
        
              }
             
  
              //this.StudentCode1 = 'KAMBERE Yohann              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Kambere_Bul5TQ.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1068':
            if( this.StudentCodeSecret == '43'){
              this.StudentCode = 'BOKINGI Claude              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Claude_Bokingi.pdf';
  
              //this.StudentCode1 = 'BOKINGI LEKALANGONGO Audrey              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Bokingi_Bul6MS.pdf';
  
              this.StudentCode2 = 'BOKINGI ENGANDA Jullian';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Jullian_Bokingi_Enganda.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1083':
            if( this.StudentCodeSecret == '45'){
              this.StudentCode = 'KOULTOUMI Juhani Roland-Marcel              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Juhani_Roland-Marcel_Koultoumi.pdf';
  
              this.StudentCode1 = 'KOULTOUMI Kedijah              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Kedijah_Koultoumi.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1102':
            if( this.StudentCodeSecret == '47'){
              //this.StudentCode = 'MALU LABENIE TSHIBANGU              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Malu_Tshibangu_Bul6SE.pdf';
  
              //this.StudentCode1 = 'KOULTOUMI Kedijah              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Koultoumi_Bul5TQ.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1105':
            if( this.StudentCodeSecret == '49'){
              this.StudentCode = 'MUNDHU JOSEPH EPHRAIM Beni              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Beni_Mundhu_Joseph_Ephraim.pdf';
  
              this.StudentCode1 = 'MUNDHU FETTY Battyston              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Battyston_Mundhu_Fetty.pdf';
  
              this.StudentCode2 = 'MUNDHU BENJAMIN Othniel';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Othniel_Mundhu_Benjamin.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1106':
            if( this.StudentCodeSecret == '51'){
              this.StudentCode = 'MUNGWA Violette              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Violette_Mungwa.pdf';
  
              this.StudentCode1 = 'MUNGWA Harmonie              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Harmonie_Mungwa.pdf';
  
              //this.StudentCode2 = 'MUNGWA Noam';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MUNGWA_NAOM.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1141':
            if( this.StudentCodeSecret == '53'){
              this.StudentCode = 'SOLA FURAHA Madeline              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Madeline_Sola_Furaha.pdf';
  
              this.StudentCode1 = 'SOLA OMOY Marilyn              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marilyn_Sola_Omoy.pdf';
  
              //this.StudentCode2 = 'SOLA MBILA Maxine';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Sola_Mbila_Maxine_P3C.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1148':
            if( this.StudentCodeSecret == '573'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
               // this.StudentCode = 'SHONGO LOMAMI Dieu-Donné              ';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Shongo_Dieu_Donne.pdf';
    
                //this.StudentCode1 = 'OKOKA Jean-Baptiste              ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Okoka_jean_Baptiste_P3B.pdf';
    
                //this.StudentCode2 = 'OSAKO LOFUDU Winner Marie';
               // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/Osako_Winner_P5C.pdf';
              }
             
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1154':
            if( this.StudentCodeSecret == '55'){
              this.StudentCode = 'NZOLANTIMA DIVANA Yona              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yona_Nzolantima_Divana.pdf';
  
              this.StudentCode1 = 'NZOLANTIMA SIFA Etia             ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Etia_Nzolantima_Sifa.pdf';
  
              this.StudentCode2 = 'NZOLANTIMA KIA Adrien';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Adrien_Nzolantima_Kia.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1166':
            if( this.StudentCodeSecret == '57'){
              this.StudentCode = 'UMBA Yann              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yann_Umba.pdf';
  
              //this.StudentCode1 = 'UMBA Keyla Kabaso              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Umba_Bul6MS.pdf';
  
              //this.StudentCode2 = 'UMBA Loïc';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Umba_Loic.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);
            break;
          case '1175':
            if( this.StudentCodeSecret == '59'){
              //this.StudentCode = 'SISIMI LOBELA Elliotte              ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Sisimi_Bul6TQ.pdf';
  
              //this.StudentCode1 = 'UMBA Keyla Kabaso              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Umba_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1218':
            if( this.StudentCodeSecret == '61'){
              this.StudentCode = 'FUNGULA MAYOYO Marguerite              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Marguerite_Fungula_Mayoyo.pdf';
  
              //this.StudentCode1 = 'UMBA Keyla Kabaso              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Umba_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1220':
            if( this.StudentCodeSecret == '63'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'BOPONGE David              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/David_Mpeti_Boponge.pdf';
        
              }
              
  
              //this.StudentCode1 = 'UMBA Keyla Kabaso              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Umba_Bul6MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;

            case '1227':
            if( this.StudentCodeSecret == '1007'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KALUME Polydor              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Polydor_Kalume.pdf';
        
              }
            }
          case '1234':
            if( this.StudentCodeSecret == '65'){
              this.StudentCode = 'SAKOMBI MOLENDO Amélie Monique              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Amelie_Monique_Sakombi_Molendo.pdf';
  
              this.StudentCode1 = 'SAKOMBI MOLENDO Anthony              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Anthony_Sakombi_Molendo.pdf';
  
              //this.StudentCode2 = 'SAKOMBI MOLENDO Grégory Aimé';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Sakombi_Gregory.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1253':
            if( this.StudentCodeSecret == '67'){
              this.StudentCode = 'BRAUN Emile              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emile_Braun.pdf';
  
              //this.StudentCode1 = 'SAKOMBI MOLENDO Anthony              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Sakombi_Bul5MS.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1267':
            if( this.StudentCodeSecret == '69'){
              this.StudentCode = 'PELESA Sarah              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Sarah_Perseverance_Pelesa.pdf';
  
              this.StudentCode1 = 'PELESA Marceline              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marceline_Prunelle_Pelesa.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1273':
            if( this.StudentCodeSecret == '71'){
              //this.StudentCode = 'MWANZA WA SINGOMA              ';
             // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Mwanza_Bul6SE.pdf';
  
              //this.StudentCode1 = 'PELESA Marceline              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Pelesa_Bul5ES.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1278':
            if( this.StudentCodeSecret == '73'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'DJONGA Prince Aaron              ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Prince_Aaron_Djonga.pdf';
              }
             
  
              //this.StudentCode1 = 'PELESA Marceline              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Pelesa_Bul5ES.pdf';
  
              //this.StudentCode2 = 'Bulletin2';
              //this.urlCode2 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1291':
            if( this.StudentCodeSecret == '75'){
              this.StudentCode = 'KABASELE Christian              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christian_Kabasele.pdf';
  
              this.StudentCode1 = 'KABASELE Anthony              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Anthony_Kabasele.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'KABASELE Michael';
              //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabasele_Michael_P5A.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
           break;
          case '1303':
            if( this.StudentCodeSecret == '77'){
              this.StudentCode = 'MALAMAS Lars              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lars_Malamas.pdf';
  
              //this.StudentCode1 = 'KABASELE Anthony              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Kabasel_Bul5MS.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1306':
            if( this.StudentCodeSecret == '79'){
              this.StudentCode = 'MAYANGA Gloria-Anne              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gloria-Anne_Mayanga.pdf';
  
              //this.StudentCode1 = 'KABASELE Anthony              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Kabasel_Bul5MS.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1313':
            if( this.StudentCodeSecret == '81'){
              this.StudentCode = 'MUKUKU Gabriel              ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gabriel_Mukuku.pdf';
  
              //this.StudentCode1 = 'KABASELE Anthony              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Kabasel_Bul5MS.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1321':
            if( this.StudentCodeSecret == '83'){
              this.StudentCode = 'LI Weiyu (Stella)             ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Stella_Li_Weiyu.pdf';
  
              //this.StudentCode1 = 'HUANG Shuwen (Stéphanie)              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/HUANG_STEPHANIE.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1324':
            if( this.StudentCodeSecret == '85'){
              this.StudentCode = 'WAN NGIMBI Mannuela            ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mannuela_Wan_Ngimbi.pdf';
  
              this.StudentCode1 = 'WAN NGIMBI Cyntiche              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Cyntiche_Wan_Ngimbi.pdf';
  
              //this.StudentCode2 = 'WAN Aïdan Joseph';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Wan_Aidan_P3B.pdf';
  
              this.StudentCode3 = 'WAN Andy Kinke';
              this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Andy_Kinke_Wan.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1325':
            this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
        
              }
            if( this.StudentCodeSecret == '87'){
              this.StudentCode = 'NGARAMBE ISHARA Dan           ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Dan_Ngarambe_Ishara.pdf';
  
              this.StudentCode1 = 'NGARAMBE CUBAYIRO Nathan              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Nathan_Ngarambe_Cubayiro.pdf';
  
              this.StudentCode2 = 'NGARAMBE NGAMIJE Loïc';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Loic_Ngarambe_Ngamije.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1332':
            if( this.StudentCodeSecret == '89'){
              this.StudentCode = 'KITENGE MUNKINDJI Johan           ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Johan_Kitenge_Munkindji.pdf';
  
              this.StudentCode1 = 'BILENGIE MUNKINDJI CLE              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Clemence_Bilengie_Munkindji.pdf';
  
              this.StudentCode2 = 'KITOTO MUNKINDJI Marcia';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Marcia_Kitoto_Munkindji.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1338':
            if( this.StudentCodeSecret == '91'){
              this.StudentCode = 'KAWAMA MWADI Roseline           ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Roseline_Kawama_Mwadi.pdf';
  
              this.StudentCode1 = 'KAWAMA BANZA Daniella              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Daniella_Kawama_Banza.pdf';
  
              this.StudentCode2 = 'KAWAMA KOMBA Emmanuel';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Emmanuel_Kawama_Komba.pdf';
  
              this.StudentCode3 = 'KAWAMA MALENGELE Ketsia';
              this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Ketsia_Kawama_Malengele.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
            case '1344':
              if( this.StudentCodeSecret == '911'){
                this.StudentCode = 'Hind_Hammad           ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Hind_Hammad.pdf';
    
                //this.StudentCode1 = 'KAWAMA BANZA Daniella              ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Daniella_Kawama_Banza.pdf';
    
                //this.StudentCode2 = 'KAWAMA KOMBA Emmanuel';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Emmanuel_Kawama_Komba.pdf';
    
                //this.StudentCode3 = 'KAWAMA MALENGELE Ketsia';
                //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Ketsia_Kawama_Malengele.pdf';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;
              break;
          case '1349':
            if( this.StudentCodeSecret == '93'){
              this.StudentCode = 'LEZIN Brenda           ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Brenda_Beatrice_Lezin.pdf';
  
              this.StudentCode1 = 'LEZIN Charlotte              ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Charlotte_Lezin.pdf';
  
              this.StudentCode2 = 'LEZIN Kimberley';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Kimberley_Lezin.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1354':
            if( this.StudentCodeSecret == '575'){
              //this.StudentCode = 'LOLE EFUE Joël             ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/LOLE_JOEL.pdf';
  
              //this.StudentCode1 = 'HUANG Shuwen (Stéphanie)              ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/HUANG_STEPHANIE.pdf';
  
              //this.StudentCode2 = 'KABASELE Axel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kabasele_Bul6MS.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
            case '1359':
            this.StudentCode = 'ANDREANI ANIE';
            this.Ordre =true;
            break;
          case '1374':
            if( this.StudentCodeSecret == '95'){
              this.StudentCode = 'BULU LASSASS Jacques          ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jacques_Bulu_Lassass.pdf';
  
              this.StudentCode1 = 'BULU OWEN Junior             ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Owen_Junior_Bulu_Owen.pdf';
  
              //this.StudentCode2 = 'BULU Séraphine';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/BULU_SERAPHINE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1376':
            if( this.StudentCodeSecret == '97'){
              this.StudentCode = 'OTONGA LUTSHINDA Dorcas         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Dorcas_Otonga_Lutshinda.pdf';
  
              //this.StudentCode1 = 'OTONGA NGWENDE Jonathan             ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Otonga_Bul6MS.pdf';
  
              this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Vincianne_Otonga_Ngwende.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1377':
            if( this.StudentCodeSecret == '99'){
              this.StudentCode = 'LIANAVWA MUMBERE Philippe         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Philippe_Lianavwa_Mumbere.pdf';
  
              //this.StudentCode1 = 'OTONGA NGWENDE Jonathan             ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Otonga_Bul6MS.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1380':
            if( this.StudentCodeSecret == '101'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'IMAMA ILEBO Catherine         ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Catherine_Imama_Ilebo.pdf';
    
                this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Pierre_Imama_Bokanga.pdf';
    
                //this.StudentCode2 = 'IMAMA Marie-José';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/IMAMA_MARIE%20_%20JOSEE.pdf';
              }
            
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1383':
            if( this.StudentCodeSecret == '103'){
              this.StudentCode = 'LUHAKA MUSAFIRI Nelson         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nelson_Luhaka_Musafiri.pdf';
  
              this.StudentCode1 = 'LUHAKA IMANI MULINDA Gloria            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Gloria_Luhaka_Imani_Mulinda.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1388':
            if( this.StudentCodeSecret == '105'){
              this.StudentCode = 'MUKUNA KANKU Elie         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Elie_Mukuna_Kanku.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1392':
            this.StudentCode = 'BOUZOUITA LAETITIA';
            this.Ordre =false;
            break;
          case '1396':
            if( this.StudentCodeSecret == '107'){
              this.StudentCode = 'NZILA TADULU Tracy         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Tracy_Nzila_Tadulu.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;
            break;
          case '1397':
            if( this.StudentCodeSecret == '109'){
              this.StudentCode = 'STAVELOT Lucas         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lucas_Stavelot.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1406':
            if( this.StudentCodeSecret == '111'){
              this.StudentCode = 'MOLAMBA LOWA Louise-Odette Seseti         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Louise_Odette_Seseti_Molamba_Lowa.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1413':
            if( this.StudentCodeSecret == '113'){
              this.StudentCode = 'ODENT Morgann         ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Morgann_Odent.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1430':
            if( this.StudentCodeSecret == '115'){
              //this.StudentCode = 'MAKAMBO Victoria-Kona        ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Makambo_Bul6TQ.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1472':
            if( this.StudentCodeSecret == '117'){
              this.StudentCode = 'GRACE MARIE-CLAIRE Shukrani        ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Shukrani_Grace_Marie_Claire.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1480':
            if( this.StudentCodeSecret == '119'){
              this.StudentCode = 'TOTO Zika Samantha       ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Zika_Samantha_Toto.pdf';
  
              //this.StudentCode1 = 'TOTO LUBANDA Alicia           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Toto_Alicia_P5A.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1482':
            if( this.StudentCodeSecret == '121'){
              this.StudentCode = 'MULUMBA KATCHY Althéa Tshibuyi       ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Althea_Tshibuyi_Mulumba_Katchy.pdf';
  
              //this.StudentCode1 = 'IMAMA BOKANGA Pierre            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Imama_BulS2I.pdf';
  
              //this.StudentCode2 = 'OTONGA NGWENDE Vincianne';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Otonga_BulS1III.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1487':
            if( this.StudentCodeSecret == '123'){
              this.StudentCode = 'LUYEYE NTINU Dodie       ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Dodie_Luyeye_Ntinu_Dodie.pdf';
  
              this.StudentCode1 = 'LUYEYE MANTETO Mignon            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Mignon_Luyeye_Manteto.pdf';
  
              this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Watoma_Emmanuelle_Luyeye.pdf';
  
              this.StudentCode3 = 'LUYEYE TADI MFUMU Délice';
              this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Delice_Luyeye_Tadi_Mfumu.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1488':
            if( this.StudentCodeSecret == '125'){
              this.StudentCode = 'NZILA Pongo Jaelle       ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Pongo_Jaelle_Nzila.pdf';
  
              //this.StudentCode1 = 'LUYEYE MANTETO Mignon            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3_eco/Luyeye_Bul3ECO.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1489':
            if( this.StudentCodeSecret == '127'){
              //this.StudentCode = 'KATSHUVA MASIKA Keren       ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Katshuva_Bul6MS.pdf';
  
              //this.StudentCode1 = 'LUYEYE MANTETO Mignon            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3_eco/Luyeye_Bul3ECO.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1493':
            if( this.StudentCodeSecret == '129'){
              this.StudentCode = 'de LANNOY Nyssia       ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nyssia_Lea_Jacquie_de_Lannoy.pdf';
  
              this.StudentCode1 = 'de LANNOY Maëlle            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Maelle_de_Lannoy.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1494':
            if( this.StudentCodeSecret == '131'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'ZIELE-COMIATI Dominique       ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Ziele.pdf';
  
              this.StudentCode1 = 'ZIELE Raphaël            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Raphael_Ziele.pdf';
        
              }
              
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1509':
            if( this.StudentCodeSecret == '133'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KATIKIA AZANGA Malan      ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Malan_Katikia_Azanga.pdf';
        
              }
          
  
              //this.StudentCode1 = 'de LANNOY Maëlle            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/de_Lannoy_Bul4ECO.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1511':
            if( this.StudentCodeSecret == '135'){
              this.StudentCode = 'KISIMBA NGOY MUAMBA Jordan-Samuel      ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jordan_Samuel_Kisimba_Ngoy_Muamba.pdf';
  
              this.StudentCode1 = 'KISIMBA NGOY Ndalewe Lilian            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ndalewe_Lilian_Kisimba_Ngoy.pdf';
  
             this.StudentCode2 = 'KISIMBA NGOY MADIYA Eden-Malika';
             this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Eden_Malika_Kisimba_Ngoy_Madiya.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1515':
            if( this.StudentCodeSecret == '137'){
              this.StudentCode = 'GUSU-WO AMBAZUDA Daniel      ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daniel_Gusu_Wo_Ambazuda.pdf';
  
              //this.StudentCode1 = 'KISIMBA NGOY Ndalewe Lilian            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Kisimba_Bul4SC.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1518':
            if( this.StudentCodeSecret == '139'){
              this.StudentCode = 'MULUMBA KALAMBAY Benjamin      ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Benjamin_Mulumba_Kalambay.pdf';
  
              //this.StudentCode1 = 'KISIMBA NGOY Ndalewe Lilian            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Kisimba_Bul4SC.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1524':
            if( this.StudentCodeSecret == '141'){
              this.StudentCode = 'SANGWA Daniel    ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daniel_Sangwa.pdf';
  
              //this.StudentCode1 = 'KISIMBA NGOY Ndalewe Lilian            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Kisimba_Bul4SC.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1528':
            if( this.StudentCodeSecret == '143'){
              this.StudentCode = 'MASIALA BINDA Veronica    ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Veronica_Grace_Masiala_Binda.pdf';
  
              //this.StudentCode1 = 'MASIALA BLOOM Marie-Josephine           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Masiala_Marie_Josephine.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1529':
            if( this.StudentCodeSecret == '577'){
              //this.StudentCode = 'LEMAIRE William   ';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Lemaire_William_P3B.pdf';
  
              this.StudentCode1 = 'LEMAIRE Leana           ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Leana_Lemaire.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';Leana_Lemaire
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1532':
            if( this.StudentCodeSecret == '145'){
              this.StudentCode = 'HATTA KETAMU Cynthia Golden   ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Cynthia_Golden_Hatta_Ketamu.pdf';
  
              //this.StudentCode1 = 'LOMOTO Christian           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Lomoto_Christian_P2D.pdf';
  
              //this.StudentCode2 = 'LUYEYE Watoma Emmanuelle';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Luyeye_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1534':
            if( this.StudentCodeSecret == '147'){
              this.StudentCode = 'TSHOVU Mukendi Naomi   ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mukendi_Naomi_Tshovu.pdf';
  
              this.StudentCode1 = 'TSHOVU Josephine Benijah            ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Josephine_Benijah_Tshovu.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1541':
            if( this.StudentCodeSecret == '149'){
              this.StudentCode = 'AïSSI MBIASIMA Pearl  ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Pearl_Aissi_Mbiasima.pdf';
  
              this.StudentCode1 = ' AïSSI MBIASIMA  Pierre Travis          ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Pierre_Travis_Aissi_Mbiasima.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1546':
            if( this.StudentCodeSecret == '151'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'MWAMINI KASEREKA Josette ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Josette_Mwamini_Kasereka.pdf';
              }
             
  
              //this.StudentCode1 = 'TSHOVU Josephine Benijah            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Tshovu_Bul5MS.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1547':
            if( this.StudentCodeSecret == '153'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KABALA KAPINGA Grace ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Grace_Kabala_Kapinga.pdf';
        
              }
              
  
              //this.StudentCode1 = 'TSHOVU Josephine Benijah            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Tshovu_Bul5MS.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1560':
            if( this.StudentCodeSecret == '155'){
               //this.StudentCode = 'NKOO MABANTULA Jean-Marie ';
               //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s2ii/Nkoo_BulS2II.pdf';
  
              //this.StudentCode1 = 'TSHOVU Josephine Benijah            ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5ms/Tshovu_Bul5MS.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1563':
            if( this.StudentCodeSecret == '157'){
              this.StudentCode = 'TSHIVUADI KALOMBO TAMBWE Andre Alex ';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Andre_Alex_Tshivuadi_Kalombo_Tambwe.pdf';
  
              this.StudentCode1 = 'TSHIVUADI KALOMBO TAMBWE Maïcha           ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Maicha_Tshivuadi_Kalombo_Tambwe.pdf';
  
               //this.StudentCode2 = 'TSHIVUADI Marie';
               //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Tshivuadi_Marie_P2B.pdf';
  
               //this.StudentCode3 = 'TSHIVUADI KALOMBO Malaïka';
               //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/TSHIVUADI_MALAIKA.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1568':
            if( this.StudentCodeSecret == '159'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'NGIAMA Jovianna ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jovianna_Ngiama.pdf';
        
              }
             
  
              //this.StudentCode1 = 'TSHIVUADI KALOMBO TAMBWE Maïcha           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshivuadi_Bul5ES.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1573':
            if( this.StudentCodeSecret == '161'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'PHAMBU MBENZA Henry Michel';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Henry_Michel_Phambu_Mbenza.pdf';
              }
             
  
              //this.StudentCode1 = 'TSHIVUADI KALOMBO TAMBWE Maïcha           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshivuadi_Bul5ES.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1578':
            if( this.StudentCodeSecret == '163'){
              this.StudentCode = 'KUMBA Patient';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Patient_Kumba.pdf';
  
              //this.StudentCode1 = 'TSHIVUADI KALOMBO TAMBWE Maïcha           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshivuadi_Bul5ES.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1581':
            if( this.StudentCodeSecret == '165'){
              this.StudentCode = 'BOLODJWA EALE';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yohane_Bolodjwa_Eale_W_amenge.pdf';
  
              //this.StudentCode1 = 'TSHIVUADI KALOMBO TAMBWE Maïcha           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshivuadi_Bul5ES.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
            case '1582':
            this. StudentCode = 'NDRIN NAOMI';
            this.Ordre =false;
            break;
          case '1583':
            if( this.StudentCodeSecret == '167'){
              this.StudentCode = 'BERTHELOT Medhi Laurent';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Medhi_Laurent_Berthelot.pdf';
  
               //this.StudentCode1 = 'BERTHELOT Maël Guy           ';
               //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Berthelot_Mael%20_P2D.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1596':
            if( this.StudentCodeSecret == '579'){
               this.StudentCode = 'OKITOSOMBA EDUMBE Alphine';
               this.urlCode = 'http://lpl-rdc.com/BULLETINS/Alphine_Okitosomba_Edumbe.pdf';
  
              this.StudentCode1 = 'OKITOSOMBA MUKANYA Darren           ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Darren_Okitosomba_Mukanya.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1601':
            if( this.StudentCodeSecret == '581'){
              this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                //this.StudentCode = 'BILENGE David Clément';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Bilenge_David.pdf';
    
                //this.StudentCode1 = 'BILENGE Jérémie Donatien           ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Bilenge_Jeremie.pdf';
              }
           
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1603':
            if( this.StudentCodeSecret == '169'){
              this.StudentCode = 'KAPINGA MUBANGA Mauricette';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mauricette_Kapinga_Mubanga.pdf';
  
              //this.StudentCode1 = 'KAPINGA-MUBANGA Alexandre           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KAPINGA_ALEXANDRE.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1607':
            if( this.StudentCodeSecret == '171'){
              this.StudentCode = 'MANKONDO MUPAPA Jocelyne Diersi';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jocelyne_Diersi_Mankondo_Mupapa.pdf';
  
              this.StudentCode1 = 'MANKONDO LEMA Jonathan Thomas           ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Jonathan_Thomas_Mankondo_Lema.pdf';
  
              this.StudentCode2 = 'MANKONDO Maboko Joseph';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Maboko_Joseph_Mankondo.pdf';
  
              //this.StudentCode3 = 'MANKONDO N_KWIM Joël';
              //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MANKONDO_JOEL.pdf';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1612':
            if( this.StudentCodeSecret == '583'){
              //this.StudentCode = 'PUSHKIN Luka';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Pushkin_Luka.pdf';
  
              //this.StudentCode1 = 'PUSHKIN Maxim          ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/PUSHKIN_MAXIM.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1614':
            if( this.StudentCodeSecret == '585'){
              //this.StudentCode = 'KABWANSONGO AYIBIL Muriel';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabwasongo_Muriel_%20P5A.pdf';
  
              //this.StudentCode1 = 'PUSHKIN Maxim          ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/PUSHKIN_MAXIM.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1618':
            if( this.StudentCodeSecret == '587'){
              //this.StudentCode = 'VERSTRAETE LIMA Karl';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Verstraeten_Lima.pdf';
  
              //this.StudentCode1 = 'VERSTRAETE Isabella          ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/VERSTRAETE_ISABELLA.pdf';
  
              //this.StudentCode2 = 'TSHOVU MWADI Qetsia';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Tshovu_Bul6SE.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1624':
            if( this.StudentCodeSecret == '173'){
              //this.StudentCode = 'TSHIPU KAYOMBO Chancelle';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tshipu_bul6MS.pdf';
  
              this.StudentCode1 = 'TSHIPU KALEV Helene           ';
              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Helene_Tshipu_Kalev.pdf';
  
              this.StudentCode2 = 'KALEV-MUTONDO Daniel';
              this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Daniel_Kalev_Mutondo.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1629':
            if( this.StudentCodeSecret == '175'){
              this.StudentCode = 'M_BARIKO Presilia';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Presilia_M_bariko.pdf';
  
              //this.StudentCode1 = 'HATTA KAFOTA Samuel          ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/HATTA_SAMUEL.pdf';
  
              //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1630':
            if( this.StudentCodeSecret == '177'){
              this.StudentCode = 'MASHAKO MAMBA';
              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Benedict_Nathan_Mashako_Mamba.pdf';
  
              //this.StudentCode1 = 'TSHIPU KALEV Helene           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Tshipu_BulS1III.pdf';
  
              //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
          case '1631':
            if( this.StudentCodeSecret == '179'){
              //this.StudentCode = 'ILUNGA MPANGA Marty';
              //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Ilunga_Bul6MS.pdf';
  
              //this.StudentCode1 = 'TSHIPU KALEV Helene           ';
              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Tshipu_BulS1III.pdf';
  
              //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
  
              //this.StudentCode3 = 'Bulletin3';
              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode4 = 'Bulletin4';
              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
  
              //this.StudentCode5 = 'Bulletin5';
              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
              //this.Ordre =true;
              //}else{
                //this.errorMessage = "Code secret ou numéro de famille non valide "
              }
  
              //console.log('valeur url: ' + this.urlCode);
              //console.log('message erreur: ' + this.errorMessage);;;
            break;
            case '1634':
              if( this.StudentCodeSecret == '181'){
                this.StudentCode = 'YAV MUJING NACATSHAK Claudia';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Claudia_Yav_Mujing_Nacatshak.pdf';
    
                //this.StudentCode1 = 'YAV MULAJ Felicia           ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/YAV_FELICIA.pdf';
    
                //this.StudentCode2 = 'YAV TSHILOMBO Vanessa';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Yav_Vanessa_P5A.pdf';
    
                this.StudentCode3 = 'YAV MWAPE Angela';
                this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Angela_Yav_Mwape.pdf';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1635':
              if( this.StudentCodeSecret == '589'){
                this.StudentCode = 'N_DUA KALONG Dayana';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Dayana_N_dua_Kalong.pdf';
    
                //this.StudentCode1 = 'TSHIPU KALEV Helene           ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Tshipu_BulS1III.pdf';
    
                //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1641':
              if( this.StudentCodeSecret == '183'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'PANZA IFELO';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Georgina_Panza_Ifelo.pdf';
        
              }
                
    
                //this.StudentCode1 = 'TSHIPU KALEV Helene           ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Tshipu_BulS1III.pdf';
    
                //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1644':
              if( this.StudentCodeSecret == '185'){
                this.StudentCode = 'SHAMPA Barachiel';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Barachiel_Shampa.pdf';
    
                this.StudentCode1 = 'MPIDI Marie-Rose           ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Rose_Mpidi.pdf';
    
                //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1645':
              if( this.StudentCodeSecret == '187'){
                this.StudentCode = 'MPULU KANKIEZA Grace';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Grace_Felicienne_Mpulu_Kankieza.pdf';
    
                this.StudentCode1 = 'VIKA KANKIEZA Jean-Vincent          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Jean_Vincent_Vika_Kankieza.pdf';
    
                //this.StudentCode2 = 'KALEV-MUTONDO Daniel';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/kalev_Bul5ES.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1646':
              if( this.StudentCodeSecret == '189'){
                //this.StudentCode = 'MIOUIDI Anaki Jules';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1i/Miouidi_BulS1I.pdf';
    
                //this.StudentCode1 = 'MIOUIDI Noah Jules          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Jules_BulS2I.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1648':
              if( this.StudentCodeSecret == '191'){
                this.StudentCode = 'SAFUNIA KIAMBA Anne-Sophie';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Anne_Sophie_Safunia_Kiamba.pdf';
    
                this.StudentCode1 = 'ONEZYME NYOTA Gloria          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Onezyme_Nyota.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
            case '1654':
              if( this.StudentCodeSecret == '193'){
                this.StudentCode = 'BILEK Chiara';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Chiara_Genevieve_Bilek.pdf';
    
                //this.StudentCode1 = 'MIOUIDI Noah Jules          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Jules_BulS2I.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1659':
              if( this.StudentCodeSecret == '195'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'BANGUDI MFUANA Lea';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lea_Marie_Annie_Bangudi_Mfuana.pdf';
    
                this.StudentCode1 = 'BANGUDI BASUNGA Ethan          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ethan_Kayamba_Bangudi_Basunga.pdf';
    
                //this.StudentCode2 = 'KIBAMBA BANGUDI Yann';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibamba_Yann_P3B.pdf';
        
              }
              
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1661':
              if( this.StudentCodeSecret == '197'){
                this.StudentCode = 'PALMY Lise';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lise_Palmy.pdf';
    
                this.StudentCode1 = 'PALMY Kelly Ande Kalagi          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Kelly_Ande_Kalagi_Palmy.pdf';
    
                this.StudentCode2 = 'PALMY Auréole';
                this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Aureole_Palmy.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1671':
              if( this.StudentCodeSecret == '199'){
                this.StudentCode = 'KANYONGA KABEYA ISEALINA Florian';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Florian_Kanyonga_Kabeya_Isealina.pdf';
    
                //this.StudentCode1 = 'KANYONGA KABEYA Mpala-Mulu William          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kanyonga_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1674':
              if( this.StudentCodeSecret == '201'){
                this.StudentCode = 'KABEMBA Chiara';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Chiara_Maelle_Kabemba.pdf';
    
                //this.StudentCode1 = 'KABEMBA Yelena         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KABEMBA_YELENA.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1675':
              if( this.StudentCodeSecret == '203'){
                this.StudentCode = 'TCHELO MAZOMBO Ariel Christopher';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ariel_Christopher_Tchelo_Mazombo.pdf';
    
                //this.StudentCode1 = 'KANYONGA KABEYA Mpala-Mulu William          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kanyonga_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1676':
              if( this.StudentCodeSecret == '205'){
                //this.StudentCode = 'DIKONDO LUTHA Diju';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Dikondo_Bul6TQ.pdf';
    
                //this.StudentCode1 = 'KANYONGA KABEYA Mpala-Mulu William          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kanyonga_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1677':
              if( this.StudentCodeSecret == '207'){
                //this.StudentCode = 'GOOSSENS Ilhan Jan';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Goossens_Bul6SE.pdf';
    
                //this.StudentCode1 = 'KANYONGA KABEYA Mpala-Mulu William          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kanyonga_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1680':
              if( this.StudentCodeSecret == '591'){
                //this.StudentCode = 'MABUNDA Jean-Paul';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MABUNDA_JEAN_AUL.pdf';
    
                this.StudentCode1 = 'MABUNDA Michel-Ange Emmanuel         ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Michel_Ange_Emmanuel_Mabunda.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1682':
              if( this.StudentCodeSecret == '593'){
                //this.StudentCode = 'DEFISE Amaury';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Defise_Amaury.pdf';
    
                //this.StudentCode1 = 'DEFISE Benjamin         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/DEFISE_BENJAMIN.pdf';
    
                //this.StudentCode2 = 'DEFISE Raphaël';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/DEFISE_RAPHAEL.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1691':
              if( this.StudentCodeSecret == '209'){
                this.StudentCode = 'MOBUTU Ilaniya Ladawa';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ilaniya_Ladawa_Mobutu.pdf';
    
                //this.StudentCode1 = 'MOBUTU Isaiah Waza         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Mobutu_Isaiah.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1694':
              if( this.StudentCodeSecret == '595'){
                this.StudentCode = 'NTUMBA KAPOLE Bolide Junior';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bolide_Junior_Ntumba_Kapole.pdf';
    
                //this.StudentCode1 = 'MOBUTU Isaiah Waza         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Mobutu_Isaiah.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1704':
              if( this.StudentCodeSecret == '211'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'ELONGA LIBOKE Geoffrey';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Geoffrey_Elonga_Liboke.pdf';
    
                this.StudentCode1 = 'ELONGA IMANI Andrea          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Andrea_Elonga_Imani.pdf';
        
              }
                
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1708':
              if( this.StudentCodeSecret == '213'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'MORDANT Brian Manuel';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1ii/Mordant_BulS1II.pdf';
    
                //this.StudentCode1 = 'MORDANT Oceane          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mordant_Bul4ECO.pdf';
        
              }
           
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1712':
              if( this.StudentCodeSecret == '215'){
                this.StudentCode = 'TANGI Cassandra';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Cassandra_Grace_Mathilde_Tangi.pdf';
    
                //this.StudentCode1 = 'MORDANT Oceane          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mordant_Bul4ECO.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1714':
              if( this.StudentCodeSecret == '597'){
                //this.StudentCode = 'KIAKESIDI Fumu Mbombo Joyce';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KIAKESIDI_JOYCE.pdf';
    
                //this.StudentCode1 = 'MORDANT Oceane          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mordant_Bul4ECO.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1715':
              if( this.StudentCodeSecret == '217'){
                this.StudentCode = 'TSHIYOYO NGALAMULUME Paul Henri';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Paul_Henri_Tshiyoyo_Ngalamulume.pdf';
    
                //this.StudentCode1 = 'TSHIYOYO NGALAMULUME Pierre-Emma         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Tshiyoyo_Pierre_Emmanuel_P5A.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1719':
              if( this.StudentCodeSecret == '219'){
                this.StudentCode = 'KASUKU SIMADA David-Cornelis';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/David_Cornelis_Kasuku_Simada.pdf';
    
                //this.StudentCode1 = 'KASUKU MWANZA Isabelle          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kasuku_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1723':
              if( this.StudentCodeSecret == '221'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'BUZENGI MAFUTA Annabelle Joyce';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Annabelle_Joyce_Buzengi_Mafuta.pdf';
    
                this.StudentCode1 = 'UMBA DI MAFUTA Délices Michaël         ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Delices_Michael_Umba_Di_Mafuta.pdf';
        
              }
               
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1724':
              if( this.StudentCodeSecret == '223'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KASONGO LWANZA Princesse';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Princesse_Kasongo_Lwanza.pdf';
    
                 //this.StudentCode1 = 'NGOY LWANZA Christian         ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NGOY_CHRISTIAN.pdf';
        
              }
              
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1734':
              if( this.StudentCodeSecret == '599'){
                 //this.StudentCode = 'TABU Kyungu Jad';
                 //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/TABU_JAD.pdf';
    
                 //this.StudentCode1 = 'TABU KAKUDJI ALIMASI Jonathan         ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/TABU_JONATHAN.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1737':
              if( this.StudentCodeSecret == '225'){
                this.StudentCode = 'FINET DA CRUZ Lola Tamara';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lola_Tamara_Finet_Da_Cruz.pdf';
    
                // this.StudentCode1 = 'FINET DA CRUZ Manon         ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/FINET_DA_CRUZ_MANON.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1740':
              if( this.StudentCodeSecret == '227'){
                 //this.StudentCode = 'KAJ A-KAMB Benita';
                 //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kaj_a_Kamb_Bul6SE.pdf';
    
                //this.StudentCode1 = 'KASUKU MWANZA Isabelle          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Kasuku_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1743':
              if( this.StudentCodeSecret == '601'){
                this.StudentCode = 'SHAMBA Michel Ange';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Michel_Ange_Shamba.pdf';
    
                //this.StudentCode1 = 'FINET DA CRUZ Manon         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/FINET_DA_CRUZ_MANON.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1746':
              if( this.StudentCodeSecret == '229'){
                this.StudentCode = 'MOKOLI NGOY Camille Elie';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Camille_Elie_Mokoli_Ngoy.pdf';
    
                // this.StudentCode1 = 'MOKOLI Momeme Tiffany          ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MOKOLI_TIFFANY.pdf';
    
                 //this.StudentCode2 = 'MOKOLI TADY Pricillia';
                 //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Mokoli_Pricillia_%20P5A.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1752':
              if( this.StudentCodeSecret == '231'){
                this.StudentCode = 'PAY-PAY VAGHENI Kiyana';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kiyana_Pay-Pay_Vagheni.pdf';
    
                 //this.StudentCode1 = 'PAY-PAY VAGHENI Zayi          ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/PAY_PAY_ZAYI.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1753':
              if( this.StudentCodeSecret == '233'){
                this.StudentCode = 'M_SIRI KYULU Anais';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Anais_M_siri_Kyulu.pdf';
    
                 //this.StudentCode1 = 'M_SIRI KISYABA Aurore          ';
                 //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/M_siri_Bul6MS.pdf';
    
                 //this.StudentCode2 = 'M_SIRI NKOME Jérémy';
                 //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/M_SIRI_JEREMY.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1755':
              if( this.StudentCodeSecret == '235'){
                this.StudentCode = 'THALIA WATCHIBA';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Watchiba_Thalia_Watchiba.pdf';
    
                //this.StudentCode1 = 'M_SIRI KISYABA Aurore          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/M_siri_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1757':
              if( this.StudentCodeSecret == '237'){
                this.StudentCode = 'MOKONDA MA BATULE Marc Henri';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Marc_Henri_Mokonda_Ma_Batule.pdf';
    
                //this.StudentCode1 = 'M_SIRI KISYABA Aurore          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/M_siri_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1758':
              if( this.StudentCodeSecret == '603'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                 //this.StudentCode = 'WAMPA TENDA Christella';
                 //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/WAMPA_CHRISTELLA.pdf';
    
        
              }
                
                //this.StudentCode1 = 'M_SIRI KISYABA Aurore          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/M_siri_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1759':
              if( this.StudentCodeSecret == '239'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'N_GBENDE DIANGO Wendy Michelle';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/N_gbende_BulS2I.pdf';
    
                //this.StudentCode1 = 'N_GBENDE BONZIA Oliver-Sydney          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2iv/N_gbende_BulS2IV.pdf';
        
              }
               
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1764':
              if( this.StudentCodeSecret == '241'){
                this.StudentCode = 'BISIMWA LUEMBA Charles-Henri';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Charles_Henri_Bisimwa_Luemba.pdf';
    
                //this.StudentCode1 = 'N_GBENDE BONZIA Oliver-Sydney          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2iv/N_gbende_BulS2IV.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1770':
              if( this.StudentCodeSecret == '605'){
                //this.StudentCode = 'MUKE Tuyana';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/MUKE_TUYANA.pdf';
    
                //this.StudentCode1 = 'M_SIRI KISYABA Aurore          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/M_siri_Bul6MS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
             break;
            case '1771':
              if( this.StudentCodeSecret == '243'){
                this.StudentCode = 'HUSSEIN BARAKA Hussein';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Hussein_Hussein_Baraka.pdf';
    
                this.StudentCode1 = 'BUPILA FURAISHA Lea          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Lea_Bupila_Furaisha.pdf';
    
                //this.StudentCode2 = 'HUSSEIN NDAMB SHAMY Grégory';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Hussein_Gregory.pdf';
    
                //this.StudentCode3 = 'HUSSEIN Choukrani';
                //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/HUSSEIN%20_CHOUKRANI.pdf';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1778':
              if( this.StudentCodeSecret == '245'){
                //this.StudentCode = 'MOSIKWA KASHIKA Amelie';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Mosikwa_Bul6TQ.pdf';
    
                this.StudentCode1 = 'MOSIKWA AMANA Maxim          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Maxim_Mosikwa_Amana.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1781':
              if( this.StudentCodeSecret == '247'){
               // this.StudentCode = 'LUMBUENAMO GERE';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Lumbuenamo_Bul6MS.pdf';
    
                //this.StudentCode1 = 'AHONZIALA NZAPA NZONI David          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/AHONZIALA_DAVID.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1792':
              if( this.StudentCodeSecret == '249'){
                this.StudentCode = 'BULAYA Camille Mangaza';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Camille_Mangaza_Bulaya.pdf';
    
                this.StudentCode1 = 'BULAYA Heîdi Kaoma          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Heidi_Kaoma_Bulaya.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1801':
              if( this.StudentCodeSecret == '607'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'UTSHUDIEMA Daniel';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/UTSHUDIEMA_DANIEL.pdf';
        
              }
                
    
                //this.StudentCode1 = 'BULAYA Heîdi Kaoma          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Bulaya_Heidi_P6B.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1803':
              if( this.StudentCodeSecret == '251'){
                this.StudentCode = 'PLESSERS Maxime Ibrahim';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Maxime_Ibrahim_Plessers.pdf';
    
                //this.StudentCode1 = 'MOSIKWA AMANA Maxim          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Mosikwa_Bul5ES.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1819':
              if( this.StudentCodeSecret == '253'){
                this.StudentCode = 'LIKIN Tristan';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Tristan_Likin.pdf';
    
                //this.StudentCode1 = 'LIKIN Nathan          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/LIKIN_NATHAN.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1820':
              if( this.StudentCodeSecret == '609'){
                //this.StudentCode = 'BASEMBE ITELA LOFALANGA Precieuse';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Basembe_Precieuse.pdf';
    
               // this.StudentCode1 = 'BASEMBE BOMOLO BOMBOKA Feni         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Basembe_Feni_P5A.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
              case '1822':
              this.StudentCode = 'RIGUELLE SARAH';
              this.Ordre =true;
              break;
            case '1823':
              if( this.StudentCodeSecret == '611'){
                //this.StudentCode = 'ROUX Emmanuel';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Roux_Emmanuel_P2D.pdf';
    
                //this.StudentCode1 = 'ROUX Paul-Marie         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/ROUX_PAUL.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1829':
              if( this.StudentCodeSecret == '255'){
                this.StudentCode = 'HENRY Saniya';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Saniya_Henry.pdf';
    
                //this.StudentCode1 = 'MOSIKWA AMANA Maxim          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Mosikwa_Bul5ES.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1832':
              if( this.StudentCodeSecret == '613'){
               // this.StudentCode = 'KABUIKA Azalée Mbuyu';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kabuika_Azalee_P3B.pdf';
    
                this.StudentCode1 = 'KABUIKA Dahlia Mbuyu         ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dahlia_Mbuyu_Kabuika.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1844':
              if( this.StudentCodeSecret == '615'){
                //this.StudentCode = 'DITEKEMENA TSHIMANGA Jade';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/DITEKEMENA_JADE.pdf';
    
                //this.StudentCode1 = 'KABUIKA Dahlia Mbuyu         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6C/Kabuika_Dahlia_P6C.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1851':
              if( this.StudentCodeSecret == '257'){
                //this.StudentCode = 'MWEPU MAKASA TSHIANGALA Kylian';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Mwepu_Kylian_P2D.pdf';
    
                //this.StudentCode1 = 'MWEPU MAKASA TSHIANGALA Océane Bink   ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1ii/Mwepu_BulS1II.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1856':
              if( this.StudentCodeSecret == '259'){
                this.StudentCode = 'BIGOSHI Gloria Magnificat';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gloria_Magnificat_Bigoshi.pdf';
    
                //this.StudentCode1 = 'BIGOSHI William Gabriel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/BIGOSH_WILLIAM.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1857':
              if( this.StudentCodeSecret == '261'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'MUTINGA KATUBALONDI Evajolie';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Evajoly_Mutinga_Katubalondi.pdf';
              }
               
    
                //this.StudentCode1 = 'MOSIKWA AMANA Maxim          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Mosikwa_Bul5ES.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1859':
              if( this.StudentCodeSecret == '617'){
                //this.StudentCode = 'KWANDA-KINGU Peter';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kwanda_Kingu_Peter_P3C.pdf';
    
                //this.StudentCode1 = 'BIGOSHI William Gabriel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/BIGOSH_WILLIAM.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1860':
              if( this.StudentCodeSecret == '263'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MBAYA NGALULA Marie-Catherine';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Marie_Catherine_Mbaya_Ngalula.pdf';
    
                //this.StudentCode1 = 'MBAYA MATANDA Marie Josée         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Mbaya_Marie_Jose_P3B.pdf';
        
              }
               
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1862':
              if( this.StudentCodeSecret == '265'){
                this.StudentCode = 'KAYEMBE MINZUNDU Noe';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Noe_Kayembe_Minzundu.pdf';
    
                this.StudentCode1 = 'KAYEMBE NSAMINUE Marie Splendide          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Splendide_Kayembe_Nsaminue.pdf';
    
                this.StudentCode2 = 'KAYEMBE MODWARIZA Muriel';
                this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Muriel_Kayembe_Modwariza.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1866':
              if( this.StudentCodeSecret == '267'){
               // this.StudentCode = 'MULOKO MWAMBA Gaspy';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1ii/Muloko_BulS1II.pdf';
    
                //this.StudentCode1 = 'KAYEMBE NSAMINUE Marie Splendide          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2iii/kayembe_BulS2IV.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1867':
              if( this.StudentCodeSecret == '619'){
                //this.StudentCode = 'NYEMBO MAYUMA KALA Ayan';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/NYEMBO_AYAN.pdf';
    
               // this.StudentCode1 = 'NYEMBO KAPOYA LENGE Maëlys         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NYEMBO_MAELYS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1869':
              if( this.StudentCodeSecret == '621'){
                this.StudentCode = 'MULAND KAYIJ Nicolas Arthur';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nicolas_Arthur_Muland_Kayij.pdf';
    
                //this.StudentCode1 = 'NYEMBO KAPOYA LENGE Maëlys         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NYEMBO_MAELYS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1870':
              if( this.StudentCodeSecret == '269'){
                this.StudentCode = 'MAYEMBA Andrea';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Andrea_Mayemba.pdf';
    
                //this.StudentCode1 = 'MAYEMBA Lucas          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MAYEMBA_LUCAS.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1873':
              if( this.StudentCodeSecret == '271'){
                this.StudentCode = 'LUHONGE SIFA Chloe Alexia';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Chloe_Alexia_Luhonge_Sifa.pdf';
    
                //this.StudentCode1 = 'LUHONGE LEYA Audrey Gabriella          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Luhonge_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1875':
              if( this.StudentCodeSecret == '273'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'KAOZI LUBAMBA Serge-Jeremie';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s1ii/Kaozi_BulS1II.pdf';
        
              }
               
    
                //this.StudentCode1 = 'LUHONGE LEYA Audrey Gabriella          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Luhonge_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1876':
              if( this.StudentCodeSecret == '275'){
                this.StudentCode = 'LUFUMA SONY Carl-Anthony';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Carl_Anthony_Lufuma_Sony.pdf';
    
                //this.StudentCode1 = 'MASENGO SONY Kenzy-Joyce         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Masengo_Kenzy_Joyce.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1877':
              if( this.StudentCodeSecret == '277'){
                //this.StudentCode = 'BOLUMBE Kessia';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s3_sciences/Bolumbe_Bul3SC.pdf';
    
                //this.StudentCode1 = 'LUHONGE LEYA Audrey Gabriella          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Luhonge_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1882':
              if( this.StudentCodeSecret == '279'){
                //this.StudentCode = 'LABANA ILUNGA Jean-David';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Labana_Bul6SE.pdf';
    
                //this.StudentCode1 = 'LUHONGE LEYA Audrey Gabriella          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Luhonge_Bul6SE.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1889':
              if( this.StudentCodeSecret == '623'){
                //this.StudentCode = 'LUSINDE WA LUSANGI K. Paul';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Lusinde_Wa_Lusangi_Pau_P3C.pdf';
    
                this.StudentCode1 = 'LUSINDE WA LUSANGI Yohali         ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Yohali_Lusinde_Wa_Lusangi.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1890':
              if( this.StudentCodeSecret == '625'){
                //this.StudentCode = 'LI YIFU';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Li_Yifu_P5A.pdf';
    
                //this.StudentCode1 = 'LUSINDE WA LUSANGI Yohali         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6C/Lusinde_Yohali_P6C.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1893':
              if( this.StudentCodeSecret == '281'){
                this.StudentCode = 'KAHASHA CHILAGANE Raia';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Raia_Kahasha_Chilagane.pdf';
    
                //this.StudentCode1 = 'KAHASHA Ayo Imanie          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kahasha_Ayo_P3B.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1897':
              if( this.StudentCodeSecret == '283'){
                this.StudentCode = 'CHOVU Issa Christian';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Issa_Christian_Chovu.pdf';
    
                //this.StudentCode1 = 'CHOVU MUKENDI Eli          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Chovu_Eli.pdf';
    
                //this.StudentCode2 = 'CHOVU NDUNGI Isaac';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Chovu_Ndungi_Isaac.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1903':
              if( this.StudentCodeSecret == '627'){
                //this.StudentCode = 'KATEMBWE KAPINGA Grace';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Katembwe_Grace_P3B.pdf';
    
                //this.StudentCode1 = 'KAHASHA Ayo Imanie          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kahasha_Ayo_P3B.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1907':
              if( this.StudentCodeSecret == '285'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MATONDO TSUMBU Yohan';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yohan_Matondo_Tsumbu.pdf';
    
                //this.StudentCode1 = 'MATONDO LEBELENA Marie-Colette          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Matondo_Marie_Colette.pdf';
    
                //this.StudentCode2 = 'MATONDO-NIANGA  Maeva';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MATONDO_MAEVA.pdf';
        
              }
               
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1909':
              if( this.StudentCodeSecret == '629'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'BEYA TSHIBAMBE Ortance';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/BEYA_ORTANCE.pdf';
    
                //this.StudentCode1 = 'KASEKA Tshibambe Rosalie          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/KASEKA_ROSALIE.pdf';
    
                //this.StudentCode2 = 'NTUMBA Tshibambe (Mousharaf)';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NTUMBA_MOUSHARAF.pdf';
        
              }
                
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1912':
              if( this.StudentCodeSecret == '631'){
                //this.StudentCode = 'PEIFFER Nathan';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/PEIFFER_NATHAN.pdf';
    
                //this.StudentCode1 = 'KASEKA Tshibambe Rosalie          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/KASEKA_ROSALIE.pdf';
    
                //this.StudentCode2 = 'NTUMBA Tshibambe (Mousharaf)';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NTUMBA_MOUSHARAF.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1916':
              if( this.StudentCodeSecret == '633'){
                //this.StudentCode = 'MAKUNDA Marie-Christelle';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/Makunda_Marie_Christelle_P3A.pdf';
    
                this.StudentCode1 = 'MAKUNDA SEFEKESE Yanis Serge-Alain          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Yanis_Serge_Alain_Makunda_Sefekese.pdf';
    
                //this.StudentCode2 = 'NTUMBA Tshibambe (Mousharaf)';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NTUMBA_MOUSHARAF.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1917':
              if( this.StudentCodeSecret == '287'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'KOMBO LISASI Christ';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christ_Kombo_Lisasi.pdf';
    
                this.StudentCode1 = 'KOMBO BOOKE Victoria          ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Victoria_Kombo_Booke.pdf';
              }
               
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1918':
              if( this.StudentCodeSecret == '635'){
                //this.StudentCode = 'DAKLALLAH Nour';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Daklallah_Nour_P3C.pdf';
    
                //this.StudentCode1 = 'DAKLALLAH Ibrahim          ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/DAKLALLAH_IBRAHIM.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1925':
              if( this.StudentCodeSecret == '637'){
                //this.StudentCode = 'SALGADO Luis Reo';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/SALGADO_REO.pdf';
    
                //this.StudentCode1 = 'DAKLALLAH Ibrahim          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/DAKLALLAH_IBRAHIM.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1927':
              if( this.StudentCodeSecret == '289'){
                this.StudentCode = 'BOPE MABINTSH Keren';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Keren_Bope_Mabintsh.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1937':
              if( this.StudentCodeSecret == '291'){
                this.StudentCode = 'KASUNKA Mwanza Elliott';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mwanza_Elliott_Kasunka.pdf';
    
                //this.StudentCode1 = 'KASUNKA WA BANZA Elon          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kasunka_Elon_P3B.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1938':
              if( this.StudentCodeSecret == '639'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'MEMBA ONEMA Paul Rayan';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Memba_Paul_Rayan.pdf';
    
                //this.StudentCode1 = 'MEMBA Mughole Michaella         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MEMBA_MICHAELLA.pdf';
        
              }
               
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1939':
              if( this.StudentCodeSecret == '293'){
                this.StudentCode = 'KALONJI TAMBAYI Stephanie';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Stephanie_Kalonji_Tambayi.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1944':
              if( this.StudentCodeSecret == '641'){
                //this.StudentCode = 'AKEYE Camille';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Akeye_Camile.pdf';
    
                //this.StudentCode1 = 'MEMBA Mughole Michaella         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MEMBA_MICHAELLA.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1950':
              if( this.StudentCodeSecret == '295'){
                this.StudentCode = 'KELE MATUNDU Paul-Elie';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Paul_Elie_Kele_Matundu.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1955':
              if( this.StudentCodeSecret == '297'){
                this.StudentCode = 'BAGBENI Adeito Perla';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Adeito_Perla_Bagbeni.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1956':
              if( this.StudentCodeSecret == '643'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'SHAMBA SHAMBA Charly Junior';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/SHAMBA_CHARLY.pdf';
    
               // this.StudentCode1 = 'KONGO SHAMBA Berdy         ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kongo_Berdy_P5A.pdf';
        
              }
                
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1957':
              if( this.StudentCodeSecret == '645'){
                //this.StudentCode = 'OFUMA ELIJAH David';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Ofuma_Elijah_David_P3C.pdf';
    
                //this.StudentCode1 = 'OFUMA Eden         ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Ofuma_Eden_P5A.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1964':
              if( this.StudentCodeSecret == '299'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'YAMBA KABANGO Prince David';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Prince_David_Yamba_Kabango.pdf';
        
              }
               
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1966':
              if( this.StudentCodeSecret == '647'){
                this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'NTALAJA KUPA Winner';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Winner_Ntalaja_Kupa.pdf';
        
              }
                
    
                //this.StudentCode1 = 'OFUMA Eden         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Ofuma_Eden_P5A.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1977':
              this. StudentCode = 'MOUSSEBOIS VIRGINIE';
              this.Ordre =true;
              break;
            case '1979':
              if( this.StudentCodeSecret == '649'){
                //this.StudentCode = 'YAKA NAYABA Jestony';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Yaka_Jestony.pdf';
    
                //this.StudentCode1 = 'YAKA KOKONDE Ben-Rich         ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/YAKA_BEN_RICH.pdf';
    
                this.StudentCode2 = 'YAKA NDANSA Pierrette';
                this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/YAKA_PIERRETTE.pdf';
    
                this.StudentCode3 = 'YAKA MOTEMA Ephraïm';
                this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Ephraim_Yaka_Motema.pdf';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
              case '1980':
              this. StudentCode = 'UMBA YVETTE';
              this.Ordre =true;
              break;
            case '1982':
              if( this.StudentCodeSecret == '651'){
                //this.StudentCode = 'MADUDU BATUZITISA Karl Samuel';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Madudu_Karl_Samuel_P2B.pdf';
    
                //this.StudentCode1 = 'OFUMA Eden         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Ofuma_Eden_P5A.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1986':
              if( this.StudentCodeSecret == '301'){
                this.StudentCode = 'N_SA LOKWA Bradley';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bradley_N_sa_Lokwa.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1987':
              if( this.StudentCodeSecret == '303'){
                //this.StudentCode = 'MALILA Adam David';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Malila_Bul6SE.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1997':
              if( this.StudentCodeSecret == '653'){
                //this.StudentCode = 'MWAMBA KANSELA Mathys';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Mwamba_Mathys_P2B.pdf';
    
                //this.StudentCode1 = 'MWAMBA KANSELA Nolan         ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Mwamba_Nolan_%20P3B.pdf';
    
                //this.StudentCode2 = 'KASUNKA MWEPU SEPE Elliane';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KASUNKA_ELIANE.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '1998':
              if( this.StudentCodeSecret == '305'){
                this.StudentCode = 'BODI-MATONDO Jeovana';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jeovana_Bodi_Matondo.pdf';
    
                //this.StudentCode1 = 'BOPE MBAWOTA Carmel          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Bope_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '2001':
              if( this.StudentCodeSecret == '307'){
                //this.StudentCode = 'ARBID Ali';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s3_eco/Arbid_Bul3ECO.pdf';
    
               // this.StudentCode1 = 'ARBID Mohamad          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Arbid_Bul6TQ.pdf';
    
                //this.StudentCode2 = 'ARBID Jana';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6A/ARBID_JANA.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '2006':
              if( this.StudentCodeSecret == '309'){
                this.StudentCode = 'MWANA MAY DOSITHE Fils';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Fils_Mwana_May_Dosithe.pdf';
    
                //this.StudentCode1 = 'MWANA MAY MABUNI Miradi          ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mwana_May_Mabun_Miradi_P3C.pdf';
    
                this.StudentCode2 = 'MWANA MAY Olive';
                this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Olive_Mwana_May.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
            case '2007':
              if( this.StudentCodeSecret == '655'){
                this.StudentCode = 'KINYOKI Atea Stella';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Atea_Stella_Kinyoki.pdf';
    
                //this.StudentCode1 = 'MWANA MAY MABUNI Miradi          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mwana_May_Mabun_Miradi_P3C.pdf';
    
                //this.StudentCode2 = 'MWANA MAY Olive';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Mwana_Olive_P6B.pdf';
    
                //this.StudentCode3 = 'Bulletin3';
                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode4 = 'Bulletin4';
                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    
                //this.StudentCode5 = 'Bulletin5';
                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                //this.Ordre =true;
                //}else{
                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                }
    
                //console.log('valeur url: ' + this.urlCode);
                //console.log('message erreur: ' + this.errorMessage);;;
              break;
              case '2009':
                if( this.StudentCodeSecret == '657'){
                  this.StudentCode = 'LOKOTO PING ZAU Alberto Sancini';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Alberto_Sancini_Lokoto_Ping_Zau.pdf';
      
                  //this.StudentCode1 = 'MWANA MAY MABUNI Miradi          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mwana_May_Mabun_Miradi_P3C.pdf';
      
                  //this.StudentCode2 = 'MWANA MAY Olive';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Mwana_Olive_P6B.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2010':
                if( this.StudentCodeSecret == '311'){
                  this.StudentCode = 'ITAMA GIKAHOYA Ferdyl';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ferdyl_Itama_Gikahoya.pdf';
      
                  this.StudentCode1 = 'ITAMA ASIKALINE Darly Germaine          ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Darly_Germaine_Itama_Asikaline.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2016':
                if( this.StudentCodeSecret == '659'){
                  //this.StudentCode = 'NGOUOTO Malaïka Maria Sophia';
                  //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/NGOUOTO_MALAIKA.pdf';
      
                 // this.StudentCode1 = 'NGOUOTO Neil Karl          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Ngouoto_Neil.pdf';
      
                  //this.StudentCode2 = 'MWANA MAY Olive';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Mwana_Olive_P6B.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2017':
                if( this.StudentCodeSecret == '661'){
                 // this.StudentCode = 'LONJI KABEYA Michel-Ange';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Longi_Michel_Ange.pdf';
      
                  //this.StudentCode1 = 'LONJI BILONDA Johanna          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Lonji_johanna_P3C.pdf';
      
                  //this.StudentCode2 = 'MWANA MAY Olive';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Mwana_Olive_P6B.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2022':
                if( this.StudentCodeSecret == '313'){
                  this.StudentCode = 'BONDO Priscilla';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Priscilla_Bondo.pdf';
      
                  //this.StudentCode1 = 'ITAMA ASIKALINE Darly Germaine          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Itama_Bul5TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2023':
                if( this.StudentCodeSecret == '315'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'BOSHAB BESHAL MONYO Emmanuel';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emmanuel_Boshab_Beshal_Monyo.pdf';
    
                //this.StudentCode1 = 'BOSHAB BOSHAB Eva Ezer         ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Boshab_Eva.pdf';
    
                //this.StudentCode2 = 'BOSHAB NKETSH Mathilde';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/BOSHAB_MATHILDE.pdf';
        
              }
              
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2027':
                if( this.StudentCodeSecret == '663'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'GONDA DI MATINA Allan-Frédéric';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Gonda_D_Matina%20Allan_P3C.pdf';
    
                this.StudentCode1 = 'GONDA DI MATINA Chloé Bénédicte        ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Chloe_Benedicte_Gonda_Di_Matina.pdf';
        
              }
                 
      
                  //this.StudentCode2 = 'BOSHAB NKETSH Mathilde';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/BOSHAB_MATHILDE.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
              case '2028':
                if( this.StudentCodeSecret == '665'){
                  //this.StudentCode = 'TSHIBWABWA MASWEP Kayla Mulanga';
                  //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Tshibwabwa_Maswep.pdf';
      
                 // this.StudentCode1 = 'MADJANI Ermo Zoey Tumaini        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/Madjani_Zoey_P4C.pdf';
      
                 // this.StudentCode2 = 'NDJOKU Neriah';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/NDJOKU_NERIAH.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2030':
                if( this.StudentCodeSecret == '317'){
                  this.StudentCode = 'MULENDA MULAZA Ruphin';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ruphin_Mulenda_Mulaza.pdf';
      
                  //this.StudentCode1 = 'ITAMA ASIKALINE Darly Germaine          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Itama_Bul5TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2031':
                if( this.StudentCodeSecret == '319'){
                  this.StudentCode = 'ANDEMA KABOYI Muriel';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Muriel_Andema_Kaboyi.pdf';
      
                  //this.StudentCode1 = 'ANSIMA KABOYI Vanessa          ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/ANSIMA_VANESSA.pdf';
      
                  this.StudentCode2 = 'BINJA KABOYI Melissa';
                  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Melissa_Binja_Kaboyi.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2038':
                if( this.StudentCodeSecret == '321'){
                 // this.StudentCode = 'NYEMBWE KINI Lola';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Nyembwe_Bul6SE.pdf';
      
                  //this.StudentCode1 = 'ITAMA ASIKALINE Darly Germaine          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Itama_Bul5TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2040':
                if( this.StudentCodeSecret == '667'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MALIABO KOYAGIALO Zelhia';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MALIABO_ZELHIA.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'ANSIMA KABOYI Vanessa          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/ANSIMA_VANESSA.pdf';
      
                  //this.StudentCode2 = 'BINJA KABOYI Melissa';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6A/BINJA_MELISSA.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2041':
                if( this.StudentCodeSecret == '323'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'DIBATHIA NKETANI Jade';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jade_Meline_Dibathia_Nketani.pdf';
      
                 // this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                //  this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
        
              }
                  
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2042':
                if( this.StudentCodeSecret == '325'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'MUAMBA MBUJABO Yan David';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s2ii/Muamba_BulS2II.pdf';
    
               // this.StudentCode1 = 'MUAMBA MBUJABO Anne-Caroline          ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Muamba_Bul4SC.pdf';
        
              }
                 
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2049':
                if( this.StudentCodeSecret == '669'){
                  //this.StudentCode = 'VERHOESTRAETE Dan';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VERHOESTRAETE_DAN.pdf';
      
                  //this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                  //this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2051':
                if( this.StudentCodeSecret == '671'){
                 // this.StudentCode = 'MUKUNGU Israël';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MUKUNGU_ISRAEL.pdf';
      
                  //this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                  //this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2061':
                if( this.StudentCodeSecret == '327'){
                  this.StudentCode = 'NGANDU NGOYI Daniella';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daniella_Ngandu_Ngoyi.pdf';
      
                  this.StudentCode1 = 'KAPANGA NGOYI Nathan          ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Nathan_Kapanga_Ngoyi.pdf';
      
                  //this.StudentCode2 = 'MALABA NGOYI Israël                 ';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/MALABA_ISRAEL.pdf';
      
                  //this.StudentCode3 = 'NGOYI WA NGOYI Grace';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/NGOYI_WA_NGOYI_GRACE.pdf';
      
                  //this.StudentCode4 = 'NGALULA NGOYI Becky';
                  //this.urlCode4 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Ngalula_Becky_P5A.pdf';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2064':
                if( this.StudentCodeSecret == '673'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'MANZAMBI KAVAKO Clarkstone';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/MANZAMB_CLARKSTONE.pdf';
        
              }
                 
      
                  //this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                  //this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2065':
                if( this.StudentCodeSecret == '329'){
                  this.StudentCode = 'PHEMBA Lea';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lea_Phemba.pdf';
      
                  this.StudentCode1 = 'PHEMBA Roland          ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Roland_Phemba.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2068':
                if( this.StudentCodeSecret == '331'){
                  this.StudentCode = 'SAFI MUTABUSHA MUYEMBE Aurelie';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Aurelie_Safi_Mutabusha_Muyembe.pdf';
      
                  //this.StudentCode1 = 'EFFILES MUYEMBE Cassey-Martine        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Effiles_Muyembe_Bul6TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2069':
                if( this.StudentCodeSecret == '333'){
                  this.StudentCode = 'KIKUNDA Yomi Ekila';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yomi_Ekila_Kikunda.pdf';
      
                  //this.StudentCode1 = 'EFFILES MUYEMBE Cassey-Martine        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Effiles_Muyembe_Bul6TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2071':
                if( this.StudentCodeSecret == '335'){
                  this.StudentCode = 'LEHANI RASHIDI Jonathan';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jonathan_Lehani_Rashidi.pdf';
      
                  //this.StudentCode1 = 'EFFILES MUYEMBE Cassey-Martine        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Effiles_Muyembe_Bul6TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2072':
                if( this.StudentCodeSecret == '337'){
                 // this.StudentCode = 'MASANGU KALENGA Shekina';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Masangu_Bul6MS.pdf';
      
                  //this.StudentCode1 = 'EFFILES MUYEMBE Cassey-Martine        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Effiles_Muyembe_Bul6TQ.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2075':
                if( this.StudentCodeSecret == '675'){
                 // this.StudentCode = 'NDONGENDEBA Elijah Ipoma';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NDONGENDEBA_ELIJAH.pdf';
      
                  //this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                  //this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2077':
                if( this.StudentCodeSecret == '677'){
                 // this.StudentCode = 'BYABUZE MBOKOLO Marc Henri';
                  //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/BYABUZE_MARC_HENRI.pdf';
      
                  //this.StudentCode1 = 'DIBATHIA NKETANI Lillyah          ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Dibathia_Lillyah_P3B.pdf';
      
                  //this.StudentCode2 = 'DIBATHIA NKETANI Nolan';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Dibathia_Nolan_%20P5A.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2081':
                if( this.StudentCodeSecret == '339'){
                 // this.StudentCode = 'IRAKOZE Carol Simplice';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Irakoze_e_Bul4SC.pdf';
      
                  //this.StudentCode1 = 'INANKUYO Marie Ange Josepha                  ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Inankuyo_Josepha_%20P6B.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2089':
                if( this.StudentCodeSecret == '341'){
                  this.StudentCode = 'JABER Hussein';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Hussein_Jaber.pdf';
      
                  //this.StudentCode1 = 'JABER Ibrahim        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Jaber_Bul6MS.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2095':
                if( this.StudentCodeSecret == '343'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'ENGULU BOYEKA LOMIAKA';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Loile_Marie_Engulu_Boyeka_Lomiaka.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'JABER Ibrahim        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Jaber_Bul6MS.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2098':
                if( this.StudentCodeSecret == '345'){
                  this.StudentCode = 'MBAKU MANGAMFU Reiel';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Reiel_Mbaku_Mangamfu.pdf';
      
                  //this.StudentCode1 = 'JABER Ibrahim        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Jaber_Bul6MS.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2103':
                if( this.StudentCodeSecret == '679'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MADAKA MIENAKU (Claudia)';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/MADAKA_CLAUDIA.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'INANKUYO Marie Ange Josepha                  ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Inankuyo_Josepha_%20P6B.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2105':
                if( this.StudentCodeSecret == '681'){
                 // this.StudentCode = 'KIALA BAMBI Antony Junior';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KIALA_ANTHONY.pdf';
      
                  //this.StudentCode1 = 'INANKUYO Marie Ange Josepha                  ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Inankuyo_Josepha_%20P6B.pdf';
      
                  //this.StudentCode2 = 'MIOUIDI Sabrina Gulrich';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Miouidi_Sabrina_BulS2I.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2106':
                if( this.StudentCodeSecret == '347'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MWEHU Charles Mayundo';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Charles_Mayundo_Mwehu.pdf';
      
                  this.StudentCode1 = 'MWEHU Henri Baruani        ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Henri_Baruani_Mwehu.pdf';
      
                  this.StudentCode2 = 'MWEHU Chloe';
                  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Chloe_Mwehu.pdf';
        
              }
                  
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2107':
                if( this.StudentCodeSecret == '683'){
                 // this.StudentCode = 'BOPEYA Lila';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/BOPEYA_LILA.pdf';
      
                 // this.StudentCode1 = 'BOPEYA Lior Lekoboko        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Bopeya_Lior_P5A.pdf';
      
                  //this.StudentCode2 = 'MWEHU Chloe';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Mwehu_Bul4SC.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2108':
                if( this.StudentCodeSecret == '685'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'TSASA MERSEIGNE Emmanuel';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Tsasa_Emmanuel.pdf';
    
                //this.StudentCode1 = 'TSASA NZAMBI Laurence        ';
                //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Tsasa_Laurence.pdf';
    
               // this.StudentCode2 = 'TSASA BUEYA Grâce-Véro';
                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/TSASA_GRACE_VERO.pdf';
        
              }
                 
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2109':
                if( this.StudentCodeSecret == '349'){
                  this.StudentCode = 'MBELOLA Neema';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Neema_Mbelola.pdf';
      
                  //this.StudentCode1 = 'MWEHU Henri Baruani        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s2i/Mwehu_BulS2I.pdf';
      
                  //this.StudentCode2 = 'MWEHU Chloe';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Mwehu_Bul4SC.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2114':
                if( this.StudentCodeSecret == '351'){
                  this.StudentCode = 'KANI KANI LUAMBA Nodin';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nodin_Kani_Kani_Luamba.pdf';
      
                  this.StudentCode1 = 'KANI KANI OPINE Nathan        ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Nathan_Kani_Kani_Opine.pdf';
      
                  this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Nehemie_Kani_Kani_Mpiri.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2115':
                if( this.StudentCodeSecret == '687'){
                 // this.StudentCode = 'MBATSHI TOVO William Simon-Floriber';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MBATSHI_WILLIAM.pdf';
      
                 // this.StudentCode1 = 'MBATSHI TOVO Alexandre Blaise       ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MBATSHI_ALEXANDRE.pdf';
      
                  //this.StudentCode2 = 'MWEHU Chloe';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Mwehu_Bul4SC.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2117':
                if( this.StudentCodeSecret == '689'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'ZANYAKO Simeon';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Zanyako_Simeon.pdf';
      
                 // this.StudentCode1 = 'ZANYAKO ZBONGA David        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Zanyako_David_P3B.pdf';
      
                //  this.StudentCode2 = 'ZANYAKO Yabi (Opale)';
                //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/ZANYAKO_OPALE.pdf';
        
              }
                  
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2118':
                if( this.StudentCodeSecret == '691'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MALEMBA KAPINGA Kyria Felicia';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Malemba_Kyria.pdf';
      
                //  this.StudentCode1 = 'SHABANI Zoé Malemba       ';
                //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/SHABANI_ZOE.pdf';
        
              }
                  
      
                  //this.StudentCode2 = 'MWEHU Chloe';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Mwehu_Bul4SC.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2120':
                if( this.StudentCodeSecret == '353'){
                  this.StudentCode = 'KASONGO NGEMBA Merci';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Merci_Kasongo_Ngemba.pdf';
      
                 // this.StudentCode1 = 'KASONGO BIELUNGA Beco Victor        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Kasongo_Victor_P2D.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
               break;
              case '2121':
                if( this.StudentCodeSecret == '693'){
                 // this.StudentCode = 'KABASU Thomas';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Kabasu_Thomas.pdf';
      
                  //this.StudentCode1 = 'KASONGO BIELUNGA Beco Victor        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Kasongo_Victor_P2D.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2124':
                if( this.StudentCodeSecret == '695'){
                 // this.StudentCode = 'FUTI BOLIE Asteria';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/FUTI%20_ASTERIA.pdf';
      
                  //this.StudentCode1 = 'KASONGO BIELUNGA Beco Victor        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Kasongo_Victor_P2D.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2125':
                if( this.StudentCodeSecret == '697'){
                //  this.StudentCode = 'MUANAMPU AYIR David';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Muanampu_David_P2D.pdf';
      
                 // this.StudentCode1 = 'MUANAMPU AYIR Gabrielle        ';
                //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MUANAMPU_GABRIELLE.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2127':
                if( this.StudentCodeSecret == '699'){
                 // this.StudentCode = 'KHATOUN YOHA AHMAD Adnan';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Khatoun_Adnan.pdf';
      
                 // this.StudentCode1 = 'KHATOUN YOHA AHMAD Mahdi        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Khatoun_Mahdi_P2B.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2129':
                if( this.StudentCodeSecret == '355'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MUHIYA FALIALA Gad';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gad_Muhiya_Faliala.pdf';
        
              }
                 
      
                  //this.StudentCode1 = 'KANI KANI OPINE Nathan        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Kani_kani_BulS1III.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2131':
                if( this.StudentCodeSecret == '701'){
                 // this.StudentCode = 'MBENGA TSHINGUTA Euphrasie                  ';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Mbenga_Euphrasie.pdf';
      
                 // this.StudentCode1 = 'NTUMBA KAKOLO Evan        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Ntumba_Evan_P2D.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2134':
                if( this.StudentCodeSecret == '357'){
                  this.StudentCode = 'MATATA YOHALI Archange';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Archange_Matata_Yohali.pdf';
      
                 // this.StudentCode1 = 'MATATA KABEDI Sainthia        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Matata_Sainthia_P2D.pdf';
      
                 // this.StudentCode2 = 'MATATA KAYIBA Divine';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MATATA_DIVINE.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2135':
                if( this.StudentCodeSecret == '359'){
                  this.StudentCode = 'ULUA EDUMBE Victoria';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Victoria_Ulua_Edumbe.pdf';
      
                //  this.StudentCode1 = 'ULUA LONGOMO Barack       ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/ULUA_BARACK.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2136':
                if( this.StudentCodeSecret == '703'){
                //  this.StudentCode = 'MAMAKALA Kahungu Jo_Sson';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Mamakala_Jo_sson.pdf';
      
                //  this.StudentCode1 = 'MAMAKALA KANKUNDA Splendide       ';
                //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MAMAKALA_SPLENDIDE.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2138':
                if( this.StudentCodeSecret == '705'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'NYOMBI PEMBA Graciella';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/NYOMBI_PEMBA_GRACIELLA.pdf';
      
                 // this.StudentCode1 = 'NYOMBI MBELENGE Anthony      ';
                //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/NYOMBI_ANTHONY.pdf';
        
              }
                  
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2139':
                if( this.StudentCodeSecret == '361'){
                  this.StudentCode = 'LUONGWE KIHANZULA Pitchou';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Pitchou_Luongwe_Kihanzula.pdf';
      
                 // this.StudentCode1 = 'LUONGWE SHIMBA Dorcas                ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/LUONGWE_DORCAS.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2142':
                if( this.StudentCodeSecret == '707'){
                 // this.StudentCode = 'KIMPWELA N_SELE Precieuse';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIMPWELA_PRECIEUSE.pdf';
      
                  //this.StudentCode1 = 'LUONGWE SHIMBA Dorcas                ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/LUONGWE_DORCAS.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2145':
                if( this.StudentCodeSecret == '709'){
                 // this.StudentCode = 'KALAMA Julian Nuru';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/KALAMA_JULIAN.pdf';
      
                  //this.StudentCode1 = 'LUONGWE SHIMBA Dorcas                ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/LUONGWE_DORCAS.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2146':
                if( this.StudentCodeSecret == '711'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MUBIMBA WAYANDI Marc Anthony';
               //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Mubimba_Marc_Anthony_P2B.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'LUONGWE SHIMBA Dorcas                ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/LUONGWE_DORCAS.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2147':
                if( this.StudentCodeSecret == '363'){
                  this.StudentCode = 'BECQUEVORT Harmony Kabagema';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Harmony_Kabagema_Becquevort.pdf';
      
                  //this.StudentCode1 = 'BECQUEVORT Lohan        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Becquevort_Lohan.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2148':
                if( this.StudentCodeSecret == '365'){
                  this.StudentCode = 'OKONGE MODESTE Junior Daniel';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Junior_Daniel_Okonge_Modeste.pdf';
      
                  this.StudentCode1 = 'OKONGE Joy        ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Joy_Okonge.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2149':
                if( this.StudentCodeSecret == '367'){
                  this.StudentCode = 'MPUEKELA TSHISUAKA (Keren)';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Keren_Mpuekela_Tshisuaka.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2150':
                if( this.StudentCodeSecret == '369'){
                  this.StudentCode = 'SITA PEMBELE NKANKU Gemima';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gemima_Sita_Pembele_Nkanku.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2155':
                if( this.StudentCodeSecret == '371'){
                  this.StudentCode = 'AMULI TAGIRAWABO Nathan';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nathan_Amuli_Tagirawabo.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2158':
                if( this.StudentCodeSecret == '713'){
                 // this.StudentCode = 'VANGU Kenayah Marie Christine';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/VANGU_KENAYAH.pdf';
      
                 // this.StudentCode1 = 'VANGU Emmanuella Marie Nelly        ';
                //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Vangu_Emmanuella_P3C.pdf';
      
                 // this.StudentCode2 = 'VANGU BUNGANA Leonel Louis';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/VANGU_LEONEL.pdf';
      
                 // this.StudentCode3 = 'VANGU Jean Gabriel';
                 // this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2159':
                if( this.StudentCodeSecret == '715'){
                 // this.StudentCode = 'MINAKU KENGE Imelda';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/MINAKU_IMELDA.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2160':
                if( this.StudentCodeSecret == '717'){
                 // this.StudentCode = 'KABUYA MBUYI BIN KABUYA Yithzack';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KABUYA_YITHZAC.pdf';
      
                 // this.StudentCode1 = 'KABUYA KABANGA BIN KABUYA Malaïka        ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KABUYA_MALAIKA.pdf';
      
                 // this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                 // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2162':
                if( this.StudentCodeSecret == '719'){
                 // this.StudentCode = 'KIBUKA KIA KIESE Raphaël Junior';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Kibuka_Raphael_P2B.pdf';
      
                //  this.StudentCode1 = 'KIBUKA KELA Monica       ';
                 // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
      
                  //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2166':
                if( this.StudentCodeSecret == '373'){
                  this.StudentCode = 'YAV Kurtis Mukiny';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kurtis_Mukiny_Yav.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2167':
                if( this.StudentCodeSecret == '721'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'MPOY WA MUAMBA David Yvan';
                //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Mpoy_Wa_Muamba_David_Yvan_P2D.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
      
                  //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2170':
                if( this.StudentCodeSecret == '723'){
                //  this.StudentCode = 'YOBELUO NANGAA DIMBISA Corneillio';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/YOBELUO_CORNEILLIO.pdf';
      
                  //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
      
                  //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2176':
                if( this.StudentCodeSecret == '375'){
                  this.StudentCode = 'MPUTU-MOKABILA Julien';
                  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Julien_Mputu-Mokabila.pdf';
      
                  //this.StudentCode1 = 'OKONGE Joy        ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Okonge_Bul5ES.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2177':
                if( this.StudentCodeSecret == '377'){
                  //this.StudentCode = 'PORSPERGER Ethan Alexandre';
                  //this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s3_eco/Porsperger_Bul3ECO.pdf';
      
                  this.StudentCode1 = 'PORSPERGER Tomiya Stamilie       ';
                  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Tomiya_Stamilie_Porsperger.pdf';
      
                  //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
      
                  //this.StudentCode3 = 'Bulletin3';
                  //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2178':
                if( this.StudentCodeSecret == '725'){
                 // this.StudentCode = 'NAWEJ MFUMWASH ASUMBUL Gradi';
                  //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Nawej_Gradi.pdf';
      
                  //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
      
                  //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                break;
              case '2183':
                if( this.StudentCodeSecret == '727'){
                  this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'NITU Ashton';
                 // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/NITU_ASHTON.pdf';
        
              }
                  
      
                  //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                  //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
      
                  //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                  //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
      
                  //this.StudentCode3 = 'VANGU Jean Gabriel';
                  //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
      
                  //this.StudentCode4 = 'Bulletin4';
                  //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      
                  //this.StudentCode5 = 'Bulletin5';
                  //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                  //this.Ordre =true;
                  //}else{
                    //this.errorMessage = "Code secret ou numéro de famille non valide "
                  }
      
                  //console.log('valeur url: ' + this.urlCode);
                  //console.log('message erreur: ' + this.errorMessage);;;
                case '2184':
                  if( this.StudentCodeSecret == '729'){
                   // this.StudentCode = 'MAVUNGU Héléna';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Mavungu_Helena.pdf';
        
                    //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
        
                    //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
        
                    //this.StudentCode3 = 'VANGU Jean Gabriel';
                    //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2185':
                  if( this.StudentCodeSecret == '731'){
                   // this.StudentCode = 'FREY Ruth Adorah';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Frey_Ruth.pdf';
        
                    //this.StudentCode1 = 'KIBUKA KELA Monica       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kibuka_Monica_P3B.pdf';
        
                    //this.StudentCode2 = 'KABUYA MBUYI MULOMBA Brayan Evarist';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kabuya_Brayan_P5A.pdf';
        
                    //this.StudentCode3 = 'VANGU Jean Gabriel';
                    //this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/VANGU_JEAN_GABRIEL.pdf';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2187':
                  if( this.StudentCodeSecret == '379'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'CHALUPA Kyara';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kyara_Chalupa.pdf';
        
              }
                   
        
                    //this.StudentCode1 = 'PORSPERGER Tomiya Stamilie       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Porsperger_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2188':
                  if( this.StudentCodeSecret == '381'){
                    this.StudentCode = 'ZHOU Zheng (Enock)';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Enock_Zhou_Zheng.pdf';
        
                   // this.StudentCode1 = 'WU YINGNAN       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Wu_Yingnan_P2D.pdf';
        
                   // this.StudentCode2 = 'XUE ZHIQIAN (Kevin)';
                   // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/XUE_KEVIN.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2192':
                  if( this.StudentCodeSecret == '733'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'MUBAY EYI Anaël';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MUBAY_ANAEL.pdf';
        
              }
                   
        
                    //this.StudentCode1 = 'PORSPERGER Tomiya Stamilie       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Porsperger_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2193':
                  if( this.StudentCodeSecret == '735'){
                   // this.StudentCode = 'ADRUPIAKO DONKERS Emmanuelle';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Adrupiako_Emmanuelle%20_P2B.pdf';
        
                    //this.StudentCode1 = 'PORSPERGER Tomiya Stamilie       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Porsperger_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2200':
                  if( this.StudentCodeSecret == '383'){
                    this.StudentCode = 'LEBENI Ilian Gabriel';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ilian_Gabriel_Celestin_Lebeni.pdf';
        
                    this.StudentCode1 = 'LEBENI Tanaël Kara       ';
                    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Tanael_Kara_Lebeni.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2210':
                  if( this.StudentCodeSecret == '385'){
                    this.StudentCode = 'TSHISEKEDI TSHIMIYI Christopher';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christopher_Tshisekedi_Tshimiyi.pdf';
        
                    this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Christian_Samuel_Tshisekedi_Mulumba.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2214':
                  if( this.StudentCodeSecret == '737'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
               // this.StudentCode = 'NDJOLI MPUTU Nathanuel';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Ndjoli_Nathanuel.pdf';
    
               // this.StudentCode1 = 'NDJOLI Venthan-Amba       ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Ndjoli_Venthan_Amba.pdf';
              }
                    
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2216':
                  if( this.StudentCodeSecret == '387'){
                    this.StudentCode = 'TCHUMA MILONGA Jonathan';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jonathan_Tchuma_Milonga.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2217':
                  if( this.StudentCodeSecret == '739'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
               // this.StudentCode = 'MIESSI GIPELA Victoria';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Miessi_Victoria.pdf';
    
               // this.StudentCode1 = 'MIESSI MATULONGA Jeremie       ';
              //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/MIESSI_JEREMIE.pdf';
              }
                    
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2223':
                  if( this.StudentCodeSecret == '741'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'DE FEO Jade';
                //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/De_Feo_Jade_P2D.pdf';
        
              }
                    
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2224':
                  if( this.StudentCodeSecret == '743'){
                    this.StudentCode = 'JACOBS Benjamin Michaël';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Benjamin_Michael_Jacobs.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2225':
                  if( this.StudentCodeSecret == '389'){
                   // this.StudentCode = 'WETCHI Merveille';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Wetchi_Bul6MS.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2229':
                  if( this.StudentCodeSecret == '745'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'YANG XIYU Shucrany';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Yang_Xiyu_Shucran_P2D.pdf';
        
              }
                   
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2237':
                  if( this.StudentCodeSecret == '747'){
                   // this.StudentCode = 'BOSALA NTAMFUMU Yohan';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bosala_Yohan_P2B.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2238':
                  if( this.StudentCodeSecret == '749'){
                   // this.StudentCode = 'TSHEBUA KADIMA Owen Messie';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/TSHEBWA_OWEN.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2239':
                  if( this.StudentCodeSecret == '751'){
                   // this.StudentCode = 'MABAYA MAVOKA Johnson';
                  //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Mabaya_Johnson.pdf';
        
                   // this.StudentCode1 = 'MABAYA MAVOKA Jordan     ';
                  //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Mabaya_Jordan.pdf';
        
                   // this.StudentCode2 = 'MABAYA MAVOKA Jojohn';
                   // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mabaya_jojohn_P3C.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2240':
                  if( this.StudentCodeSecret == '753'){
                   // this.StudentCode = 'MENDY Maelyss Madeleine Gigi';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Mendy_Maelyss_P2B.pdf';
        
                   // this.StudentCode1 = 'MENDY Miyah Antoinette     ';
                   // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MENDY_MIYAH.pdf';
        
                    //this.StudentCode2 = 'MABAYA MAVOKA Jojohn';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mabaya_jojohn_P3C.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2244':
                  if( this.StudentCodeSecret == '755'){
                  //  this.StudentCode = 'YOHALI NDAKALA Yolande';
                  //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Yohali_Yolande_P3B.pdf';
        
                    //this.StudentCode1 = 'MENDY Miyah Antoinette     ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MENDY_MIYAH.pdf';
        
                    //this.StudentCode2 = 'MABAYA MAVOKA Jojohn';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mabaya_jojohn_P3C.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2245':
                  if( this.StudentCodeSecret == '391'){
                    this.StudentCode = 'KIBAMBE NKONGOLO Triomphe';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Triomphe_Kibambe_Nkongolo.pdf';
        
                   // this.StudentCode1 = 'KIBAMBE MPOPOLA Fortune       ';
                   // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/KIBAMBE_FORTUNE.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2246':
                  if( this.StudentCodeSecret == '757'){
                    this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'KADIMA Malia Kapinga';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KADIMA_MALIA.pdf';
        
              }
                   
        
                    //this.StudentCode1 = 'KIBAMBE MPOPOLA Fortune       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/KIBAMBE_FORTUNE.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2248':
                  if( this.StudentCodeSecret == '393'){
                  //  this.StudentCode = 'IKUKT Feza';
                   // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Ikukt_Bul6SE.pdf';
        
                    //this.StudentCode1 = 'TSHISEKEDI MULUMBA Christian Samuel       ';
                    //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Tshisekedi_de_Bul5ES.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2249':
                  if( this.StudentCodeSecret == '759'){
                  //  this.StudentCode = 'BUHASHE CHIGANGU Abel';
                  //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/BUHASHE_ABEL.pdf';
        
                  //  this.StudentCode1 = 'NZIGIRE CHIGANGU Kerene Patricia       ';
                 //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/NZIGIRE_PATRICIA.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2250':
                  if( this.StudentCodeSecret == '395'){
                    this.StudentCode = 'SINGA KUPA MADUGALA Francois';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Francois_Singa_Kupa_Madugala.pdf';
        
                    this.StudentCode1 = 'SINGA YATOMBE Leonnie       ';
                    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Leonnie_Singa_Yatombe.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2252':
                  if( this.StudentCodeSecret == '761'){
                  //  this.StudentCode = 'NGAMBI TITA Alexandre David Michel ';
                  //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Ngambi_Alexandre_P2B.pdf';
        
                    this.StudentCode1 = 'MAHY Pol       ';
                    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Pol_Mahy.pdf';
        
                    //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                    //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                case '2254':
                  if( this.StudentCodeSecret == '397'){
                    this.StudentCode = 'BILOMBA Trish Agreyta';
                    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Trish_Agreyta_Bilomba.pdf';
        
                    this.StudentCode1 = 'BILOMBA WA BILOMBA Priscilla       ';
                    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Priscilla_Bilomba_Wa_Bilomba.pdf';
        
                  //  this.StudentCode2 = 'BILOMBA Emmanuella Isabelle';
                  //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Bilomba_Emmanuella.pdf';
        
                    //this.StudentCode3 = 'Bulletin3';
                    //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode4 = 'Bulletin4';
                    //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
        
                    //this.StudentCode5 = 'Bulletin5';
                    //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                    //this.Ordre =true;
                    //}else{
                      //this.errorMessage = "Code secret ou numéro de famille non valide "
                    }
        
                    //console.log('valeur url: ' + this.urlCode);
                    //console.log('message erreur: ' + this.errorMessage);;;
                  break;
                  case '2255':
                    if( this.StudentCodeSecret == '763'){
                   //   this.StudentCode = 'POLET Charline';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Polet_Charline.pdf';
          
                   //   this.StudentCode1 = 'POLET Florian ';
                   //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/POLET_FLORIAN.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2256':
                    if( this.StudentCodeSecret == '765'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'TANGU NOBATO Patrick-Noah';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Tangu_Noah.pdf';
          
                    //  this.StudentCode1 = 'TANGU EFOLE Naomi ';
                     // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/TANGU_NAOMI.pdf';
        
              }
                      
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2258':
                    if( this.StudentCodeSecret == '399'){
                      this.StudentCode = 'NZOLANTIMA NKEMBO';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nkembo_Nzolantima.pdf';
          
                      //this.StudentCode1 = 'BILOMBA WA BILOMBA Priscilla       ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Bilomba_Bul3TQ.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2260':
                    if( this.StudentCodeSecret == '767'){
                    //  this.StudentCode = 'TSHOVU KAMWANYA Orane Géraldine';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Tshovu_Orane_P3C.pdf';
          
                    //  this.StudentCode1 = 'TSHOVU ILUNGA Elvis Patient';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/TSHOVU_ELVIS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2261':
                    if( this.StudentCodeSecret == '769'){
                    //  this.StudentCode = 'SABWE KAMWANYA Anaëlle';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Sabwe_Anaelle.pdf';
          
                    //  this.StudentCode1 = 'SABWE ILUNGA Lucas Kemis';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/SABWE_LUCAS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2262':
                    if( this.StudentCodeSecret == '771'){
                    //  this.StudentCode = 'OMATOKO LOKETE NDJEKA Kabila Kabang';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Omatoko_Joseph.pdf';
          
                      //this.StudentCode1 = 'SABWE ILUNGA Lucas Kemis';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/SABWE_LUCAS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2264':
                    if( this.StudentCodeSecret == '773'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'YEMBE Benjamin';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Yembe_Benjamin.pdf';
    
              //  this.StudentCode1 = 'YEMBE Joseph';
              //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/YEMBE_JOSEPH.pdf';
        
              }
                     
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                  case '2265':
                    if( this.StudentCodeSecret == '401'){
                      this.StudentCode = 'BALINGIBISO Esther';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Esther_Balingibiso.pdf';
          
                     // this.StudentCode1 = 'BALINGIBISO Faith-Eunice       ';
                     // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BALINGIBISO_FAITH_EUNICE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2267':
                    if( this.StudentCodeSecret == '403'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'TONGO BINI Andre';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s4tq/Tongo_Bul4TQ.pdf';
    
               // this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
               // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
        
              }
                     
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2268':
                    this.StudentCode = 'KAKUDJI RACHEL';
                    this.Ordre =true;
                    break;
                  case '2271':
                    if( this.StudentCodeSecret == '405'){
                      this.StudentCode = 'M_BWANKIEM NIAROLIEM Jonathan';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jonathan_M_bwankiem_Niaroliem.pdf';
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2272':
                    if( this.StudentCodeSecret == '775'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'KILAPI OYELE Elio';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/KILAPI_ELIO.pdf';
          
                    //  this.StudentCode1 = 'KILAPI LUVILO Freesia      ';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Kilapi_Luvilo.pdf';
          
                   //   this.StudentCode2 = 'KILAPI PELA Alioune';
                    //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KILAPI_ALIOUNE.pdf';
        
              }
                      
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2273':
                    if( this.StudentCodeSecret == '777'){
                    //  this.StudentCode = 'SALUMU MUSAMBI Stephanie';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/SALUMU_STEPHANIE.pdf';
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2275':
                    if( this.StudentCodeSecret == '779'){
                    //  this.StudentCode = 'PAY-PAY MBUSA BUBUSA Marie-Ondeï';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Pay_pay_Marie_Ondei.pdf';
          
                     // this.StudentCode1 = 'PAY-PAY MBUSA BUBUSA Pierre';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/PAY_PAY_PIERRE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2276':
                    if( this.StudentCodeSecret == '407'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'AMOURI NZALI Elam';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Elam_Amouri_Nzali.pdf';
        
              }
                     
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2277':
                    if( this.StudentCodeSecret == '409'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'BILA UKUNDJI Dan';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Bila_Bul6SE.pdf';
        
              }
                     
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2279':
                    if( this.StudentCodeSecret == '411'){
                      this.StudentCode = 'SIMENE KILICHO Yasmine';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Yasmine_Simene_Kilicho.pdf';
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2281':
                    if( this.StudentCodeSecret == '781'){
                   //   this.StudentCode = 'MONGANE Alissa Josephine Basisa';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Mongane_Alissa_P3B.pdf';
          
                    //  this.StudentCode1 = 'MONGANE BALINABABO (Marc-Antoine)';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/MONGANE_MARC_ANTOINE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2282':
                    if( this.StudentCodeSecret == '413'){
                      this.StudentCode = 'MVULAZANA KILUBU Daisy';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daisy_Veronique_Mvulazana_Kilubu.pdf';
          
                      //this.StudentCode1 = 'TONGO LIKINDO Exaucee     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Tongo_Bul6MS.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2284':
                    if( this.StudentCodeSecret == '783'){
                   //   this.StudentCode = 'LIMPENS Gwendoline';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Limpes_Gwendoline.pdf';
          
                      //this.StudentCode1 = 'MONGANE BALINABABO (Marc-Antoine)';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/MONGANE_MARC_ANTOINE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2285':
                    this.StudentCode= 'MWEPU BROS CHARLENE';
                    this.Ordre =true;
                    break;
                  case '2286':
                    if( this.StudentCodeSecret == '415'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'MAKIASHI Junias';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Junias_Makiashi.pdf';
          
                      this.StudentCode1 = 'MAKIASHI Sem     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Sem_Makiashi.pdf';
          
                    //  this.StudentCode2 = 'MAKIASHI Vasti';
                    //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/MAKIASHI_VASTI.pdf';
          
                    //  this.StudentCode3 = 'MAKIASHI Aser';
                    //  this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MAKIASH_ASER.pdf';
        
              }
                      
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2287':
                    if( this.StudentCodeSecret == '785'){
                   //   this.StudentCode = 'NGOIE LUMISHA Justin Benjamin';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Ngoie_Benjamin_P2B.pdf';
          
                      //this.StudentCode1 = 'MONGANE BALINABABO (Marc-Antoine)';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/MONGANE_MARC_ANTOINE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2288':
                    if( this.StudentCodeSecret == '787'){
                      
                    //  this.StudentCode = 'PATAMARANGKOOL Chompoonapha';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6C/Patamarangkool_Chompoonapha_P6C.pdf';
          
                      //this.StudentCode1 = 'MONGANE BALINABABO (Marc-Antoine)';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/MONGANE_MARC_ANTOINE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2289':
                    if( this.StudentCodeSecret == '789'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'TANGI SINGA Bénédicte';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/TANGI_SINGA_BENEDICTE.pdf';
        
              }
                     
          
                      //this.StudentCode1 = 'MONGANE BALINABABO (Marc-Antoine)';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/MONGANE_MARC_ANTOINE.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2290':
                    this.StudentCode = 'PETTEAU STEEVE';
                    this.Ordre =true;
                    break;
                  case '2291':
                    if( this.StudentCodeSecret == '417'){
                      this.StudentCode = 'YEMA Henri Paul';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Henri_Paul_Yema.pdf';
          
                      //this.StudentCode1 = 'MAKIASHI Sem     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Makiashi_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2292':
                    if( this.StudentCodeSecret == '419'){
                     // this.StudentCode = 'NUZZO-ROMANO';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6ms/Nuzzo_Bul6MS.pdf';
          
                     // this.StudentCode1 = 'KOURKOULIOTIS ROMANO Athéna';
                     // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kourkouliotis_Athen_P3B.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2294':
                    if( this.StudentCodeSecret == '791'){
                     // this.StudentCode = 'BEMBA GALIMA Lianna Josette';
                      //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Bemba_Lianna.pdf';
          
                      //this.StudentCode1 = 'MAKIASHI Sem     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Makiashi_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2295':
                    this.StudentCode = 'TOMBU CHANTAL';
                    this.Ordre =false;
                    break;
                  case '2297':
                    if( this.StudentCodeSecret == '421'){
                      this.StudentCode = 'KAMANDA TSHAKAYA Asael';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Asael_Kamanda_Tshakaya.pdf';
          
                      this.StudentCode1 = 'KAMANDA BAGANDA Oren     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Oren_Kamanda_Baganda.pdf';
          
                     // this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                     // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2300':
                    if( this.StudentCodeSecret == '423'){
                      this.StudentCode = 'ZHAO Zihan';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Zihan_Zhao.pdf';
          
                      //this.StudentCode1 = 'KAMANDA BAGANDA Oren     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Kamanda_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2302':
                    this.StudentCode = 'ILYNE CHARLOTTE';
                    this.Ordre =false;
                    break;
                  case '2305':
                    if( this.StudentCodeSecret == '425'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'CHEN Rui';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Rui_Chen.pdf';
              }
                      
          
                      //this.StudentCode1 = 'KAMANDA BAGANDA Oren     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Kamanda_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2306':
                    if( this.StudentCodeSecret == '793'){
                     // this.StudentCode = 'MULUMBA TSHENDA Alexandre';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Mulumba_Alexandre.pdf';
          
                      //this.StudentCode1 = 'MAKIASHI Sem     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Makiashi_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2307':
                    if( this.StudentCodeSecret == '795'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'KATOMPA TSHITENGE Zoe';
                 //     this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/KATOMPA_ZOE.pdf';
        
              }
                      
          
                      //this.StudentCode1 = 'MAKIASHI Sem     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Makiashi_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2309':
                    if( this.StudentCodeSecret == '427'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'GIPEMBELE MABANDO Michael';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Gipembele_Bul6SE.pdf';
        
              }
                      
          
                      //this.StudentCode1 = 'KAMANDA BAGANDA Oren     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Kamanda_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2310':
                    if( this.StudentCodeSecret == '429'){
                      this.StudentCode = 'MWILWA TAMBWE Francis';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Francis_Mwilwa_Tambwe.pdf';
          
                      this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Benedicte_Grace_Musoga_Tambwe.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2313':
                    if( this.StudentCodeSecret == '431'){
                      this.StudentCode = 'JOIRIS Gabriel';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gabriel_Joiris.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2314':
                    if( this.StudentCodeSecret == '797'){
                     // this.StudentCode = 'MUKWANGA MEDI Sydné';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/MUKWANGA_SYDNE.pdf';
          
                      //this.StudentCode1 = 'MAKIASHI Sem     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Makiashi_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KANI KANI MPIRI Nehemie';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/Kani_kani_Bul5ES.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                   break;
                  case '2315':
                    if( this.StudentCodeSecret == '433'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'SEKE Vanecia Kibaba';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Vanecia_Kibaba_Seke.pdf';
        
              }
                     
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2317':
                    this.StudentCode = 'MATHY ASTRID ';
                    this.Ordre =true;
                    break;
                  case '2321':
                    if( this.StudentCodeSecret == '435'){
                      this.StudentCode = 'SANGES Matteo';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Matteo_Sanges.pdf';
          
                    //  this.StudentCode1 = 'SANGES LUSINDE Luigi ';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/SANGES_LUIGI.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2327':
                    if( this.StudentCodeSecret == '799'){
                   //   this.StudentCode = 'KATENDE Paul-Emile';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Katende_Paul_Emile_P5A.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2331':
                    if( this.StudentCodeSecret == '801'){
                    //  this.StudentCode = 'LECLERCQS Malya Rose-Marie';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Leclercqs_Malya_P3C.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2336':
                    if( this.StudentCodeSecret == '803'){
                    //  this.StudentCode = 'KAWARA Kenzo';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kawara_Kenzo_P3C.pdf';
          
                      this.StudentCode1 = 'KAWARA Allégria';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Allegria_Kawara.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2342':
                    if( this.StudentCodeSecret == '805'){
                    //  this.StudentCode = 'LANGE Tyssia';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/LANGE_TYSSIA.pdf';
          
                      //this.StudentCode1 = 'KAWARA Allégria';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6C/Kawara_All%C3%A9gria_P6C.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2344':
                    this.StudentCode = 'RIGUELLE SARAH';
                    this.Ordre =true;
                    break;
                  case '2346':
                    this.StudentCode = 'LIVRIZZI MARIA';
                    this.Ordre =false;
                    break;
                  case '2347':
                    if( this.StudentCodeSecret == '807'){
                    //  this.StudentCode = 'KASHAMA KWETE Jonathan';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Kashama_Jonathan.pdf';
          
                    //  this.StudentCode1 = 'KASHAMA MUTEBA Emmanuel';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/KASHAMA_EMMANUEL.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2349':
                    if( this.StudentCodeSecret == '809'){
                    //  this.StudentCode = 'AVZARADEL Shana';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Avzaradel_Shana_P3C.pdf';
          
                      //this.StudentCode1 = 'KASHAMA MUTEBA Emmanuel';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/KASHAMA_EMMANUEL.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2350':
                    this.StudentCode = 'CHARLIER SOPHIE';
                    this.Ordre =true;
                    break;
                  case '2353':
                    if( this.StudentCodeSecret == '437'){
                      this.StudentCode = 'NGONGO MANGAZA Christenvie';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christenvie_Ngongo_Mangaza.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2355':
                    if( this.StudentCodeSecret == '439'){
                      this.StudentCode = 'SAADA HAMULI Pascaline';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Pascaline_Saada_Hamuli.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2356':
                    if( this.StudentCodeSecret == '811'){
                   //   this.StudentCode = 'DOS ANJOS LEAO Nora Sarah';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/DOS_ANJOS_NORA.pdf';
          
                      this.StudentCode1 = 'DOS ANJOS LEAO Astrid-Redempta';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Astrid_Redempta_Dos_Anjos_Leao.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2357':
                    this.StudentCode = 'PREGARDIEN QUENTIN';
                    this.Ordre =false;
                    break;
                  case '2359':
                    if( this.StudentCodeSecret == '441'){
                      this.StudentCode = 'MUSANGU NKONGOLO Priscillia Anastas';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Priscillia_Anastas_Musangu_Nkongolo.pdf';
          
                      this.StudentCode1 = 'MUSANGU BILAMBO John Bells Benjamin';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/John_Bells_Benjamin_Musangu_Bilambo.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                    case '2361':
                    if( this.StudentCodeSecret == '1009'){
                      this.StudentCode = 'KIMBEMBE LUZAYAMO Chrismas';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Chrismas_Kimbembe_Luzayamo.pdf';
          
                      //this.StudentCode1 = 'MUSANGU BILAMBO John Bells Benjamin';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/John_Bells_Benjamin_Musangu_Bilambo.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2362':
                    if( this.StudentCodeSecret == '813'){
                     // this.StudentCode = 'BUENO METUSALA Noah';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BUENO_NOAH.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2363':
                    if( this.StudentCodeSecret == '815'){
                    //  this.StudentCode = 'GARBA Yvan Emmanuel';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Garba_Yvan_Emmanuel_P3C.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2364':
                    if( this.StudentCodeSecret == '817'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'KAHASHA MBASHA Jean-Daniel';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/KAHASHA_JEAN_DANIEL.pdf';
          
                    //  this.StudentCode1 = 'KAHASHA MBASHA Joseph-Hoshea';
                    //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kahasha_Joseph_Hoshea_P3C.pdf';
        
              }
                      
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2366':
                    if( this.StudentCodeSecret == '443'){
                      this.StudentCode = 'ZHOU Muyan';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Muyan_Zhou.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2368':
                    if( this.StudentCodeSecret == '445'){
                      this.StudentCode = 'BAHWERE NDOVA Jemima';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jemima_Bahwere_Ndova.pdf';
          
                      this.StudentCode1 = 'BAHWERE PALUKU Tresor     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Tresor_Bahwere_Paluku.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2369':
                    if( this.StudentCodeSecret == '819'){
                     // this.StudentCode = 'AMULI WILONDJA Versi Zahira Imani';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/AMUL_IMANI.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2370':
                    if( this.StudentCodeSecret == '447'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'AUTSAI AYIKORU Joy';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Joy_Autsai_Ayikoru.pdf';
        
              }
                    
          
                      //this.StudentCode1 = 'BAHWERE PALUKU Tresor     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Bahwere_Bul3ES.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2371':
                    if( this.StudentCodeSecret == '821'){
                    //  this.StudentCode = 'VAKE VAYIVWIRA Florent Michel';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Vake_Florent.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2372':
                    if( this.StudentCodeSecret == '823'){
                    //  this.StudentCode = 'MUNDADI KALALA Bryan Emmanuel';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mundadi_Brayan_P3C.pdf';
          
                      //this.StudentCode1 = 'MUSOGA TAMBWE Benedicte     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s1iii/Musoga_BulS1III.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2373':
                    this.StudentCode = 'KABANGU KANDA';
                    this.Ordre =true;
                    break;
                  case '2374':
                    if( this.StudentCodeSecret == '825'){
                     // this.StudentCode = 'LWANGO WATANGA Harvest';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Lwango_Harvest_%20P3C.pdf';
          
                      this.StudentCode1 = 'LWANGO MALATI Victoria';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Victoria_Lwango_Malati.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2375':
                    if( this.StudentCodeSecret == '449'){
                      this.StudentCode = 'FOTA KONGOLO KAJIKU Deo Gracias';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Deo_Gracias_Fota_Kongolo_Kajiku.pdf';
          
                      this.StudentCode1 = 'FOTA YOMBWE Joachim     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Joachim_Fota_Yombwe.pdf';
          
                    //  this.StudentCode2 = 'FOTA NGOMBE KAHEMBA Jan';
                     // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/FOTA_JAN.pdf';
          
                    //  this.StudentCode3 = 'FOTA SOMPO KAJIKU Alex';
                    //  this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/FOTA_ALEX.pdf';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2379':
                    if( this.StudentCodeSecret == '451'){
                      this.StudentCode = 'ENDUNDO FERREIRA Joachim Gregory';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Joachim_Gregory_Endundo_Ferreira.pdf';
          
                      this.StudentCode1 = 'ENDUNDO FERREIRA Sandro Félix ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Sandro_Felix_Endundo_Ferreira.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2380':
                    if( this.StudentCodeSecret == '453'){
                      this.StudentCode = 'SHULUNGU MUYUMBA Israel';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Israel_Shulungu_Muyumba.pdf';
          
                      this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Kerene_Shulungu_Omba.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2382':
                    if( this.StudentCodeSecret == '455'){
                    //  this.StudentCode = 'KINZONZI MOLENGI Guy Patrice';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kinzonzi_Bul6SE.pdf';
          
                      //this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Shulungu_Kerene_Bul5TQ.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2384':
                    this.StudentCode = 'MUNGA MURIEL';
                    this.Ordre =true;
                    break;
                  case '2385':
                    if( this.StudentCodeSecret == '457'){
                      this.StudentCode = 'ILUNGA-MBENGI Christelle';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christelle_Ilunga-Mbengi.pdf';
          
                      //this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Shulungu_Kerene_Bul5TQ.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2386':
                    if( this.StudentCodeSecret == '459'){
                      this.StudentCode = 'TSHIBINGU NDIBU Lionel';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lionel_Tshibingu_Ndibu.pdf';
          
                      //this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Shulungu_Kerene_Bul5TQ.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2387':
                    if( this.StudentCodeSecret == '461'){
                     // this.StudentCode = 'LOGBO Lagoda Janet Maria';
                     // this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s2ii/Logbo_BulS2II.pdf';
          
                      //this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Shulungu_Kerene_Bul5TQ.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2389':
                    if( this.StudentCodeSecret == '463'){
                      this.StudentCode = 'NSEKA LANDA Elliot Kiese';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Elliot_Kiese_Nseka_Landa.pdf';
          
                      this.StudentCode1 = 'NSEKA LANDA Danaelle     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Danaelle_Nseka_Landa.pdf';
          
                     // this.StudentCode2 = 'NSEKA LANDA Marissa Neema';
                    //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Nseka_Marissa.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2391':
                    if( this.StudentCodeSecret == '827'){
                   //   this.StudentCode = 'SHENG Yu Xia';
                  //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Sheng_Vanessa_P5A.pdf';
          
                      //this.StudentCode1 = 'SHULUNGU OMBA Kerene     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Shulungu_Kerene_Bul5TQ.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2394':
                    if( this.StudentCodeSecret == '829'){
                   //   this.StudentCode = 'WETSHI OTOMBA Rebeca';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Wetshi_Otomba_Rebeca.pdf';
          
                    //  this.StudentCode1 = 'AMBUTANAKA OTOMBA Esther';
                   //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Ambutakana_Esthe_P3C.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2395':
                    if( this.StudentCodeSecret == '465'){
                      this.StudentCode = 'MBUYU MUKOLONGO Keren';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Keren_Mbuyu_Mukolongo.pdf';
          
                      this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Samuel_Mbuyu_Ilunga_Nkassa.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2396':
                    if( this.StudentCodeSecret == '831'){
                    //  this.StudentCode = 'GROBELNY Samuel';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/GROBELNY_SAMUEL.pdf';
          
                      this.StudentCode1 = 'GROBELNY Joseph';
                      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Joseph_Grobelny.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2398':
                    if( this.StudentCodeSecret == '833'){
                    //  this.StudentCode = 'ACHOUR Kamel';
                    //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/ACHOUR_KAMEL.pdf';
          
                      //this.StudentCode1 = 'GROBELNY Joseph';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6C/Grobelny_Joseph%20_P6C.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2401':
                    this.StudentCode = 'DURIEZ ALEXANDRE';
                    this.Ordre =true;
                    break;
                  case '2405':
                    this.StudentCode = 'BORON KILLIAN';
                    this.Ordre =true;
                    break;
                  case '2406':
                    this.StudentCode = 'LEBEAU MARIE-PAULE';
                    this.Ordre =true;
                    break;
                  case '2409':
                    this.StudentCode = 'PREGARDIEN QUENTIN';
                    this.Ordre =false;
                    break;
                  case '2410':
                    if( this.StudentCodeSecret == '467'){
                      this.StudentCode = 'MORIAME Ryan';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ryan_Moriame.pdf';
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2411':
                    if( this.StudentCodeSecret == '469'){
                      this.StudentCode = 'DURIEUX Gaelle';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gaelle_Durieux.pdf';
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2412':
                    if( this.StudentCodeSecret == '471'){
                   //   this.StudentCode = 'KAYOMO MIBIER Joshua';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Kayom_Bul3ES.pdf';
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2415':
                    this.StudentCode = 'BOUVIER FREDERIC';
                    this.Ordre =true;
                    break;
                  case '2418':
                    if( this.StudentCodeSecret == '473'){
                      this.StudentCode = 'LWELA BINDA KADEFI Emmanuel';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emmanuel_Lwela_Binda_Kadefi.pdf';
          
                     // this.StudentCode1 = 'EMONY NDOMBE Parady Charline ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Emony_Parady_P3B.pdf';
          
                      //this.StudentCode2 = 'EMONY NDOMBE Benjamin';
                     // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/EMONY_BENJAMIN.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2420':
                    this.StudentCode = 'NOTTERMAN ANNIE';
                    this.Ordre =false;
                    break;
                  case '2421':
                    this.StudentCode = 'UMBA YVETTE';
                    this.Ordre =true;
                    break;
                  case '2423':
                    if( this.StudentCodeSecret == '835'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MIKALUKIDI LENGO Julianna Divine';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Mikalukidi_Julianna_P3C.pdf';
        
              }
                     
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2427':
                    this.StudentCode = 'LEBENI ILLA';
                    this.Ordre =false;
                    break;
                  case '2431':
                    if( this.StudentCodeSecret == '837'){
                      this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'LU YUAN Junior';
                   //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/lu_yuan.pdf';
        
              }
                      
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                  case '2433':
                    this.StudentCode = 'KABANGU KANDA';
                    this.Ordre =true;
                    break;
                  case '2434':
                    this.StudentCode = 'CHAMI KARIN';
                    this.Ordre =true;
                    break;
                  case '2437':
                    this.StudentCode = 'DEVOS OLIVIER';
                    this.Ordre =false;
                    break;
                  case '2439':
                    if( this.StudentCodeSecret == '475'){
                      this.StudentCode = 'DEMEESTER Josephine';
                      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Josephine_Severine_Demeester.pdf';
          
                      //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                      //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
          
                      //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                      //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
          
                      //this.StudentCode3 = 'Bulletin3';
                      //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode4 = 'Bulletin4';
                      //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
          
                      //this.StudentCode5 = 'Bulletin5';
                      //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                      
                      //this.Ordre =true;
                      //}else{
                        //this.errorMessage = "Code secret ou numéro de famille non valide "
                      }
          
                      //console.log('valeur url: ' + this.urlCode);
                      //console.log('message erreur: ' + this.errorMessage);;;
                    break;
                    case '2441':
                      if( this.StudentCodeSecret == '839'){
                       // this.StudentCode = 'DE JONGH Quinten';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/DE_JONGH_QUINTEN.pdf';
            
                       // this.StudentCode1 = 'DE JONGH Noah';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/De_Jongh_Noah_P2D.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2442':
                      if( this.StudentCodeSecret == '841'){
                       // this.StudentCode = 'KUGLIN Joanna Katherine';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Kuglin_Joanna.pdf';
            
                      //  this.StudentCode1 = 'KUGLIN Esther Kay';
                      //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kuglin_Esther_P3C.pdf';
            
                      //  this.StudentCode2 = 'KUGLIN Caleb Charles';
                     //   this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KUGLIN_CALEB.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2444':
                      if( this.StudentCodeSecret == '843'){
                       // this.StudentCode = 'FATAKI ALIPADE Séréna Gräce';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Fataki_Alipade.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2445':
                      if( this.StudentCodeSecret == '477'){
                        this.StudentCode = 'MAISHA MUKUNA Mael';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mael_Maisha_Mukuna.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2446':
                      if( this.StudentCodeSecret == '845'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'PORYVAEV Egor';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Poryvaev_Egor.pdf';
        
              }
                     
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2452':
                      if( this.StudentCodeSecret == '847'){
                     //   this.StudentCode = 'OTSCHUDI OMANGA Kenaya Nyembwe';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Otschudi_Kenaya_P2B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2453':
                      if( this.StudentCodeSecret == '849'){
                        this.StudentCode = 'MUKEBA KANUMUBADI Gilbert';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Mukeba_Gilbert_P2B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                    case '2455':
                      if( this.StudentCodeSecret == '851'){
                     //   this.StudentCode = 'DUHAU Maya Juliette';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Duhau_Maya.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2456':
                      if( this.StudentCodeSecret == '479'){
                        this.StudentCode = 'INKUNE MBOYO Joseph';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Joseph_Inkune_Mboyo.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2457':
                      if( this.StudentCodeSecret == '481'){
                        this.StudentCode = 'MWANA-DEO GRATIAS Gloria';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gloria_Mwana_Deo_Gratias.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2459':
                      if( this.StudentCodeSecret == '853'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MUPITA LUSAMBA Chris';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Mupita_Chris_P2D.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2460':
                      if( this.StudentCodeSecret == '855'){
                     //   this.StudentCode = 'KABUYA Leila Francesca';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Kabuya_Leila.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2461':
                      if( this.StudentCodeSecret == '483'){
                        this.StudentCode = 'BOYELA ILEMBWA Donel';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Donel_Boyela_Ilembwa.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2463':
                      if( this.StudentCodeSecret == '857'){
                      //  this.StudentCode = 'LANDU PHANZU Emmanuel';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Landu_Emmanuel_P2B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2464':
                      if( this.StudentCodeSecret == '859'){
                     //   this.StudentCode = 'DESCHRYVER Charles';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Deschryver_Charles_P2B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2465':
                      if( this.StudentCodeSecret == '861'){
                      //  this.StudentCode = 'MEULEMAN Eline Felicia Maria';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Meuleman_Eline.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2466':
                      if( this.StudentCodeSecret == '863'){
                      //  this.StudentCode = 'MATATA SAFI Christiana';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Matata_Christiana.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2467':
                      if( this.StudentCodeSecret == '865'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'FAUCHER Gustave';
              //          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Faucher_Gustave.pdf';
        
              }
                        
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2468':
                      this.StudentCode = 'MEYERS MARIE';
                      this.Ordre =true;
                      break;
                    case '2469':
                      if( this.StudentCodeSecret == '867'){
                      //  this.StudentCode = 'KAWONDA HAJIME Ethan-Dei';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Kawonda_Ethan_Dei.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2471':
                      this.StudentCode= 'MWEPU BROS CHARLENE';
                      this.Ordre =true;
                      break;
                    case '2472':
                      if( this.StudentCodeSecret == '869'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'KIBUKA MAKI Jahwill';
                //        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Kibuka_Jahwill_P2D.pdf';
        
              }
                        
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2473':
                      this.StudentCode = 'NZUZI YVON';
                      this.Ordre =true;
                      break;
                    case '2474':
                      if( this.StudentCodeSecret == '871'){
                    //    this.StudentCode = 'NSILULU LUZAYADIO Emmanuel';
                    //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Nsilulu_Lusayadio_Emmanuel%20_P2A_fusionne.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2476':
                      if( this.StudentCodeSecret == '485'){
                        this.StudentCode = 'MAKONGA NTUMBA MULOPWE Raissa';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Raissa_Makonga_Ntumba_Mulopwe.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2477':
                      if( this.StudentCodeSecret == '873'){
                      //  this.StudentCode = 'BISELELE OWASE Amalia';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Biselele_Amalia_P2D.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2478':
                      if( this.StudentCodeSecret == '487'){
                        this.StudentCode = 'NEEMA VUNABANDI Doxa';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Doxa_Neema_Vunabandi.pdf';
            
                      //  this.StudentCode1 = 'NIYONZIMA VUNABANDI Emmanuel ';
                      //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Niyonzima_Emmanuel.pdf';
            
                      //  this.StudentCode2 = 'UWIMPAYE VUNABANDI Zoé';
                     //   this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/UWINPAYE_ZOE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2480':
                      this.StudentCode = 'SUMAILI ARTHUR';
                      this.Ordre =true;
                      break;
                    case '2481':
                      if( this.StudentCodeSecret == '875'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'MBANGU TSHIKUT BINEN William Alexan';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Mbangu_William.pdf';
    
              //  this.StudentCode1 = 'MBANGU KAMWIN MUJING Yelena';
              //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/MBANGU_YELENA.pdf';
    
                this.StudentCode2 = 'MBANGU MWADI KADI Emy';
                this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Emy_Mbangu_Mwadi_Kadi.pdf';
        
              }
                       
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2483':
                      this.StudentCode = 'TOMBU CHANTAL';
                      this.Ordre =true;
                      break;
                    case '2484':
                      if( this.StudentCodeSecret == '877'){
                      //  this.StudentCode = 'SUAREZ ILUNGA Max';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Suarez_Max_P3B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2485':
                      if( this.StudentCodeSecret == '489'){
                        this.StudentCode = 'MUDINA WA MUDINA Bernoully';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bernoully_Mudina_Wa_Mudina.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2486':
                      if( this.StudentCodeSecret == '879'){
                      //  this.StudentCode = 'NGOYI MBAYA Joseph';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/NGOYI_JOSEPH.pdf';
            
                        this.StudentCode1 = 'NGOYI MUKENDI Samuella   ';
                        this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Samuella_Ngoyi_Mukendi.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2488':
                      if( this.StudentCodeSecret == '491'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'NEMOYATO ENKOTOSIYA Maguy';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Maguy_Nemoyato_Enkotosiya.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2489':
                      if( this.StudentCodeSecret == '493'){
                        this.StudentCode = 'BILA MINLANGU ZOLE Ana-Caroline';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ana_Caroline_Bila_Minlangu_Zole.pdf';
            
                      //  this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                      //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2491':
                      if( this.StudentCodeSecret == '495'){
                        this.StudentCode = 'KISHIMBA WA DIESE Eben Ezer';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Eben_Ezer_Kishimba_Wa_Diese.pdf';
            
                      //  this.StudentCode1 = 'MWEPU WA DYESE Jessyca';
                     //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Mwepu_Jessyca_P3B.pdf';
            
                        this.StudentCode2 = 'KAYUMBA WA DIESE Daniel';
                        this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Daniel_Kayumba_Wa_Diese.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2492':
                      if( this.StudentCodeSecret == '881'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MALENGO MADIYA Yvanna';
                //        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Malengo_Madiya.pdf';
        
              }
                        
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                      //  //console.log('valeur url: ' + this.urlCode);
                     //   //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2493':
                      if( this.StudentCodeSecret == '883'){
                       // this.StudentCode = 'ELONGA MPUTU Sérafine Madeleine';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Elonga_Seraphine.pdf';
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2494':
                      if( this.StudentCodeSecret == '885'){
                      //  this.StudentCode = 'SIMBA Eliana Urielle';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Simba_Eliana.pdf';
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2496':
                      if( this.StudentCodeSecret == '887'){
                      //  this.StudentCode = 'BAHATI WATELANINWA Charles';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Bahati_Charles.pdf';
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                     break;
                    case '2497':
                      if( this.StudentCodeSecret == '889'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'MASANI EPEKO Hachim';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Masani_Epeko_Hachim.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2498':
                      if( this.StudentCodeSecret == '891'){
                       // this.StudentCode = 'NGUNDA MUNZUMBU Marc-Anthony';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Ngunda_Munzumbu_Marc_Anthony%20_P2A_fusionn.pdf';
            
                        //this.StudentCode1 = 'BILA MINLANGU Love Christiana ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Bila_Love_Christiana_P2B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2499':
                      if( this.StudentCodeSecret == '497'){
                       // this.StudentCode = 'MUDAHAMA FAIDA Ines';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Mudahama_Bul6TQ.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2500':
                      if( this.StudentCodeSecret == '893'){
                      //  this.StudentCode = 'BAHATI KENGE NGANDU Emmanuel';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Bahati_Emmanuel.pdf';
            
                      //  this.StudentCode1 = 'BAHATI KENGE Guelsa ';
                     //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Bahati_Kenge_Guelsa.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;                    
                    case '2501':
                      if( this.StudentCodeSecret == '499'){
                        this.StudentCode = 'FERREIRA JUNIOR';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Malaika_Louise_Ferreira_Junior.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2502':
                      if( this.StudentCodeSecret == '501'){
                        this.StudentCode = 'MONGA Danielle';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Danielle_Monga.pdf';
            
                    //    this.StudentCode1 = 'MONGA NSENGA David  ';
                     //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Monga_David_P3B.pdf';
            
                    //    this.StudentCode2 = 'MONGA KAZADI Esther Bijou';
                   //     this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MONGA_ESTHER.pdf';
            
                        this.StudentCode3 = 'MONGA ILUNGA Adriel Roger';
                        this.urlCode3 = 'http://lpl-rdc.com/BULLETINS/Adriel_Roger_Monga_Ilunga.pdf';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2503':
                      this.StudentCode = 'DEBIESME ALEXANDRA';
                      this.Ordre =true;
                      break;
                    case '2505':
                      if( this.StudentCodeSecret == '503'){
                      //  this.StudentCode = 'SASSA NSIMINI Tristan';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Sassa_Bul6TQ.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2506':
                      this.StudentCode = 'LIVRIZZI MARIA';
                      this.Ordre =true;
                      break;
                    case '2507':
                      if( this.StudentCodeSecret == '895'){
                     //   this.StudentCode = 'KITOKO Camron Adolphe Fabien';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/KITOKO_CAMRON.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2508':
                      if( this.StudentCodeSecret == '897'){
                     //   this.StudentCode = 'ADAM Camille';
                    //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Adam_Camille.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2510':
                      if( this.StudentCodeSecret == '899'){
                      //  this.StudentCode = 'KALONJI NGALULA Bethel';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Kalonji_Bethel.pdf';
            
                      //  this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                      //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2512':
                      if( this.StudentCodeSecret == '901'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'IPAKALA ALIMOYA Grace';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Ipakala_Grace__P2D.pdf';
        
              }
                      
            
                        //this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2514':
                      if( this.StudentCodeSecret == '505'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KABEYA KABEYA';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Florian_Kanyonga_Kabeya_Isealina.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2515':
                      if( this.StudentCodeSecret == '507'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'KUSIKUMALA DUDUNA Cynthia';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Cynthia_Kusikumala_Duduna.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2516':
                      if( this.StudentCodeSecret == '509'){
                        this.StudentCode = 'ILOFO MBOYO Daniel';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daniel_Ilofo_Mboyo.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2517':
                      if( this.StudentCodeSecret == '511'){
                     //   this.StudentCode = 'MAYA MULONGOYI Nissi';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Maya_Bul6SE.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2519':
                      if( this.StudentCodeSecret == '513'){
                      //  this.StudentCode = 'TSHOMBA YADA Yada';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/Tshomba_Yada_Bul6TQ.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2522':
                      if( this.StudentCodeSecret == '515'){
                        this.StudentCode = 'MAKOSSO Cyril David';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Cyril_David_Makosso.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2524':
                      if( this.StudentCodeSecret == '903'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                this.StudentCode = 'SAGNO Mawa';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Mawa_Sagno.pdf';
        
              }
                        
            
                        //this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2525':
                      this.StudentCode = 'MOUSSEBOIS VIRGINIE';
                      this.Ordre =true;
                      break;
                    case '2526':
                      if( this.StudentCodeSecret == '905'){
                      //  this.StudentCode = 'ASITAKI ONIEMBA Alvaro';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Asitaki_Alvaro_P5A.pdf';
            
                        //this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2527':
                      this.StudentCode = 'UMBA YVETTE';
                      this.Ordre =false;
                      break;
                    case '2530':
                      if( this.StudentCodeSecret == '907'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MBASSA KOBETA Noah Emmanuel';
                   //     this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/MBASSA_NOAH.pdf';
        
              }
                        
            
                        //this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2533':
                      if( this.StudentCodeSecret == '517'){
                        this.StudentCode = 'LIZEBI SOFI Prince';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Prince_Lizebi_Sofi.pdf';
            
                      //  this.StudentCode1 = 'LIZEBI BOSUKU BUENGE Joachim ';
                       // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/LIZEBI_JOACHIM.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2536':
                      if( this.StudentCodeSecret == '909'){
                      //  this.StudentCode = 'MBAYAHE Marie-Kasya';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Mbayahe_Marie_Kasya.pdf';
            
                        //this.StudentCode1 = 'KALONJI KABEYA Abel  ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3C/Kalonji_Abel_P3C.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2537':
                      if( this.StudentCodeSecret == '911'){
                    //    this.StudentCode = 'KONDI MALEKIWA Sean Malungidi';
                    //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Kondi_Sean_P2B.pdf';
            
                   //     this.StudentCode1 = 'KONDI MALEKIWA Shanny Malundama ';
                  //      this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kondi_Shanny_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2538':
                      if( this.StudentCodeSecret == '519'){
                        this.StudentCode = 'MUNONGO Kevin Godefroid';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kevin_Godefroid_Munongo.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2539':
                      if( this.StudentCodeSecret == '913'){
                      //  this.StudentCode = 'FAYAD Ali';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/FAYAD_ALI.pdf';
            
                        //this.StudentCode1 = 'KONDI MALEKIWA Shanny Malundama ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kondi_Shanny_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;
                    case '2543':
                      if( this.StudentCodeSecret == '915'){
                     //   this.StudentCode = 'ABOUBACAR SADOU Ibrahim Aboubacar';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Aboubacar_Sadou_Ibrahim.pdf';
            
                        //this.StudentCode1 = 'KONDI MALEKIWA Shanny Malundama ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kondi_Shanny_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;
                      break;                    
                    case '2544':
                      if( this.StudentCodeSecret == '521'){
                      //  this.StudentCode = 'KAINDA KOUVAS Elise';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_6tq/KAINDA_Kouvas_Bul6TQ.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2547':
                      if( this.StudentCodeSecret == '523'){
                        this.StudentCode = 'MUGABO OLAME Elam';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Elam_Mugabo_Olame.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2548':
                      this.StudentCode = 'MUNGA MURIEL';
                      this.Ordre =true;
                      break;
                    case '2550':
                      if( this.StudentCodeSecret == '917'){
                     //   this.StudentCode = 'LENGO BEAUD Marie-Lucie Carmen';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Lengo_Marie_Lucie_P2D.pdf';
            
                      //  this.StudentCode1 = 'BEAUD LENGO Jean ';
                     //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Beaud_Jean_P5A.pdf';
            
                     //   this.StudentCode2 = 'LENGO BEAUD KIKELA Elie Joseph';
                     //   this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/LENGO_ELIE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2552':
                      if( this.StudentCodeSecret == '919'){
                     //   this.StudentCode = 'KANHY Maya Elisabeth';
                     //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Kanhy_Maya_P2B.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2553':
                      if( this.StudentCodeSecret == '525'){
                        this.StudentCode = 'HU Hanbing';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Charly_Hu_Hanbing.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2554':
                      if( this.StudentCodeSecret == '921'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'GANZA Igor Descartes';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Ganza_Igor_P2B.pdf';
    
              //  this.StudentCode1 = 'INEZA Nixie Pax';
              //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Ineza_Nixie_P5A.pdf';
        
              }
                       
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2555':
                      this.StudentCode = 'RIGUELLE SARAH';
                      this.Ordre =false;
                      break;
                      case '2557':
                      this.StudentCode = 'RIGUELLE SARAH';
                      this.Ordre =false;
                      break;
                    case '2558':
                      this.StudentCode = 'LIVRIZZI MARIA';
                      this.Ordre =false;
                      break;
                    case '2561':
                      this.StudentCode = 'ANDREANI ANIE';
                      this.Ordre =true;
                      break;
                    case '2562':
                      if( this.StudentCodeSecret == '923'){
                     //   this.StudentCode = 'KOLONGELE NGANUA Alicia';
                    //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Kolongele_Alicia_P2B.pdf';
            
                     //   this.StudentCode1 = 'KOLONGELE BINTENE Horatia';
                     //   this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kolongele_Horatia_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2563':
                      this. StudentCode = 'CHARLIER SOPHIE';
                      this.Ordre =false;
                      break;
                    case '2565':
                      if( this.StudentCodeSecret == '527'){
                        this.StudentCode = 'OKITOSOMBA OPENGE Albert Theia';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Albert_Theia_Okitosomba_Openge.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2567':
                      if( this.StudentCodeSecret == '925'){
                      //  this.StudentCode = 'KATEBA CHUKRANI Grace';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/kateba_Grace.pdf';
            
                        //this.StudentCode1 = 'KOLONGELE BINTENE Horatia';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kolongele_Horatia_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2568':
                      if( this.StudentCodeSecret == '927'){
                    //    this.StudentCode = 'BEYA KANTUNTU Dominique-Raphaël';
                    //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Beya_Dominique_Raphael.pdf';
            
                        //this.StudentCode1 = 'KOLONGELE BINTENE Horatia';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kolongele_Horatia_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2570':
                      if( this.StudentCodeSecret == '929'){
                        this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
             //   this.StudentCode = 'TATU Ethan Emmanuel Thiam';
            //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Tatu_Ethan_%20P2D.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'KOLONGELE BINTENE Horatia';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Kolongele_Horatia_P5A.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2571':
                      this.StudentCode = 'BOUZOUITA LAETITIA';
                      this.Ordre =true;
                      break;
                    case '2572':
                      if( this.StudentCodeSecret == '931'){
                    //    this.StudentCode = 'BIAGUI Nathan Philippe';
                   //     this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/BIAGUI_NATHAN.pdf';
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2573':
                      this.StudentCode = 'AGNEESSENS SUAD';
                      this.Ordre =true;
                      break;
                    case '2574':
                      if( this.StudentCodeSecret == '933'){
                        this.Ordre = false;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'MUNDADI Isaac Amen';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Mundadi_Isaac_P1C.pdf';
        
              }
                       
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2575':
                      if( this.StudentCodeSecret == '935'){
                      //  this.StudentCode = 'MUYAMBO SEKO Nate';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Muyambo_Nate.pdf';
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2576':
                      if( this.StudentCodeSecret == '937'){
                      //  this.StudentCode = 'KASONGA MISHIKA KABESE Anaëlle Gert';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Kasonga_Anaelle.pdf';
            
                        this.StudentCode1 = 'Carmella Kasonga Tshibola Ndunga';
                        this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Carmella_Be_Kasonga_Tshibola_Ndunga.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2577':
                      this. StudentCode = 'UMBA YVETTE';
                      this.Ordre =true;
                      break;
                    case '2578':
                      this.StudentCode = 'KABONGO GINETTE';
                      this.Ordre =true;
                      break;
                    case '2581':
                      this.StudentCode = 'LEBENI ILLA';
                      this.Ordre =true;
                      break;
                    case '2583':
                      if( this.StudentCodeSecret == '939'){
                       // this.StudentCode = 'RICHARD Nelson';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Richard_Nelson.pdf';
            
                        //this.StudentCode1 = 'RICHARD Juliette Marie-Laure';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6A/RICHARD_JULIETTE.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2584':
                      if( this.StudentCodeSecret == '529'){
                        this.StudentCode = 'NDONGALA KET Keline Rachel';
                        this.urlCode = 'http://lpl-rdc.com/BULLETINS/Keline_Rachel_Ndongala_Ket.pdf';
            
                        //this.StudentCode1 = 'MBUYU ILUNGA NKASSA Samuel     ';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_eco/Mbuyu_Bul4ECO.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2587':
                      if( this.StudentCodeSecret == '941'){
                       // this.StudentCode = 'MARGERY Princilia-Trésor';
                       // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2B/Margery_Princilia_Tresor_P2B.pdf';
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2588':
                      if( this.StudentCodeSecret == '943'){
                      //  this.StudentCode = 'KIYANGA KI-N_LOMBI Ethan-Philippe';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Kiyanga_Ethan.pdf';
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                    case '2589':
                      if( this.StudentCodeSecret == '945'){
                      //  this.StudentCode = 'MBAYA WALSH Michael';
                      //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Mbaya_Michael.pdf';
            
                        //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                        //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
            
                        //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                        //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
            
                        //this.StudentCode3 = 'Bulletin3';
                        //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode4 = 'Bulletin4';
                        //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            
                        //this.StudentCode5 = 'Bulletin5';
                        //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                        
                        //this.Ordre =true;
                        //}else{
                          //this.errorMessage = "Code secret ou numéro de famille non valide "
                        }
            
                        //console.log('valeur url: ' + this.urlCode);
                        //console.log('message erreur: ' + this.errorMessage);;;;
                      break;
                      case '2590':
                        if( this.StudentCodeSecret == '947'){
                        //  this.StudentCode = 'NGOY KASONGO Marie-Albertine';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Ngoy_Kasongo_Marie_Albertine.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2591':
                        if( this.StudentCodeSecret == '531'){
                          this.StudentCode = 'NTUMBA NGOY Abi Beatrice';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Abi_Beatrice_Ntumba_Ngoy.pdf';
              
                          this.StudentCode1 = 'NTUMBA NGOY Eunice Kapinga     ';
                          this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Eunice_Kapinga_Ntumba_Ngoy.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2592':
                        if( this.StudentCodeSecret == '949'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'BIYINGA Gabriella';
                       //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Biyinga_Gabriella.pdf';
        
              }
                          
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2595':
                        this.StudentCode = 'DELAET CEDRIC';
                        this.Ordre =false;
                        break;
                      case '2596':
                        this. StudentCode = 'DILU CHRISTELLE';
                        this.Ordre =true;
                        break;
                      case '2597':
                        if( this.StudentCodeSecret == '951'){
                        //  this.StudentCode = 'MOLISO TEBAZE Yoani Siméon';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/MOLISO_YOANI_SIMEON.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2598':
                        if( this.StudentCodeSecret == '953'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'KONGHOLO RACHIDI Radrich';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Kongholo_Radrich.pdf';
        
              }
                         
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                      case '2599':
                        this.StudentCode = 'HANSON AMELIE';
                        this.Ordre =false;
                        break;
                      case '2602':
                        this. StudentCode = 'ILUNGA SUAREZ MIREILLE';
                        this.Ordre =true;
                        break;
                      case '2604':
                        if( this.StudentCodeSecret == '533'){
                          this.StudentCode = 'KAJ KANYIMBU Chloe';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Chloe_Kaj_Kanyimbu.pdf';
              
                         // this.StudentCode1 = 'LUMBWE KANYIMBU Louannes ';
                        //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Lumbwe_Louannes_P5A.pdf';
              
                          this.StudentCode2 = 'KANYIMBU SHINDANY Brayan';
                          this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Brayan_Kanyimbu_Shindany.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2605':
                        this.StudentCode = 'KELE BENOIT';
                        this.Ordre =true;
                        break;
                      case '2606':
                        if( this.StudentCodeSecret == '955'){
                       //   this.StudentCode = 'ELOHO OMIONGA Alexandre';
                       //   this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/ELOHO_ALEXANDRE.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2607':
                        if( this.StudentCodeSecret == '535'){
                          this.StudentCode = 'MUKALAY ASSUMINI Laurie';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Laurie_Mukalay_Assumini.pdf';
              
                          //this.StudentCode1 = 'NTUMBA NGOY Eunice Kapinga     ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4_sc/Ntumba_Bul4SC.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2608':
                        if( this.StudentCodeSecret == '537'){
                          this.StudentCode = 'MUSAMPA MWABA Daniel';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Daniel_Musampa_Mwaba.pdf';
              
                          this.StudentCode1 = 'MUSAMPA KAPINGA Erica    ';
                          this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Erica_Musampa_Kapinga.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2609':
                        if( this.StudentCodeSecret == '539'){
                          this.StudentCode = 'KABUYA-LUMUNA CHEUSSI Christelle';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Christelle_Kabuya_Lumuna_Cheussi.pdf';
              
                          //this.StudentCode1 = 'MUSAMPA KAPINGA Erica    ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Musampa_Bul3ES.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2610':
                        this.StudentCode = 'MATETA PAGUY';
                        this.Ordre =true;
                        break;
                      case '2611':
                        if( this.StudentCodeSecret == '957'){
                      //    this.StudentCode = 'MASSAMBA LEBWAZE Beni Emmanuel';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/MASSAMBA_BENI_EMMANUEL.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                        case '2612':
                        this.StudentCode = 'MAURER CHAMMAS ALEXANDRA';
                        this.Ordre =false;
                        break;
                      case '2613':
                        if( this.StudentCodeSecret == '541'){
                          this.StudentCode = 'IVOUBA Charles-Alexandre';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Charles_Alexandre_Ivouba.pdf';
              
                          //this.StudentCode1 = 'MUSAMPA KAPINGA Erica    ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Musampa_Bul3ES.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2614':
                        if( this.StudentCodeSecret == '959'){
                        //  this.StudentCode = 'MBOYO BOFENDA Moyi Salah';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Mboyo_Moyi.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2615':
                        if( this.StudentCodeSecret == '961'){
                      //    this.StudentCode = 'KOUAKOU Candy Morane';
                     //     this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Kouakou_Candy_Morane.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2616':
                        this.StudentCode= 'MWEPU BROS CHARLENE';
                        this.Ordre =true;
                        break;
                      case '2617':
                        if( this.StudentCodeSecret == '963'){
                       //   this.StudentCode = 'VERVIER Sacha';
                      //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Vervier_Sacha_P3B.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2619':
                        if( this.StudentCodeSecret == '965'){
                        //  this.StudentCode = 'LIU Aoru';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Liu_Aorui.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2621':
                        if( this.StudentCodeSecret == '967'){
                       //   this.StudentCode = 'BOURJI HUSSEIN KANKU Mariam';
                      //    this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5A/Bourji_Mariam_P5A.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2622':
                        this.StudentCode = 'PEDUZY ALICE';
                        this.Ordre =false;
                        break;
                      case '2626':
                        this.StudentCode = 'PETTEAU STEEVE';
                        this.Ordre =true;
                        break;
                      case '2627':
                        this.StudentCode = 'RICHARD ROMAIN';
                        this.Ordre =false;
                        break;
                      case '2631':
                        if( this.StudentCodeSecret == '543'){
                          this.StudentCode = 'BAGALE BULIGO Lois';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lois_Bagale_Buligo.pdf';
              
                          //this.StudentCode1 = 'MUSAMPA KAPINGA Erica    ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Musampa_Bul3ES.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2632':
                        if( this.StudentCodeSecret == '969'){
                       //   this.StudentCode = 'SCHAUDINN Ayana Lea';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Schaudinn_Ayana.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                        case '2633':
                        if( this.StudentCodeSecret == '1011'){
                         this.StudentCode = 'LUTALA FEZA Allégresse';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Allegresse_Lutala_Feza.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2634':
                        if( this.StudentCodeSecret == '971'){
                        //  this.StudentCode = 'HOUBEN Keny';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2C/Houben_Keny.pdf';
              
                          //this.StudentCode1 = 'BIAGUI Prisca Denise Anaïs';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Biagui_Prisca_P6B.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2636':
                        if( this.StudentCodeSecret == '545'){
                          this.StudentCode = 'MATUNGUL NOBANA Dorcas Marie Benita';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Dorcas_Marie_Benita_Matungul_Nobana.pdf';
              
                          //this.StudentCode1 = 'MUSAMPA KAPINGA Erica    ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_3es/Musampa_Bul3ES.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2637':
                        this. StudentCode = 'COLSON SARAH';
                        this.Ordre =true;
                        break;
                      case '2638':
                        if( this.StudentCodeSecret == '547'){
                          this.StudentCode = 'MPOYI TSHIKALA Aggee';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Aggee_Mpoyi_Tshikala.pdf';
              
                          this.StudentCode1 = 'Glory Kamun Tshikala    ';
                          this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Glory_Kamun_Tshikala.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2639':
                        if( this.StudentCodeSecret == '549'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'VANDEWALLE Jennifer';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jennifer_Vandewalle.pdf';
    
                this.StudentCode1 = 'VANDEWALLE Jessica   ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Jessica_Vandewalle.pdf';
    
               // this.StudentCode2 = 'VANDEWALLE Céline Leonie';
              //  this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              }
                        
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2641':
                        if( this.StudentCodeSecret == '973'){
                    //      this.StudentCode = 'KAZITERIKO KASHIMBA Perez';
                    //      this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2A/Kaziteriko_Perez.pdf';
              
                      //    this.StudentCode1 = 'KAZITERIKO MADIKELA Perla';
                      //    this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIZITERIKO_PERLA.pdf';
              
                          //this.StudentCode2 = 'VANDEWALLE Céline Leonie';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2644':
                        this. StudentCode = 'PARDAENS RITA';
                        this.Ordre =true;
                        break;
                      case '2645':
                        if( this.StudentCodeSecret == '975'){
                        //  this.StudentCode = 'MUNGA Tiyani Bilikosi';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Munga_Tiyani.pdf';
              
                          //this.StudentCode1 = 'KAZITERIKO MADIKELA Perla';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIZITERIKO_PERLA.pdf';
              
                          //this.StudentCode2 = 'VANDEWALLE Céline Leonie';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2646':
                        if( this.StudentCodeSecret == '977'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'REMACLE Tiffany';
               // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/REMACLE_TIFFANY.pdf';
        
              }
                         
              
                          //this.StudentCode1 = 'KAZITERIKO MADIKELA Perla';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIZITERIKO_PERLA.pdf';
              
                          //this.StudentCode2 = 'VANDEWALLE Céline Leonie';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2648':
                        this. StudentCode = 'CHAUDRON MAÏTE';
                        this.Ordre =false;
                        break;
                      case '2649':
                        if( this.StudentCodeSecret == '979'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                //this.StudentCode = 'YANG Jie (Jacques)';
                //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P6B/Yang_Jie_Jacque_P6B.pdf';
        
              }
                         
              
                          //this.StudentCode1 = 'KAZITERIKO MADIKELA Perla';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIZITERIKO_PERLA.pdf';
              
                          //this.StudentCode2 = 'VANDEWALLE Céline Leonie';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                       break;
                      case '2650':
                        if( this.StudentCodeSecret == '981'){
                          //this.StudentCode = 'VUNDUAWE TE PEMAKO Anaya Karala';
                          //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5C/VUNDUAWE_ANAYA.pdf';
              
                          //this.StudentCode1 = 'KAZITERIKO MADIKELA Perla';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/KIZITERIKO_PERLA.pdf';
              
                          //this.StudentCode2 = 'VANDEWALLE Céline Leonie';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/VANDEWALLE_CELINE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2651':
                        if( this.StudentCodeSecret == '551'){
                          this.StudentCode = 'LUNDI Eunice';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Eunice_Lundi.pdf';
              
                          //this.StudentCode1 = 'VANDEWALLE Jessica   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Vandewalle_Bul5TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2652':
                        if( this.StudentCodeSecret == '553'){
                          this.StudentCode = 'KOSO LONGAYO Pierrette';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Pierrette_Koso_Longayo.pdf';
              
                          this.StudentCode1 = 'AMBOHEKI LONGAYO Veronique   ';
                          this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Veronique_Amboheki_Longayo.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2653':
                        this.StudentCode = 'THUMAS ANNOUCK';
                        this.Ordre =false;
                        break;
                      case '2654':
                        if( this.StudentCodeSecret == '555'){
                          this.StudentCode = 'KAGE MWANZITA Marie Florence';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Marie_Florence_Kage_Mwanzita.pdf';
              
                          //this.StudentCode1 = 'KAGE MWANZITA David';
                         // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/KageDavid_P1B.pdf';
              
                          //this.StudentCode2 = 'KAGE MWANZITA Florentin Junior';
                         // this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/KAGE_JUNIOR.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2656':
                        if( this.StudentCodeSecret == '983'){
                         // this.StudentCode = 'LUVUEZO LORENZO Lucas';
                          //this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1C/Luvuezo_Lukas.pdf';
              
                          //this.StudentCode1 = 'VANDEWALLE Jessica   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Vandewalle_Bul5TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2658':
                        if( this.StudentCodeSecret == '985'){
                         // this.StudentCode = 'SAPWE SELEMANI Tyron';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/SAPWE_TYRON.pdf';
              
                          //this.StudentCode1 = 'VANDEWALLE Jessica   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Vandewalle_Bul5TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2661':
                        if( this.StudentCodeSecret == '987'){
                         // this.StudentCode = 'KASH Ethan';
                         // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3B/Kash_Ethan_P3B.pdf';
              
                          //this.StudentCode1 = 'VANDEWALLE Jessica   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_5tq/Vandewalle_Bul5TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                        case '2662':
                        this.StudentCode = 'RIGUELLE SARAH';
                        this.Ordre =false;
                        break;
                      case '2663':
                        this.StudentCode = 'LIVRIZZI MARIA';
                        this.Ordre =true;
                        break;
                      case '2664':
                        if( this.StudentCodeSecret == '557'){
                          this.StudentCode = 'TSHIMANGA TSHIPANGA Lukyan';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lukyan_Tshimanga_Tshipanga.pdf';
              
                          //this.StudentCode1 = 'AMBOHEKI LONGAYO Veronique   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s4tq/Amboheki%20_Bul4TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2665':
                        if( this.StudentCodeSecret == '559'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
                this.StudentCode = 'MBIYE MULOMBA Betina';
                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Betina_Mbiye_Mulomba.pdf';
    
                this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Isaac_Mbiye_Mulomba.pdf';
              }
                          
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2666':
                        if( this.StudentCodeSecret == '989'){
                         // this.StudentCode = 'KABATA Okiena';
                         // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1D/Kabata_Okiena.pdf';
              
                         // this.StudentCode1 = 'KABATA Nasha';
                        //  this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/KABATA_NASHA.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2667':
                        if( this.StudentCodeSecret == '991'){
                         // this.StudentCode = 'BWITI DE SOUZA Ayrton';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P2D/Bwiti_de_Souza_P2D.pdf';
              
                          //this.StudentCode1 = 'KABATA Nasha';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/KABATA_NASHA.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2668':
                        if( this.StudentCodeSecret == '993'){
                        //  this.StudentCode = 'MUTOMBO WA MUTOMBO Keren Sharon';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4A/MUTOMBO_SHARON.pdf';
              
                          //this.StudentCode1 = 'KABATA Nasha';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4B/KABATA_NASHA.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2669':
                        if( this.StudentCodeSecret == '561'){
                          this.StudentCode = 'HENDRICK Kluivert Ric';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kluivert_Ric_Hendrick_Kluivert_Ric.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2670':
                        if( this.StudentCodeSecret == '563'){
                        //  this.StudentCode = 'BAILLET Esther Charlotte';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/bulletins_s5es/baillet_Bul5ES.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2671':
                        if( this.StudentCodeSecret == '995'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
               // this.StudentCode = 'BUDIMBU NTUBUANGA Cathy Augustine';
                         // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P3A/BUDIMBU_CATHY.pdf';
              
                         // this.StudentCode1 = 'BUDIMBU NTUBUANGA Maylee Emmerence';
                         // this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Bulletins_P5B/BUDIMBU_MAYLEE.pdf';
              
                          this.StudentCode2 = 'BUDIMBU NTUBUANGA Soraya Seraphine';
                          this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/Soraya_Seraphine_Budimbu_Ntubuanga.pdf';
        
              }
                          
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2672':
                        this.StudentCode = 'NDRIN NAOMI';
                        this.Ordre =true;
                        break;
                      case '2674':
                        if( this.StudentCodeSecret == '997'){
                         // this.StudentCode = 'KINKOKO YOYO Joseph';
                         // this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P4C/KINKOKO_JOSEPH.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2675':
                        if( this.StudentCodeSecret == '999'){
                        //  this.StudentCode = 'FELBER Charles René';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/FELBER_CHARLES_RENE.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2676':
                        if( this.StudentCodeSecret == '1001'){
                        //  this.StudentCode = 'LEMAIRE Capucine Angèle';
                        //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1A/Lemaire_Capucine.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2679':
                        if( this.StudentCodeSecret == '1003'){
                          this.Ordre = true;
              if (!this.Ordre) {

                this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                setTimeout(
                  () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
              //  this.StudentCode = 'KAPEND NZAM Doriane';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/KAPENDE_DORIANE.pdf';
        
              }
                         
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                      case '2680':
                        if( this.StudentCodeSecret == '1005'){
                          this.Ordre = true;
                          if (!this.Ordre) {

                            this.errorMessage = 'Vous n êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec le HELPDESK au +243(0)899 450 848';
        
                            setTimeout(
                    () => {
                    //this.isAuth = true;
                    this.router.navigate(['/auth', 'signin']);
                  }, 15000
                );
                
          
              }  else{
                
               // this.StudentCode = 'CHEN QUAN Duo';
              //  this.urlCode = 'http://lpl-rdc.com/BULLETINS/Bulletins_P1B/KAPENDE_DORIANE.pdf';
              }
                          
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;
                        case '2681':
                        if( this.StudentCodeSecret == '1007'){
                          this.StudentCode = 'GIRINGANJI Don Aymard';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Don_Aymard_Giringanji.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;

                        case '2685':
                        if( this.StudentCodeSecret == '1009'){
                          this.StudentCode = 'DOLLY WANDALA Ebed';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ebed_Dolly_Wandala.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;

                        case '2686':
                        if( this.StudentCodeSecret == '1010'){
                          this.StudentCode = 'MUMBEMBELE MIMPA Emmanuella';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emmanuella_Mumbembele_Mimpa.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;

                        case '2692':
                        if( this.StudentCodeSecret == '1013'){
                          this.StudentCode = 'KANINDA NGALULA Thallia';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Thallia_Kaninda_Ngalula.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;

                        case '2696':
                        if( this.StudentCodeSecret == '1015'){
                          this.StudentCode = 'PAMI KIESSE Benie';
                          this.urlCode = 'http://lpl-rdc.com/BULLETINS/Benie_Pami_Kiesse.pdf';
              
                          //this.StudentCode1 = 'MBIYE MULOMBA Isaac   ';
                          //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/bulletins_s3tq/Mbiye_Bul3TQ.pdf';
              
                          //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                          //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
              
                          //this.StudentCode3 = 'Bulletin3';
                          //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode4 = 'Bulletin4';
                          //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
              
                          //this.StudentCode5 = 'Bulletin5';
                          //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                          
                          //this.Ordre =true;
                          //}else{
                            //this.errorMessage = "Code secret ou numéro de famille non valide "
                          }
              
                          //console.log('valeur url: ' + this.urlCode);
                          //console.log('message erreur: ' + this.errorMessage);;;;
                        break;

                        case '2701':
                          if( this.StudentCodeSecret == '1017'){
                            this.StudentCode = 'ZOUNDI Alena Athanaïs W';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Alena_Athanais_W_Zoundi.pdf';
                
                            this.StudentCode1 = 'ZOUNDI Aaron Wendnere   ';
                            this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Aaron_Wendnere_Zoundi.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2703':
                          if( this.StudentCodeSecret == '1019'){
                            this.StudentCode = 'LOKALE NZEBA Emmanuella';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emmanuella_Lokale_Nzeba.pdf';
                
                            this.StudentCode1 = 'LOKALE KAPIA Chloé   ';
                            this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Chloe_Lokale_Kapia.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2707':
                          if( this.StudentCodeSecret == '1021'){
                            this.StudentCode = 'MUKENDI YEZE Karel Yace';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Karel_Yace_Mukendi_Yeze.pdf';
                
                            //this.StudentCode1 = 'LOKALE KAPIA Chloé   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Chloe_Lokale_Kapia.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2708':
                          if( this.StudentCodeSecret == '1023'){
                            this.StudentCode = 'ADOMBE KETOPE Gabriella';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gabriella_Adombe_Ketope.pdf';
                
                            this.StudentCode1 = 'ADOMBE KETOPE Daniella   ';
                            this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Daniella_Adombe_Ketope.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2710':
                          if( this.StudentCodeSecret == '1025'){
                            this.StudentCode = 'MUSUMBA TSHIBOLA Estelle Astrid';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Estelle_Astrid_Musumba_Tshibola.pdf';
                
                            this.StudentCode1 = 'TSHIOTA TSHIBOLA Marie Annaelle   ';
                            this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Annaelle_Tshiota_Tshibola.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2712':
                          if( this.StudentCodeSecret == '1027'){
                            this.StudentCode = 'MADAIL TANDUNDU AUGUSTO Maria-Julia';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Maria_Julia_Madail_Tandundu_Augusto.pdf';
                
                            //this.StudentCode1 = 'TSHIOTA TSHIBOLA Marie Annaelle   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Annaelle_Tshiota_Tshibola.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2723':
                          if( this.StudentCodeSecret == '1029'){
                            this.StudentCode = 'NZIGIRE Kimberley';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Kimberley_Nzigire.pdf';
                
                            //this.StudentCode1 = 'TSHIOTA TSHIBOLA Marie Annaelle   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Annaelle_Tshiota_Tshibola.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2727':
                          if( this.StudentCodeSecret == '1031'){
                            this.StudentCode = 'BAHATI BISIKA Ketsia';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Ketsia_Bahati_Bisika.pdf';
                
                            //this.StudentCode1 = 'TSHIOTA TSHIBOLA Marie Annaelle   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Marie_Annaelle_Tshiota_Tshibola.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2731':
                          if( this.StudentCodeSecret == '1033'){
                            this.StudentCode = 'NLANDU NEHEMA Claire';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Claire_Nlandu_Nehema.pdf';
                
                            this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2732':
                          if( this.StudentCodeSecret == '1035'){
                            this.StudentCode = 'TEIXEIRA Emmanuela Shanaya';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Emmanuela_Shanaya_Teixeira.pdf';
                
                            //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2737':
                          if( this.StudentCodeSecret == '1037'){
                            this.StudentCode = 'MANGULU NDJIMA Gracia Esther';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Gracia_Esther_Mangulu_Ndjima.pdf';
                
                            //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2738':
                          if( this.StudentCodeSecret == '1039'){
                            this.StudentCode = 'CIBALONZA BYATERANA Celestin Junior';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Cibalonza_Celestin_Junior_Cibalonza.pdf';
                
                            //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2740':
                          if( this.StudentCodeSecret == '1041'){
                            this.StudentCode = 'MASUDI OPESE Jean-Gédéon';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jean_Gedeon_Masudi_Opese.pdf';
                
                            //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2741':
                          if( this.StudentCodeSecret == '1043'){
                            this.StudentCode = 'LUBANDJI ESHIKA Charle';
                            this.urlCode = 'http://lpl-rdc.com/BULLETINS/Charles_Lubandji_Eshika.pdf';
                
                            //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                            //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                
                            //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                            //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                
                            //this.StudentCode3 = 'Bulletin3';
                            //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode4 = 'Bulletin4';
                            //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                
                            //this.StudentCode5 = 'Bulletin5';
                            //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                            
                            //this.Ordre =true;
                            //}else{
                              //this.errorMessage = "Code secret ou numéro de famille non valide "
                            }
                
                            //console.log('valeur url: ' + this.urlCode);
                            //console.log('message erreur: ' + this.errorMessage);;;;
                          break;

                          case '2746':
                            if( this.StudentCodeSecret == '1045'){
                              this.StudentCode = 'TULUKA N_SOMPI Marie-Eliane';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Marie_Eliane_Tuluka_N_sompi.pdf';
                  
                              //this.StudentCode1 = 'NLANDU Dorianne Shamah   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Dorianne_Shamah_Nlandu_Dorianne_Shamah.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2748':
                            if( this.StudentCodeSecret == '1047'){
                              this.StudentCode = 'PANDA Caroline';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Caroline_Panda.pdf';
                  
                              this.StudentCode1 = 'PANDA Annie-Gabrielle   ';
                              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Annie_Gabrielle_Panda.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2750':
                            if( this.StudentCodeSecret == '1049'){
                              this.StudentCode = 'MASSAMBA Nouria Eloheeka';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Nouria_Eloheeka_Massamba.pdf';
                  
                              //this.StudentCode1 = 'PANDA Annie-Gabrielle   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Annie_Gabrielle_Panda.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2753':
                            if( this.StudentCodeSecret == '1051'){
                              this.StudentCode = 'DE ROECK Anthony Cédric Christian';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Anthony_Cedric_Christian_de_Roeck.pdf';
                  
                              //this.StudentCode1 = 'PANDA Annie-Gabrielle   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Annie_Gabrielle_Panda.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2761':
                            if( this.StudentCodeSecret == '1053'){
                              this.StudentCode = 'HIJAZI Amani';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Amani_Hijazi.pdf';
                  
                              //this.StudentCode1 = 'PANDA Annie-Gabrielle   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Annie_Gabrielle_Panda.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2766':
                            if( this.StudentCodeSecret == '1055'){
                              this.StudentCode = 'BADIBANGA Hiram';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Hiram_BADIBANGA.pdf';
                  
                              this.StudentCode1 = 'BADIBANGA Ketsia-Cristale   ';
                              this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ketsia_Cristale_BADIBANGA.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2770':
                            if( this.StudentCodeSecret == '1057'){
                              this.StudentCode = 'LUMBU Joëlle';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Joelle_Lumbu.pdf';
                  
                              //this.StudentCode1 = 'BADIBANGA Ketsia-Cristale   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ketsia_Cristale_BADIBANGA.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2772':
                            if( this.StudentCodeSecret == '1059'){
                              this.StudentCode = 'MUZEU-MULLER Lys';
                              this.urlCode = 'http://lpl-rdc.com/BULLETINS/Lys_Muzeu_Muller.pdf';
                  
                              //this.StudentCode1 = 'BADIBANGA Ketsia-Cristale   ';
                              //this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Ketsia_Cristale_BADIBANGA.pdf';
                  
                              //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                              //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                  
                              //this.StudentCode3 = 'Bulletin3';
                              //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode4 = 'Bulletin4';
                              //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                  
                              //this.StudentCode5 = 'Bulletin5';
                              //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                              
                              //this.Ordre =true;
                              //}else{
                                //this.errorMessage = "Code secret ou numéro de famille non valide "
                              }
                  
                              //console.log('valeur url: ' + this.urlCode);
                              //console.log('message erreur: ' + this.errorMessage);;;;
                            break;

                            case '2776':
                              if( this.StudentCodeSecret == '1061'){
                                this.StudentCode = 'SETI Jean-Yanny';
                                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Jean_Yanny_SETI.pdf';
                    
                                this.StudentCode1 = 'SETI Alexandre ';
                                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Alexandre_SETI.pdf';
                    
                                //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                    
                                //this.StudentCode3 = 'Bulletin3';
                                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                                //this.StudentCode4 = 'Bulletin4';
                                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                                //this.StudentCode5 = 'Bulletin5';
                                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                                
                                //this.Ordre =true;
                                //}else{
                                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                                }
                    
                                //console.log('valeur url: ' + this.urlCode);
                                //console.log('message erreur: ' + this.errorMessage);;;;
                              break;

                              case '2778':
                              if( this.StudentCodeSecret == '1063'){
                                this.StudentCode = 'ANDRIANTAVY Laure-Aina Karys Asandratra';
                                this.urlCode = 'http://lpl-rdc.com/BULLETINS/Laure_Aina_Karys_Asandratra_ANDRIANTAVY.pdf';
                    
                                this.StudentCode1 = 'ANDRIANTAVY Yanis Fandresena   ';
                                this.urlCode1 = 'http://lpl-rdc.com/BULLETINS/Yanis_Fandresena_ANDRIANTAVY.pdf';
                    
                                //this.StudentCode2 = 'KAMANDA TSHIBANGU MUTEBA Exauce';
                                //this.urlCode2 = 'http://lpl-rdc.com/BULLETINS/bulletins_6se/Kamanda_Bul6SE.pdf';
                    
                                //this.StudentCode3 = 'Bulletin3';
                                //this.urlCode3 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                                //this.StudentCode4 = 'Bulletin4';
                                //this.urlCode4 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                    
                                //this.StudentCode5 = 'Bulletin5';
                                //this.urlCode5 = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
                                
                                //this.Ordre =true;
                                //}else{
                                  //this.errorMessage = "Code secret ou numéro de famille non valide "
                                }
                    
                                //console.log('valeur url: ' + this.urlCode);
                                //console.log('message erreur: ' + this.errorMessage);;;;
                              break;
                        case '01107678':
                          this.StudentCode = 'NOTTERMAN ANNIE';
                          this.Ordre =true;               
      
               
                            }
                  } else {

                    this.errorMessage = " Veuillez renseigner un code de famille"
                  }
      
  
    }

}
