<div class="container-fluid">
    <div class="row">
      <div class="col-8">
        <h3>-------------------------------------------------------------------------------------------------------------------------------SECRETARIAT(Liste  des familles)--------------------------------------------------------------------------------------------------------------------------------------</h3>
      
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        -- <button class="btn btn-success" (click)="getNavigation('secretariatcreate', '')">Nouvelle Famille</button>
         <button class="btn btn-secondary" (click)="getNavigation('', '')">retour</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table #productsTableFinance class="display" style="width:100%">
          <thead>
          <tr>            
            <th>#</th>  
            <th>Nom de la famille</th>          
            <th>Numéro de Famille</th>
            <th>Nombre d'enfant</th>
            <th>Observation</th>
            <th>Status</th>
            <th>En Ordre?</th>
            <th>Facture</th>            
          </tr>
          </thead>
          <tbody>
           
          <tr *ngFor="let productfinance of productsfinance; let i =index;">            
            <td  >{{i + 1}}</td>
            <td>{{productfinance.NomFamille}}</td>
            <td>{{productfinance.NumeroFamille}}</td>
            <td>{{productfinance.NumeroEleve}}</td>
            <td>{{productfinance.Observations}}</td>
            <div style="width:20px;height:60px;background-color:red;" *ngIf="productfinance.OrdrePyt === '0'">
            </div>
            <div style="width:20px;height:60px;background-color:green;" *ngIf="productfinance.OrdrePyt === '1'">
            </div>
            <td>{{productfinance.OrdrePyt}}</td>
            <td><input type="file" name="avatar1" /></td>
            
            <td>             
              
                <button type="button" class="btn btn-success" (click)="getNavigation('secretariatupdate', productfinance.id)">Modifier({{productfinance.NumeroFamille}})</button>              
             

           
            </td>
          </tr>
          </tbody>
          </table>
      </div>
    </div>
  </div>