
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >
  <p> </p>
  <p> </p>

<div class="row">
  <div class="col-sm-8 col-sm-offset-2">
    <h2>Connexion</h2>

    <div class="jumbotron" style="background-color:rgb(170, 226, 240)" >
    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Adresse e-mail</label>
        <input type="text"
               id="email"
               class="form-control"
               formControlName="email" (input)="onVerifEmail($event.target.value)">
      </div>
      <div class="form-group">
        <label for="password">Mot de passe</label>
        <input type="password"
               id="password"
               class="form-control"
               formControlName="password">
      </div>

      <div class="form-group">
        <label for="idUser">Numéro de famille (renseignez uniquement les chiffres)</label>
        <input type="text"
               id="identifiantUtilisateur"
               class="form-control"
               formControlName="text" (input)="onSearchChange($event.target.value)" placeholder="Tapez uniquement les chiffres">
      </div>
      <div class="form-group">
        <label for="class-filter">Classe</label>
        <select id="class-filter" formControlName="studentClass" class="form-control" (change)="selectChangeHandler($event)">
          <option *ngFor="let classe of (classList$ | async)" [value]="classe.value">{{ classe.text }}</option>
        </select>
      </div>

      <button class="btn btn-primary"
              type="submit"
              [disabled]=" !Ordre " >Connexion</button>

    </form>
    
    <p class="text-danger">{{ errorMessage }}</p>



    </div>
  </div>
  </div>
</div>

