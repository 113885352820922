<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >
  <p> </p>
  <p> </p>
  <ul class="breadcrumb">
    <li><a href="#" data-toggle="popover" title="Grace à votre code secret, vous pouvez récuperer vos bulletin" [routerLink]="'/authsuppbulletin'" routerLinkActive="active" > Bulletin en ligne </a></li>
    <li><a href="#" data-toggle="popover" title="Grace à votre code secret, vous pouvez récuperer vos factures" [routerLink]="'/authsuppfacture'" routerLinkActive="active" > Facture en ligne </a></li>
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site des évaluations en ligne'Quiziniere'"  href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a></li>
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site de Visio-conférence'jit-si'"  href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a></li>
    <li><a  href="#" data-toggle="popover" title="Fermeture de la session en cours"  [routerLink]="'auth/signin'" routerLinkActive="active"  > Déconnexion </a></li>     
  </ul>

  <form class="example-form" [formGroup]="loginForm" (ngSubmit)="onFormSubmit(loginForm.value)">
      <h2>Veuillez vous connecter en Choisissant votre Nom</h2>
      <div class="jumbotron" style="background-color:lavenderblush" >

        <h2>LOGIN(CHAT-ROOM)</h2>
      <mat-form-field class="example-full-width">
        <mat-label>Nom</mat-label>        

                <select formControlName="nickname"  id="listedechoix" >
                  <option   value=""></option>
                  <option *ngFor="let title of titleOptions" >Mise à jour en cours!</option>
                  </select>

                  <div class="form-group">
                    <label for="alerte"></label>
                    <input type="text" id="code_secret"
                           class="form-control" formControlName="code_secret" placeholder="Code secret">     
                  </div>

        <mat-error>
          <span *ngIf="!loginForm.get('nickname').valid && loginForm.get('nickname').touched">Veuillez entrer votre nom</span>
        </mat-error>
      </mat-form-field>
      <div class="button-row">
          <button type="submit" [disabled]="!loginForm.valid" mat-fab color="primary"><mat-icon>login</mat-icon></button>
      </div>
    </div>
  </form>
</div>
