<!DOCTYPE html>
<html>
  <head>
    <script src='https://meet.jit.si/external_api.js'></script>

    <style>html, body, #jaas-container { height: 100%; }</style>
    <script type="text/javascript">
      window.onload = () => {
        const api = new JitsiMeetExternalAPI("8x8.vc", {
          roomName: "vpaas-magic-cookie-be46576a759c40f9bb216097007a1a55/SampleAppProudBedroomsVaryNaturally",
          parentNode: document.querySelector('#jaas-container')
        });
      }
    </script>
  </head>
  <body><div id="jaas-container"></div></body>
</html>