import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooksService } from '../services/books.service';
import { Book } from '../models/Book.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Classe } from '../models/Classe.model';
import { ClassesService } from '../services/classes.service';
import { AuthService } from '../services/auth.service';

import {MatDialog } from '@angular/material/dialog';
import { DialogExampleComponent } from '../dialog-example/dialog-example.component';


@Component({
  selector: 'app-book-list-student-m1-ii',
  templateUrl: './book-list-student-m1-ii.component.html',
  styleUrls: ['./book-list-student-m1-ii.component.scss']
})
export class BookListStudentM1IIComponent implements OnInit, OnDestroy {

  books$: Observable<Book[]>;
  filterForm: FormGroup;
  destroy$: Subject<boolean>;
  studentClass$: Observable<string>;

  num_famille : string;

  constructor(private booksService: BooksService,
              private router: Router,
              private formBuilder: FormBuilder,
              private auth: AuthService,
              public dialog:MatDialog
              ) {
                this.num_famille = this.router.getCurrentNavigation().extras.state.example; // la valeur du numéro de la famille
                console.log('book-list-student-m1-ii:' + this.num_famille);
              }



              ngOnInit() {
                this.destroy$ = new Subject();
                this.studentClass$ = this.auth.getClass();
                this.filterForm = this.formBuilder.group({
                  titleFilter: [null],
                  authorFilter: [null],
                  liens: [null],
                  dateFilter: [null]
                });
                this.books$ = this.studentClass$.pipe(
                  tap(console.log),
                  switchMap((classe: string) => combineLatest([
                    this.booksService.bookSubject,
                    this.filterForm.valueChanges.pipe(
                      startWith({
                        titleFilter: null,
                        authorFilter: null,
                        liens: this.num_famille,
                        dateFilter: null
                      })
                    )]).pipe(
                      debounceTime(300),
                      map(([books, valueChanges]) => {
                        if (valueChanges.titleFilter) {
                          books = books.filter(book => book.title.toLowerCase().includes(valueChanges.titleFilter.toLowerCase()));
                        }
                        if (valueChanges.authorFilter) {
                          books = books.filter(book => book.author.toLowerCase().includes(valueChanges.authorFilter.toLowerCase()));
                        }
                        if (valueChanges.liens) {
                          books = books.filter(book => book.liens.toLowerCase().includes(valueChanges.liens.toLowerCase()));  //N'afficher que le devoir corrigé et rendu à l'élève par l'enseignant
                        }
                        if (valueChanges.dateFilter) {
                          books = books.filter(book =>
                            new Date(book.dateJour).getDate() === new Date(valueChanges.dateFilter).getDate() &&
                            new Date(book.dateJour).getMonth() === new Date(valueChanges.dateFilter).getMonth()
                          );
                        }
                        return books;
                      }),
                      map(books => {
                        if (classe) {
                          books = books.filter(book => book.listedechoix.toLowerCase() === classe.toLowerCase());
                        }
                        return books;
                      }),
                      map(books => books.sort(
                        (a: Book, b: Book) => (new Date(b.dateJour).getTime() - new Date(a.dateJour).getTime()) < 0 ? -1 : 1))
                    )
                  )
                );
                this.booksService.emitBooks();
                //this.OpenDialog();
              }

             // OpenDialog() {
                //this.dialog.open(DialogExampleComponent, { data : { name: 'HML'} } );
              //}
            
              onNewBook() {
                this.router.navigate(['/books', 'new']);
              }
            
              onDeleteBook(book: Book) {
                this.booksService.removeBook(book);
              }

              onArchiveStudent() {
                // this.router.navigate(['/books', 'new']);
                this.router.navigate(['/booksStudentP1A']);
              }
            
              onViewBook(id: number) {
                this.router.navigate(['/booksStudent', 'view', id], { state: { example: this.num_famille } });
                //this.router.navigate(['/booksStudent', 'view', id]);
              }
            
              onResetFilters() {
                this.filterForm.reset();
              }
            
              ngOnDestroy() {
                this.destroy$.next(true);
              }

              onBackStudent() {
                this.router.navigate(['/booksStudent'], { state: { example: this.num_famille } });
                //this.router.navigate(['/booksStudent']);
              }

}
