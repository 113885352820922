import { Component, OnInit } from '@angular/core';
import {ProductsFinance} from '../../models/productsfinance';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BooksService} from '../../services/books.service';
import {ActivatedRoute, Router} from '@angular/router';

import {MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-updatesecretariat',
  templateUrl: './updatesecretariat.component.html',
  styleUrls: ['./updatesecretariat.component.css']
})
export class UpdatesecretariatComponent implements OnInit {
  productFormFinance: FormGroup;
  productID: any;
  productID_1: any;
  productID_2: any;
  productID_description: any;

  prix_expertise : any;
  montant_expertise : number;

  prix_vente : any;
  montant_vente : number;

  valeur_vente : number;
  errorMessage : string;

  date_expertise : Date;
  
  date_validite : Date;

  montant_reel_expertise: number;

  verif_date: boolean;

  numero_ID : any;

  id : string;
  NomFamille : string;
  NumeroFamille : string;
  NumeroEleve: string;
  Observations: string;
  OrdrePyt: string;
  Facture: string;
  values_Ordre_Pyt : string;
  
  dateemissionenvoi : string;
  datevaliditeenvoi : string;

  lastUpdate = new Date();

  pricevente_downloader: string;

  productDataFinance: ProductsFinance;

  constructor(private fb: FormBuilder,
    private crudService: BooksService,
    private router: Router,
    public dialog:MatDialog,
    private actRoute: ActivatedRoute) { 
      this.productFormFinance = this.fb.group({
        NomFamille: ['', Validators.required],
        NumeroFamille: ['', Validators.required],
        NumeroEleve: [''],
        Observations: [''],
        OrdrePyt: ['', Validators.required],
        //desc: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(1000)])],
        //pricevente: ['', Validators.compose([Validators.required])],
        //acheteur: ['', Validators.required]
      });
  // code
    }

  ngOnInit(): void {
    this.productID = this.actRoute.snapshot.params.id;
    console.log('ID :' + this.productID);
    this.loadProductDetailsfinance(this.productID);

  }

  loadProductDetailsfinance(productID) {
    this.crudService.getProductDetailsFinance(productID).subscribe(product => {
      this.productDataFinance = product;
      this.productFormFinance.controls.NomFamille.setValue(this.productDataFinance.NomFamille);
      this.productFormFinance.controls.NumeroFamille.setValue(this.productDataFinance.NumeroFamille);
      this.productFormFinance.controls.NumeroEleve.setValue(this.productDataFinance.NumeroEleve);
      this.productFormFinance.controls.Observations.setValue(this.productDataFinance.Observations);
      this.productFormFinance.controls.OrdrePyt.setValue(this.productDataFinance.OrdrePyt);
      //this.productFormFinance.controls.price.setValue(this.productDataFinance.Bulletin);
      this.productFormFinance.controls.Facture.setValue(this.productDataFinance.Facture);
    });
  }

  updateProductData_finance(values) {

    this.id= this.productDataFinance.id; //date de validité
    this.NomFamille= this.productDataFinance.NomFamille; //date de validité
    this.NumeroFamille= this.productDataFinance.NumeroFamille; //date de validité
    this.NumeroEleve= this.productDataFinance.NumeroEleve; //date de validité
    this.Observations= this.productDataFinance.Observations; //date de validité
    this.OrdrePyt= this.productDataFinance.OrdrePyt; //date de validité
    //this.certificatvol= this.productDataFinance.bulletin; //date de validité
    this.Facture= this.productDataFinance.Facture; //date de validité
   
    //this.acheteur= this.productFormFinance.acheteur; //date de validité
    //this.datevalidite= this.productFormFinance.datevalidite.toString(); //date de validité

    const productDataFinance = new FormData();
    productDataFinance.append('id', this.id);
    console.log('id : ' + this.id);

    productDataFinance.append('NomFamille', values.NomFamille); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('NomFamille : ' + values.NomFamille);

    productDataFinance.append('NumeroFamille', values.NumeroFamille); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('NumeroFamille : ' + values.NumeroFamille);

    productDataFinance.append('NumeroEleve', values.NumeroEleve); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('NumeroEleve : ' + values.NumeroEleve);

    productDataFinance.append('Observations', values.Observations);
    console.log('Observations : ' + values.Observations);

   this.values_Ordre_Pyt = this.productFormFinance.get('OrdrePyt').value;  // La valeur du champs Ordre_Pyt mise à jour

    productDataFinance.append('OrdrePyt', this.productFormFinance.get('OrdrePyt').value); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('OrdrePyt : ' + this.productFormFinance.get('OrdrePyt').value);

    productDataFinance.append('Bulletin', this.productDataFinance.Bulletin);
    console.log('Bulletin : ' + this.productDataFinance.Bulletin);

    productDataFinance.append('Facture', this.Facture);
    console.log('Facture : ' + this.Facture);         

     this.crudService.updateProduct_finance(productDataFinance).subscribe(result => {
      
    });

    this.getNavigation('secretariat', '');

  }

  getNavigation(link, id) {
    if (id === '') {
      
      this.router.navigate([link]);
    } else {
      console.log('Valeur ID : ' + id);
      this.router.navigate([link + '/' + id]);
      
    }
  }

}
