import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProfesseursService {



    professeurService = new Subject<any[]>();

  private appareils = [
    {
      id: 1,
      name: 'hml@lpl-rdc.com',
      status: '150978'
    }  
  ];
  
  constructor(private httpClient: HttpClient) { }

  ngOinit () {


  }

  saveAppareilsToServer() {
    this.httpClient
      .post('http://lpl-rdc.com.mysql/appareils.json', this.appareils)
      .subscribe(
        () => {
          console.log('Enregistrement terminé !');
        },
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
}
}