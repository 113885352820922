<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >
  <p> </p>
  <p> </p>

 
  <div class="row" [ngStyle]="{'color': 'blue'}">
      <div class="col-xs-12">        

        
        <p></p>
            
        <div class="jumbotron" style="background-color:lavenderblush" >
         <div class="row">
          
            <div class="col-sm-2">
              <h2>{{ book.listedechoix | uppercase }}</h2>
            </div>
            <div class="col-sm-10">
              <p class="overflow-ellipsis"> <span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert"><h4>{{ book.alerte }}</h4></span></span> </p>
            </div>
          </div>
        
          <h4>{{ book.dateJour | date:"dd/MM/yyyy" }}</h4>
          <h2><i>{{ book.title }}</i></h2>
          <h3>{{ book.authorId }}</h3>
          <h3>{{ book.author }}</h3>

          <ckeditor [(ngModel)]="book.synopsis"  (change)="onChange($event)" data="Bonjour, cher(es) élèves!"></ckeditor>

     
          <h4>{{ book.liens }}</h4>

        
          <div *ngIf="book.file1name">          
            <h4><i>Le(s) Fichier(s) à télécharger en bleu(s)</i></h4>
            <a  [href]="book.file1link" target="_blank">- {{ book.file1name }}</a>            
          </div>
          <div *ngIf="book.file2name">      
            <a  [href]="book.file2link" target="_blank">- {{ book.file2name }}</a>        
          </div>
          <div *ngIf="book.file3name">      
            <a  [href]="book.file3link" target="_blank">- {{ book.file3name }}</a>        
          </div>
          <div *ngIf="book.file4name">      
            <a  [href]="book.file4link" target="_blank">- {{ book.file4name }}</a>        
          </div>
          <div *ngIf="book.file5name">      
          <a  [href]="book.file5link" target="_blank">- {{ book.file5name }}</a>        
          </div>
          <div *ngIf="book.file6name">      
            <a  [href]="book.file6link" target="_blank">- {{ book.file6name }}</a>        
          </div>
          <div *ngIf="book.file7name">      
            <a  [href]="book.file7link" target="_blank">- {{ book.file7name }}</a>        
          </div>
          <div *ngIf="book.file8name">      
            <a  [href]="book.file8link" target="_blank">- {{ book.file8name }}</a>        
          </div>
          <div *ngIf="book.file9name">      
            <a  [href]="book.file9link" target="_blank">- {{ book.file9name }}</a>        
          </div>
          <div *ngIf="book.file10name">      
            <a  [href]="book.file10link" target="_blank">- {{ book.file10name }}</a>        
          </div>                          
     
          <button class="btn btn-default" (click)="onBackStudent()">Retour</button>  
        
        

          <form [formGroup]="bookForm" (ngSubmit)="onSaveBook()">
            <p></p>         
          
          
    
            <div class="form-group">
              <label for="authorEleve">Nom complet de l'élève </label>
              <input type="text" id="authorEleve"
                   class="form-control" formControlName="authorEleve">
            </div>
    
            <div class="form-group">
              <label for="title">Sujet du devoir</label>
              <input type="text" id="title"
                   class="form-control" formControlName="title">     
            </div>  
    

    
            <div class="form-group">
            <h4>Ajouter un fichier à envoyer(PDF, WORD, EXCEL et POWERPOINT)</h4>
            <input type="file" name="avatar1" (change)="onFileSelect1($event)" />        
            
          </div>

          <div *ngIf="book.title === 'CORRECTION DU DEVOIR'">

            <div class="bookForm">
              <label for="comment">Cotation/Commentaire/Annotation:</label>
              <textarea disabled class="form-control" placeholder="Saisissez une annotation ici, Maximum 100 caractères" rows="5" formControlName="comment" id="comment">{{ book.alerte }}</textarea>
            </div>

          </div>

    
          <div class="form-group" *ngIf=' fileIsUploading1' >       
            <input type="file" name="avatar2" (change)="onFileSelect2($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading2'>       
            <input type="file" name="avatar3" (change)="onFileSelect3($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading3'>       
            <input type="file" name="avatar4" (change)="onFileSelect4($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading4'>       
            <input type="file" name="avatar5" (change)="onFileSelect5($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading5'>       
            <input type="file" name="avatar6" (change)="onFileSelect6($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading6'>       
            <input type="file" name="avatar7" (change)="onFileSelect7($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading7'>       
            <input type="file" name="avatar8" (change)="onFileSelect8($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading8'>       
            <input type="file" name="avatar9" (change)="onFileSelect9($event)" />        
            
          </div>
    
          <div class="form-group" *ngIf=' fileIsUploading9'>       
            <input type="file" name="avatar10" (change)="onFileSelect10($event)" />        
    
          </div>
          <ul *ngIf="uploadResponse1 && uploadResponse1.status === 'success'">
            <li>{{ file1name }}</li>
          </ul>
          <ul *ngIf="uploadResponse2 && uploadResponse2.status === 'success'">
            <li>{{ file2name }}</li>
          </ul>
          <ul *ngIf="uploadResponse3 && uploadResponse3.status === 'success'">
            <li>{{ file3name }}</li>
          </ul>
          <ul *ngIf="uploadResponse4 && uploadResponse4.status === 'success'">
            <li>{{ file4name }}</li>
          </ul>
          <ul *ngIf="uploadResponse5 && uploadResponse5.status === 'success'">
            <li>{{ file5name }}</li>
          </ul>
          <ul *ngIf="uploadResponse6 && uploadResponse6.status === 'success'">
            <li>{{ file6name }}</li>
          </ul>
          <ul *ngIf="uploadResponse7 && uploadResponse7.status === 'success'">
            <li>{{ file7name }}</li>
          </ul>
          <ul *ngIf="uploadResponse8 && uploadResponse8.status === 'success'">
            <li>{{ file8name }}</li>
          </ul>
          <ul *ngIf="uploadResponse9 && uploadResponse9.status === 'success'">
            <li>{{ file9name }}</li>
          </ul>
          <ul *ngIf="uploadResponse10 && uploadResponse10.status === 'success'">
            <li>{{ file10name }}</li>
          </ul>
    
          <button class="btn btn-success" [disabled]="bookForm.invalid || fileIsUploading || fileIsUploadingDocument || envoi_fichier"
          type="submit">Envoyer
          
          </button>
    
        </form>
        <p class="text-danger">{{ errorMessage }}</p>    
      </div>
    </div>