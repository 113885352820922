export class Book {
  public title: string;
  public listedechoix: string;
  public author: string;
  public authorId: string;
  public synopsis: string;
  public alerte: string;
  public liens: string;
  public dateJour: string;
  public  file1name: string;
  public  file1link: string
  public  file2name: string;
  public  file2link: string;
  public  file3name: string;
  public  file3link: string;
  public  file4name: string;
  public  file4link: string;
  public  file5name: string;
  public  file5link: string;
  public  file6name: string;
  public  file6link: string;
  public  file7name: string;
  public  file7link: string;
  public  file8name: string;
  public  file8link: string;
  public  file9name: string;
  public  file9link: string;
  public  file10name: string;
  public  file10link: string;
  public  file11name: string;
  public  file11link: string;
  public  file12name: string;
  public  file12link: string;
  public  file13name: string;
  public  file13link: string;
  public  file14name: string;
  public  file14link: string;
  public  file15name: string;
  public  file15link: string;
  public  file16name: string;
  public  file16link: string;
  public  file17name: string;
  public  file17link: string;
  public  file18name: string;
  public  file18link: string;
  public  file19name: string;
  public  file19link: string;
  public  file20name: string;
  public  file20link: string;

  constructor( title: string,
               listedechoix: string,
               author: string,
               authorId: string,
               synopsis: string,
               alerte: string,
               liens: string,
               dateJour: string,
                file1name: string,
               file1link: string,
                file2name: string,
                file2link: string,
                file3name: string,
                file3link: string,
               file4name: string,
                file4link: string,
                file5name: string,
                file5link: string,
                file6name: string,
                file6link: string,
                file7name: string,
               file7link: string,
               file8name: string,
                file8link: string,
                file9name: string,
                file9link: string,
                file10name: string,
                file10link: string,
                file11name: string,
                file11link: string,
                file12name: string,
                file12link: string,
                file13name: string,
                file13link: string,
                file14name: string,
                file14link: string,
                file15name: string,
                file15link: string,
                file16name: string,
                file16link: string,
                file17name: string,
                file17link: string,
                file18name: string,
                file18link: string,
                file19name: string,
                file19link: string,
                file20name: string,
                file20link: string ) {
                  this.title= title
                  this.listedechoix= listedechoix
                  this.author=  author
                  this.authorId= authorId
                  this.synopsis= synopsis
                  this.alerte= alerte
                  this.liens= liens
                  this.dateJour= dateJour
                  this.file1name=file1name
                  this.file1link=file1link
                  this.file2name= file2name
                  this.file2link= file2link
                  this.file3name=  file3name
                  this.file3link=file3link
                  this.file4name= file4name
                  this.file4link= file4link
                  this.file5name= file5name
                  this.file5link= file5link
                  this.file6name= file6name
                  this.file6link= file6link
                  this.file7name= file7name
                  this.file7link= file7link
                  this.file8name= file8name
                  this.file8link= file8link
                  this.file9name=  file9name
                  this.file9link= file9link
                  this.file10name= file10name
                  this.file10link= file10link
                  this.file11name= file11name
                  this.file11link= file11link
                  this.file12name= file12name
                  this.file12link= file12link
                  this.file13name= file13name
                  this.file13link= file13link
                  this.file14name= file14name
                  this.file14link= file14link
                  this.file15name= file15name
                  this.file15link= file15link
                  this.file16name= file16name
                  this.file16link= file16link
                  this.file17name= file17name
                  this.file17link= file17link
                  this.file18name= file18name
                  this.file18link= file18link
                  this.file19name= file19name
                  this.file19link= file19link
                  this.file20name= file20name
                  this.file20link= file20link
  }
}