import { Component, OnInit } from '@angular/core';
import {ProductsFinanceAdmin} from '../../models/productsfinanceadmin';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BooksService} from '../../services/books.service';
import {ActivatedRoute, Router} from '@angular/router';

import {MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-createadmin',
  templateUrl: './createadmin.component.html',
  styleUrls: ['./createadmin.component.css']
})
export class CreateadminComponent implements OnInit {


  productFormFinance: FormGroup;
  productID: any;
  productID_1: any;
  productID_2: any;
  productID_description: any;

  prix_expertise : any;
  montant_expertise : number;

  prix_vente : any;
  montant_vente : number;

  valeur_vente : number;
  errorMessage : string;

  date_expertise : Date;
  
  date_validite : Date;

  montant_reel_expertise: number;

  verif_date: boolean;

  numero_ID : any;

  id : string;
  NomFamille : string;
  NumeroFamille : string;
  NumeroEleve: string;
  Observations: string;
  OrdrePyt: string;
  Bulletin : string
  Facture: string;
  values_Ordre_Pyt : string;
  
  dateemissionenvoi : string;
  datevaliditeenvoi : string;

  lastUpdate = new Date();

  pricevente_downloader: string;

  productDataFinance: ProductsFinanceAdmin;

  constructor(private fb: FormBuilder,
    private crudService: BooksService,
    private router: Router,
    public dialog:MatDialog,
    private actRoute: ActivatedRoute) { 
      this.productFormFinance = this.fb.group({
        NomUtilisateur: ['', Validators.required],
        Adressemail: ['', Validators.required],
        IdentUnique: ['', Validators.required],
        ClassRoom: [''],
        ChatRoom: [''],
        Finance: [''],
        Secretariat: [''],
        Communication: [''],
        Administration: [''],
        //desc: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(1000)])],
        //pricevente: ['', Validators.compose([Validators.required])],
        //acheteur: ['', Validators.required]
      });
  // code
    }

  ngOnInit(): void {
  }



  saveProduct_admin(values) {

    //this.id= this.productDataFinance.id; //date de validité
    //this.NomFamille= this.productDataFinance.NomFamille; //date de validité
    //this.NumeroFamille= this.productDataFinance.NumeroFamille; //date de validité
    //this.NumeroEleve= this.productDataFinance.NumeroEleve; //date de validité
    //this.Observations= this.productDataFinance.Observations; //date de validité
    //this.OrdrePyt= this.productDataFinance.OrdrePyt; //date de validité
    //this.certificatvol= this.productDataFinance.bulletin; //date de validité
    //this.Facture= this.productDataFinance.Facture; //date de validité
   
    //this.acheteur= this.productFormFinance.acheteur; //date de validité
    //this.datevalidite= this.productFormFinance.datevalidite.toString(); //date de validité

    const productDataFinance = new FormData();
    //productDataFinance.append('id', this.id);
    //console.log('id : ' + this.id);

    productDataFinance.append('NomUtilisateur', values.NomUtilisateur); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('NomUtilisateur : ' + values.NomUtilisateur);

    productDataFinance.append('Adressemail', values.Adressemail); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('Adressemail : ' + values.Adressemail);

    productDataFinance.append('IdentUnique', values.IdentUnique); // La variable envoyé à l'URL ne doit pas comporter des caratères spéciaux tel que '_'
    console.log('IdentUnique : ' + values.IdentUnique);

    productDataFinance.append('ClassRoom', values.ClassRoom);
    console.log('ClassRoom : ' + values.ClassRoom);

    productDataFinance.append('ChatRoom', values.ChatRoom);
    console.log('ChatRoom : ' + values.ChatRoom);

    productDataFinance.append('Finance', values.Finance);
    console.log('Finance : ' + values.Finance);

    productDataFinance.append('Secretariat', values.Secretariat);
    console.log('Secretariat : ' + values.Secretariat);

    productDataFinance.append('Communication', values.Communication);
    console.log('Communication : ' + values.Communication);

    productDataFinance.append('Administration', values.Administration);
    console.log('Administration : ' + values.Administration);


     this.crudService.createProduct_admin(productDataFinance).subscribe(result => {
      
    });

    this.getNavigation('admin', '');

  }

  getNavigation(link, id) {
    if (id === '') {
      
      this.router.navigate([link]);
    } else {
      console.log('Valeur ID : ' + id);
      this.router.navigate([link + '/' + id]);
      
    }
  }

}
