import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Book } from '../../models/book.model';
import { BooksService } from '../../services/books.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {HttpClientModule, HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';


@Component({
  selector: 'app-book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss']
})
export class BookFormComponent implements OnInit {

  bookForm: FormGroup;
  form: FormGroup;  
  fileIsUploading = false;
  fileIsUploading1 = false;
  fileIsUploading2 = false;
  fileIsUploading3 = false;
  fileIsUploading4 = false;
  fileIsUploading5 = false;
  fileIsUploading6 = false;
  fileIsUploading7 = false;
  fileIsUploading8 = false;
  fileIsUploading9 = false;
  fileIsUploading10 = false;
  fileUrl: string;
  fileUploaded = false;

  envoi_fichier = false;

  fileIsUploadingDocument = false;
  fileDocuments: {
    name: string,
    url: string
  }[];
  fileUploadedDocument = 0;

  lastUpdate = new Date();
  semainier: Date;
  liste: string;
  texte: string;

  images = [];
  authorId: string = '';
  errorMessage: string;
  authorIdValue: string = '';
  authorEmail: string = '';

  afiichageCnx: any ;
   time1 ;
   time2 ;
   vitesseMs;
   resultSoustract;
   isBonneCnx = '';
   isMauvaiseCnx = false;
   vitesseMsAfiichage;
   synopsis_cke_editor : string = 'Bonjour, cher(es) élèves!';

   i : any ;

   libelle1 : any;
   selectBox : string = '';
   tableau = [] ;

   listedechoix : string;

   valeur_tableau : any;

   name_file1_uploaded : any;

    file1name: string='';
     file1link: string='';
     file2name: string='';
     file2link: string='';
     file3name: string='';
     file3link: string='';
     file4name: string='';
     file4link: string='';
     file5name: string='';
     file5link: string='';
     file6name: string='';
     file6link: string='';
     file7name: string='';
     file7link: string='';
     file8name: string='';
     file8link: string='';
     file9name: string='';
     file9link: string='';
     file10name: string='';
     file10link: string='';
     file11name: string='';
     file11link: string='';
     file12name: string='';
     file12link: string='';
     file13name: string='';
     file13link: string='';
     file14name: string='';
     file14link: string='';
     file15name: string='';
     file15link: string='';
     file16name: string='';
     file16link: string='';
     file17name: string='';
     file17link: string='';
     file18name: string='';
     file18link: string='';
     file19name: string='';
     file19link: string='';
     file20name: string='';
     file20link: string='';

     uploadResponse1;
     uploadResponse2;
     uploadResponse3;
     uploadResponse4;
     uploadResponse5;
     uploadResponse6;
     uploadResponse7;
     uploadResponse8;
     uploadResponse9;
     uploadResponse10;
     uploadResponse11;
     uploadResponse12;
     uploadResponse13;
     uploadResponse14;
     uploadResponse15;
     uploadResponse16;
     uploadResponse17;
     uploadResponse18;
     uploadResponse19;
     uploadResponse20;

     mail_enseignant: string;
     
    

     url_json = 'http://plateforme-lpl-rdc.com/COMMANDES/web_api/create_lecon.php';

       // defined the array of data
    public data = ['Snooker', 'Tennis', 'Cricket', 'Football', 'Rugby'];



  titleOptions = [{local: '------------------------------------------------------------------'},{local: 'MATERNELLE'},{local: '------------------------------------------------------------------'},
    {local: 'M0Cr'},{local: 'M1I'},{local: 'M1II'},{local: 'M2I'},{local: 'M2II'},{local: 'M3I'},
    {local: 'M3II'},{local: 'M3III'},{local: '------------------------------------------------------------------'},{local: 'PRIMAIRE'},{local: '------------------------------------------------------------------'},
    {local: 'P1A'},{local: 'P1B'},{local: 'P1C'},{local: 'P1D'},
    {local: 'P2A'},{local: 'P2B'},{local: 'P2C'},{local: 'P2D'},{local: 'P3A'},{local: 'P3B'},
    {local: 'P3C'},{local: 'P3D'},{local: 'P4A'},{local: 'P4B'},{local: 'P4C'},{local: 'P4D'},{local: 'P5A'},{local: 'P5B'},
    {local: 'P5C'},{local: 'P5D'},{local: 'P6A'},{local: 'P6B'},{local: 'P6C'},{local: 'P6D'},{local: '------------------------------------------------------------------'},{local: 'SECONDAIRE'},{local: '------------------------------------------------------------------'},
    {local: 'S1I'},{local: 'S1II'},
    {local: 'S1III'},{local: 'S1IV'},{local: 'S2I'},{local: 'S2II'},{local: 'S2III'},{local: 'S2IV'},
    {local: 'S3ECO1'},{local: 'S3ECO2'},{local: 'S3SC1'},{local: 'S3SC2'},{local: 'S3TQ'},{local: 'S4ES'},{local: 'S4ECO'},{local: 'S4SC'},{local: 'S4TQ'},
    {local: 'S5MS1'},{local: 'S5MS2'},{local: 'S5TQ'},{local: 'S5ECO1'},{local: 'S5ECO2'},{local: 'S6ECO'},{local: 'S6MS1'},{local: 'S6MS2'},{local: 'S6TQ'},   
  ];




  constructor(private formBuilder: FormBuilder, private booksService: BooksService,
              private router: Router, private http: HttpClient) {
                this.mail_enseignant = this.router.getCurrentNavigation().extras.state.example; // la valeur du nom de l'enseignant
                
  }

  ngOnInit() {
    this.fileDocuments = [];
    this.initForm();
    this.initForm_upload();
    
  }

  initForm() {
    this.bookForm = this.formBuilder.group({
      title: ['', Validators.required],
      author: [''],
      authorId: ['', Validators.required],
      Semainier: [""],
      synopsis: '',
      alerte: '',
      liens: '',
      dateJour: '',
      listedechoix: ['', Validators.required]
    });

  }

  initForm_upload() {

    this.form = this.formBuilder.group({
      avatar1: [''],
      avatar2: [''],
      avatar3: [''],
      avatar4: [''],
      avatar5: [''],
      avatar6: [''],
      avatar7: [''],
      avatar8: [''],
      avatar9: [''],
      avatar10: ['']
  });

}


  onFileSelect1(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar1').setValue(file);
      this.file1name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit1();
    }
  }

  onFileSelect2(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar2').setValue(file);
      this.file2name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit2();
    }
  }

  onFileSelect3(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar3').setValue(file);
      this.file3name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit3();
    }
  }
  onFileSelect4(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar4').setValue(file);
      this.file4name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit4();
    }
  }
  onFileSelect5(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar5').setValue(file);
      this.file5name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit5();
    }
  }
  onFileSelect6(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar6').setValue(file);
      this.file6name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit6();
    }
  }
  onFileSelect7(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar7').setValue(file);
      this.file7name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit7();
    }
  }
  onFileSelect8(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar8').setValue(file);
      this.file8name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit8();
    }
  }
  onFileSelect9(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar9').setValue(file);
      this.file9name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit9();
    }
  }
  onFileSelect10(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar10').setValue(file);
      this.file10name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit10();
    }
  }

  onSubmit1() {
    const formData = new FormData();
    formData.append('avatar1', this.form.get('avatar1').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse1 = res;
        this.file1link = this.uploadResponse1.url;  // le lien du fichier uploader
        this.file1name = this.file1name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading1 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }

  onSubmit2() {
    const formData = new FormData();
    formData.append('avatar2', this.form.get('avatar2').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile2(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse2 = res;
        this.file2link = this.uploadResponse2.url;  // le lien du fichier uploader
        this.file2name = this.file2name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading2 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit3() {
    const formData = new FormData();
    formData.append('avatar3', this.form.get('avatar3').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile3(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse3 = res;
        this.file3link = this.uploadResponse3.url;  // le lien du fichier uploader
        this.file3name = this.file3name;  // le nom du fichier uploader
        this.errorMessage = '';
        console.log('lien du fichier : ' + this.file3link);
        this.fileIsUploading3 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit4() {
    const formData = new FormData();
    formData.append('avatar4', this.form.get('avatar4').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile4(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse4 = res;
        this.file4link = this.uploadResponse4.url;  // le lien du fichier uploader
        this.file4name = this.file4name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading4 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit5() {
    const formData = new FormData();
    formData.append('avatar5', this.form.get('avatar5').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile5(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse5 = res;
        this.file5link = this.uploadResponse5.url;  // le lien du fichier uploader
        this.file5name = this.file5name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading5 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit6() {
    const formData = new FormData();
    formData.append('avatar6', this.form.get('avatar6').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile6(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse6 = res;
        this.file6link = this.uploadResponse6.url;  // le lien du fichier uploader
        this.file6name = this.file6name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading6 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit7() {
    const formData = new FormData();
    formData.append('avatar7', this.form.get('avatar7').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile7(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse7 = res;
        this.file7link = this.uploadResponse7.url;  // le lien du fichier uploader
        this.file7name = this.file7name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading7 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit8() {
    const formData = new FormData();
    formData.append('avatar8', this.form.get('avatar8').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile8(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse8 = res;
        this.file8link = this.uploadResponse8.url;  // le lien du fichier uploader
        this.file8name = this.file8name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading8 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit9() {
    const formData = new FormData();
    formData.append('avatar9', this.form.get('avatar9').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile9(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse9 = res;
        this.file9link = this.uploadResponse9.url;  // le lien du fichier uploader
        this.file9name = this.file9name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading9 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  onSubmit10() {
    const formData = new FormData();
    formData.append('avatar10', this.form.get('avatar10').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile10(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse10 = res;
        this.file10link = this.uploadResponse10.url;  // le lien du fichier uploader
        this.file10name = this.file10name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading10 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }



  public onChange( event: CKEditor4.EventInfo ) {
    console.log( event.editor.getData() );
    this.synopsis_cke_editor = event.editor.getData()
}


  detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }

  detectSemainier(event) {
    this.semainier = this.bookForm.get('Semainier').value;
    this.lastUpdate = this.semainier;
    console.log(this.lastUpdate);  

  }

  detectAlertDevoir(event) {
   
  }

  detectFilesDocument($event) {
    if ($event.target.files && $event.target.files[0]) {
      const filesAmount = $event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.images.push(event.target.result);

          this.bookForm.patchValue({
            fileSource: this.images
          });
        };

       // this.onUploadFileDocument($event.target.files[i], i, $event.target.files.length);  //firebase
      }
    }         
    
  }

  
  classesMultiples($event) {

    var liste, texte;
    liste = document.getElementById("listedechoix");
    this.libelle1 = liste.options[liste.selectedIndex].text;

    console.log( "Les Valeurs sélectionnées : " + this.libelle1 );


    //this.libelle1 = this.bookForm.get('listedechoix').value;

      this.selectBox =  this.selectBox + ';' + this.libelle1;
      console.log( "Les Valeurs sélectionnées : " + this.selectBox );

      this.tableau = this.selectBox.split(";");  //coupe la chaine en plusieurs sous_chaines en fonction du séparateur
      //console.log( 'valeur du tableau n\° 3 : ' + this.tableau[2]);

      this.valeur_tableau =  Object.keys(this.tableau).length; //renvoie la longueur d'un tableau
     console.log( 'valeur du tableau totale : ' + this.valeur_tableau);    
     

    
      
  }

  onDeleteClasse() {
    this.selectBox = '';

  }

  onSaveBook() { 

    for ( this.i = 1; this.i < this.valeur_tableau; this.i++) {

      console.log( 'valeur du tableau n : ' + this.i +' = ' + this.tableau[this.i]);

      this.onSaveBookMultiples();  //appel de la methode pour plusieurs classes

      
      
      //if (this.libelle1.options[i].selected) {
        //this.listedechoix =   this.tableau[i];
        //console.log( 'la liste de choix est : ' + this.listedechoix);
      //}
    }

    

  }
  


  onSaveBookMultiples() { 

    const newBookData = new FormData();

    const dateJour = this.lastUpdate.toString();
    newBookData.append('dateJour', this.lastUpdate.toString());
    const title = this.bookForm.get('title').value;
    newBookData.append('title', this.bookForm.get('title').value);
     //this.listedechoix = this.bookForm.get('listedechoix').value;
     this.listedechoix = this.tableau[this.i]; //Valeur de la classe en cours
     newBookData.append('listedechoix', this.tableau[this.i]);
    const author ="";
    newBookData.append('author', "");
    this.authorIdValue = this.bookForm.get('authorId').value;
    newBookData.append('authorId', this.bookForm.get('authorId').value);

   
    this.authorId = this.authorIdValue.trim();
    //const synopsis = this.bookForm.get('synopsis').value;
    
    const synopsis = this.synopsis_cke_editor;
    newBookData.append('synopsis', this.synopsis_cke_editor);

    console.log( 'le synopsis est : ' + synopsis);

    
    

    const alerte = this.bookForm.get('alerte').value;
    newBookData.append('alerte', this.bookForm.get('alerte').value);
    const liens = this.bookForm.get('liens').value;
    newBookData.append('liens', this.bookForm.get('liens').value);

    const newBook = new Book(title, this.listedechoix, author, this.authorId, synopsis, alerte, liens,  dateJour,
      this.file1name,
      this.file1link,
      this.file2name,
      this.file2link,
      this.file3name,
      this.file3link,
      this.file4name,
      this.file4link,
      this.file5name,
      this.file5link,
      this.file6name,
      this.file6link,
      this.file7name,
      this.file7link,
      this.file8name,
      this.file8link,
      this.file9name,
      this.file9link,
      this.file10name,
      this.file10link,
      this.file11name,
      this.file11link,
      this.file12name,
      this.file12link,
      this.file13name,
      this.file13link,
      this.file14name,
      this.file14link,
      this.file15name,
      this.file15link,
      this.file16name,
      this.file16link,
      this.file17name,
      this.file17link,
      this.file18name,
      this.file18link,
      this.file19name,
      this.file19link,
      this.file20name,
      this.file20link);

    

    newBook.listedechoix = this.listedechoix;
    newBookData.append('listedechoix', this.listedechoix);
    //newBook.authorId = this.authorId;
    newBook.synopsis = synopsis;
    newBook.alerte = alerte;
    newBook.liens = liens;
    newBook.dateJour = dateJour;
    

    // Mettre le test des codes uniques identifiant des profs ici !!!!!!!! et déplacer

    //var monthName: string;


    switch (this.authorId) {
          case 'E41':
          // newBook.author = 'ADARVE ESPINOSA SANDRA';
            //newBookData.append('author', 'ADARVE ESPINOSA SANDRA');  // A rajouter partout 
            newBookData.append('author', "ADARVE ESPINOSA SANDRA");
            newBookData.append('authorId', "sandra.adarve@lpl-rdc.com");
              // A rajouter partout
            break;

            case 'PY181':
              //newBook.author = 'SERGINE LEROSSIGNOL';
              //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
              newBookData.append('author', 'Alexandra Verschaeren');  // A rajouter partout 
            newBookData.append('authorId', "alexandra.verschaeren@lpl-rdc.com");
              break;

            case 'GY33':
              //newBook.author = 'SERGINE LEROSSIGNOL';
              //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
              newBookData.append('author', 'SERGINE LEROSSIGNOL');  // A rajouter partout 
            newBookData.append('authorId', "sergine.lerossignol@lpl-rdc.com");
              break;
              case 'PZ18':
              //newBook.author = 'SERGINE LEROSSIGNOL';
              //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
              newBookData.append('author', 'AIMEE LUHAHI');  // A rajouter partout 
            newBookData.append('authorId', "aimee.luhahi@lpl-rdc.com");
              break;
              case 'P616':
              //newBook.author = 'SERGINE LEROSSIGNOL';
              //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
              newBookData.append('author', 'BIANCARELLI RENE');  // A rajouter partout 
            newBookData.append('authorId', "rene.biancarelli@lpl-rdc.com");
              break;
              case 'P636':
              //newBook.author = 'SERGINE LEROSSIGNOL';
              //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
              newBookData.append('author', 'ROMANO LUCREZIA');  // A rajouter partout 
            newBookData.append('authorId', "lucrezia.romano@lpl-rdc.com");
              break;
              case 'PY16':
                //newBook.author = 'SERGINE LEROSSIGNOL';
                //newBook.authorId = "sergine.lerossignol@lpl-rdc.com";
                newBookData.append('author', 'VERBEEK ELIANE');  // A rajouter partout 
              newBookData.append('authorId', "eliane.verbeeck@lpl-rdc.com");
                break;
              case 'F48':
              // newBook.author = 'KENYA DOSSOU';
              // newBook.authorId = "kenya.dossou@lpl-rdc.com";
                newBookData.append('author', 'KENYA DOSSOU');  // A rajouter partout 
            newBookData.append('authorId', "kenya.dossou@lpl-rdc.com");
                break;
                case 'A435':
                //newBook.author = 'EVELYNE DEROOVER';
                // newBook.authorId = "evelyne.deroover@lpl-rdc.com";
                  newBookData.append('author', 'EVELYNE DEROOVER');  // A rajouter partout 
            newBookData.append('authorId', "evelyne.deroover@lpl-rdc.com");
                  break;
                  case 'A337':
                  // newBook.author = 'LAURENCE DEBRY';
                  // newBook.authorId = "laurence.debry@lpl-rdc.com";
                    newBookData.append('author', 'LAURENCE DEBRY');  // A rajouter partout 
            newBookData.append('authorId', "laurence.debry@lpl-rdc.com");
                    break;
                    case 'F211':
                    // newBook.author = 'RAPHAEL WANZIO';
                    //  newBook.authorId = "raphael.wanzio@lpl-rdc.com";
                      newBookData.append('author', 'RAPHAEL WANZIO');  // A rajouter partout 
            newBookData.append('authorId', "raphael.wanzio@lpl-rdc.com");
                      break;
                      case 'B2012':
                      // newBook.author = 'LEOPOLD TANGANAGBA';
                      // newBook.authorId = "leopold.tanganagba@lpl-rdc.com";
                        newBookData.append('author', 'LEOPOLD TANGANAGBA');  // A rajouter partout 
            newBookData.append('authorId', "leopold.tanganagba@lpl-rdc.com");
                        break;
                        case 'G4837':
                        //  newBook.author = 'MANON NARDELLOTTO';
                        //  newBook.authorId = "manon.nardellotto@lpl-rdc.com";
                          newBookData.append('author', 'MANON NARDELLOTTO');  // A rajouter partout 
            newBookData.append('authorId', "manon.nardellotto@lpl-rdc.com");
                          break;
                          case 'A139':
                          //  newBook.author = 'OLIVIER COYETTE';
                          //  newBook.authorId = "olivier.coyette@lpl-rdc.com";
                            newBookData.append('author', 'OLIVIER COYETTE');  // A rajouter partout 
            newBookData.append('authorId', "olivier.coyette@lpl-rdc.com");
                            break;
                            case 'G4640':
                            //  newBook.author = 'ALEXANDRE LATKOWSKI';
                          //   newBook.authorId = "alexandre.latkowski@lpl-rdc.com";
                              newBookData.append('author', 'ALEXANDRE LATKOWSKI');  // A rajouter partout 
            newBookData.append('authorId', "alexandre.latkowski@lpl-rdc.com");
                              break;
                              case 'G4441':
                              //  newBook.author = 'RAYHANA SURAY';
                            //   newBook.authorId = "rayhana.suray@lpl-rdc.com";
                                newBookData.append('author', 'RAYHANA SURAY');  // A rajouter partout 
            newBookData.append('authorId', "rayhana.suray@lpl-rdc.com");
                                break;
                                case 'B1053':
                                //  newBook.author = 'SOPHIE MAHY';
                                //  newBook.authorId = "sophie.mahy@lpl-rdc.com";
                                  newBookData.append('author', 'SOPHIE MAHY');  // A rajouter partout 
            newBookData.append('authorId', "sophie.mahy@lpl-rdc.com");
                                  break;

          case 'B182':
          //  newBook.author = 'BEYA SIMON';
          //  newBook.authorId = 'simon.beya@lpl-rdc.com';
            newBookData.append('author', 'BEYA SIMON');  // A rajouter partout 
            newBookData.append('authorId', "simon.beya@lpl-rdc.com");
            break;
          case 'C63':
          //  newBook.author = 'BORGUGNONS CASSANDRA';
          //  newBook.authorId ='cassandra.borgugnons@lpl-rdc.com';
            newBookData.append('author', 'BORGUGNONS CASSANDRA');  // A rajouter partout 
            newBookData.append('authorId', "cassandra.borgugnons@lpl-rdc.com");
            break;
          case 'E14':
          //  newBook.author = 'DE ROSEN MELISSA';
          //  newBook.authorId ='melisssa.derosen@lpl-rdc.com';
            newBookData.append('author', 'DE ROSEN MELISSA');  // A rajouter partout 
            newBookData.append('authorId', "melisssa.derosen@lpl-rdc.com");
            break;
          case 'GY5':
          //  newBook.author = 'DELAET CEDRIC';
          //  newBook.authorId = 'cedric.delaet@lpl-rdc.com';
            newBookData.append('author', 'DELAET CEDRIC');  // A rajouter partout 
            newBookData.append('authorId', "cedric.delaet@lpl-rdc.com");
            break;
          case 'F56':
          //  newBook.author = 'DILU CHRISTELLE';
          //  newBook.authorId = 'christelle.dilu@lpl-rdc.com';
            newBookData.append('author', 'DILU CHRISTELLE');  // A rajouter partout 
            newBookData.append('authorId', "christelle.dilu@lpl-rdc.com");
            break;
          case 'F47':
          //  newBook.author = 'FAWAZ SORAYA';
          //  newBook.authorId = 'soraya.fawaz@lpl-rdc.com';
            newBookData.append('author', 'FAWAZ SORAYA');  // A rajouter partout 
            newBookData.append('authorId', "soraya.fawaz@lpl-rdc.com");
            break;
          case 'G68':
          //  newBook.author = 'GREGOIRE CLEMENT';
          //  newBook.authorId = 'clement.gregoire@lpl-rdc.com';
            newBookData.append('author', 'GREGOIRE CLEMENT');  // A rajouter partout 
            newBookData.append('authorId', "clement.gregoire@lpl-rdc.com");
            break;
          case 'C19':
          //  newBook.author = 'HANSON AMELIE';
          //  newBook.authorId = 'amelie.hanson@lpl-rdc.com';
            newBookData.append('author', 'HANSON AMELIE');  // A rajouter partout 
            newBookData.append('authorId', "amelie.hanson@lpl-rdc.com");
            break;
          case 'F210':
          //  newBook.author = 'ILUNGA SUAREZ MIREILLE';
          //  newBook.authorId = 'mireille.ilunga@lpl-rdc.com';
            newBookData.append('author', 'ILUNGA SUAREZ MIREILLE');  // A rajouter partout 
            newBookData.append('authorId', "mireille.ilunga@lpl-rdc.com");
            break;
          case 'B2011':
          //  newBook.author = 'KAKUDJI RACHEL';
          //  newBook.authorId = 'rachel.kakudji@lpl-rdc.com';
            newBookData.append('author', 'KAKUDJI RACHEL');  // A rajouter partout 
            newBookData.append('authorId', "rachel.kakudji@lpl-rdc.com");
            break;
          case 'C312':
          //  newBook.author = 'KELE BENOIT';
          //  newBook.authorId = 'benoit.kele@lpl-rdc.com';
            newBookData.append('author', 'KELE BENOIT');  // A rajouter partout 
            newBookData.append('authorId', "benoit.kele@lpl-rdc.com");
            break;
          case 'C213':
          //  newBook.author = 'KIADI KATIA';
          //  newBook.authorId = 'katia.kiadi@lpl-rdc.com';
            newBookData.append('author', 'KIADI KATIA');  // A rajouter partout 
            newBookData.append('authorId', "katia.kiadi@lpl-rdc.com");
            break;
          case 'E314':
          //  newBook.author = 'LECOQ QUENTIN';
          //  newBook.authorId = 'quentin.lecoq@lpl-rdc.com';
            newBookData.append('author', 'LECOQ QUENTIN');  // A rajouter partout 
            newBookData.append('authorId', "quentin.lecoq@lpl-rdc.com");
            break;
          case 'E615':
          //  newBook.author = 'MAES DAMIEN';
          //  newBook.authorId = 'damien.maes@lpl-rdc.com';
            newBookData.append('author', 'MAES DAMIEN');  // A rajouter partout 
            newBookData.append('authorId', "damien.maes@lpl-rdc.com");
            break;
          case 'B1916':
          //  newBook.author = 'MARION LAURENCE';
          //  newBook.authorId = 'laurence.marion@lpl-rdc.com';
            newBookData.append('author', 'MARION LAURENCE');  // A rajouter partout 
            newBookData.append('authorId', "laurence.marion@lpl-rdc.com");
            break;
          case 'G417':
          //  newBook.author = 'MATETA PAGUY';
          //  newBook.authorId = 'paguy.mateta@lpl-rdc.com';
            newBookData.append('author', 'MATETA PAGUY');  // A rajouter partout 
            newBookData.append('authorId', "paguy.mateta@lpl-rdc.com");
            break;
          case 'G218':
          //  newBook.author = 'MAURER CHAMMAS ALEXANDRA';
          //  newBook.authorId = 'alexandra.maurer@lpl-rdc.com';
            newBookData.append('author', 'MAURER CHAMMAS ALEXANDRA');  // A rajouter partout 
            newBookData.append('authorId', "alexandra.maurer@lpl-rdc.com");
            break;
          case 'C419':
          //  newBook.author = 'MERLE KATY';
          //  newBook.authorId = 'katy.merle@lpl-rdc.com';
            newBookData.append('author', 'MERLE KATY');  // A rajouter partout 
            newBookData.append('authorId', "katy.merle@lpl-rdc.com");
            break;
          case 'MO20':
          //  newBook.author = 'MEYERS MARIE';
        //   newBook.authorId = 'marie.meyers@lpl-rdc.com';
            newBookData.append('author', 'MEYERS MARIE');  // A rajouter partout 
            newBookData.append('authorId', "marie.meyers@lpl-rdc.com");
            break;
          case 'E221':
          //  newBook.author = 'MISTIAEN CHRISTOPHER';
          //  newBook.authorId = 'christopher.mistiaen@lpl-rdc.com';
            newBookData.append('author', 'MISTIAEN CHRISTOPHER');  // A rajouter partout 
            newBookData.append('authorId', "christopher.mistiaen@lpl-rdc.com");
            break;
          case 'E722':
          //  newBook.author = 'MWEPU BROS CHARLENE';
        //   newBook.authorId = 'charlene.mwepu@lpl-rdc.com';
            newBookData.append('author', 'MWEPU BROS CHARLENE');  // A rajouter partout 
            newBookData.append('authorId', "charlene.mwepu@lpl-rdc.com");
            break;
          case 'G3423':
          //  newBook.author = 'NOTERMAN ANNIE';
          //  newBook.authorId = 'annie.noterman@lpl-rdc.com';
            newBookData.append('author', 'NOTERMAN ANNIE');  // A rajouter partout 
            newBookData.append('authorId', "annie.noterman@lpl-rdc.com");
            break;
          case 'F624':
          //  newBook.author = 'NZUZI YVON';
          //  newBook.authorId = 'yvon.nzuzi@lpl-rdc.com';
            newBookData.append('author', 'NZUZI YVON');  // A rajouter partout 
            newBookData.append('authorId', "yvon.nzuzi@lpl-rdc.com");
            break;
          case 'C525':
          //  newBook.author = 'OLIVA ANNABELLE';
        //   newBook.authorId = 'annabelle.oliva@lpl-rdc.com';
            newBookData.append('author', 'OLIVA ANNABELLE');  // A rajouter partout 
            newBookData.append('authorId', "annabelle.oliva@lpl-rdc.com");
            break;
          case 'E826':
          //  newBook.author = 'PEDUZY ALICE';
          //  newBook.authorId = 'alice.peduzy@lpl-rdc.com';
            newBookData.append('author', 'PEDUZY ALICE');  // A rajouter partout 
            newBookData.append('authorId', "alice.peduzy@lpl-rdc.com");
            break;
          case 'F127':
          //  newBook.author = 'PETTEAU STEEVE';
          //  newBook.authorId ='steeve.petteau@lpl-rdc.com	';
            newBookData.append('author', 'PETTEAU STEEVE');  // A rajouter partout 
            newBookData.append('authorId', "steeve.petteau@lpl-rdc.com");
            break;
          case 'E928':
          //  newBook.author = 'RICHARD ROMAIN';
          //  newBook.authorId = 'romain.richard@lpl-rdc.com';
            newBookData.append('author', 'RICHARD ROMAIN');  // A rajouter partout 
            newBookData.append('authorId', "romain.richard@lpl-rdc.com");
            break;
          case 'G829':
          //  newBook.author = 'SUMAILI ARTHUR';
          //  newBook.authorId = 'arthur.sumaili@lpl-rdc.com';
            newBookData.append('author', 'SUMAILI ARTHUR');  // A rajouter partout 
            newBookData.append('authorId', "arthur.sumaili@lpl-rdc.com");
            break;
          case 'F330':
          //  newBook.author = 'THORP WILLIAM';
          //  newBook.authorId = 'william.thorp@lpl-rdc.com';
            newBookData.append('author', 'THORP WILLIAM');  // A rajouter partout 
            newBookData.append('authorId', "william.thorp@lpl-rdc.com");
            break;
          case 'C731':
          //  newBook.author = 'TOMBU CHANTAL';
          //  newBook.authorId = 'chantal.tombu@lpl-rdc.com	';
            newBookData.append('author', 'TOMBU CHANTAL');  // A rajouter partout 
            newBookData.append('authorId', "chantal.tombu@lpl-rdc.com");
            break;
          case 'GY32':
          //  newBook.author = 'ZANGERLE GILLES';
          //  newBook.authorId = 'gilles.zangerle@lpl-rdc.com';
            newBookData.append('author', 'ZANGERLE GILLES');  // A rajouter partout 
            newBookData.append('authorId', "gilles.zangerle@lpl-rdc.com");
            break;
          case 'G4033':
          //  newBook.author = 'COLSON SARAH';
          //  newBook.authorId = 'sarah.colson@lpl-rdc.com';
            newBookData.append('author', 'COLSON SARAH');  // A rajouter partout 
            newBookData.append('authorId', "sarah.colson@lpl-rdc.com");
            break;
          case 'G40331':
          //  newBook.author = 'ILYINE CHARLOTTE';
          //  newBook.authorId = 'charlotte.ilyine@lpl-rdc.com';
            newBookData.append('author', 'ILYINE CHARLOTTE');  // A rajouter partout 
            newBookData.append('authorId', "charlotte.ilyine@lpl-rdc.com");
            break;
          case 'A434':
          //  newBook.author = 'DETHY LAURE';
          //  newBook.authorId = 'laure.dethy@lpl-rdc.com';
            newBookData.append('author', 'DETHY LAURE');  // A rajouter partout 
            newBookData.append('authorId', "laure.dethy@lpl-rdc.com");
            break;
          case 'A235':
          //  newBook.author = 'SIMOES ISABEL';
          //  newBook.authorId = 'isabelle.simoes@lpl-rdc.com';
            newBookData.append('author', 'SIMOES ISABEL');  // A rajouter partout 
            newBookData.append('authorId', "isabelle.simoes@lpl-rdc.com");
            break;
          case 'A336':
          //  newBook.author = 'PARDAENS RITA';
          //  newBook.authorId = 'ritha.pardaens@lpl-rdc.com	';
            newBookData.append('author', 'PARDAENS RITA');  // A rajouter partout 
            newBookData.append('authorId', "ritha.pardaens@lpl-rdc.com");
            break;
          case 'G4837':
          //  newBook.author = 'CASTELA CERRO DELPHINE';
          //  newBook.authorId = 'delphine.castela@lpl-rdc.com';
            newBookData.append('author', 'CASTELA CERRO DELPHINE');  // A rajouter partout 
            newBookData.append('authorId', "delphine.castela@lpl-rdc.com");
            break;
          case 'A138':
          //  newBook.author = 'BOSCO CHRISTINA';
          //  newBook.authorId = 'christina.bosco@lpl-rdc.com';
            newBookData.append('author', 'BOSCO CHRISTINA');  // A rajouter partout 
            newBookData.append('authorId', "christina.bosco@lpl-rdc.com");
            break;
          case 'G4639':
          //  newBook.author = 'CHAUDRON MAÏTE';
          //  newBook.authorId = 'maite.chaudron@lpl-rdc.com';
            newBookData.append('author', 'CHAUDRON MAÏTE');  // A rajouter partout 
            newBookData.append('authorId', "maite.chaudron@lpl-rdc.com");
            break;
          case 'G4440':
          //  newBook.author = 'BOURSAS NADIA';
          //  newBook.authorId = 'nadia.boursas@lpl-rdc.com';
            newBookData.append('author', 'BOURSAS NADIA');  // A rajouter partout 
            newBookData.append('authorId', "nadia.boursas@lpl-rdc.com");
            break;
          case 'G4241':
          //  newBook.author = 'IKETE JESSICA';
          //  newBook.authorId = 'jessica.ikete@lpl-rdc.com';
            newBookData.append('author', 'IKETE JESSICA');  // A rajouter partout 
            newBookData.append('authorId', "jessica.ikete@lpl-rdc.com");
            break;
          case 'B542':
          //  newBook.author = 'MATHY ASTRID ';
          //  newBook.authorId = 'astrid.mathy@lpl-rdc.com';
            newBookData.append('author', 'MATHY ASTRID');  // A rajouter partout 
            newBookData.append('authorId', "astrid.mathy@lpl-rdc.com");
            break;
          case 'B643':
          //  newBook.author = 'MUNGA MURIEL';
          //  newBook.authorId = 'muriel.munga@lpl-rdc.com	';
            newBookData.append('author', 'MUNGA MURIEL');  // A rajouter partout 
            newBookData.append('authorId', "muriel.munga@lpl-rdc.com");
            break;
          case 'B744':
          //  newBook.author = 'THUMAS ANNOUCK';
          //  newBook.authorId ='annouck.thumas@lpl-rdc.com	';
            newBookData.append('author', 'THUMAS ANNOUCK');  // A rajouter partout 
            newBookData.append('authorId', "annouck.thumas@lpl-rdc.com");
            break;
          case 'B845':
          //  newBook.author = 'BOUX SOPHIE';
          //  newBook.authorId = 'sophie.boux@lpl-rdc.com';
            newBookData.append('author', 'BOUX SOPHIE');  // A rajouter partout 
            newBookData.append('authorId', "sophie.boux@lpl-rdc.com");
            break;
          case 'B146':
          //  newBook.author = 'RAHIER VERONIQUE';
          //  newBook.authorId = 'veronique.rahier@lpl-rdc.com';
            newBookData.append('author', 'RAHIER VERONIQUE');  // A rajouter partout 
            newBookData.append('authorId', "veronique.rahier@lpl-rdc.com");
            break;
          case 'B447':
          //  newBook.author = 'DEBIESME ALEXANDRA';
          //  newBook.authorId = 'alexandra.debiesme@lpl-rdc.com';
            newBookData.append('author', 'DEBIESME ALEXANDRA');  // A rajouter partout 
            newBookData.append('authorId', "alexandra.debiesme@lpl-rdc.com");
            break;
          case 'B348':
          //  newBook.author = 'RIGUELLE SARAH';
        //   newBook.authorId = 'sarah.riguelle@lpl-rdc.com	';
            newBookData.append('author', 'RIGUELLE SARAH');  // A rajouter partout 
            newBookData.append('authorId', "sarah.riguelle@lpl-rdc.com");
            break;
          case 'B249':
          //  newBook.author = 'LIVRIZZI MARIA';
          //  newBook.authorId = 'maria.livrizzi@lpl-rdc.com';
            newBookData.append('author', 'LIVRIZZI MARIA');  // A rajouter partout 
            newBookData.append('authorId', "maria.livrizzi@lpl-rdc.com");
            break;
          case 'B1150':
          //  newBook.author = 'ANDREANI ANNIE';
          //  newBook.authorId = 'annie.andreani@lpl-rdc.com	';
            newBookData.append('author', 'ANDREANI ANNIE');  // A rajouter partout 
            newBookData.append('authorId', "annie.andreani@lpl-rdc.com");
            break;
          case 'B951':
          //  newBook.author = 'LAMBERT MAXIME';
          //  newBook.authorId = 'maxime.lambert@lpl-rdc.com';
            newBookData.append('author', 'LAMBERT MAXIME');  // A rajouter partout 
            newBookData.append('authorId', "maxime.lambert@lpl-rdc.com");
            break;
          case 'B1052':
          //  newBook.author = 'CHARLIER SOPHIE';
          //  newBook.authorId = 'sophie.charlier@lpl-rdc.com';
            newBookData.append('author', 'CHARLIER SOPHIE');  // A rajouter partout 
            newBookData.append('authorId', "sophie.charlier@lpl-rdc.com");
            break;
          case 'G1853':
          //  newBook.author = 'DURIEZ ALEXANDRE';
          //  newBook.authorId = 'alexandre.duriez@lpl-rdc.com';
            newBookData.append('author', 'DURIEZ ALEXANDRE');  // A rajouter partout 
            newBookData.append('authorId', "alexandre.duriez@lpl-rdc.com");
            break;
          case 'G2254':
          //  newBook.author = 'BORON KILLIAN';
          //  newBook.authorId = 'kilian.boron@lpl-rdc.com';
            newBookData.append('author', 'BORON KILLIAN');  // A rajouter partout 
            newBookData.append('authorId', "kilian.boron@lpl-rdc.com");
            break;
          case 'G2055':
          //  newBook.author = 'LEBEAU MARIE-PAULE';
          //  newBook.authorId ='marie-paule.lebeau@lpl-rdc.com	';
            newBookData.append('author', 'LEBEAU MARIE-PAULE');  // A rajouter partout 
            newBookData.append('authorId', "marie-paule.lebeau@lpl-rdc.com");
            break;
          case 'G3056':
          //  newBook.author = 'PREGARDIEN QUENTIN';
          //  newBook.authorId = 'quentin.pregardien@lpl-rdc.com';
            newBookData.append('author', 'PREGARDIEN QUENTIN');  // A rajouter partout 
            newBookData.append('authorId', "quentin.pregardien@lpl-rdc.com");
            break;
          case 'G2657':
          //  newBook.author = 'BOUZOUITA LAETITIA';
          //  newBook.authorId = 'laeticia.bouzouita@lpl-rdc.com';
            newBookData.append('author', 'BOUZOUITA LAETITIA');  // A rajouter partout 
            newBookData.append('authorId', "laeticia.bouzouita@lpl-rdc.com");
            break;
          case 'G2458':
          //  newBook.author = 'NDRIN NAOMI';
          //  newBook.authorId = 'naomie.ndrin@lpl-rdc.com	';
            newBookData.append('author', 'NDRIN NAOMI');  // A rajouter partout 
            newBookData.append('authorId', "naomie.ndrin@lpl-rdc.com");
            break;
          case 'B1559':
          //  newBook.author = 'AGNEESSENS SUAD';
          //  newBook.authorId = 'suad.agnessens@lpl-rdc.com';
            newBookData.append('author', 'AGNEESSENS SUAD');  // A rajouter partout 
            newBookData.append('authorId', "suad.agnessens@lpl-rdc.com");
            break;
          case 'B1460':
          //  newBook.author = 'BOUVIER FREDERIC';
          //  newBook.authorId = 'frederic.bouvier@lpl-rdc.com';
            newBookData.append('author', 'BOUVIER FREDERIC');  // A rajouter partout 
            newBookData.append('authorId', "frederic.bouvier@lpl-rdc.com");
            break;
          case 'B1661':
          //  newBook.author = 'MOUSSEBOIS VIRGINIE';
          //  newBook.authorId = 'virginie.moussebois@lpl-rdc.com	';
            newBookData.append('author', 'MOUSSEBOIS VIRGINIE');  // A rajouter partout 
            newBookData.append('authorId', "virginie.moussebois@lpl-rdc.com");
            break;
          case 'G3462':
          //  newBook.author = 'NOTTERMAN ANNIE';
          //  newBook.authorId ='annie.noterman@lpl-rdc.com	';
            newBookData.append('author', 'NOTTERMAN ANNIE');  // A rajouter partout 
            newBookData.append('authorId', "annie.noterman@lpl-rdc.com");
            break;
          case 'B1363':
          //  newBook.author = 'UMBA YVETTE';
          //  newBook.authorId = 'yvette.umba@lpl-rdc.com';
            newBookData.append('author', 'UMBA YVETTE');  // A rajouter partout 
            newBookData.append('authorId', "yvette.umba@lpl-rdc.com");
            break;
          case 'B1764':
          //  newBook.author = 'KABONGO GINETTE';
          //  newBook.authorId = 'ginette.kabongo@lpl-rdc.com';
            newBookData.append('author', 'KABONGO GINETTE');  // A rajouter partout 
            newBookData.append('authorId', "ginette.kabongo@lpl-rdc.com");
            break;
          case 'G3865':
          //  newBook.author = 'LEBENI ILLA';
          //  newBook.authorId = 'illa.lebeni@lpl-rdc.com';
            newBookData.append('author', 'LEBENI ILLA');  // A rajouter partout 
            newBookData.append('authorId', "illa.lebeni@lpl-rdc.com");
            break;
          case 'GY66':
          //  newBook.author = 'VERBRAEKEN AMELIE';
          //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
            newBookData.append('author', 'VERBRAEKEN AMELIE');  // A rajouter partout 
            newBookData.append('authorId', "amelie.verbraeken@lpl-rdc.com");
            break;











            case 'HY66':
              //  newBook.author = 'VERBRAEKEN AMELIE';
              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                newBookData.append('author', 'DECONINCK LEONORE');  // A rajouter partout 
                newBookData.append('authorId', "leonore.deconinck@lpl-rdc.com");
                break;

                case 'AH86':
                  //  newBook.author = 'VERBRAEKEN AMELIE';
                  //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                    newBookData.append('author', 'DHUYVETTER KOEN');  // A rajouter partout 
                    newBookData.append('authorId', "koen.dhuyvetter@lpl-rdc.com");
                    break;

                    case 'SS42':
                      //  newBook.author = 'VERBRAEKEN AMELIE';
                      //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                        newBookData.append('author', 'KILEMBE JASON');  // A rajouter partout 
                        newBookData.append('authorId', "jason.kilembe@lpl-rdc.com");
                        break;
                        case 'MS52':
                          //  newBook.author = 'VERBRAEKEN AMELIE';
                          //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                            newBookData.append('author', 'GUILMAIN LUC');  // A rajouter partout 
                            newBookData.append('authorId', "luc.guilmain@lpl-rdc.com");
                            break;
                            case 'FM35':
                              //  newBook.author = 'VERBRAEKEN AMELIE';
                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                newBookData.append('author', 'HOUGARDY GERAUD');  // A rajouter partout 
                                newBookData.append('authorId', "geraud.hougardy@lpl-rdc.com");
                                break;
                                case 'AM542':
                              //  newBook.author = 'VERBRAEKEN AMELIE';
                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                newBookData.append('author', 'JUNG TANYA');  // A rajouter partout 
                                newBookData.append('authorId', "tanya.jung@lpl-rdc.com");
                                break;

                                case 'FA052':
                              //  newBook.author = 'VERBRAEKEN AMELIE';
                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                newBookData.append('author', 'KABUYA CELINE');  // A rajouter partout 
                                newBookData.append('authorId', "celine.kabuya@lpl-rdc.com");
                                break;

                                case 'MF022':
                              //  newBook.author = 'VERBRAEKEN AMELIE';
                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                newBookData.append('author', 'LUKUSA OLIVIER');  // A rajouter partout 
                                newBookData.append('authorId', "olivier.lukusa@lpl-rdc.com");
                                break;
                                case 'MM028':
                              //  newBook.author = 'VERBRAEKEN AMELIE';
                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                newBookData.append('author', 'MALONDA FRANCK');  // A rajouter partout 
                                newBookData.append('authorId', "franck.malonda@lpl-rdc.com");
                                break;
                                case 'GM005':
                                  //  newBook.author = 'VERBRAEKEN AMELIE';
                                  //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                    newBookData.append('author', 'MATOUL MARTIN');  // A rajouter partout 
                                    newBookData.append('authorId', "martin.martin@lpl-rdc.com");
                                    break;

                                    case 'EG008':
                                      //  newBook.author = 'VERBRAEKEN AMELIE';
                                      //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                        newBookData.append('author', 'MBAYA EMMANUEL');  // A rajouter partout 
                                        newBookData.append('authorId', "emmanuel.mbaya@lpl-rdc.com");
                                        break;
                                        case 'HE252':
                                          //  newBook.author = 'VERBRAEKEN AMELIE';
                                          //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                            newBookData.append('author', 'NDE FEUKAM EMMANUEL');  // A rajouter partout 
                                            newBookData.append('authorId', "emmanuel.ndefeukam@lpl-rdc.com");
                                            break;
                                            case 'NH222':
                                              //  newBook.author = 'VERBRAEKEN AMELIE';
                                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                                newBookData.append('author', 'NGAMA NANCY');  // A rajouter partout 
                                                newBookData.append('authorId', "nancy.ngama@lpl-rdc.com");
                                                break;
                                                case 'EN212':
                                              //  newBook.author = 'VERBRAEKEN AMELIE';
                                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                                newBookData.append('author', 'REO JORDY');  // A rajouter partout 
                                                newBookData.append('authorId', "jordy.reo@lpl-rdc.com");
                                                break;

                                                case 'XV14':
                                              //  newBook.author = 'VERBRAEKEN AMELIE';
                                              //  newBook.authorId ='amelie.verbraeken@lpl-rdc.com';
                                                newBookData.append('author', 'LEMAIRE ADRIEN');  // A rajouter partout 
                                                newBookData.append('authorId', "adrien.lemaire@lpl-rdc.com");
                                                break;








          case 'GY67':
          //  newBook.author = 'KABANGU KANDA';
          //  newBook.authorId = 'kanda.kabangu@lpl-rdc.com';
            newBookData.append('author', 'KABANGU KANDA');  // A rajouter partout 
            newBookData.append('authorId', "kanda.kabangu@lpl-rdc.com");
            break;
          case 'P68':
          //  newBook.author = 'CHAMI KARIN';
          //  newBook.authorId = 'karin.chami@lpl-rdc.com';
            newBookData.append('author', 'CHAMI KARIN');  // A rajouter partout 
            newBookData.append('authorId', "karin.chami@lpl-rdc.com");
            break;
            case 'DP69':
          //  newBook.author = 'DEVOS OLIVIER';
          //  newBook.authorId = 'direction.primaire@lpl-rdc.com';
            newBookData.append('author', 'DEVOS OLIVIER');  // A rajouter partout 
            newBookData.append('authorId', "direction.primaire@lpl-rdc.com");
            break;
            case 'P661':
          //  newBook.author = 'FLORIANE MAROY';
          //  newBook.authorId = 'floriane.maroy@lpl-rdc.com';
            newBookData.append('author', 'FLORIANE MAROY');  // A rajouter partout 
            newBookData.append('authorId', "floriane.maroy@lpl-rdc.com");
            break;
            case 'PY17':
          //  newBook.author = 'JOANNE FLEURVIL';
          //  newBook.authorId = 'joanne.fleurvil@lpl-rdc.com';
            newBookData.append('author', 'JOANNE FLEURVIL');  // A rajouter partout 
            newBookData.append('authorId', "joanne.fleurvil@lpl-rdc.com");
            break;
            case 'YY5':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'BAVUIDI BODIA');  // A rajouter partout 
            newBookData.append('authorId', "bavuidi.bodia@lpl-rdc.com");
            break;
            case 'FF6':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'DE GROEF COLETTE ');  // A rajouter partout 
            newBookData.append('authorId', "degroef.colette@lpl-rdc.com");
            break;
            case 'Z47':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'DE JAHAM SOURAYA');  // A rajouter partout 
            newBookData.append('authorId', "dejaham.souraya@lpl-rdc.com");
            break;
            case 'G681':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'GAKALLA VALERY');  // A rajouter partout 
            newBookData.append('authorId', "gakalla.valery@lpl-rdc.com");
            break;
            case 'C19C':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'JACQUERYE ODILE');  // A rajouter partout 
            newBookData.append('authorId', "jacquerye.odile@lpl-rdc.com");
            break;
            case 'F2F1':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'NGAMA NANCY');  // A rajouter partout 
            newBookData.append('authorId', "ngama.nancy@lpl-rdc.com");
            break;
            case 'B2021':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'NGINDU MICHAELA ');  // A rajouter partout 
            newBookData.append('authorId', "ngindu.michaela@lpl-rdc.com");
            break;
            case 'C31C':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'STURAM JOSEPHINE');  // A rajouter partout 
            newBookData.append('authorId', "sturam.josephine@lpl-rdc.com");
            break;
            case 'C21C':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'THAMBWE JASON');  // A rajouter partout 
            newBookData.append('authorId', "thambwe.jason@lpl-rdc.com");
            break;
            case 'E31E':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'VLERICK VINCENT');  // A rajouter partout 
            newBookData.append('authorId', "vlerick.vincent@lpl-rdc.com");
            break;
            case 'H615':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'VERSCHAEREN ALEXANDRA');  // A rajouter partout 
            newBookData.append('authorId', "verschaeren.alexandra@lpl-rdc.com");
            break;
            case 'CZ47':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'KALANGA RITHA');  // A rajouter partout 
            newBookData.append('authorId', "kalanga.ritha@lpl-rdc.com");
            break;
            case 'F20H':
              //  newBook.author = 'HERVE MBENGA LIKITA';
            //   newBook.authorId = 'hml@hml-solution.com';
                newBookData.append('author', 'GUYOT JULIETTE');  // A rajouter partout 
                newBookData.append('authorId', "guyot.juliette@lpl-rdc.com");
                break;
                case 'CB19':
              //  newBook.author = 'HERVE MBENGA LIKITA';
            //   newBook.authorId = 'hml@hml-solution.com';
                newBookData.append('author', 'MBAYA NGANDU EMMANUEL');  // A rajouter partout 
                newBookData.append('authorId', "mbaya.emmanuel@lpl-rdc.com");
                break;

                case 'PA29':
              //  newBook.author = 'HERVE MBENGA LIKITA';
            //   newBook.authorId = 'hml@hml-solution.com';
                newBookData.append('author', 'MUTOMBO KANA');  // A rajouter partout 
                newBookData.append('authorId', "mutombo.kana@lpl-rdc.com");
                break;
                case 'H654':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'LUKUSA OLIVIER');  // A rajouter partout 
                    newBookData.append('authorId', "lukusa.olivier@lpl-rdc.com");
                    break;
                    case 'C2HE':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'MATHYS DENIS');  // A rajouter partout 
                    newBookData.append('authorId', "mathys.denis@lpl-rdc.com");
                    break;
                    case 'FPA19':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'JUNG TANIA');  // A rajouter partout 
                    newBookData.append('authorId', "jung.tania@lpl-rdc.com");
                    break;
                    case 'CF19':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'MATHOUL MARTIN');  // A rajouter partout 
                    newBookData.append('authorId', "mathoul.martin@lpl-rdc.com");
                    break;
                    case 'BPA5':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'DRAOU DALIL');  // A rajouter partout 
                    newBookData.append('authorId', "draou.dalil@lpl-rdc.com");
                    break;
                    case 'ZFF65':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'de GUELDRE SOPHIE');  // A rajouter partout 
                    newBookData.append('authorId', "degueldre.sophie@lpl-rdc.com");
                    break;
                    case 'PAF31':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'MBAYA EMMANUEL');  // A rajouter partout 
                    newBookData.append('authorId', "mbaya.emmanuel@lpl-rdc.com");
                    break;
                    case 'AGY47':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'ABDOULI ASMA');  // A rajouter partout 
                    newBookData.append('authorId', "abdouli.asma@lpl-rdc.com");
                    break;
                    case 'B6G':
                  //  newBook.author = 'HERVE MBENGA LIKITA';
                //   newBook.authorId = 'hml@hml-solution.com';
                    newBookData.append('author', 'NICOLAS BOURLON');  // A rajouter partout 
                    newBookData.append('authorId', "nicolas.bourlon@lpl-rdc.com");
                    break;

            case '150978':
          //  newBook.author = 'HERVE MBENGA LIKITA';
        //   newBook.authorId = 'hml@hml-solution.com';
            newBookData.append('author', 'HERVE MBENGA LIKITA');  // A rajouter partout 
            newBookData.append('authorId', "hml@hml-solution.com");
            break;
          case 'AVIS PARENTS SECONDAIRE': // TODO: REMOVE FOR PRODUCTION
          //  newBook.author = 'DIRECTION PEDAGOGIQUE SECONDAIRE';
          //  newBook.authorId = 'direction.secondaire@lpl-rdc.com';
            newBookData.append('author', 'DIRECTION PEDAGOGIQUE SECONDAIRE');  // A rajouter partout 
            newBookData.append('authorId', "direction.secondaire@lpl-rdc.com");
            break;
          case 'AVIS PARENTS FONDAMENTALE': // TODO: REMOVE FOR PRODUCTION
          //  newBook.author = 'DIRECTION PEDAGOGIQUE FONDAMENTALE';
          //  newBook.authorId = 'direction-pedagogique-fondamentale@lpl-rdc.com';
            newBookData.append('author', 'DIRECTION PEDAGOGIQUE FONDAMENTALE');  // A rajouter partout 
            newBookData.append('authorId', "direction-pedagogique-fondamentale@lpl-rdc.com");
            break;
          default:
            this.authorId = '';
    }
    console.log('Mon ID : ' + this.authorId);

    if (this.authorId != '') {

      //this.booksService.createNewBook(newBook);  // vers firebase
      //this.router.navigate(['/books']);         // La suite logique 

      //newBook.file1name = '-';
      newBookData.append('file1name', this.file1name);
      //newBook.file1link= '-';
      newBookData.append('file1link', this.file1link);
      //newBook.file2name= '-';
      newBookData.append('file2name', this.file2name);
      // newBook.file2link= '-';
      newBookData.append('file2link', this.file2link);
       //newBook.file3name= '-';
      newBookData.append('file3name', this.file3name);
      // newBook.file3link= '-';
      newBookData.append('file3link', this.file3link);
      //newBook.file4name= '-';
      newBookData.append('file4name', this.file4name);
     // newBook.file4link= '-';
      newBookData.append('file4link', this.file4link);
      //newBook.file5name= '-';
      newBookData.append('file5name', this.file5name);
      //newBook.file5link= '-';
      newBookData.append('file5link', this.file5link);
     // newBook.file6name= '-';
      newBookData.append('file6name', this.file6name);
      //newBook.file6link= '-';
      newBookData.append('file6link', this.file6link);
      //newBook.file7name= '-';
      newBookData.append('file7name', this.file7name);
     // newBook.file7link= '-';
      newBookData.append('file7link', this.file7link);
      //newBook.file8name= '-';
      newBookData.append('file8name', this.file8name);
      //newBook.file8link= '-';
      newBookData.append('file8link', this.file8link);
      //newBook.file9name= '-';
      newBookData.append('file9name', this.file9name);
      //newBook.file9link= '-';
      newBookData.append('file9link', this.file9link);
      //newBook.file10name= '-';
      newBookData.append('file10name', this.file10name);
      // newBook.file10link= '-';
      newBookData.append('file10link', this.file10link);
      // newBook.file11name= '-';
      newBookData.append('file11name', '');
      //newBook.file11link= '-';
      newBookData.append('file11link', '');
      //newBook.file12name= '-';
      newBookData.append('file12name', '');
      // newBook.file12link= '-';
      newBookData.append('file12link', '');
      // newBook.file13name= '-';
      newBookData.append('file13name', '');
      // newBook.file13link= '-';
      newBookData.append('file13link', '');
      // newBook.file14name= '-';
      newBookData.append('file14name', '');
      //newBook.file14link= '-';
      newBookData.append('file14link', '');
      //newBook.file15name= '-';
      newBookData.append('file15name', '');
      //newBook.file15link= '-';
      newBookData.append('file15link', '');
      //newBook.file16name= '-';
      newBookData.append('file16name', '');
      //newBook.file16link= '-';
      newBookData.append('file16link', '');
      //newBook.file17name= '-';
      newBookData.append('file17name=', '');
      //newBook.file17link= '-';
      newBookData.append('file17link', '');
      //newBook.file18name= '-';
      newBookData.append('file18name', '');
      //newBook.file18link= '-';
      newBookData.append('file18link', '');
      //newBook.file19name= '-';
      newBookData.append('file19name', '');
      //newBook.file19link= '-';
      newBookData.append('file19link', '');
      //newBook.file20name= '-';
      newBookData.append('file20name', '');
      //newBook.file20link= '-';
      newBookData.append('file20link', '');
      
      this.booksService.createLecon( newBookData);

      this.booksService.getBooksFromServer();
      this.booksService.emitBooks();
      this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
      //this.router.navigate(['/books']);
 

    } else {
      this.errorMessage = 'Identifiant Unique invalide';

    }


  }





  

  onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.booksService.uploadFile_old(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }

  onUploadFileDocument(fileDocument: File, currentIndex: number, totalFiles: number) {
    this.fileIsUploadingDocument = true;
    this.booksService.uploadFileDocument(fileDocument).then(
      (urlDocument: { name: string, url: string }) => {
        this.fileDocuments.push(urlDocument);
        if (currentIndex + 1 === totalFiles) {
          this.fileIsUploadingDocument = false;
          this.fileUploadedDocument = totalFiles;
        }
      }
    );
  }
}

