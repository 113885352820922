import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-choix',
  templateUrl: './dialog-choix.component.html',
  styleUrls: ['./dialog-choix.component.css']
})
export class DialogChoixComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data : any) { }

  ngOnInit(): void {

       

  }

}
