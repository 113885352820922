import {Component, OnInit, ViewChild, Renderer2, AfterViewInit} from '@angular/core';
import {BooksService} from '../services/books.service';
import {Router} from '@angular/router';
import {ProductsFinance} from '../models/productsfinance';

import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';

declare var $;

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  public productsfinance: ProductsFinance [];
  @ViewChild('productsTableFinance', {static: false}) Table;
  public dataTable: any;
  public dtOptions;
  public tableElement: any;

  constructor(private crudService: BooksService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loadProductsFinance();
  }

  loadProductsFinance() {
    this.crudService.getProductsFinance().subscribe(data => {
      this.productsfinance = data;
    }, err => {}, () => {
      setTimeout(() => {
        this.dataTable = $(this.Table.nativeElement);
        this.tableElement = this.dataTable.DataTable(this.dtOptions);
      }, 1000);  
    });

  }

  getNavigation(link, id) {
    if (id === '') {
      
      this.router.navigate([link]);
    } else {
      console.log('Valeur ID : ' + id);
      this.router.navigate([link + '/' + id]);
      
    }
  }

}
