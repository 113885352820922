import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private class$: BehaviorSubject<string>;

  constructor() {
    this.class$ = new BehaviorSubject<string>('');
  }

  getClass() {
    return this.class$.asObservable();
  }

  setClass(classe: string) {
    this.class$.next(classe);
  }

  //createNewUser(email: string, password: string) {
   // return new Promise(
      //(//resolve, reject) => {
       // firebase.auth().createUserWithEmailAndPassword(email, password).then(
          //() => {
            //resolve();
          //},
          //(error) => {
           // reject(error);
          //}
        //);
      //}
    //);
//}
//signInUser(email: string, password: string) {
  //return new Promise(
    //(resolve, reject) => {
      //firebase.auth().signInWithEmailAndPassword(email, password).then(
        //() => {
         // resolve();
        //},
        //(error) => {
         // reject(error);
        //}
     // );
    //}
  //);
//}
signOutUser() {
  firebase.auth().signOut();
}

verificationUser(isStudent: boolean){

    isStudent = isStudent;
}
}
