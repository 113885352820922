<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >
  <p> </p>
  <p> </p>


<div class="example-container mat-elevation-z8">
  <div class="jumbotron" style="background-color:lavenderblush" >
  <h3>Bienvenue {{nickname}}! <button href="#" data-toggle="popover" title="Quittez de la session en cours" mat-flat-button (click)="logout()"><mat-icon>logout</mat-icon></button></h3>
  <p class="text-danger">{{ errorMessage }}</p>

  <div class="dropdown-menu">
    <h6 class="dropdown-header">Dropdown header</h6>
    <a class="dropdown-item" href="#">Action</a>
    <a class="dropdown-item" href="#">Another action</a>
  </div>
</div>
  
  <h2>Liste des groupes</h2>
  <div class="example-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="button-row">
    <button href="#" data-toggle="popover" title="Permet de créer des nouveaux groupes'Désactivé'" disabled mat-flat-button color="primary" [routerLink]="['/addroom']"><mat-icon>add</mat-icon></button>
  </div>
  <div class="mat-elevation-z8">    

    <table mat-table [dataSource]="rooms" class="example-table"
           matSort matSortActive="roomname" matSortDisableClear matSortDirection="asc">
         

      <!-- Room Name Column -->
      <ng-container matColumnDef="roomname">
        <th mat-header-cell *matHeaderCellDef>Nom du groupe</th>
        <td mat-cell *matCellDef="let row">{{row.roomname}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="enterChatRoom(row.roomname)"></tr>
    </table>
  </div>
</div>

</div>
