
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >  
  <p> </p>
  <p> </p>


  <h2>Vos Devoirs corrigés</h2>

    <div class="jumbotron" style="background-color:lavenderblush" >
      <div class="row">
        <div class="col-sm-4">
          <button class="btn btn-default" (click)="onBackStudent()">Retour</button>
          
        </div>
      </div>

    </div>
      <div class="list-group">
        <button
          class="list-group-item"
          *ngFor="let book of (books$ | async); let i = index"
          [disabled]="book.title == 'DEVOIR'"

          (click)="onViewBook(book.id)" >
          <h3 class="list-group-item-heading">

            {{ book.listedechoix | uppercase }}
            <p></p>
            -<span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert">Attention</span></span> 
            
              <p> </p>
            <b>{{ book.dateJour | date:"dd/MM/yyyy" }}</b>
            <p> </p>

            <b>{{ book.author | uppercase}}</b>
          

           <button class="btn btn-default pull-right" >      
               <span class="glyphicon glyphicon-remove" ></span>
           </button>              

          </h3>
          <p class="list-group-item-text">{{ book.title | uppercase  }}</p>
          <p class="list-group-item-text">{{ book.id }}</p>

        </button>
    
    
    
  </div>
 </div>

