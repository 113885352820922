
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/paques5.png');" >
  <p> </p>
  <p> </p>

 
  <div class="row" [ngStyle]="{'color': 'blue'}">
      <div class="col-xs-12">        

        
        <p></p>
            
      <div class="jumbotron" style="background-color:lavenderblush" >
        <div class="row">

          <div class="col-sm-2">
            <h2>{{ book.listedechoix | uppercase }}</h2>
          </div>
          <div class="col-sm-10">
            <p class="overflow-ellipsis"> <span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert"><h4>{{ book.alerte }}</h4></span></span> </p>
          </div>
        </div>
        
        <h4>{{ book.dateJour | date:"dd/MM/yyyy" }}</h4>
        <h2><i>{{ book.title }}</i></h2>
      
        <h3>{{ book.authorId }}</h3>
        <h3>{{ book.author }}</h3>

        <ckeditor [(ngModel)]="book.synopsis"></ckeditor>
      
       
        <h4>{{ book.liens }}</h4>

        
        <div *ngIf="book.file1name">          
          <h4><i>Le(s) Fichier(s) à télécharger en bleu(s)</i></h4>
          <a  [href]="book.file1link" target="_blank">- {{ book.file1name }}</a>            
        </div>
        <div *ngIf="book.file2name">      
          <a  [href]="book.file2link" target="_blank">- {{ book.file2name }}</a>        
        </div>
        <div *ngIf="book.file3name">      
          <a  [href]="book.file3link" target="_blank">- {{ book.file3name }}</a>        
        </div>
        <div *ngIf="book.file4name">      
          <a  [href]="book.file4link" target="_blank">- {{ book.file4name }}</a>        
        </div>
        <div *ngIf="book.file5name">      
          <a  [href]="book.file5link" target="_blank">- {{ book.file5name }}</a>        
        </div>
        <div *ngIf="book.file6name">      
          <a  [href]="book.file6link" target="_blank">- {{ book.file6name }}</a>        
        </div>
        <div *ngIf="book.file7name">      
          <a  [href]="book.file7link" target="_blank">- {{ book.file7name }}</a>        
        </div>
        <div *ngIf="book.file8name">      
          <a  [href]="book.file8link" target="_blank">- {{ book.file8name }}</a>        
        </div>
        <div *ngIf="book.file9name">      
          <a  [href]="book.file9link" target="_blank">- {{ book.file9name }}</a>        
        </div>
        <div *ngIf="book.file10name">      
          <a  [href]="book.file10link" target="_blank">- {{ book.file10name }}</a>        
        </div>
       
      </div>        
     
        <button class="btn btn-default" (click)="onBack()">Retour</button>


        <div *ngIf="book.title === 'DEVOIR'">

          <form [formGroup]="bookForm" (ngSubmit)="onSaveBook()" >
            <p></p>                 
            
      
            <div class="bookForm">
              <label for="comment">Cotation/Commentaire/Annotation:</label>
              <textarea class="form-control" placeholder="Saisissez une annotation ici, Maximum 100 caractères" rows="5" formControlName="comment" id="comment"></textarea>
            </div>

            <button class="btn btn-success" [disabled]="bookForm.invalid "
            type="submit">Envoyer
            
            </button>
      
          </form>
        </div>
      </div>
    </div>