<h2 mat-dialog-title >Bonjour   {{data.userName}}!</h2>
<mat-dialog-content ></mat-dialog-content>




<div style="height: 50vh;">

    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button>
          <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button>
        <h1>Espace parents</h1>
        <span class="menu-spacer"></span>
  
        
        <button mat-icon-button>
          <mat-icon (click)="sidenav1.toggle()">menu</mat-icon>
        </button>
       
        <span class="menu-spacer"></span>
  
 
       
      
      </mat-toolbar-row>
    
      <mat-toolbar-row >
          <span style="font-size: 12px;" ><h3><i>Plateforme en ligne du Lycée Prince de Liège-Kinshasa(Digital-School)</i></h3></span>
      </mat-toolbar-row>
    </mat-toolbar>
    
    <mat-sidenav-container fxFlexFill class="example-container">
      <mat-sidenav #sidenav >
        <mat-nav-list>
    
          <a mat-list-item [routerLink]="'/avis'" routerLinkActive="active" *ngIf="data.Avis"> Avis </a>
          <a mat-list-item [routerLink]="'authsuppbulletin'" routerLinkActive="active" *ngIf="data.Bulletin"> Bulletin en ligne </a>
          <a mat-list-item [routerLink]="'authsuppfacture'" routerLinkActive="active" *ngIf="data.Facture"> Facture en ligne </a>
          <a mat-list-item [routerLink]="'calendar'" routerLinkActive="active" *ngIf="data.Calendrier"> Prise de R.D.V. </a>
          <a mat-list-item   href="https://forms.gle/vRvE1JcMQWrddp1G9"  Target="_blank"> Académie des parents </a>
          <a mat-list-item href="http://www.hanna-sarl.com//" Target="_blank"> Commande en ligne </a>
          <a mat-list-item   [routerLink]="'auth/signin'" routerLinkActive="active" *ngIf="data.Auth" > Connexion </a>
          
          
    
        </mat-nav-list>
      </mat-sidenav>
  
      <mat-sidenav #sidenav1  >
        <mat-nav-list >
           
          <a mat-list-item   [routerLink]="'booksStudent'" routerLinkActive="active" > ClassRoom </a>
              <a mat-list-item   [routerLink]="'auth/signin'" routerLinkActive="active" *ngIf="data.isAuth"> Class-Room </a>
              <a mat-list-item   href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a>
              <a mat-list-item   href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a>              
              <a mat-list-item   href="https://login.one.com/mail" Target="_blank" > Webmail </a>
              <a mat-list-item   href="https://videotheque2.lpl-rdc.com/" Target="_blank" > Médiathèque </a>
              <a mat-list-item   [routerLink]="'login'" routerLinkActive="active" *ngIf="data.ChatRoom"> Chat-Room </a>
              <a mat-list-item   [routerLink]="'finance'" routerLinkActive="active" *ngIf="data.Finance" > Finance </a>
              <a mat-list-item   [routerLink]="'secretariat'" routerLinkActive="active" *ngIf="data.Secretariat" > Secrétariat </a>
              <a mat-list-item   [routerLink]="'communication'" routerLinkActive="active" *ngIf="data.Communication" > Communication </a>
              <a mat-list-item   [routerLink]="'admin'" routerLinkActive="active" *ngIf="data.Administration" > Administration du Système </a>
              
                      
         
           
        </mat-nav-list>
      </mat-sidenav>
  
      <mat-sidenav-content fxFlexFill>
        <div style="height: 10vh;">
    
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    
    </div> 
    
    
    
    
<mat-dialog-actions >
    <button mat-button mat-dialog-close mat-dialog-close='True'> Fermer</button>
    
</mat-dialog-actions>
