import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-roomlist',
  templateUrl: './roomlist.component.html',
  styleUrls: ['./roomlist.component.css']
})
export class RoomlistComponent implements OnInit {
  nickname = '';
  displayedColumns: string[] = ['roomname'];
  rooms = [];
  isLoadingResults = true;
  errorMessage : string;


  constructor(private route: ActivatedRoute, private router: Router, public datepipe: DatePipe) {
    this.nickname = localStorage.getItem('nickname');
    firebase.database().ref('rooms/').on('value', resp => {
      this.rooms = [];
      this.rooms = snapshotToArray(resp);
      this.isLoadingResults = false;
    });
  }

  ngOnInit(): void {
  }

  enterChatRoom(roomname: string) {

    switch (roomname) {
      case 'S1I':
       var nom_eleves = "BOKINGI Julian BUDIMBU Soraya DOS ANJOS Astrid ENDUNDO FERREIRA Sandro GONDA DI MATINA Chloé GROBELNY Joseph KABUIKA Dahlia KANYIMBU Brayan KASONGA NDUNGA KAYEMBE MODWARIZA Muriel KAYUMBA Daniel KISIMBA Eden KOMBO Victoria LEBENI Tanaël LEMAIRE Leana LOKALE Emmanuella LUSINDE Yohali MONGA ILUNGA Adriel Roger NLANDU Shamah OKITOSOMBA Darren PALMY Auréole PANDA Caroline SAGNO Mawa SHAMBA Michel-Ange ";
       var  position = nom_eleves.indexOf(this.nickname);

       if( position != -1){
        console.log('Mon GROUPE : ' + position);

        this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
       
  
        } else{
          console.log('Mon GROUPE : ' + position);  
          this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
        
        }
        break;

        case 'S1II':
          var nom_eleves = "AISSI Pierre ANDRIANTAVY Laure-Aina CIBALONZA Junior JACOBS Benjamin KAWAMA Ketsia KAWARA Allégria KINYOKI Atéa LOKOTO Sancini LUHAKA Gloria LWANGO Victoria MABUNDA Michel-Ange MANGULU Gracia MUMBEMBELE Emmanuella MWANA MAY Olive N_DUA Dayana NGANDU Yaëlle NTUMBA Bolide ONEZYME Gloria SINGA Léonie THALIA WATCHIBA UMBA Délices ZOUNDI Aaron ";
          var  position = nom_eleves.indexOf(this.nickname);
   
          if( position != -1){
           console.log('Mon GROUPE : ' + position);
   
           this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
          
     
           } else{
             console.log('Mon GROUPE : ' + position);  
             this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
           
           }
           break;

           case 'S1III':
            var nom_eleves = "BULAYA Heidi IBRAHIM Mohamed LUKEMBA Chloé Hellemans LUYEYE TADI MFUMU Délice MAHY Pol MAKUNDA Yanis MASUDI Jean-Gedeon MBANGU Emy MULAND Nicolas MULENDA Ruphin MUNDHU Benjamin MUSANGU Benjamin NGOYI Samuella NTALAJA Winner NZOLANTIMA Adrien NZOLANTIMA Etia WAN Andy YAKA Ephraïm YAV Angela ZIELE Raphaël ";
            var  position = nom_eleves.indexOf(this.nickname);
     
            if( position != -1){
             console.log('Mon GROUPE : ' + position);
     
             this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
            
       
             } else{
               console.log('Mon GROUPE : ' + position);  
               this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
             
             }
             break;

             case 'S2I':
              var nom_eleves = "ANDEMA KABOYI Muriel BAHWERE NDOVA Jemima BERTHELOT Medhi Laurent BILEK Chiara Genevière BILOMBA Trish Agreyta BULU LASSASS Jacques FOTA YOMBWE Joachim ILOFO MBOYO Daniel IMAMA ILEBO Catherine KAHASHA CHILAGANE Raïa KOULTOUMI Juhani Roland-Marcel LANCELOT Thalia Grâce LIKIN Tristan LUNDI Eunice MATATA YOHALI Archange MAYEMBA Andrea MBAYA NGALULA Marie-Catherine MUTINGA KATUBALONDI Evajolie MWEHU Charles Mayundo NEEMA VUNABANDI Doxa PONGOMBO ONUSUMBA Henri ZHOU Muyan ";
              var  position = nom_eleves.indexOf(this.nickname);
       
              if( position != -1){
               console.log('Mon GROUPE : ' + position);
       
               this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
              
         
               } else{
                 console.log('Mon GROUPE : ' + position);  
                 this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
               
               }
               break;

               case 'S2II':
                var nom_eleves = "HU Hanbing Charly KABALA KAPINGA Grâce KABUYA-LUMUNA CHEUSSI Christelle KANI KANI OPINE Nathan KASONGO NGEMBA Merci KATIKIA AZANGA Malan KAVUO MATHE Grace KOSO LONGAYO Pierrette LIZEBI SOFI Prince LUYEYE NTINDU Dodie MAKIASHI Sem MASIALA BINDA Veronica Grâce MBIYE MULOMBA Betina MWANA-DEO GRATIAS Gloria MWEPU MAKASA TSHIANGALA Océane NGARAMBE ISHARA Dan NGENYI MULOWAYI Xavier Samu NSEKA LANDA Elliot Kiese OKITOSOMBA OPENGE Albert Theia TCHUMA MILONGA Jonathan ZHOU Zheng Enock ";
                var  position = nom_eleves.indexOf(this.nickname);
         
                if( position != -1){
                 console.log('Mon GROUPE : ' + position);
         
                 this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                
           
                 } else{
                   console.log('Mon GROUPE : ' + position);  
                   this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                 
                 }
                 break;

                 case 'S2III':
                var nom_eleves = "BUZENGI MAFUTA Annabelle Joyce CHOVU Issa Christian KAGE MWANZITA Marie Florent KAYEMBE MINZUNDU Noé LEZIN Charlotte LUFUMA SONY Carl-Anthony MANKONDO LEMA Jonathan Thomas MATONDO TSUMBU Yohan MULOKO MWAMBA Gaspy MUSAMPA MWABA Daniel MUSOGA TAMBWE Bénédicte Grâce OKONGE MODESTE Junior Daniel OTONGA NGWENDE Vincianne PANDA Annie-Gabrielle PHAMBU Henry-Michel SANGES Matteo SEKE Vanecia Kibaba SETI Alexandre SINGA KUPA MADUGALA François TSHIPU KALEV Hélène ";
                var  position = nom_eleves.indexOf(this.nickname);
         
                if( position != -1){
                 console.log('Mon GROUPE : ' + position);
         
                 this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                
           
                 } else{
                   console.log('Mon GROUPE : ' + position);  
                   this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                 
                 }
                 break;

                 case 'S3TQ':
                  var nom_eleves = "BADIBANGA Hiram DOLLY WANDALA Ebed KAWAMA MWADI Roseline KISIMBA NGOY MUAMBA Jordan-Samuel MUKENDI YEZE Karel Yace NGANDU NGOYI Daniella TANGI Cassandra Grâce Mathilde TSHIOTA TSHIBOLA Marie Annaelle ";
                  var  position = nom_eleves.indexOf(this.nickname);
           
                  if( position != -1){
                   console.log('Mon GROUPE : ' + position);
           
                   this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                  
             
                   } else{
                     console.log('Mon GROUPE : ' + position);  
                     this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                   
                   }
                   break;

                   case 'S3 ECO I':
                    var nom_eleves = "ADOMBE KETOPE Daniella BADIBANGA Kestia-Cristale BECQUEVORT Harmony Kabagema BISIMWA LUEMBA Charles-Henri DE ROECK Anthony Cédric Christian ELONGA LIBOKE Geoffrey ITAMA GIKAHOYA Ferdyl KALONJI TAMBAYI Stéphanie LEZIN Brenda Béatrice LUHAKA MUSAFIRI Nelson MADAIL TANDUNDU AUGUSTO Marie-Julia NGONGO MANGAZA Christenvie NTUMBA NGOY Abi Béatrice OKITOSOMBA EDUMBE Alphine PAMI KIESSE Benie SHAMPA Barchiel STAVELOT Lucas TSHIYOYO NGALAMULUME Paul Henri ";
                    var  position = nom_eleves.indexOf(this.nickname);
             
                    if( position != -1){
                     console.log('Mon GROUPE : ' + position);
             
                     this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                    
               
                     } else{
                       console.log('Mon GROUPE : ' + position);  
                       this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                     
                     }
                     break;

                     case 'S3 ECO II':
                    var nom_eleves = "DIBATHIA NKETANI Jade Méline FERREIRA JUNIOR Malaïka Louise IMAMA BOKANGA Pierre KAMANDA TSHAKAYA Asael KITENGE MUNKINDJI Johan LI Weiyu Stella LIANAVWA MUMBERE Philippe MAKOSSO Cyril David MBAKU MANGAMFU Reiel MOKONDA MA BATULE Marc Henri MONGA Danielle MUHIYA FALIALA Gad MWILWA TAMBWE Francis NLANDU NEHEMA Claire SANGWA Daniel SETI Jean-Yanny VANCUTSEM Alain Martin WATCHIBA SAIDI Samuel ZHAO Zihan ";
                    var  position = nom_eleves.indexOf(this.nickname);
             
                    if( position != -1){
                     console.log('Mon GROUPE : ' + position);
             
                     this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                    
               
                     } else{
                       console.log('Mon GROUPE : ' + position);  
                       this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                     
                     }
                     break;

                     case 'S3 SC I':
                      var nom_eleves = "BADU AKETI PAIZANOS Alex BOSHAB BESHAL MONYO Emmanuel BULU OWEN Junior DJONGA Prince Aaron JACQUERYE Christian KABEMBA Chiara Maëlle KANYONGA KABEYA FLORIAN LUHONGE SIFA Chloé Alexia MOKOLI NGOY Camille Elie MULENDA SANGEWA Emérite MUSUMBA TSHIBOLA Estelle Astrid MVULAZANA KILUBU Daisy Véronique MWEHU Henri Baruani NZIGIRE Kimberley PAY-PAY VAGHENI Kiyana SAFUNIA KIAMBA Anne-Sophie SIMENE KILICHO Yasmine ZOUNDI Alena Athanaïs W ";
                      var  position = nom_eleves.indexOf(this.nickname);
               
                      if( position != -1){
                       console.log('Mon GROUPE : ' + position);
               
                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                      
                 
                       } else{
                         console.log('Mon GROUPE : ' + position);  
                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                       
                       }
                       break;

                       case 'S3 SC II':
                      var nom_eleves = "BANGUDI MFUANA Léa Marie-Annie BOYELA ILEMBWA Donel GRACE MARIE-CLAIRE Shukrani HUSSEIN BARAKA Hussein JABER Hussein KAJ KANYIMBU Chloé KAMBERE Kayla KASONGO LWANZA Princesse KASUKU SIMADA David-Cornelis KAYEMBE NSAMINUE Marie Splendid MANKONDO MUPAPA Jocelyne Diersi MOLAMBA LOWA Louise-Odette Sese MPULU KANKIESA Grâce-Félicienne MUDINA WA MUDINA Bernoully N_SA LOKWA Bradley NYAKABASA IRAGI Mina Solène NZILA TADULU Tracy PALMY Lise PHEMBA Léa  ";
                      var  position = nom_eleves.indexOf(this.nickname);
               
                      if( position != -1){
                       console.log('Mon GROUPE : ' + position);
               
                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                      
                 
                       } else{
                         console.log('Mon GROUPE : ' + position);  
                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                       
                       }
                       break;

                       case 'S4SC':
                        var nom_eleves = "AMOURI NZALI Elam ANDRIANTAVY Yanis AUTSAI AYIKORU Joy BANGUDI BASUNGA Ethan Kayamba de LANNOY Nyssia Léa Jacquie DEMEESTER Joséphine Séverine FOTA KONGOLO KAJIKU Deo Gracia ILUNGA MBENGI Christelle KABASELE Christian KAMANZI Nyota KIBAMBE NKONGOLO Triomphe KISHIMBA WA DIESE Eben Ezer MAISHA MUKUNA Maël MBALA Gabriella Sarah MBELOLA Neema MUNGWA Violette PANZA IFELO Géorgina SAKOMBI MOLENDO Amélie Monique  ";
                        var  position = nom_eleves.indexOf(this.nickname);
                 
                        if( position != -1){
                         console.log('Mon GROUPE : ' + position);
                 
                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                        
                   
                         } else{
                           console.log('Mon GROUPE : ' + position);  
                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                         
                         }
                         break;

                         case 'S4ECO':
                        var nom_eleves = "BALINGIBISO Esther ELONGA IMANI Andréa HATTA KETAMU Cynthia Golden KAWAMA BANZA Daniella KOMBO LISASI Christ LEBENI Ilian Gabriel Célestin LUMBU Joëlle LUYEYE MANTETO Mignon LWELA BINDA KADEFI Emmanuel MASHAKO MAMBA Bénédict Nathan MPUTU Julien MUAMBA Ryan MULUMBA KATCHY Althéa Tshibuyi NDONGALA KET Keline Rachel NEMOYATO ENKOTOSIYA Maguy NSEKA LANDA Danaëlle OTONGA LUTSHINDA Dorcas PLESSERS Maxime Ibrahim TCHELO MAZOMBO Ariel Christopher VIKA KANKIEZA Jean-Vincent WAN NGIMBI Cyntiche YAMBA KABANGO Prince David  ";
                        var  position = nom_eleves.indexOf(this.nickname);
                 
                        if( position != -1){
                         console.log('Mon GROUPE : ' + position);
                 
                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                        
                   
                         } else{
                           console.log('Mon GROUPE : ' + position);  
                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                         
                         }
                         break;

                         case 'S4ES':
                          var nom_eleves = "ADOMBE GABRIELLA BAHWERE PALUKU Trésor DURIEUX Gaëlle ENDUNDO FERREIRA Joachim Grégo ENGULU BOYEKA LOMIAKA Loïle Ma GIRINGANJI DON Aymard HAMAD Hind HIJAZI Amani KAMUN TSHIKALA Glory KAPINGA MUBANGA Mauricette KIMBEMBE CHRISMAS LOKALE CHLOE MATUNGUL NOBANA Dorcas Marie B MOBUTU Ilaniya Ladawa MUSAMPA KAPINGA Erica MUSANGU Priscillia TEIXEIRA Emmanuela Shanaya TSHISEKEDI TSHIMIYI Christopher TSHIVUADI KALOMBO TAMBWE TSHOVU Mukendi Naomi  ";
                          var  position = nom_eleves.indexOf(this.nickname);
                   
                          if( position != -1){
                           console.log('Mon GROUPE : ' + position);
                   
                           this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                          
                     
                           } else{
                             console.log('Mon GROUPE : ' + position);  
                             this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                           
                           }
                           break;

                           case 'S4TQ':
                            var nom_eleves = "BILOMBA WA BILOMBA Priscilla HENDRICK Kluivert Ric LUBANDJI CHARLES MARTINS LUIS Helder David MBIYE MULOMBA Isaac M_PETI BOPONGE David MUNDHU JOSEPH EPHRAIM Beni PHEMBA Roland VIRA MATHE Esther ";
                            var  position = nom_eleves.indexOf(this.nickname);
                     
                            if( position != -1){
                             console.log('Mon GROUPE : ' + position);
                     
                             this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                            
                       
                             } else{
                               console.log('Mon GROUPE : ' + position);  
                               this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                             
                             }
                             break;

                             case 'S5 MS':
                              var nom_eleves = "BAHATI KETSIA BULAYA Camille Mangaza CHEN Rui GUSU-WO AMBZUDA Daniel IVOUBA Charles-Alexandre KASUNKA Mwanza Elliott KISIMBA NGOY Ndalewe Lilian KUMBA Patient MALAMAS Lars MASSAMBA ELOHEEKA MBUYU MUKOLONGO Keren MUNDHU FETTY Battyston MWEHU Chloé NTUMBA NGOY Eunice Kapinga NYAKABASA Ntale David SAFI MUTABUSHA MUYEMBE Aurélie SOLA FURAHA Madeline TOTO Zika Samantha TULUKA N_SOMPI MARIE-ELIANE YAV MUJING NACATSHAK Claudia  ";
                              var  position = nom_eleves.indexOf(this.nickname);
                       
                              if( position != -1){
                               console.log('Mon GROUPE : ' + position);
                       
                               this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                              
                         
                               } else{
                                 console.log('Mon GROUPE : ' + position);  
                                 this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                               
                               }
                               break;

                               case 'S5 ECO':
                              var nom_eleves = "AÏSSI MBAIASIMA Pearl BOKINGI Claude BOLODJWA EALE W_AMENGE Yohan BONDO Priscila BUPILA FURAISHA Léa de LANNOY Maëlle HENRY Saniya KALUME POLYDOR KAMANDA BAGANDA Oren KANI KANI LUAMBA Nodin LEHANI RASHIDI Jonathan LUONGWE KIHANZULA Pitchou MBUYU ILUNGA NKASSA Samuel M_BWANKIEM NIAROLIEM Jonathan MUNGWA Harmonie NZILA Pongo Jaëlle NZOLANTIMA NKEMBO UMBA Yann VANDEWALLE Jennifer ";
                              var  position = nom_eleves.indexOf(this.nickname);
                       
                              if( position != -1){
                               console.log('Mon GROUPE : ' + position);
                       
                               this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                              
                         
                               } else{
                                 console.log('Mon GROUPE : ' + position);  
                                 this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                               
                               }
                               break;

                               case 'S5 TQ':
                                var nom_eleves = "AMBOHEKI LONGAYO Véronique BILENGE MUNKINDJI Clémence KAPANGA NGOYI Nathan KATIKIA AZANGA Lance KUSIKUMALA DUDUNA Cynthia LUTALA ALLEGRESSE MAKIASHI Junias M_BARIKO Presilia MORIAME Ryan MUKUNA KANKU Elie NGARAMBE CUBAYIRO Nathan  ";
                                var  position = nom_eleves.indexOf(this.nickname);
                         
                                if( position != -1){
                                 console.log('Mon GROUPE : ' + position);
                         
                                 this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                
                           
                                 } else{
                                   console.log('Mon GROUPE : ' + position);  
                                   this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                 
                                 }
                                 break;

                                 case 'S6 MS':
                                  var nom_eleves = "BAGALE BULIGO Loïs BAGBENI Adeito Perla BIGOSHI Gloria Magnificat BILA MINLANGU ZOLE Ana-Caroline BOPE MABINTSH Keren BRAUN Emile JOIRIS Gabriel KABASELE Anthony KANINDA Thalia MAYANGA Gloria-Anne MPOYI Aggee MPUEKELA TSHISUAKA (Keren) M_SIRI KYULU Anaïs MUGABO OLAME Elam MUKALAY ASSUMINI Laurie MUKUKU Gabriel MUZEU-MULLER Lys MWAMINI KASEREKA Josette MWINYI MUHEMEDI NASIBU Danny NZOMONO BAFWANGA Jenovie PALMY Kelly Ande Kalgi SAADA HAMULI Pascaline SAKOMBI MOLENDO Anthony TSHISEKEDI MULUMBA Christian Samuel TSHIVUADI KALOMBO TAMBWE Maïcha TSHOVU Joséphine Benijah ";
                                  var  position = nom_eleves.indexOf(this.nickname);
                           
                                  if( position != -1){
                                   console.log('Mon GROUPE : ' + position);
                           
                                   this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                  
                             
                                   } else{
                                     console.log('Mon GROUPE : ' + position);  
                                     this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                   
                                   }
                                   break;

                                   case 'S6 ECO':
                                  var nom_eleves = "AMULI TAGIRAWABO Nathan BODI-MATONDO Jeovana FUNGULA MAYOYO Marguerite KALEV-MUTONDO Daniel KANI KANI MPIRI Nehemie KAWAMA EmmanueL KELE Paul-Elie KIKUNDA Yomi Ekila KITOTO MUNKINDJI Marcia LEZIN Kimberley LUYEYE Watoma Emmanuelle MANKONDO Maboko Joseph MOSIKWA AMANA Maxim MPIDI Marie-Rose MWANA MAY DOSITHE Fils NGARAMBE NGAMIJE Loïc NZOLANTIMA DIVANA Yona ODENT Morgann OKONGE Joy PELESA Marceline-Prunelle PORSPERGER Tomiya SOLA Marilyn Omoy TSHIBINGU NDIBU Lionel TSHIMANGA Samuel ";
                                  var  position = nom_eleves.indexOf(this.nickname);
                           
                                  if( position != -1){
                                   console.log('Mon GROUPE : ' + position);
                           
                                   this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                  
                             
                                   } else{
                                     console.log('Mon GROUPE : ' + position);  
                                     this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                   
                                   }
                                   break;

                                   case 'S6TQ':
                                    var nom_eleves = "CHALUPA Kyara INKUNE MBOYO Joseph ITAMA ASIKALINE Darly Germaine KAMBERE Yohann KOULTOUMI Kedijah MAKONGA NTUMBA MULOPWE Raïssa MULUMBA KALAMBAY Benjamin MUNONGO Kevin Godefroid PELESA Sarah-Persévérance SHULUNGU MUYUMBA Israël SHULUNGU OMBA Kerene SITA PEMBELE NKANKU Mami VANDEWALLE Jessica ";
                                    var  position = nom_eleves.indexOf(this.nickname);
                             
                                    if( position != -1){
                                     console.log('Mon GROUPE : ' + position);
                             
                                     this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                    
                               
                                     } else{
                                       console.log('Mon GROUPE : ' + position);  
                                       this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                     
                                     }
                                     break;

                                     case 'ALM':
                                      var nom_eleves = " ";
                                      var  position = nom_eleves.indexOf(this.nickname);
                               
                                      if( position != -1){
                                       console.log('Mon GROUPE : ' + position);
                               
                                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                      
                                 
                                       } else{
                                         console.log('Mon GROUPE : ' + position);  
                                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                       
                                       }
                                       break;

                                     case 'Anglais I':
                                      var nom_eleves = " ";
                                      var  position = nom_eleves.indexOf(this.nickname);
                               
                                      if( position != -1){
                                       console.log('Mon GROUPE : ' + position);
                               
                                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                      
                                 
                                       } else{
                                         console.log('Mon GROUPE : ' + position);  
                                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                       
                                       }
                                       break;

                                       case 'Neerlandais I':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;

                                         case 'Latin I':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;

                                         case 'Anglais 2':
                                      var nom_eleves = " ";
                                      var  position = nom_eleves.indexOf(this.nickname);
                               
                                      if( position != -1){
                                       console.log('Mon GROUPE : ' + position);
                               
                                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                      
                                 
                                       } else{
                                         console.log('Mon GROUPE : ' + position);  
                                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                       
                                       }
                                       break;

                                       case 'Neerlandais 2':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;

                                         case 'Latin 2':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;

                                         case 'Anglais 3':
                                      var nom_eleves = " ";
                                      var  position = nom_eleves.indexOf(this.nickname);
                               
                                      if( position != -1){
                                       console.log('Mon GROUPE : ' + position);
                               
                                       this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                      
                                 
                                       } else{
                                         console.log('Mon GROUPE : ' + position);  
                                         this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                       
                                       }
                                       break;

                                       case 'Neerlandais 3':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;

                                         case 'Latin 3':
                                        var nom_eleves = " ";
                                        var  position = nom_eleves.indexOf(this.nickname);
                                 
                                        if( position != -1){
                                         console.log('Mon GROUPE : ' + position);
                                 
                                         this.verifChatRoom (roomname);  // Si l'élève appartient au groupe alors accés autoriser
                                        
                                   
                                         } else{
                                           console.log('Mon GROUPE : ' + position);  
                                           this.errorMessage = 'Pas autorisé à accéder dans ce groupe de Chat'; // sinon, envoyer un message d'erreur et bloquer l'accès
                                         
                                         }
                                         break;
     
     
     
   

      
      default:
        roomname = '';
    } 



  }

  verifChatRoom (roomname: string){

  const chat = { roomname: '', nickname: '', message: '', date: '', type: '' };
  chat.roomname = roomname;
  chat.nickname = this.nickname;
  chat.date = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
  chat.message = `${this.nickname} a rejoint le groupe`;
  chat.type = 'join';
  const newMessage = firebase.database().ref('chats/').push();
  newMessage.set(chat);

  firebase.database().ref('roomusers/').orderByChild('roomname').equalTo(roomname).on('value', (resp: any) => {
    let roomuser = [];
    roomuser = snapshotToArray(resp);
    const user = roomuser.find(x => x.nickname === this.nickname);
    if (user !== undefined) {
      const userRef = firebase.database().ref('roomusers/' + user.key);
      userRef.update({status: 'online'});
    } else {
      const newroomuser = { roomname: '', nickname: '', status: '' };
      newroomuser.roomname = roomname;
      newroomuser.nickname = this.nickname;
      newroomuser.status = 'online';
      const newRoomUser = firebase.database().ref('roomusers/').push();
      newRoomUser.set(newroomuser);
    }
  });

  this.router.navigate(['/chatroom', roomname]);
}

  logout(): void {
    localStorage.removeItem('nickname');
    this.router.navigate(['/login']);
  }

}
