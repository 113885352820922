import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyingAnimalStudentM1II'
})
export class FlyingAnimalStudentM1IIPipe implements PipeTransform {

  valeurClasse: string='M1II';

  upsearchClasseNew(value: string) {

    this.valeurClasse = value
    console.log(this.valeurClasse);
   
  }
  transform(allAnimals: any[]) {
    console.log('valeur de :'+ this.valeurClasse);
    return allAnimals.filter(book => book.listedechoix === this.valeurClasse);    
  }

}
