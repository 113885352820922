import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuth: boolean;
  isAuthClassRoom: boolean;
  isAuthChatRoom: boolean;
  isAuthFinance: boolean;
  isAuthSecretariat: boolean;
  isAuthCommunication: boolean;
  isAuthAdministration: boolean;
  isAuthAvis: boolean;
  isAuthBulletin: boolean;
  isAuthCommande: boolean;
  

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.isAuth = true;
    this.isAuthClassRoom = false;
    this.isAuthChatRoom = false;
    this.isAuthFinance = false;
    this.isAuthSecretariat = false;
    this.isAuthCommunication = false;
    this.isAuthAdministration = false;
    this.isAuthAvis = false;
    this.isAuthBulletin = false;
    this.isAuthCommande = false;
    //firebase.auth().onAuthStateChanged(
      //(user) => {
        //if(user) {
          //this.isAuth = true;
        //} else {
          //this.isAuth = false;
        //}
      //}
    //);
  }

  onSignOut() {
    this.authService.signOutUser();
  }


}
