import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyingAnimalStudentP3B'
})
export class FlyingAnimalStudentP3BPipe implements PipeTransform {

  valeurClasse: string='P3B';

  upsearchClasseNew(value: string) {

    this.valeurClasse = value
    console.log(this.valeurClasse);
   
  }
  transform(allAnimals: any[]) {
    console.log('valeur de :'+ this.valeurClasse);
    return allAnimals.filter(book => book.listedechoix === this.valeurClasse);    
  }
}
