<div class="container" [ngStyle]="{'color': 'white'}"  style="background-image: url('assets/img/paque7.png');" >
  <p> </p>
  <p> </p>

  <ul class="breadcrumb">
    <li><a    href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a></li>
    <li><a    href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a></li>
    <li><a    href="https://login.one.com/mail" Target="_blank" > Webmail </a></li>
    <li><a    [routerLink]="'login'" routerLinkActive="active" > Chat-Room </a></li> 
    <li><a  href="#" data-toggle="popover" title="Fermeture de la session en cours"  [routerLink]="'auth/signin'" routerLinkActive="active"  > Déconnexion </a></li>            
  </ul>

<div class="jumbotron" style="background-color:lavenderblush" >

<div class="row" [ngStyle]="{'color': 'blue'}">
  <div class="col-sm-8 col-sm-offset-2">
    <h2>Enregistrer une nouvelle leçon</h2>
    
    <h3> </h3>
    <form [formGroup]="bookForm" (ngSubmit)="onSaveBook()">

      <p></p>

      

      <div class="form-group">
      <label for="listedechoix">Classe</label>
        <select formControlName="listedechoix" (input)="classesMultiples($event.target.value)" id="listedechoix" >
          <option   value=""></option>
          <option *ngFor="let title of titleOptions" >{{ title.local }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="listedechoix">Classe(s) sélectionnée(s)</label>
          <button class="btn btn-default pull-left" (click)="onDeleteClasse()">      
            <span class="glyphicon glyphicon-remove" ></span>
         </button> 
          <h5><b><i> {{ selectBox }} </i></b></h5>
         
            </div>

      <div class="form-group">
        <label for="authorId">Identifiant Unique de l'auteur </label>
        <input type="text" id="authorId"
               class="form-control" formControlName="authorId">
      </div>

      <div class="form-group">
        <label for="Semainier">Date(Semainier)</label>
        <input type="date" id="Semainier" formControlName="Semainier" (input)="detectSemainier($event.target.value)">
      </div>

      <div class="form-group">
        <label for="title">Sujet de la leçon</label>
        <input type="text" id="title"
               class="form-control" formControlName="title">     
      </div>

      <label for="title">Contenu de la leçon</label>
      <div class="form-group">
        <ckeditor  (change)="onChange($event)" data="Bonjour, cher(es) élèves!"></ckeditor>
      </div>

      <div class="form-group">
        <label for="alerte">Message d'alerte</label>
        <input type="text" id="alerte"
               class="form-control" formControlName="alerte" placeholder="Maximum 100 caractères">  
               <span href="#" data-toggle="popover" title="Rappel du devoir" class="glyphicon glyphicon-bell"></span>
               <input type="date" id="alerte" formControlName="alerte" (input)="detectAlertDevoir($event.target.value)">   
      </div>

      <div class="form-group">
        <label for="liens">Vos liens externes</label>
        <textarea id="liens"
                  class="form-control" formControlName="liens" placeholder="Liens hypertextes, vers autres sites, séparés par des espaces vides" >
        </textarea>     
      </div>


      <div class="form-group">
        <h4>Ajouter un fichier à la leçon(VIDEOS, AUDIO, PDF, WORD, EXCEL, POWERPOINT, Etc.)</h4>
        <input type="file" name="avatar1" (change)="onFileSelect1($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading1' >       
        <input type="file" name="avatar2" (change)="onFileSelect2($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading2'>       
        <input type="file" name="avatar3" (change)="onFileSelect3($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading3'>       
        <input type="file" name="avatar4" (change)="onFileSelect4($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading4'>       
        <input type="file" name="avatar5" (change)="onFileSelect5($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading5'>       
        <input type="file" name="avatar6" (change)="onFileSelect6($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading6'>       
        <input type="file" name="avatar7" (change)="onFileSelect7($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading7'>       
        <input type="file" name="avatar8" (change)="onFileSelect8($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading8'>       
        <input type="file" name="avatar9" (change)="onFileSelect9($event)" />        
        
      </div>

      <div class="form-group" *ngIf=' fileIsUploading9'>       
        <input type="file" name="avatar10" (change)="onFileSelect10($event)" />        

      </div>
      <ul *ngIf="uploadResponse1 && uploadResponse1.status === 'success'">
        <li>{{ file1name }}</li>
      </ul>
      <ul *ngIf="uploadResponse2 && uploadResponse2.status === 'success'">
        <li>{{ file2name }}</li>
      </ul>
      <ul *ngIf="uploadResponse3 && uploadResponse3.status === 'success'">
        <li>{{ file3name }}</li>
      </ul>
      <ul *ngIf="uploadResponse4 && uploadResponse4.status === 'success'">
        <li>{{ file4name }}</li>
      </ul>
      <ul *ngIf="uploadResponse5 && uploadResponse5.status === 'success'">
        <li>{{ file5name }}</li>
      </ul>
      <ul *ngIf="uploadResponse6 && uploadResponse6.status === 'success'">
        <li>{{ file6name }}</li>
      </ul>
      <ul *ngIf="uploadResponse7 && uploadResponse7.status === 'success'">
        <li>{{ file7name }}</li>
      </ul>
      <ul *ngIf="uploadResponse8 && uploadResponse8.status === 'success'">
        <li>{{ file8name }}</li>
      </ul>
      <ul *ngIf="uploadResponse9 && uploadResponse9.status === 'success'">
        <li>{{ file9name }}</li>
      </ul>
      <ul *ngIf="uploadResponse10 && uploadResponse10.status === 'success'">
        <li>{{ file10name }}</li>
      </ul>

      <button class="btn btn-success" [disabled]="bookForm.invalid || fileIsUploading || fileIsUploadingDocument || isMauvaiseCnx || envoi_fichier"
      type="submit">Enregistrer
      </button>

    </form>
    <p class="text-danger">{{ errorMessage }}</p>
    
  </div>
</div>
</div>
</div>
