import { Component, OnInit } from '@angular/core';
import { Book } from '../../models/book.model';
import { Book1 } from '../../models/book1.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BooksService } from '../../services/books.service';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';



@Component({
  selector: 'app-single-book-student-archive',
  templateUrl: './single-book-student-archive.component.html',
  styleUrls: ['./single-book-student-archive.component.css']
})
export class SingleBookStudentArchiveComponent implements OnInit {

  book: Book1;

  constructor(private route: ActivatedRoute, private booksService: BooksService,
              private router: Router) {}

  ngOnInit() {

    this.book = new Book1('', '', '', '', '', '','','');
    const id = this.route.snapshot.params['id'];
    this.booksService.getSingleBook(+id).then(
      (book: Book1) => {
        this.book = book;

      }
    );
  }

  onBack() {
    this.router.navigate(['/booksStudentP1A']);
  }
}
