import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Book } from '../models/book.model';
import { BooksService } from '../services/books.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {HttpClientModule, HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';


@Component({
  selector: 'app-avis',
  templateUrl: './avis.component.html',
  styleUrls: ['./avis.component.css']
})
export class AvisComponent implements OnInit {

  bookForm: FormGroup;
  form: FormGroup;  
  fileIsUploading = false;
  fileIsUploading1 = false;
  //fileIsUploading2 = false;
  //fileIsUploading3 = false;
  //fileIsUploading4 = false;
  //fileIsUploading5 = false;
  //fileIsUploading6 = false;
  //fileIsUploading7 = false;
  //fileIsUploading8 = false;
  //fileIsUploading9 = false;
  //fileIsUploading10 = false;
  fileUrl: string;
  fileUploaded = false;

  envoi_fichier = false;

  fileIsUploadingDocument = false;
  fileDocuments: {
    name: string,
    url: string
  }[];
  fileUploadedDocument = 0;

  lastUpdate = new Date();
  semainier: Date;
  liste: string;
  texte: string;

  images = [];
  authorId: string = '';
  errorMessage: string;
  authorIdValue: string = '';
  authorEmail: string = '';

  afiichageCnx: any ;
   time1 ;
   time2 ;
   vitesseMs;
   resultSoustract;
   isBonneCnx = '';
   isMauvaiseCnx = false;
   vitesseMsAfiichage;
   synopsis_cke_editor : string = 'Bonjour, cher(es) élèves!';

   i : any ;

   libelle1 : any;
   selectBox : string = '';
   tableau = [] ;

   listedechoix : string;

   valeur_tableau : any;

   name_file1_uploaded : any;

    file1name: string='';
     file1link: string='';
     file2name: string='';
     file2link: string='';
     file3name: string='';
     file3link: string='';
     file4name: string='';
     file4link: string='';
     file5name: string='';
     file5link: string='';
     file6name: string='';
     file6link: string='';
     file7name: string='';
     file7link: string='';
     file8name: string='';
     file8link: string='';
     file9name: string='';
     file9link: string='';
     file10name: string='';
     file10link: string='';
     file11name: string='';
     file11link: string='';
     file12name: string='';
     file12link: string='';
     file13name: string='';
     file13link: string='';
     file14name: string='';
     file14link: string='';
     file15name: string='';
     file15link: string='';
     file16name: string='';
     file16link: string='';
     file17name: string='';
     file17link: string='';
     file18name: string='';
     file18link: string='';
     file19name: string='';
     file19link: string='';
     file20name: string='';
     file20link: string='';

     uploadResponse1;
     //uploadResponse2;
     //uploadResponse3;
     //uploadResponse4;
     //uploadResponse5;
     //uploadResponse6;
     //uploadResponse7;
     //uploadResponse8;
     //uploadResponse9;
     //uploadResponse10;
     //uploadResponse11;
     //uploadResponse12;
     //uploadResponse13;
     //uploadResponse14;
     //uploadResponse15;
     //uploadResponse16;
     //uploadResponse17;
     //uploadResponse18;
     //uploadResponse19;
     //uploadResponse20;
     
    

     url_json = 'http://lpl-rdc.com/COMMANDES/web_api/create_lecon_avis.php';

       // defined the array of data
    public data = ['Snooker', 'Tennis', 'Cricket', 'Football', 'Rugby'];



  titleOptions = [{local: '------------------------------------------------------------------'},{local: 'FONDAMENTALE'},{local: '------------------------------------------------------------------'},
   {local: '------------------------------------------------------------------'},{local: 'SECONDAIRE'},{local: '------------------------------------------------------------------'},
   {local: '------------------------------------------------------------------'},
      
  ];




  constructor(private formBuilder: FormBuilder, private booksService: BooksService,
              private router: Router, private http: HttpClient) {
                
  }

  ngOnInit() {
    this.fileDocuments = [];
    this.initForm();
    this.initForm_upload();
    
  }

  initForm() {
    this.bookForm = this.formBuilder.group({
      title: ['', Validators.required],
      //author: [''],
      authorId: ['', Validators.required],
      Semainier: [""],
      synopsis: '',
      //alerte: '',
      //liens: '',
      dateJour: '',
      listedechoix: ['', Validators.required]
    });

  }

  initForm_upload() {

    this.form = this.formBuilder.group({
      avatar1: [''],
      //avatar2: [''],
      //avatar3: [''],
     // avatar4: [''],
      //avatar5: [''],
     // avatar6: [''],
     // avatar7: [''],
      //avatar8: [''],
      //avatar9: [''],
      //avatar10: ['']
  });

}


  onFileSelect1(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('avatar1').setValue(file);
      this.file1name = file.name ;
      this.errorMessage = 'Chargement du fichier en cours...';

      this.onSubmit1();
    }
  }

 

  onSubmit1() {
    const formData = new FormData();
    formData.append('avatar1', this.form.get('avatar1').value);
    this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    this.booksService.uploadFile(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
      (res) => {
        this.uploadResponse1 = res;
        this.file1link = this.uploadResponse1.url;  // le lien du fichier uploader
        this.file1name = this.file1name;  // le nom du fichier uploader
        this.errorMessage = '';
        this.fileIsUploading1 = true;
        this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      },
      (err) => {  
        console.log(err);
      }
    );
  }

  //onSubmit2() {
    //const formData = new FormData();
    //formData.append('avatar2', this.form.get('avatar2').value);
    //this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

    //this.booksService.uploadFile2(formData).subscribe(
      ////this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
        //this.uploadResponse2 = res;
       // this.file2link = this.uploadResponse2.url;  // le lien du fichier uploader
        //this.file2name = this.file2name;  // le nom du fichier uploader
        //this.errorMessage = '';
        //this.fileIsUploading2 = true;
        //this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
      //},
      //(err) => {  
        //console.log(err);
     // }
    //);
  //}
 // onSubmit3() {
   // const formData = new FormData();
   // formData.append('avatar3', this.form.get('avatar3').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile3(formData).subscribe(
      ////this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
       // this.uploadResponse3 = res;
       // this.file3link = this.uploadResponse3.url;  // le lien du fichier uploader
       // this.file3name = this.file3name;  // le nom du fichier uploader
       // this.errorMessage = '';
        //console.log('lien du fichier : ' + this.file3link);
       // this.fileIsUploading3 = true;
        //this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
     // },
      //(err) => {  
        //console.log(err);
      //}
    //);
  //}
 // onSubmit4() {
   // const formData = new FormData();
   // formData.append('avatar4', this.form.get('avatar4').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile4(formData).subscribe(
      //this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
      //  this.uploadResponse4 = res;
      //  this.file4link = this.uploadResponse4.url;  // le lien du fichier uploader
      //  this.file4name = this.file4name;  // le nom du fichier uploader
      //  this.errorMessage = '';
      //  this.fileIsUploading4 = true;
     //   this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
     // },
     // (err) => {  
     //   console.log(err);
     // }
   // );
 // }
 // onSubmit5() {
   // const formData = new FormData();
   // formData.append('avatar5', this.form.get('avatar5').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

  //  this.booksService.uploadFile5(formData).subscribe(
    //  //this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
     //   this.uploadResponse5 = res;
     //   this.file5link = this.uploadResponse5.url;  // le lien du fichier uploader
     //   this.file5name = this.file5name;  // le nom du fichier uploader
     //   this.errorMessage = '';
     //   this.fileIsUploading5 = true;
    //    this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
    //  },
     // (err) => {  
      //  console.log(err);
     // }
   // );
 // }
 // onSubmit6() {
   // const formData = new FormData();
   // formData.append('avatar6', this.form.get('avatar6').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile6(formData).subscribe(
     // //this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
      //  this.uploadResponse6 = res;
     //   this.file6link = this.uploadResponse6.url;  // le lien du fichier uploader
     //   this.file6name = this.file6name;  // le nom du fichier uploader
     //   this.errorMessage = '';
     //   this.fileIsUploading6 = true;
     //   this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
     // },
     // (err) => {  
     //   console.log(err);
     // }
   // );
 // }
 // onSubmit7() {
  //  const formData = new FormData();
  //  formData.append('avatar7', this.form.get('avatar7').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

  //  this.booksService.uploadFile7(formData).subscribe(
     // //this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
      //  this.uploadResponse7 = res;
     //   this.file7link = this.uploadResponse7.url;  // le lien du fichier uploader
     //   this.file7name = this.file7name;  // le nom du fichier uploader
    //    this.errorMessage = '';
    //    this.fileIsUploading7 = true;
    //    this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
   //   },
   //   (err) => {  
   //     console.log(err);
   //   }
 //   );
 // }
 // onSubmit8() {
   // const formData = new FormData();
   // formData.append('avatar8', this.form.get('avatar8').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile8(formData).subscribe(
    //  //this.booksService.SaveToServer(formData).subscribe(
    //  (res) => {
     //   this.uploadResponse8 = res;
    //    this.file8link = this.uploadResponse8.url;  // le lien du fichier uploader
    //    this.file8name = this.file8name;  // le nom du fichier uploader
     //   this.errorMessage = '';
    //    this.fileIsUploading8 = true;
    //    this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
    //  },
   //   (err) => {  
   //     console.log(err);
  //    }
 //   );
 // }
 // onSubmit9() {
   // const formData = new FormData();
   // formData.append('avatar9', this.form.get('avatar9').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile9(formData).subscribe(
      ////this.booksService.SaveToServer(formData).subscribe(
      //(res) => {
      //  this.uploadResponse9 = res;
      //  this.file9link = this.uploadResponse9.url;  // le lien du fichier uploader
      //  this.file9name = this.file9name;  // le nom du fichier uploader
      //  this.errorMessage = '';
      //  this.fileIsUploading9 = true;
     //   this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
     // },
     // (err) => {  
      //  console.log(err);
     // }
   // );
  //}
 // onSubmit10() {
   // const formData = new FormData();
   // formData.append('avatar10', this.form.get('avatar10').value);
   // this.envoi_fichier = true;  // bloque le bouton enregistrer tant que le fichier n'est pas completement uploader

   // this.booksService.uploadFile10(formData).subscribe(
     // //this.booksService.SaveToServer(formData).subscribe(
     // (res) => {
       // this.uploadResponse10 = res;
      //  this.file10link = this.uploadResponse10.url;  // le lien du fichier uploader
      //  this.file10name = this.file10name;  // le nom du fichier uploader
       // this.errorMessage = '';
      //  this.fileIsUploading10 = true;
     //   this.envoi_fichier = false; // débloque le bouton enregistrer une fois que le fichier est completement uploader
     // },
     // (err) => {  
     //   console.log(err);
    //  }
   // );
 // }



  public onChange( event: CKEditor4.EventInfo ) {
    console.log( event.editor.getData() );
    this.synopsis_cke_editor = event.editor.getData()
}


  detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }

  detectSemainier(event) {
    this.semainier = this.bookForm.get('Semainier').value;   

    this.lastUpdate = this.semainier;
    console.log(this.lastUpdate);  

  }

  detectFilesDocument($event) {
    if ($event.target.files && $event.target.files[0]) {
      const filesAmount = $event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.images.push(event.target.result);

          this.bookForm.patchValue({
            fileSource: this.images
          });
        };

       // this.onUploadFileDocument($event.target.files[i], i, $event.target.files.length);  //firebase
      }
    }         
    
  }

  
  classesMultiples($event) {

    var liste, texte;
    liste = document.getElementById("listedechoix");
    this.libelle1 = liste.options[liste.selectedIndex].text;

    console.log( "Les Valeurs sélectionnées : " + this.libelle1 );


    //this.libelle1 = this.bookForm.get('listedechoix').value;

      this.selectBox =  this.selectBox + ';' + this.libelle1;
      console.log( "Les Valeurs sélectionnées : " + this.selectBox );

      this.tableau = this.selectBox.split(";");  //coupe la chaine en plusieurs sous_chaines en fonction du séparateur
      //console.log( 'valeur du tableau n\° 3 : ' + this.tableau[2]);

      this.valeur_tableau =  Object.keys(this.tableau).length; //renvoie la longueur d'un tableau
     console.log( 'valeur du tableau totale : ' + this.valeur_tableau);    
     

    
      
  }

  onDeleteClasse() {
    this.selectBox = '';

  }

  onSaveBookAvis() { 

    for ( this.i = 1; this.i < this.valeur_tableau; this.i++) {

      console.log( 'valeur du tableau n : ' + this.i +' = ' + this.tableau[this.i]);

      this.onSaveBookMultiplesAvis();  //appel de la methode pour plusieurs classes

      
      
      //if (this.libelle1.options[i].selected) {
        //this.listedechoix =   this.tableau[i];
        //console.log( 'la liste de choix est : ' + this.listedechoix);
      //}
    }

    

  }
  


  onSaveBookMultiplesAvis() { 

    const newBookData = new FormData();

    

    //const dateJour = this.lastUpdate.toString();
    const dateJour = this.lastUpdate.toISOString().substring(0, 10);  //Mettre la date par defaut (Aujourd'hui)au format yyyy-mm-jj

    console.log('Date transformée :' + dateJour );
    newBookData.append('dateJour', dateJour); // Date mise au format
    const title = this.bookForm.get('title').value;
    newBookData.append('title', this.bookForm.get('title').value);
     //this.listedechoix = this.bookForm.get('listedechoix').value;
     this.listedechoix = this.tableau[this.i]; //Valeur de la classe en cours
     newBookData.append('listedechoix', this.tableau[this.i]);
    const author ="";
    newBookData.append('author', "");
    this.authorIdValue = this.bookForm.get('authorId').value;
    newBookData.append('authorId', this.bookForm.get('authorId').value);

   
    this.authorId = this.authorIdValue.trim();
    //const synopsis = this.bookForm.get('synopsis').value;
    
    const synopsis = 'Chers Parents!';
    newBookData.append('synopsis', 'Chers Parents!');

    console.log( 'le synopsis est : ' + synopsis);

    
    

    //const alerte = this.bookForm.get('alerte').value;
    const alerte = 'RAS';
    //newBookData.append('alerte', this.bookForm.get('alerte').value);
    newBookData.append('alerte', 'RAS');
    //const liens = this.bookForm.get('liens').value;
    const liens = 'RAS';
    //newBookData.append('liens', this.bookForm.get('liens').value);
    newBookData.append('liens', 'RAS');

    const newBook = new Book(title, this.listedechoix, author, this.authorId, synopsis, alerte, liens,  dateJour,
      this.file1name,
      this.file1link,
      this.file2name,
      this.file2link,
      this.file3name,
      this.file3link,
      this.file4name,
      this.file4link,
      this.file5name,
      this.file5link,
      this.file6name,
      this.file6link,
      this.file7name,
      this.file7link,
      this.file8name,
      this.file8link,
      this.file9name,
      this.file9link,
      this.file10name,
      this.file10link,
      this.file11name,
      this.file11link,
      this.file12name,
      this.file12link,
      this.file13name,
      this.file13link,
      this.file14name,
      this.file14link,
      this.file15name,
      this.file15link,
      this.file16name,
      this.file16link,
      this.file17name,
      this.file17link,
      this.file18name,
      this.file18link,
      this.file19name,
      this.file19link,
      this.file20name,
      this.file20link);

    

    newBook.listedechoix = this.listedechoix;
    newBookData.append('listedechoix', this.listedechoix);
    //newBook.authorId = this.authorId;
    newBook.synopsis = synopsis;
    newBook.alerte = alerte;
    newBook.liens = liens;
    newBook.dateJour = dateJour;
    

    // Mettre le test des codes uniques identifiant des profs ici !!!!!!!! et déplacer

    //var monthName: string;


    switch (this.authorId) {
      case 'E221':
      //  newBook.author = 'MISTIAEN CHRISTOPHER';
      //  newBook.authorId = 'christopher.mistiaen@lpl-rdc.com';
        newBookData.append('author', 'MISTIAEN CHRISTOPHER');  // A rajouter partout 
        newBookData.append('authorId', "christopher.mistiaen@lpl-rdc.com");
        break;

        case 'DP69':
      //  newBook.author = 'DEVOS OLIVIER';
      //  newBook.authorId = 'direction.primaire@lpl-rdc.com';
        newBookData.append('author', 'DEVOS OLIVIER');  // A rajouter partout 
        newBookData.append('authorId', "direction.primaire@lpl-rdc.com");
        break;
      
      case 'DIR001': // TODO: REMOVE FOR PRODUCTION
      //  newBook.author = 'DIRECTION PEDAGOGIQUE FONDAMENTALE';
      //  newBook.authorId = 'direction-pedagogique-fondamentale@lpl-rdc.com';
        newBookData.append('author', 'JOSE DE LOS SANTOS MOYANO');  // A rajouter partout 
        newBookData.append('authorId', "direction@lpl-rdc.com");
        break;
      default:
        this.authorId = '';
    }
    console.log('Mon ID : ' + this.authorId);

    if (this.authorId != '') {

      //this.booksService.createNewBook(newBook);  // vers firebase
      //this.router.navigate(['/books']);         // La suite logique 

      //newBook.file1name = '-';
      newBookData.append('file1name', this.file1name);
      //newBook.file1link= '-';
      newBookData.append('file1link', this.file1link);
      //newBook.file2name= '-';
      newBookData.append('file2name', this.file2name);
      // newBook.file2link= '-';
      newBookData.append('file2link', this.file2link);
       //newBook.file3name= '-';
      newBookData.append('file3name', this.file3name);
      // newBook.file3link= '-';
      newBookData.append('file3link', this.file3link);
      //newBook.file4name= '-';
      newBookData.append('file4name', this.file4name);
     // newBook.file4link= '-';
      newBookData.append('file4link', this.file4link);
      //newBook.file5name= '-';
      newBookData.append('file5name', this.file5name);
      //newBook.file5link= '-';
      newBookData.append('file5link', this.file5link);
     // newBook.file6name= '-';
      newBookData.append('file6name', this.file6name);
      //newBook.file6link= '-';
      newBookData.append('file6link', this.file6link);
      //newBook.file7name= '-';
      newBookData.append('file7name', this.file7name);
     // newBook.file7link= '-';
      newBookData.append('file7link', this.file7link);
      //newBook.file8name= '-';
      newBookData.append('file8name', this.file8name);
      //newBook.file8link= '-';
      newBookData.append('file8link', this.file8link);
      //newBook.file9name= '-';
      newBookData.append('file9name', this.file9name);
      //newBook.file9link= '-';
      newBookData.append('file9link', this.file9link);
      //newBook.file10name= '-';
      newBookData.append('file10name', this.file10name);
      // newBook.file10link= '-';
      newBookData.append('file10link', this.file10link);
      // newBook.file11name= '-';
      newBookData.append('file11name', '');
      //newBook.file11link= '-';
      newBookData.append('file11link', '');
      //newBook.file12name= '-';
      newBookData.append('file12name', '');
      // newBook.file12link= '-';
      newBookData.append('file12link', '');
      // newBook.file13name= '-';
      newBookData.append('file13name', '');
      // newBook.file13link= '-';
      newBookData.append('file13link', '');
      // newBook.file14name= '-';
      newBookData.append('file14name', '');
      //newBook.file14link= '-';
      newBookData.append('file14link', '');
      //newBook.file15name= '-';
      newBookData.append('file15name', '');
      //newBook.file15link= '-';
      newBookData.append('file15link', '');
      //newBook.file16name= '-';
      newBookData.append('file16name', '');
      //newBook.file16link= '-';
      newBookData.append('file16link', '');
      //newBook.file17name= '-';
      newBookData.append('file17name=', '');
      //newBook.file17link= '-';
      newBookData.append('file17link', '');
      //newBook.file18name= '-';
      newBookData.append('file18name', '');
      //newBook.file18link= '-';
      newBookData.append('file18link', '');
      //newBook.file19name= '-';
      newBookData.append('file19name', '');
      //newBook.file19link= '-';
      newBookData.append('file19link', '');
      //newBook.file20name= '-';
      newBookData.append('file20name', '');
      //newBook.file20link= '-';
      newBookData.append('file20link', '');

      this.listedechoix = this.listedechoix.trim(); // Supprimer les caractères blanc en début et fin de la variable
      console.log('Blanc en début et fin supprimés : ' + this.listedechoix );
      switch (this.listedechoix) {
        case 'FONDAMENTALE':
          console.log('Avis fondamental posté ');
          this.booksService.createLeconAvis( newBookData);  //Pour alimenter la  BDDFondamentale
          
          break;
  
          case 'SECONDAIRE':
            console.log('Avis sécondaire posté');
            this.booksService.createLeconAvisSecon( newBookData);  //Pour alimenter la  BDDSecondaire
            
          break;

      }


      
     
     
      this.booksService.getBooksFromServer();
      this.booksService.emitBooks();
      this.router.navigate(['/auth/signin']);
 

    } else {
      this.errorMessage = 'Identifiant Unique invalide';

    }


  }





  

  onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.booksService.uploadFile_old(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }

  onUploadFileDocument(fileDocument: File, currentIndex: number, totalFiles: number) {
    this.fileIsUploadingDocument = true;
    this.booksService.uploadFileDocument(fileDocument).then(
      (urlDocument: { name: string, url: string }) => {
        this.fileDocuments.push(urlDocument);
        if (currentIndex + 1 === totalFiles) {
          this.fileIsUploadingDocument = false;
          this.fileUploadedDocument = totalFiles;
        }
      }
    );
  }
}


