import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Book } from '../models/Book.model';
import { Book1 } from '../models/Book1.model';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import { BehaviorSubject, Observable } from 'rxjs';


import {HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

import { HttpClientModule } from '@angular/common/http';

import { retry, catchError } from 'rxjs/operators';
import {  throwError } from 'rxjs';

import { Client } from '../models/Client.model';
import { ReturnStatement } from '@angular/compiler';

import {ProductsFinance} from '../models/productsfinance';
import { ProductsFinanceAdmin } from '../models/productsfinanceadmin';






@Injectable({
  providedIn: 'root'
})
export class BooksService {

  SERVER_URL: string = "http://plateforme-lpl-rdc.com";
  url_json = 'http://plateforme-lpl-rdc.com/COMMANDES/web_api/create_lecon.php';
  url_json_avis = 'http://plateforme-lpl-rdc.com/COMMANDES/web_api/create_lecon_avis.php';
  url_json_avis_secon = 'http://plateforme-lpl-rdc.com/COMMANDES/web_api/create_lecon_avis_secon.php'; //Pour alimenter la 2 ièmme BDD avec les mêmes info que la base fondamentale
  public urlFinance = 'http://plateforme-lpl-rdc.com/COMMANDES/web_api/';

  books: Book[] = [];
  books1: Book1[] = [];
  bookSubject = new BehaviorSubject<Book[]>(this.books);
  bookSubject1 = new BehaviorSubject<Book1[]>(this.books1);
  storageFileDocument: string;

  verifId: number = 0;
  verifTailleFile1: number = 0;
  verifTailleFile2: number = 0;
  resultFile: number = 0;

  afiichageCnx: any ;
  time1 ;
  time2 ;
  vitesseMs;
  resultSoustract;
  isBonneCnx = '';
  isMauvaiseCnx = false;
  vitesseMsAfiichage;
  // base api url
  public url = environment.web_api_url_base;
  

  constructor(private http: HttpClient) {
    //this.getBooks();
    this.getBooks1();
    this.getBooksFromServer();
  }

  public uploadFile(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile2(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload2_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile3(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload3_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile4(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload4_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile5(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload5_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile6(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload6_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile7(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload7_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile8(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload8_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile9(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload9_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  public uploadFile10(data) {
    let uploadURL = `${this.SERVER_URL}/COMMANDES/web_api/upload10_new.php`;
    return this.http
    .post<any>(uploadURL, data, {responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  emitBooks() {
    this.bookSubject.next(this.books);
  }

  emitBooks1() {
    this.bookSubject1.next(this.books1);
  }

  saveBooks() {
    firebase.database().ref('/books').set(this.books);
  }

  // Get all product list
  getProducts(): Observable<Book []> {
    return this.http.get<Book []>(this.url + 'view_lecon.php').pipe( map(data => {
        return data;
      })
    );
  }

   // Get all product list
   getProductsFinance(): Observable<ProductsFinance []> {
    return this.http.get<ProductsFinance []>(this.urlFinance + 'view_finance_lpl.php').pipe( map(data => {
        return data;
      })
    );
  }

     // Get all product list
     getProductsFinanceAdmin(): Observable<ProductsFinanceAdmin []> {
      return this.http.get<ProductsFinanceAdmin []>(this.urlFinance + 'view_admin_lpl.php').pipe( map(data => {
          return data;
        })
      );
    }

    // Get single product
    getProductDetailsFinance(id): Observable<ProductsFinance> {
      return this.http.get<ProductsFinance>(this.urlFinance + 'view_finance_one_lecon.php?id=' + id).pipe(map(response => {
        return response;
      }));
    }

      // Get single product
      getProductDetailsFinanceAdmin(id): Observable<ProductsFinanceAdmin> {
        return this.http.get<ProductsFinanceAdmin>(this.urlFinance + 'view_admin_one_lecon.php?id=' + id).pipe(map(response => {
          return response;
        }));
      }

    // Update an existing product
      updateProduct_finance(data) {
        return this.http.post(this.urlFinance + 'update_finance.php', data).pipe(map(response => {
            return response;
          })
        );
      }

      // Update an existing product
        updateProduct_admin(data) {
          return this.http.post(this.urlFinance + 'update_admin.php', data).pipe(map(response => {
              return response;
            })
          );
        }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  

  getBooksFromServer() {
    this.http
      .get<any []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/bdd_json.php', {responseType: 'json'} )
      .pipe(catchError(this.handleError))
      .subscribe(
        (response) => {
          this.books = response;

           console.log('succes ! : OK' );
          this.emitBooks();
        },
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
}
  // Get single client
  getBooksDetails(id): Observable<Book []>{
    console.log('valeur de l\'ID : ' + id)
    return this.http.get<Book []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/view_one_lecon.php?id=' + id, {responseType: 'json'})
    .pipe(catchError(this.handleError))
    .pipe( map(response => {

      console.log('retour de la valeur de la lecon : ' + response)
      return response;
    },
    (error) => {
      console.log('Erreur ! : ' + error);
    })
    
  );
}

 // Get single client
 getClientId(NumeroFamille): Observable<Client []>{
  console.log('valeur de l\'ID : ' + NumeroFamille)
  return this.http.get<Client []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/view_client_id.php?NumeroFamille=' + NumeroFamille, {responseType: 'json'})
  .pipe(catchError(this.handleError))
  .pipe( map(response => {

    console.log('retour de la valeur de la lecon : ' + response)
    return response;
  },
  (error) => {
    console.log('Erreur ! : ' + error);
  })
  
);
}

 // Get single client
 getClientIdPersonel(NumeroFamille): Observable<Client []>{
  console.log('valeur de l\'ID : ' + NumeroFamille)
  return this.http.get<Client []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/view_client_id_personel.php?NumeroFamille=' + NumeroFamille, {responseType: 'json'})
  .pipe(catchError(this.handleError))
  .pipe( map(response => {

    console.log('retour de la valeur de la lecon : ' + response)
    return response;
  },
  (error) => {
    console.log('Erreur ! : ' + error);
  })
  
);
}

 // Get single client
 getClientIdAvis(dateduJour): Observable<Client []>{
    console.log('valeur de la date : ' + dateduJour);
  return this.http.get<Client []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/view_avis_secon.php?dateJour=' + dateduJour, {responseType: 'json'})
  .pipe(catchError(this.handleError))
  .pipe( map(response => {

    console.log('retour de la valeur de la lecon : ' + response)
    return response;
  },
  (error) => {
    console.log('Erreur ! : ' + error);
  })
  
);
}

getClientIdAvisFondamentale(dateduJour): Observable<Client []>{
  console.log('valeur de la date : ' + dateduJour);
return this.http.get<Client []>('http://plateforme-lpl-rdc.com/COMMANDES/web_api/view_avis_fonda.php?dateJour=' + dateduJour, {responseType: 'json'})
.pipe(catchError(this.handleError))
.pipe( map(response => {

  console.log('retour de la valeur de la lecon : ' + response)
  return response;
},
(error) => {
  console.log('Erreur ! : ' + error);
})

);
}


  getBooks() {
    firebase.database().ref('/books')
      .on('value', (data: DataSnapshot) => {
          this.books = data.val() ? data.val() : [];
          for (let i = 0; i < this.books.length; i++) {
            //this.books[i].tempId = i;
            // //console.log(this.books[i].tempId = i);

            this.verifId = i;  
          }
          ////console.log(this.books[this.verifId].tempId); // dernière valeur de la collection
          ////console.log(this.books[this.verifId].author); 
          ////console.log(this.books[this.verifId].dateJour);
          ////console.log(this.books[this.verifId].title);

          this.emitBooks();
        }
      );

      
  }

  getBooks1() {
    firebase.database().ref('/books')
      .on('value', (data: DataSnapshot) => {
          this.books1 = data.val() ? data.val() : [];
          for (let i = 0; i < this.books1.length; i++) {
            //this.books[i].tempId = i;
            // //console.log(this.books[i].tempId = i);

            this.verifId = i;  
          }
          ////console.log(this.books[this.verifId].tempId); // dernière valeur de la collection
          ////console.log(this.books[this.verifId].author); 
          ////console.log(this.books[this.verifId].dateJour);
          ////console.log(this.books[this.verifId].title);

          this.emitBooks1();
        }
      );

      
  }


  getSingleBook(id: number) {
    return new Promise(
      (resolve, reject) => {
        firebase.database().ref('/books/' + id).once('value').then(
          (data: DataSnapshot) => {
            resolve(data.val());
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  }

  verifVitesseCnx(){    
    
    this.time1 = +Date.now();   //transformer 1/01/1970 à nos jour en milliseconde
    console.log('Temps1:' + this.time1)  
    this.getBooks();
    this.time2 = +Date.now();   //transformer 1/01/1970 à nos jour en milliseconde
    console.log('Temps2:' + this.time2)
    this.resultSoustract = this.time2 - this.time1;
    this.vitesseMs = (4600/(this.time2 - this.time1))/2;  //bande passante rélative
    console.log('Temps en milliseconde:' + this.resultSoustract)

    if( this.resultSoustract >= 35){
      this.isBonneCnx = 'Moyenne';
      this.isMauvaiseCnx = false;
      //this.errorMessage = 'Mauvaise connexion internet. Poste impossible pour le moment!';

    }
    if( this.resultSoustract > 5 && this.resultSoustract < 35){
     this.isBonneCnx = 'Moyenne';
      this.isMauvaiseCnx = false;

    }
    if( this.resultSoustract <= 5){
      this.isBonneCnx = 'Bonne';
      this.isMauvaiseCnx = false;

    }  

}

  createNewBook(newBook: Book) {

    //this.verifVitesseCnx();

    //if (this.books != [] && this.vitesseMs < 35) { //si la valeur de la collection est non-vide et la vitesse de cnx est moyenne et bonne
      //if (this.books != [] ) {
      //console.log('valeur de la bande passante avant de poster :' + this.vitesseMs);
      const verifTemps1 = new Date(); 
      //const verifTempsMs = verifTemps1.getMilliseconds;
      this.getBooks();                  //rafraichissement de la liste des leçons
      //this.verifTailleFile1 = +this.books[this.verifId].tempId;  // cast du string en numeric
      //console.log('Valeur1 :' + this.verifTailleFile1);
      this.getBooks();                  //rafraichissement de la liste des leçons
      //this.verifTailleFile2 = +this.books[this.verifId].tempId;  // cast du string en numeric
      //console.log('Valeur2 :' + this.verifTailleFile2);

      //this.resultFile = this.verifTailleFile2 - this.verifTailleFile1;  // resultat de la soustraction

      //console.log('Valeur de la soustraction :' + this.resultFile);

      //if (this.resultFile >= 0 && this.verifTailleFile2 > 0 ){  //le resultat de la soustraction doit être positif et supérieur à 4000 leçons
        
      this.books.push(newBook);
      this.saveBooks();
      this.emitBooks();
      console.log('Leçon postée avec succès !' );

      //}

    //}else{
      //console.log('impossible de poursuivre le poste de la leçon');
      return;

    //}
        
  }

  

  // Create a new Product
  createLecon1(data) {
    return this.http
    .post(this.url + 'create_lecon.php', data)
    .pipe( map(response => {
        return response;
      })
    );
  }

  createLecon( newBookData) {
    return this.http
       .post(this.url_json,  newBookData, {responseType: 'json'} )
       .subscribe(
         (res) => {
          return res;
           //console.log(res);
           //this.router.navigate(['/books']);
         },
         (error) => {
           console.log('Erreur ! : ' + error);
         }
       );   
  }

  createLeconAvis( newBookData) {
    return this.http
       .post(this.url_json_avis,  newBookData, {responseType: 'json'} )
       .subscribe(
         (res) => {
          return res;
           //console.log(res);
           //this.router.navigate(['/books']);
         },
         (error) => {
           console.log('Erreur ! : ' + error);
         }
       );   
  }

  createLeconAvisSecon( newBookData) {
    return this.http
       .post(this.url_json_avis_secon,  newBookData, {responseType: 'json'} )
       .subscribe(
         (res) => {
          return res;
           //console.log(res);
           //this.router.navigate(['/books']);
         },
         (error) => {
           console.log('Erreur ! : ' + error);
         }
       );   
  }

    // Create a new Product
    createProduct_finance(data) {
      return this.http.post(this.url + 'create_secretariat_test.php', data).pipe( map(response => {
          return response;
        })
      );
    }

        // Create a new Product
        createProduct_admin(data) {
          return this.http.post(this.url + 'create_admin_test.php', data).pipe( map(response => {
              return response;
            })
          );
        }


  removeBook(book: Book) {
   // if (book.photo) {
       //const storageRef = firebase.storage().refFromURL(book.photo);
       //storageRef.delete().then(
         //() => {
           //console.log('Photo removed!');
        // },
         //(error) => {
           //console.log('Could not remove photo! : ' + error);
         //}
       //);
     //}

     //if (book.files && book.files.length) {
       //for (let file of book.files) {
         //const storageRef = firebase.storage().refFromURL(file.url);
         //storageRef.delete().then(
           //() => console.log(`File ${file.name} removed!`)
         //).catch(
          // err => console.log(`File ${file.name} could not be removed: ${err}`)
        // );
      // }
     //}

     //const bookIndexToRemove = this.books.findIndex(
       //(bookEl) => {
        // if (bookEl === book) {
          // return true;
         //}
       //}
     //);
     //this.books.splice(bookIndexToRemove, 1);
     //this.saveBooks();
    // this.emitBooks();
}

  uploadFile_old(file: File) {
    return new Promise(
      (resolve, reject) => {
        const almostUniqueFileName = Date.now().toString();
        const upload = firebase.storage().ref()
          .child('images/' + almostUniqueFileName + file.name).put(file);
        upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement…');
          },
          (error) => {
            console.log('Erreur de chargement ! : ' + error);
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL());
          }
        );
      }
    );
}

uploadFileDocument(fileDocument: File) {
  return new Promise(
    (resolve, reject) => {
      const almostUniqueFileNameDocument = Date.now().toString();
      const upload = firebase.storage().ref()
        .child('imagesDocument/' + almostUniqueFileNameDocument + fileDocument.name).put(fileDocument);
      upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
        () => {
          console.log('Chargement…');
        },
        (error) => {
          console.log('Erreur de chargement ! : ' + error);
          reject();
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(
            url => resolve({name: fileDocument.name, url})
          );
        }
      );
    }
  );
}


}
