import { Injectable } from '@angular/core';
import { Classe } from '../models/Classe.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClassesService {

  private classMap: Classe[] = [

    { value: '', text: 'Sélection de la classe' },
    // MATERNELLES
    
    { value: '', text: 'MATERNELLE' },
    { value: '', text: '----------------------' },
    { value: 'm0cr', text: 'M0/Cr' },
    { value: 'm1i', text: 'M1I' },
    { value: 'm1ii', text: 'M1II' },
    { value: 'm2i', text: 'M2I' },
    { value: 'm2ii', text: 'M2II' },
    { value: 'm3i', text: 'M3I' },
    { value: 'm3ii', text: 'M3II' },
    { value: 'm3iii', text: 'M3III' },

    // PRIMAIRES
    
    { value: '', text: 'PRIMAIRE' },
    { value: '', text: '----------------------' },
    { value: 'p1a', text: 'P1A' },
    { value: 'p1b', text: 'P1B' },
    { value: 'p1c', text: 'P1C' },
    { value: 'p1d', text: 'P1D' },
    { value: 'p2a', text: 'P2A' },
    { value: 'p2b', text: 'P2B' },
    { value: 'p2c', text: 'P2C' },
    { value: 'p2d', text: 'P2D' },
    { value: 'p3a', text: 'P3A' },
    { value: 'p3b', text: 'P3B' },
    { value: 'p3c', text: 'P3C' },
    { value: 'p3d', text: 'P3D' },
    { value: 'p4a', text: 'P4A' },
    { value: 'p4b', text: 'P4B' },
    { value: 'p4c', text: 'P4C' },
    { value: 'p4d', text: 'P4D' },
    { value: 'p5a', text: 'P5A' },
    { value: 'p5b', text: 'P5B' },
    { value: 'p5c', text: 'P5C' },
    { value: 'p5d', text: 'P5D' },
    { value: 'p6a', text: 'P6A' },
    { value: 'p6b', text: 'P6B' },
    { value: 'p6c', text: 'P6C' },
    { value: 'p6d', text: 'P6D' },

    // SECONDAIRES
    
    { value: '', text: 'SECONDAIRE' },
    { value: '', text: '----------------------' },
    { value: 's1i', text: 'S1I' },
    { value: 's1ii', text: 'S1II' },
    { value: 's1iii', text: 'S1III' },
    { value: 's1iv', text: 'S1IV' },
    { value: 's2i', text: 'S2I' },
    { value: 's2ii', text: 'S2II' },
    { value: 's2iii', text: 'S2III' },
    { value: 's2iv', text: 'S2IV' },
        
    { value: 's3eco1', text: 'S3Eco1' },
    { value: 's3eco2', text: 'S3Eco2' },
    { value: 's3sc1', text: 'S3SC1' },
    { value: 's3sc2', text: 'S3SC2' },
    { value: 's3tq', text: 'S3TQ' },

    { value: 's4es', text: 'S4E/S' },
    { value: 's4eco', text: 'S4Eco' },
       
    { value: 's4sc', text: 'S4SC' },
    { value: 's4tq', text: 'S4TQ' },

    { value: 's5eco1', text: 'S5Eco1' },
    { value: 's5eco2', text: 'S5Eco2' },    
    { value: 's5ms1', text: 'S5MS1' },
    { value: 's5ms2', text: 'S5MS2' },    
    { value: 's5tq', text: 'S5TQ' },

   
    { value: 's6ms1', text: 'S6MS1' },
    { value: 's6ms2', text: 'S6MS2' },
    { value: 's6tq', text: 'S6TQ' },
    { value: 's6eco', text: 'S6Eco' },

    // AUTRES
   
    { value: '', text: 'ADMINISTRATION' },
    { value: '', text: '----------------------' },
    { value: 'CHEF', text: 'CHEF D\'ETABLISSEMENT' },
    { value: 'PROJETS', text: 'DIR. PROJETS' },
    { value: 'FONDAMENTALE', text: 'DIR. PEDAGOGIQUE FONDAMENTALE' },
    { value: 'SECONDAIRE', text: 'DIR. PEDAGOGIQUE SECONDAIRE' },
    { value: 'FINANCES', text: 'FINANCES' },
    { value: 'SECRETARIAT', text: 'SECRETARIAT' },
    { value: 'GENERAUX', text: 'SERVICES GENERAUX' },
    { value: 'ADMIN', text: 'ADMIN' },
  ];

  private classesSubject: BehaviorSubject<Classe[]>;

  selectedClass: string;

  constructor() {
    this.classesSubject = new BehaviorSubject<Classe[]>(this.classMap);
  }

  getClasses() {
    return this.classesSubject.asObservable();
  }
}
