<div class="row">
    <div class="col-sm-8 col-sm-offset-2">
      <h2>Créer un compte</h2>
      <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Adresse mail</label>
          <input type="text"
                 id="email"
                 class="form-control"
                 formControlName="email">
        </div>
        <div class="form-group">
          <label for="password">Mot de passe (Minimum 6 caractères)</label>
          <input type="password"
                 id="password"
                 class="form-control"
                 formControlName="password">
        </div>

        <button class="btn btn-primary"
        type="submit"
        [disabled]="signUpForm.invalid">Créer mon compte</button>

      </form>
      <p class="text-danger">{{ errorMessage }}</p>
    </div>
  </div>