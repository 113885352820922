import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authsupp',
  templateUrl: './authsupp.component.html',
  styleUrls: ['./authsupp.component.scss']
})
export class AuthsuppComponent implements OnInit {

  signInFormSupp: FormGroup;
  errorMessage: string;
  idService: string;

  studentId: string;
  isStudent: boolean;
  result: string;

  mail_enseignant: string;
  

  constructor(private formBuilder: FormBuilder,  
    private router: Router) { 
      this.mail_enseignant = this.router.getCurrentNavigation().extras.state.example; // la valeur du nom de l'enseignant
    }

    ngOnInit() {
      this.initFormSupp();
      
    }

    initFormSupp() {
      this.signInFormSupp = this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
        
      });  
      
  
    }

    onSubmit() {
      const password = this.signInFormSupp.get('password').value;
      
      if( password == '01107678'){
        this.router.navigate(['/books', 'new'], { state: { example: this.mail_enseignant } });
        //this.router.navigate(['/books', 'new']);
  
        } else{
          this.errorMessage = 'Autorisation réfusée';
        

        }
     
      
  
    }
    onSubmitFacture() {
    
        this.router.navigate(['authsuppfacture']);
  
  
    }

}
