import {Injectable} from '@angular/core';
import {DayPilot} from 'daypilot-pro-angular';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  resources: any[] = [
    {
      name: 'Administration', id: 'GA', expanded: true, children: [
        {name: 'Chef d\'Etablissement', id: 'R1'},
        {name: 'Direction des Projets', id: 'R2'},
        {name: 'Direction Financière', id: 'R3'},
        {name: 'Secrétariat', id: 'R4'}
      ]
    },
    {
      name: 'Directions Pédagogiques', id: 'GB', expanded: true, children: [
        {name: 'Fondamentale', id: 'R5'},
        {name: 'Sécondaire', id: 'R6'}
      ]
    }
    ,
    {
      name: 'Bibliothèque', id: 'GB', expanded: true, children: [
        {name: 'Bibliothèque', id: 'R7'}
      ]
    },
    {
      name: 'Maternelle', id: 'GB', expanded: true, children: [
        {name: 'M1I', id: 'R8'}
      ]
    }
    ,
    {
      name: 'Primaire', id: 'GB', expanded: true, children: [
        {name: 'P1A', id: 'R9'},
        {name: 'P2A', id: 'R10'},
        {name: 'P3A', id: 'R11'},
        {name: 'P4A', id: 'R12'},
        {name: 'P5A', id: 'R13'},
        {name: 'P6A', id: 'R14'}
      ]
    },
    {
      name: 'Sécondaire', id: 'GB', expanded: true, children: [
        {name: 'S1I', id: 'R15'},
        {name: 'S2I', id: 'R16'},
        {name: 'S3ECO', id: 'R17'},
        {name: 'S4SCI', id: 'R18'},
        {name: 'S5TQ', id: 'R19'},
        {name: 'S6MS', id: 'R20'}
      ]
    }
  ];

  events: any[] = [
    {
      id: '1',
      resource: 'R1',
      start: '2020-02-04',
      end: '2020-02-09',
      text: 'Reunion',
      color: '#e69138'
    },
    {
      id: '2',
      resource: 'R2',
      start: '2020-02-03',
      end: '2020-02-06',
      text: 'Prise de contact',
      color: '#6aa84f'
    },
    {
      id: '3',
      resource: 'R3',
      start: '2020-02-07',
      end: '2020-02-10',
      text: 'Réconciliations comptes',
      color: '#3c78d8'
    },
    {
      id: '4',
      resource: 'R4',
      start: '2020-02-07',
      end: '2020-02-10',
      text: 'Reprise des bulletins',
      color: '#3c78d8'
    }
  ];

  constructor(private http: HttpClient) {
  }

  getEvents(from: DayPilot.Date, to: DayPilot.Date): Observable<any[]> {

    // simulating an HTTP request
    return new Observable(observer => {
      setTimeout(() => {
        observer.next(this.events);
      }, 200);
    });

    // return this.http.get("/api/events?from=" + from.toString() + "&to=" + to.toString());
  }

  getResources(): Observable<any[]> {

    // simulating an HTTP request
    return new Observable(observer => {
      setTimeout(() => {
        observer.next(this.resources);
      }, 200);
    });

    // return this.http.get("/api/resources");
  }
}
