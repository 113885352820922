
<div class="container">
    <div class="row">
        <div class="col-xs-12">   
        
  
          <h2>{{ book.listedechoix | uppercase }}</h2>
          <p></p>
              -<span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert"><h4>{{ book.alerte }}</h4></span></span> 
          <h4>{{ book.dateJour | date:"dd/MM/yyyy" }}</h4>
          <h1>{{ book.title }}</h1>
          <h3>{{ book.authorId }}</h3>
          <h3>{{ book.author }}</h3>
  
          <ckeditor [(ngModel)]="book.synopsis"></ckeditor>
        
          <p>{{ book.liens }}</p>
  
          <div *ngIf="book.files && book.files.length">
            <h4><i>Le(s) Fichier(s) à télécharger en bleu(s)</i></h4>
            <a *ngFor="let file of book.files" [href]="file.url" target="_blank">{{ file.name }} | </a> 
            <p>|</p>
            <p>{{ book.tempId }}</p>
          
          </div>
                
       
          <button class="btn btn-default" (click)="onBack()">Retour</button>
        </div>
      </div>