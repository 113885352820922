<div class="row">
    <div class="col-sm-8 col-sm-offset-2">
      <h2>Enregistrer un nouvel avis</h2>
      
      <h3> </h3>
      <form [formGroup]="bookForm" (ngSubmit)="onSaveBookAvis()">
  
        <p></p>
  
        
  
        <div class="form-group">
        <label for="listedechoix">Section</label>
          <select formControlName="listedechoix" (input)="classesMultiples($event.target.value)" id="listedechoix" >
            <option   value=""></option>
            <option *ngFor="let title of titleOptions" >{{ title.local }}</option>
            </select>
          </div>
  
          <div class="form-group">
            <label for="listedechoix">Section(s) sélectionnée(s)</label>
            <button class="btn btn-default pull-left" (click)="onDeleteClasse()">      
              <span class="glyphicon glyphicon-remove" ></span>
           </button> 
            <h5><b><i> {{ selectBox }} </i></b></h5>
           
              </div>
  
        <div class="form-group">
          <label for="authorId">Identifiant Unique de l'auteur </label>
          <input type="text" id="authorId"
                 class="form-control" formControlName="authorId">
        </div>
        
  
        <div class="form-group">
          <label for="Semainier">Date(Semainier)</label>
          <input type="date" id="Semainier" formControlName="Semainier" (input)="detectSemainier($event.target.value)">
        </div>
  
        <div class="form-group">
          <label for="title">Sujet de l'avis</label>
          <input type="text" id="title"
                 class="form-control" formControlName="title">     
        </div>
  
  
        <div class="form-group">
          <h4>Ajouter un fichier à l'avis'(PDF ou WORD)</h4>
          <input type="file" name="avatar1" (change)="onFileSelect1($event)" />        
          
        </div>
  
       
        <ul *ngIf="uploadResponse1 && uploadResponse1.status === 'success'">
          <li>{{ file1name }}</li>
        </ul>
       
  
        <button class="btn btn-success" [disabled]="bookForm.invalid || fileIsUploading || fileIsUploadingDocument || isMauvaiseCnx || envoi_fichier"
        type="submit">Enregistrer
        </button>
  
      </form>
      <p class="text-danger">{{ errorMessage }}</p>
      
    </div>
  </div>
