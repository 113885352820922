<div class="container-fluid">
    <div class="row">
      <div class="col-8">
        <h3>----------------------------------------------------------------------------------------------------------------ADMINISTRATION DU SYSTEME(Gestion des droits des utilisateurs)-------------------------------------------------------------------------------------------</h3>
      
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        -- <button class="btn btn-success" (click)="getNavigation('admincreate', '')">Nouvel Utilisateur</button>
         <button class="btn btn-secondary" (click)="getNavigation('', '')">retour</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table #productsTableFinance class="display" style="width:100%">
          <thead>
          <tr>            
            <th>#</th>  
            <th>Nom de l'utilisateur</th>          
            <th>Adresse mail</th>
            <th>Code d'identifiant unique</th>
            <th>Class-room?</th>
            <th>Chat-room?</th>
            <th>Finance?</th>
            <th>Secrétariat?</th>  
            <th>Communication?</th> 
            <th>Administration?</th>         
          </tr>
          </thead>
          <tbody>
           
          <tr *ngFor="let productfinance of productsfinance; let i =index;">            
            <td  >{{i + 1}}</td>
            <td>{{productfinance.NomUtilisateur}}</td>
            <td>{{productfinance.Adressemail}}</td>
            <td>{{productfinance.IdentUnique}}</td>
            <td>{{productfinance.ClassRoom}}</td>
            <td>{{productfinance.ChatRoom}}</td>
            <td>{{productfinance.Finance}}</td>
            <td>{{productfinance.Secretariat}}</td>
            <td>{{productfinance.Communication}}</td>
            <td>{{productfinance.Administration}}</td>
            
            <td>             
              
                <button type="button" class="btn btn-success" (click)="getNavigation('adminupdate', productfinance.id)">Modifier</button>              
             

           
            </td>
          </tr>
          </tbody>
          </table>
      </div>
    </div>
  </div>