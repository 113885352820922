import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyingAnimalStudentS2IV'
})
export class FlyingAnimalStudentS2IVPipe implements PipeTransform {

  valeurClasse: string='S2IV';

  upsearchClasseNew(value: string) {

    this.valeurClasse = value
    console.log(this.valeurClasse);
   
  }
  transform(allAnimals: any[]) {
    console.log('valeur de :'+ this.valeurClasse);
    return allAnimals.filter(book => book.listedechoix === this.valeurClasse);    
  }
}
