
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >  
  <p> </p>
  <p> </p>

  <ul class="breadcrumb">
    <li><a href="#" data-toggle="popover" title="Grace à votre code secret, vous pouvez récuperer vos bulletin" [routerLink]="'/authsuppbulletin'" routerLinkActive="active" > Bulletin en ligne </a></li>
    <li><a href="#" data-toggle="popover" title="Grace à votre code secret, vous pouvez récuperer vos factures" [routerLink]="'/authsuppfacture'" routerLinkActive="active" > Facture en ligne </a></li>
    <li><a href="#" data-toggle="popover" title="Après le clic, cette page va laisser place à 'prise de rendez-vous' au bout de quelques secondes" data-content="Some content inside the popover" [routerLink]="'/calendar'" routerLinkActive="active" > Prise de R.D.V. </a></li>
    <li><a href="#" data-toggle="popover" title="Méssagérie instantanée privée de l'école. Permet de participer à un groupe dédié de CHAT" [routerLink]="'/login'" routerLinkActive="active" > Chat-Room </a></li>   
    <li><a href="#" data-toggle="popover" title="Commande des manuels et livres pour l'année en cours" href="http://www.hanna-sarl.com//" Target="_blank"> Commande en ligne </a></li>  
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site des évaluations en ligne'Quiziniere'"  href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a></li>
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers le site de Visio-conférence'jit-si'"  href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a></li>
    <li><a  href="#" data-toggle="popover" title="Lien de rédirection vers la plateforme WAZZOU'"  href="https://www.wazzou.be/welcome/"  Target="_blank" > WAZZOU </a></li>
    <li><a href="#" data-toggle="popover" title="Fermeture de la session en cours" [routerLink]="'auth/signin'" routerLinkActive="active"  > Déconnexion </a></li>   
  </ul>


  <h2>Vos leçons</h2>

    <div class="jumbotron" style="background-color:lavenderblush" >
      <div class="row">
        <div class="col-sm-4">
          
        </div>
        <div class="col-sm-8">

          <button class="btn btn-primary" (click)="onArchiveStudent()">Allez sur les leçons mises en archives</button>

        </div>
      </div>


        <form [formGroup]="filterForm">
          <h5>Filtrer par :</h5>
  
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="title-filter">Titre</label>
                  <input type="text" id="title-filter" formControlName="titleFilter">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="author-filter">Auteur</label>
                  <input type="text" id="author-filter" formControlName="authorFilter">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="date-filter">Date</label>
                  <input type="date" id="date-filter" formControlName="dateFilter">
                </div>
              </div>         
              <div class="col-sm-3">
                <div class="form-group">
                  <button  class="btn-default btn-sm" (click)="onResetFilters()">Réinitialiser filtres</button>
                </div>
              </div>           
            </div>    
            <div class="row">
              <div class="col-sm-4">
                
              </div>
              <div class="col-sm-4">
      
                <button class="btn btn-warning" (click)="onArchiveDevoirCorrigeStudent()">Dossier de devoirs corrigés</button>
      
              </div>
              <div class="col-sm-4">
                
              </div>
            </div>
                       
  
          </form>
    </div>
      <div  class="list-group">
        <button 
          class="list-group-item"
          *ngFor="let book of (books$ | async); let i = index"
          [disabled]="book.title == 'DEVOIR' || book.title == 'CORRECTION DU DEVOIR'"

          (click)="onViewBook(book.id)" >
          <h3 class="list-group-item-heading">

            {{ book.listedechoix | uppercase }}
            <p></p>
            -<span *ngIf = "book.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert">Attention</span></span> 
            
              <p> </p>
            <b>{{ book.dateJour | date:"dd/MM/yyyy" }}</b>
            <p> </p>

            <b>{{ book.author | uppercase}}</b>
          

           <button class="btn btn-default pull-right" >      
               <span class="glyphicon glyphicon-remove" ></span>
           </button>              

          </h3>
          <p class="list-group-item-text">{{ book.title | uppercase  }}</p>
          <p class="list-group-item-text">{{ book.id }}</p>
          <div *ngIf="book.title == 'DEVOIR'">  
            <div style="width:1100px;height:20px;background-color:green;" >
            </div>        
            <p class="list-group-item-text">Devoir à domicile de : {{ book.author | uppercase  }}</p>            
          </div>

        </button>
    
    
    
  </div>
 </div>

