import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ClassesService } from '../../services/classes.service';
import { Observable } from 'rxjs';
import { Classe } from '../../models/Classe.model';

import { BooksService } from '../../services/books.service';
import { Client } from '../../models/Client.model';
import { ClientAvis } from '../../models/ClientAvis.model';
import { ClientAvisPersonel } from '../../models/ClientAvisPersonel.model';


import {MatDialog } from '@angular/material/dialog';
import { DialogExampleComponent } from '../../dialog-example/dialog-example.component';
import { DialogChoixComponent } from '../../dialog-choix/dialog-choix.component';



@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {


  classList$: Observable<Classe[]>;


  signInForm: FormGroup;
  errorMessage: string;
  idService: string;

  studentId: string;
  isStudent: boolean;
  result: number;
  identifiantStudent: string;
  classeStudent:  string;

  StudentCode: any;
  StudentCodeId: any;
  Ordre: boolean = false;

  emailVerification :string;

  client: Client; 

  clientAvis: ClientAvis;

  clientAvisPersonel: ClientAvisPersonel;

  lastUpdate = new Date();
  dateduJour: string

  resultSynopsis : string = '';

  isAuth: boolean;
  isAuthClassRoom: boolean;
  isAuthChatRoom: boolean;
  isAuthFinance: boolean;
  isAuthSecretariat: boolean;
  isAuthCommunication: boolean;
  isAuthAdministration: boolean;
  isAuthAvis: boolean;
  isAuthBulletin: boolean;
  isAuthCommande: boolean;
  isAuthFacture: boolean;
  isAuthCalendrier: boolean;
  
  mail_enseignant : string='';
  // nom_enseignant : string ='';
  num_famille: string;

  

  

  titleOptions = [
    {local: '------------------------------------------------------------------'},{local: 'MATERNELLE'},{local: '------------------------------------------------------------------'},
    {local: 'M0/Cr'},{local: 'M1I'},{local: 'M1II'},{local: 'M2I'},{local: 'M2II'},{local: 'M3I'},
    {local: 'M3II'},{local: 'M3III'},{local: '------------------------------------------------------------------'},{local: 'PRIMAIRE'},{local: '------------------------------------------------------------------'},
    {local: 'P1A'},{local: 'P1B'},{local: 'P1C'},{local: 'P1D'},
    {local: 'P2A'},{local: 'P2B'},{local: 'P2C'},{local: 'P2D'},{local: 'P3A'},{local: 'P3B'},
    {local: 'P3C'},{local: 'P4A'},{local: 'P4B'},{local: 'P4C'},{local: 'P5A'},{local: 'P5B'},
    {local: 'P5C'},{local: 'P6A'},{local: 'P6B'},{local: 'P6C'},{local: '------------------------------------------------------------------'},{local: 'SECONDAIRE'},{local: '------------------------------------------------------------------'},
    {local: 'S1I'},{local: 'S1II'},
    {local: 'S1III'},{local: 'S2I'},{local: 'S2II'},{local: 'S2III'},{local: 'S2IV'},{local: 'S3E/S'},
    {local: 'S3ECO'},{local: 'S3SC'},{local: 'S3TQ'},{local: 'S4ECO'},{local: 'S4SC'},{local: 'S4TQ'},
    {local: 'S5E/S'},{local: 'S5MS'},{local: 'S5TQ'},{local: 'S6E/S'},{local: 'S6MS'},{local: 'S6TQ'},
   
  ];
  

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private booksService: BooksService,
    private router: Router,
    private classes: ClassesService,
    public dialog:MatDialog,
    public dialogChoix:MatDialog
        ) { }



    ngOnInit() {
        this.initForm();
        this.classList$ = this.classes.getClasses();
        //console.log("Authentification : " + this.isAuth);
        this.client = new Client('', '', '', '', '', '');
      this.isAuth = true;
      this.isAuthClassRoom = false;
      this.isAuthChatRoom = false;
      this.isAuthFinance = false;
      this.isAuthSecretariat = false;
      this.isAuthCommunication = false;
      this.isAuthAdministration = false;
      this.isAuthAvis = false;
      this.isAuthBulletin = false;
      this.isAuthCommande = false;
      this.isAuthFacture = false;
      this.isAuthCalendrier = false
    }



    OpenDialog() {
      //let dialogRef = this.dialog.open(DialogExampleComponent, { data : { name: this.book.alerte, p_jointe_name : this.book.file1name, p_jointe_file : this.book.file1link} } );
      let dialogRef = this.dialog.open(DialogExampleComponent, { data : { listedechoix: this.clientAvis.listedechoix, title: this.clientAvis.title,  file1Name : 'Cliquez ici', file1link : this.clientAvis.file1link} } );
    
      dialogRef.afterClosed().subscribe(result => { 
        this.router.navigate(['/booksStudent']);
    
      } );
    }

    OpenDialog_Choix() {

      if( this.clientAvisPersonel.ClassRoom == 'OUI' || this.clientAvisPersonel.ClassRoom == 'oui' ){
        this.isAuthClassRoom = true;    
  
        } else{
          this.isAuthClassRoom = false;

        }
        if( this.clientAvisPersonel.ChatRoom == 'OUI' || this.clientAvisPersonel.ChatRoom == 'oui' ){
          this.isAuthChatRoom = true;    
    
          } else{
            this.isAuthChatRoom = false;
  
          }
        if( this.clientAvisPersonel.Communication == 'OUI' || this.clientAvisPersonel.Communication == 'oui' ){
          this.isAuthCommunication = true;    
      
          } else{
            this.isAuthCommunication = false;
    
          }
          if( this.clientAvisPersonel.Finance == 'OUI' || this.clientAvisPersonel.Finance == 'oui' ){
            this.isAuthFinance = true;    
        
            } else{
              this.isAuthFinance = false;
      
            }
          if( this.clientAvisPersonel.Secretariat == 'OUI' || this.clientAvisPersonel.Secretariat == 'oui' ){
            this.isAuthSecretariat = true;    
          
            } else{
              this.isAuthSecretariat = false;
        
            }
          if( this.clientAvisPersonel.Administration == 'OUI' || this.clientAvisPersonel.Administration == 'oui' ){
            this.isAuthAdministration = true;    
            
             } else{
              this.isAuthAdministration = false;
          
            }
          if( this.StudentCodeId == '760' || this.StudentCodeId == '831' || this.StudentCodeId == '679' || this.StudentCodeId == '683' || this.StudentCodeId == 'HML' || this.StudentCodeId == '617' ){
            this.isAuthAvis = true;
            this.isAuthBulletin = true;
            this.isAuthFacture = true;
            this.isAuthCalendrier = true;
            this.isAuthClassRoom = true;

              
              } 
          

      
      //let dialogRef = this.dialog.open(DialogExampleComponent, { data : { name: this.book.alerte, p_jointe_name : this.book.file1name, p_jointe_file : this.book.file1link} } );
      let dialogRefChoix = this.dialogChoix.open(DialogChoixComponent, { data : { Auth: this.isAuth , ClassRoom: this.isAuthClassRoom ,  ChatRoom : this.isAuthChatRoom , Finance : this.isAuthFinance , Secretariat : this.isAuthSecretariat , Communication : this.isAuthCommunication , Administration : this.isAuthAdministration , Avis : this.isAuthAvis , Bulletin : this.isAuthBulletin , Commande : this.isAuthCommande , Facture : this.isAuthFacture , Calendrier : this.isAuthCalendrier , userName : this.clientAvisPersonel.NomUtilisateur} } );
    
      console.log('Valeur administration : ' + this.isAuthAdministration)
      dialogRefChoix.afterClosed().subscribe(result => { 
       // this.router.navigate(['/booksStudent']);
    
      } );
    }

  initForm() {
    
      this.signInForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
        text: [''],
        //studentClass: [null, Validators.required]
        studentClass: ['']
      });


    

  }


  onSubmit() {
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;

    const studentId = this.signInForm.get('email').value;
    this.result = studentId.indexOf('lpl-rdc.com')

    this.mail_enseignant = this.signInForm.get('email').value;  //Pour vérifier le nom de l'enseignant


    if( this.result != -1){
      this.Ordre = true;
     //// this.idService = this.result;
      this.isStudent = false;
      console.log('Rédirection vers espace enseignant');

      switch (this.mail_enseignant) {
          case 'direction@lpl-rdc.com': // Si membre de la direction redirection vers espace total enseignant
          this.mail_enseignant = '',
          this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
            break;
          case 'direction.projets@lpl-rdc.com': // Si membre de la direction redirection vers espace total enseignant
          this.mail_enseignant = '',
          this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });

            break;
          case 'direction-pedagogique-secondaire@lpl-rdc.com': // Si membre de la direction redirection vers espace total enseignant
          this.mail_enseignant = '',
          this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
    
            break;
          case 'direction-pedagogique-fondamentale@lpl-rdc.com': // Si membre de la direction redirection vers espace total enseignant
          this.mail_enseignant = '',
          this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
      
              break;
          case 'hml@lpl-rdc.com': // Si membre de la direction redirection vers espace total enseignant
          this.mail_enseignant = '',
          this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
        
              break;
            default:
            this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });  // Sinon c'est espace privé enseigant
       }



     // this.authService.signInUser(email, password).then(
        //() => {

         // this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
         ////////////////////////// this.router.navigate(['/books']);
        //},
        //(error) => {
          //this.errorMessage = error;
         // // this.errorMessage = this.idService;
  
        //}
      //);

      } else{
       this.isStudent = true;
       //this.dateduJour =  this.lastUpdate.toString();
       this.dateduJour = this.lastUpdate.toISOString().substring(0, 10);  //Mettre la date par defaut (Aujourd'hui)au format yyyy-mm-jj
       //this.dateduJour =  '2021-08-03';
       
       console.log('Rédirection vers espace élève');       

       // this.authService.signInUser(email, password).then(
         // () => {
           this.authService.setClass(this.signInForm.get('studentClass').value);

           const classeselectionner = this.signInForm.get('studentClass').value;
           console.log('La classe sélectionnée est : ' + classeselectionner);
           const valeurclasseselectionner = classeselectionner.indexOf('s')  // Vérification si la classe sélectionner est en SECONDAIRE

           if( valeurclasseselectionner != -1){
              //this.Ordre = true;
              //this.isStudent = false;
              console.log('Rédirection vers AVIS sécondaire' + this.dateduJour);
              this.loadClientIdAvis(this.dateduJour);
                   
            } else{
                  //this.isStudent = true;
              
                  console.log('Rédirection vers AVIS fondamentale :' + this.dateduJour);
                  this.loadClientIdAvisFondamentale(this.dateduJour);  // Sinon, Allé vers AVIS Fondamentale
                 }


          
           //this.loadClientIdAvis(this.dateduJour);

           
           
           //this.router.navigate(['/booksStudent']);
         // },
          //(error) => {
            //this.errorMessage = error;
            //// this.errorMessage = this.idService;

          //}
        //);
      }



  }

  onSave() {
    
}

onSearchChange(searchValue: string): void {  
  console.log(searchValue);
  this.StudentCodeId = searchValue;
}

onVerifEmail(searchEmailValue: string): void { 
  const Emailverif = this.signInForm.get('email').value;
  const resultEmail =Emailverif.indexOf('lpl-rdc.com') 
  const resultEmailverification =Emailverif.indexOf('verificationcours@digital-school.com')


  if(resultEmail != -1 || resultEmailverification != -1){
    console.log('Email de post ' + resultEmail);
    console.log('Email de verif :'  + resultEmailverification);
    this.Ordre = true;
    console.log('Ordre? :'  + this.Ordre);

  }
}

selectChangeHandler (event: any) {
  this.StudentCodeId = this.StudentCodeId;
  this.num_famille = this.StudentCodeId;  // Recupération du numéro de la famille
  this.errorMessage = 'Traitement en cours...';
  
  this.loadClientId(this.StudentCodeId);
  this.loadClientIdPersonel(this.StudentCodeId);

}

loadClientId(NumeroFamille){

  this.booksService.getClientId(NumeroFamille).subscribe((data: any) => {
    this.client = data;
    console.log('La famille est en ordre : ' + this.client.OrdrePyt)

    console.log('Numero de famille est :' + this.num_famille);


    switch (this.client.OrdrePyt) {

      case '1':
  
        console.log('En ordre de paiement : ' + this.client.OrdrePyt);
        this.Ordre = true;
        this.errorMessage = '';  //Très important

  
        break;
        case '0':  
          
          this.Ordre = false;
          this.errorMessage = 'Vous n\' êtes pas autorisé à accéder à la plateforme en ligne.Veuillez prendre contact avec la Comptabilité';
          setTimeout(
            () => {
              //this.isAuth = true;
              this.router.navigate(['/auth', 'signin']);
            }, 15000
          );
          break;
        default:
          this.errorMessage = 'Numéro de famille invalide';
       
        
    }

  });
}

loadClientIdPersonel(NumeroFamille){

  this.booksService.getClientIdPersonel(NumeroFamille).subscribe((dataAvisPersonel: any) => {
    this.clientAvisPersonel = dataAvisPersonel;
    
    console.log('identifiant unique : ' + NumeroFamille)
    console.log('L\'adresse email : ' + this.clientAvisPersonel.Adressemail)



  });
}



loadClientIdAvis(dateduJour){

  this.booksService.getClientIdAvis(dateduJour).subscribe((dataAvis: any) => {
    this.clientAvis = dataAvis;
    console.log('Le titre du message : ' + this.clientAvis.title);
    console.log('La section : ' + this.clientAvis.listedechoix);
    console.log('Le corps du message : ' + this.clientAvis.synopsis);
    console.log('La date du message : ' + this.clientAvis.dateJour);
    console.log('Le nom de la pièce jointe du message : ' + this.clientAvis.file1Name);  
    console.log('Le lien de la pièce jointe du message : ' + this.clientAvis.file1link);

    console.log('Date du jour : ' + this.dateduJour); 

    
    //this.resultSynopsis =  this.clientAvis.listedechoix;
     //this.resultSynopsis = this.clientAvis.synopsis.indexOf('Chers Parents!') //Vérifie si, le champs synopsis contient la valeur:'Chers Parents', présence d'un enregistrement d'avis dans la BDD

           if( this.clientAvis.listedechoix == null){
            console.log('Pas d\'AVIS reçent trouvé');

            //this.OpenDialog_Choix();

            this.router.navigate(['/booksStudent'], { state: { example: this.num_famille } });
            //this.router.navigate(['/booksStudent']); // Continuer sans afficher l'AVIS           
                    
          } else {
            console.log('La valeur de resultSynopsis : ' + this.resultSynopsis );
            this.OpenDialog(); //sinon, Lancé l'ouverture de la boite de dialogue AVIS S'il existe un avis en cours           
            
            
          }

  });
}

loadClientIdAvisFondamentale(dateduJour){

  this.booksService.getClientIdAvisFondamentale(dateduJour).subscribe((dataAvis: any) => {
    this.clientAvis = dataAvis;
    console.log('Le titre du message : ' + this.clientAvis.title);
    console.log('La section : ' + this.clientAvis.listedechoix);
    console.log('Le corps du message : ' + this.clientAvis.synopsis);
    console.log('La date du message : ' + this.clientAvis.dateJour);
    console.log('Le nom de la pièce jointe du message : ' + this.clientAvis.file1Name);  
    console.log('Le lien de la pièce jointe du message : ' + this.clientAvis.file1link);

    console.log('Date du jour : ' + this.dateduJour); 

    
    //this.resultSynopsis =  this.clientAvis.listedechoix;
     //this.resultSynopsis = this.clientAvis.synopsis.indexOf('Chers Parents!') //Vérifie si, le champs synopsis contient la valeur:'Chers Parents', présence d'un enregistrement d'avis dans la BDD

           if( this.clientAvis.listedechoix == null){
            console.log('Pas d\'AVIS reçent trouvé');

            //this.OpenDialog_Choix();

            this.router.navigate(['/booksStudent'], { state: { example: this.num_famille } });
            //this.router.navigate(['/booksStudent']); // Continuer sans afficher l'AVIS           
                    
          } else {
            console.log('La valeur de resultSynopsis : ' + this.resultSynopsis );
            this.OpenDialog(); //sinon, Lancé l'ouverture de la boite de dialogue AVIS S'il existe un avis en cours           
            
            
          }

  });
}

  


  
}
