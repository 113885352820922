import { Component, OnDestroy, OnInit } from '@angular/core';
import { BooksService } from '../services/books.service';
import { Book } from '../models/Book.model';
import { Book1 } from '../models/Book1.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Classe } from '../models/Classe.model';
import { ClassesService } from '../services/classes.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-book-list-student-m1-i',
  templateUrl: './book-list-student-m1-i.component.html',
  styleUrls: ['./book-list-student-m1-i.component.scss']
})
export class BookListStudentM1IComponent implements OnInit {

  //books$: Observable<Book[]>;
  books$: Observable<Book[]>;
  books1$: Observable<Book1[]>;
  filterForm: FormGroup;
  destroy$: Subject<boolean>;
  studentClass$: Observable<string>;

  constructor(private booksService: BooksService,
              private router: Router,
              private formBuilder: FormBuilder,
              private auth: AuthService
              ) {}



              ngOnInit() {
                this.destroy$ = new Subject();
                this.studentClass$ = this.auth.getClass();
                this.filterForm = this.formBuilder.group({
                  titleFilter: [null],
                  authorFilter: [null],
                  dateFilter: [null]
                });
                this.books1$ = this.studentClass$.pipe(
                  tap(console.log),
                  switchMap((classe: string) => combineLatest([
                    this.booksService.bookSubject1,
                    this.filterForm.valueChanges.pipe(
                      startWith({
                        titleFilter: null,
                        authorFilter: null,
                        dateFilter: null
                      })
                    )]).pipe(
                      debounceTime(300),
                      map(([books1, valueChanges]) => {
                        if (valueChanges.titleFilter) {
                          books1 = books1.filter(book1 => book1.title.toLowerCase().includes(valueChanges.titleFilter.toLowerCase()));
                        }
                        if (valueChanges.authorFilter) {
                          books1 = books1.filter(book1 => book1.author.toLowerCase().includes(valueChanges.authorFilter.toLowerCase()));
                        }
                        if (valueChanges.dateFilter) {
                          books1 = books1.filter(book1 =>
                            new Date(book1.dateJour).getDate() === new Date(valueChanges.dateFilter).getDate() &&
                            new Date(book1.dateJour).getMonth() === new Date(valueChanges.dateFilter).getMonth()
                          );
                        }
                        return books1;
                      }),
                      map(books1 => {
                        if (classe) {
                          books1 = books1.filter(book1 => book1.listedechoix.toLowerCase() === classe.toLowerCase());
                        }
                        return books1;
                      }),
                      map(books1 => books1.sort(
                        (a: Book1, b: Book1) => (new Date(b.dateJour).getTime() - new Date(a.dateJour).getTime()) < 0 ? -1 : 1))
                    )
                  )
                );
                this.booksService.emitBooks1();
              }
            
              onNewBook() {
                this.router.navigate(['/books', 'new']);
              }
              onArchive_retour() {
                // this.router.navigate(['/books', 'new']);
                this.router.navigate(['/books']);
              }
            
              onDeleteBook(book: Book) {
                this.booksService.removeBook(book);
              }
            
              onViewBook(id: number) {
                this.router.navigate(['/booksArchive', 'view', id]);
              }
            
              onResetFilters() {
                this.filterForm.reset();
              }
            
              ngOnDestroy() {
                this.destroy$.next(true);
              }
}
