export class Client {    
  
    constructor(public NumeroFamille: string,
                public NumeroEleve: string,
                public Observations: string,
                public OrdrePyt: string,
                public Bulletin: string,
                public Facture: string,
               ) {
          }
  }