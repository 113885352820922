


<div style="height: 100vh;" >

  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button mat-icon-button>
        <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
      </button>
     
      <span class="menu-spacer"></span>


    
      <span class="menu-spacer"></span>

    <!-- The following menu items will be hidden on both SM and XS screen sizes -->
     
     
    
    </mat-toolbar-row>
  
    <mat-toolbar-row >
      <div class="row">
        <div class="col-sm-9">
          <span style="font-size: 12px;" ><h3><i>Plateforme en ligne du Lycée Prince de Liège-Kinshasa(Digital-School)</i></h3></span>       
        </div>
        <div class="col-sm-3">
          <h5>Helpdesk: +243 810 697 501/helpdesk@lpl-rdc.com</h5>
        </div>
      </div>
        
    </mat-toolbar-row>

  </mat-toolbar>
  
  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav #sidenav >
      <mat-nav-list>
  
        
        
  
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav #sidenav1  >
      <mat-nav-list >
         
           
                    
       
         
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fxFlexFill>
      <div style="height: 88vh;">
  
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  
  </div>
  
  
  
  
  