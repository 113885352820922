
<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h2>Archives</h2>
      <button class="btn btn-primary" (click)="onArchive_retour()">Retour</button>
 
      <p> </p>
      <form [formGroup]="filterForm">
        <h5>Filtrer par :</h5>
        <div class="form-group">
          <label for="title-filter">Titre</label>
          <input type="text" id="title-filter" formControlName="titleFilter">
        </div>
        <div class="form-group">
          <label for="author-filter">Auteur</label>
          <input type="text" id="author-filter" formControlName="authorFilter">
        </div>
        <div class="form-group">
          <label for="date-filter">Date</label>
          <input type="date" id="date-filter" formControlName="dateFilter">
        </div>
        <div class="form-group">
          <button class="btn-default btn-sm" (click)="onResetFilters()">Réinitialiser filtres</button>
        </div>
      </form>
      <div class="list-group">
        <button
          class="list-group-item"
          *ngFor="let book1 of (books1$ | async); let i = index"
 
          (click)="onViewBook(book1.tempId)" >
          <h3 class="list-group-item-heading">
 
            {{ book1.listedechoix | uppercase }}
            <p></p>
            -<span *ngIf = "book1.alerte != ''" class="badge badge-light"><span class="glyphicon glyphicon-alert">Attention</span></span> 
           
            <p> </p>
            <b>{{ book1.dateJour | date:"dd/MM/yyyy" }}</b>
            <p> </p>
 
            <b>{{ book1.author | uppercase}}</b>
           
 
            <button class="btn btn-default pull-right" >
              <span class="glyphicon glyphicon-plus"></span>
            </button>
          </h3>
          <p class="list-group-item-text">{{ book1.title | uppercase  }}</p>
 
        </button>
      </div>
 
    </div>
  </div>
 </div>