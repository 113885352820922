import { Component } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HML-Solution';

  studentId: string;
  isStudent: boolean;
  result: string;





  constructor(){
   var firebaseConfig = {
      apiKey: "AIzaSyA0kShgcOMH1zt_AfG9CVVs_D9hbhXIaho",
      authDomain: "hml-classroom.firebaseapp.com",
      databaseURL: "https://hml-classroom.firebaseio.com",
      projectId: "hml-classroom",
      storageBucket: "hml-classroom.appspot.com",
      messagingSenderId: "119368751448",
      appId: "1:119368751448:web:e19375328df31ed3f5ed2d",
      measurementId: "G-PC4EV1LKKP"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  ngOnInit(){
    this.getUrl();
  }

  getUrl()
{
  return "url('http://estringsoftware.com/wp-content/uploads/2017/07/estring-header-lowsat.jpg')";
}
}
