import { Component, OnInit } from '@angular/core';
import { Book } from '../../models/book.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BooksService } from '../../services/books.service';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-single-book',
  templateUrl: './single-book.component.html',
  styleUrls: ['./single-book.component.scss']
})
export class SingleBookComponent implements OnInit {

  book: Book;
  mail_enseignant: string;


  bookForm: FormGroup;
  form: FormGroup;
  file1name: string='';
     file1link: string='';
     file2name: string='';
     file2link: string='';
     file3name: string='';
     file3link: string='';
     file4name: string='';
     file4link: string='';
     file5name: string='';
     file5link: string='';
     file6name: string='';
     file6link: string='';
     file7name: string='';
     file7link: string='';
     file8name: string='';
     file8link: string='';
     file9name: string='';
     file9link: string='';
     file10name: string='';
     file10link: string='';
     file11name: string='';
     file11link: string='';
     file12name: string='';
     file12link: string='';
     file13name: string='';
     file13link: string='';
     file14name: string='';
     file14link: string='';
     file15name: string='';
     file15link: string='';
     file16name: string='';
     file16link: string='';
     file17name: string='';
     file17link: string='';
     file18name: string='';
     file18link: string='';
     file19name: string='';
     file19link: string='';
     file20name: string='';
     file20link: string='';

     uploadResponse1;
     uploadResponse2;
     uploadResponse3;
     uploadResponse4;
     uploadResponse5;
     uploadResponse6;
     uploadResponse7;
     uploadResponse8;
     uploadResponse9;
     uploadResponse10;
     uploadResponse11;
     uploadResponse12;
     uploadResponse13;
     uploadResponse14;
     uploadResponse15;
     uploadResponse16;
     uploadResponse17;
     uploadResponse18;
     uploadResponse19;
     uploadResponse20;
     errorMessage: string;
     envoi_fichier = false;
     fileIsUploading = false;
    fileIsUploading1 = false;
    fileIsUploading2 = false;
    fileIsUploading3 = false;
    fileIsUploading4 = false;
    fileIsUploading5 = false;
    fileIsUploading6 = false;
    fileIsUploading7 = false;
    fileIsUploading8 = false;
    fileIsUploading9 = false;
    fileIsUploading10 = false;
    lastUpdate = new Date();
    listedechoix : string;
    authorId: string = '';
    authorIdValue: string = '';
    fileIsUploadingDocument = false;

    num_famille: string;

    synopsis_cke_editor : string = 'Bonjour, cher(es) élèves!';

    dateduJourAjour: string;

    nom_Eleve: string;

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder, private booksService: BooksService,
              private router: Router) {
                this.mail_enseignant = this.router.getCurrentNavigation().extras.state.example; // la valeur du nom de l'enseignant
              }

  ngOnInit() {

    this.book = new Book('', '', '', '', '', '','','','','','', '', '', '', '', '','','','','','', '', '', '', '', '','','','','','', '', '', '', '', '','','','','','', '', '', '', '', '','','');
    const id = this.route.snapshot.params['id'];
    this.loadClienttDetails(id);

    this.initForm();
    this.initForm_upload();
    //this.booksService.getSingleBook(+id).then(
      //(book: Book) => {
        //this.book = book;

     // }
    //);   
    
    
  }

  initForm() {       
    
    this.bookForm = this.formBuilder.group({
      //title: ['', Validators.required],
      //author: [''],
      comment: ['', Validators.required],
      authorId:'',
      //Semainier: [""],
      //synopsis: '',
      alerte: '',
      liens: '',
      dateJour: '',
      //listedechoix: ['', Validators.required]
    });

  }

  initForm_upload() {

    this.form = this.formBuilder.group({
      avatar1: [''],
      avatar2: [''],
      avatar3: [''],
      avatar4: [''],
      avatar5: [''],
      avatar6: [''],
      avatar7: [''],
      avatar8: [''],
      avatar9: [''],
      avatar10: ['']
  });

}



  loadClienttDetails(id){
    this.booksService.getBooksDetails(id).subscribe((data: any) => {
      this.book = data;
      console.log('valeur de la lecon : ' + this.book)
    });
  }
  

  onBack() {
    this.router.navigate(['/books'], { state: { example: this.mail_enseignant } });
    //this.router.navigate(['/books']);
  }

  onSaveBook() { 

    ////for ( this.i = 1; this.i < this.valeur_tableau; this.i++) {

      //console.log( 'valeur du tableau n : ' + this.i +' = ' + this.tableau[this.i]);

      this.onSaveBookMultiples();  //appel de la methode pour plusieurs classes

      
      
      //if (this.libelle1.options[i].selected) {
        //this.listedechoix =   this.tableau[i];
        //console.log( 'la liste de choix est : ' + this.listedechoix);
      //}
   // }

    

  }

  onSaveBookMultiples() { 

    const newBookData = new FormData();

    const dateJour = this.lastUpdate.toString();
    newBookData.append('dateJour', this.lastUpdate.toString());
    const title = 'CORRECTION DU DEVOIR';
    newBookData.append('title', title);  //Valeur du titre de la leçon en cours renvoyer par le serveur
     //this.listedechoix = this.bookForm.get('listedechoix').value;
     this.listedechoix = this.book.listedechoix; //Valeur de la classe en cours renvoyer par le serveur
     newBookData.append('listedechoix', this.listedechoix);
    this.nom_Eleve = this.book.author;  //Récupérer le nom complet de l'élève
    newBookData.append('author', this.nom_Eleve);
    this.authorIdValue = this.book.authorId;  //Récupérer l'email' du prof 
    newBookData.append('authorId', this.authorIdValue);

   
    this.authorId = this.authorIdValue.trim();
    //const synopsis = this.bookForm.get('synopsis').value;

    const synopsis = this.synopsis_cke_editor;
    newBookData.append('synopsis', this.synopsis_cke_editor);

    console.log( 'le synopsis est : ' + synopsis);

    const alerte = this.bookForm.get('comment').value;  //Récupérer le commentaire du prof
    newBookData.append('alerte', this.bookForm.get('comment').value); //Récupérer le commentaire du prof
    const liens = this.num_famille;
    newBookData.append('liens', this.book.liens);  //Récupérer le num de famille de l'élève

    const newBook = new Book(title, this.listedechoix, this.nom_Eleve, this.authorId, synopsis, alerte, this.book.liens,  dateJour,
      this.file1name,
      this.file1link,
      this.file2name,
      this.file2link,
      this.file3name,
      this.file3link,
      this.file4name,
      this.file4link,
      this.file5name,
      this.file5link,
      this.file6name,
      this.file6link,
      this.file7name,
      this.file7link,
      this.file8name,
      this.file8link,
      this.file9name,
      this.file9link,
      this.file10name,
      this.file10link,
      this.file11name,
      this.file11link,
      this.file12name,
      this.file12link,
      this.file13name,
      this.file13link,
      this.file14name,
      this.file14link,
      this.file15name,
      this.file15link,
      this.file16name,
      this.file16link,
      this.file17name,
      this.file17link,
      this.file18name,
      this.file18link,
      this.file19name,
      this.file19link,
      this.file20name,
      this.file20link);

    

    newBook.listedechoix = this.listedechoix;
    newBookData.append('listedechoix', this.listedechoix);
    //newBook.authorId = this.authorId;
    newBook.synopsis = synopsis;
    newBook.alerte = alerte;
    newBook.liens = liens;
    newBook.dateJour = dateJour;
    

    // Mettre le test des codes uniques identifiant des profs ici !!!!!!!! et déplacer

    //var monthName: string;

    if (this.authorId != '') {

      //this.booksService.createNewBook(newBook);  // vers firebase
      //this.router.navigate(['/books']);         // La suite logique 

      //newBook.file1name = '-';
      newBookData.append('file1name', this.file1name);
      //newBook.file1link= '-';
      newBookData.append('file1link', this.file1link);
      //newBook.file2name= '-';
      newBookData.append('file2name', this.file2name);
      // newBook.file2link= '-';
      newBookData.append('file2link', this.file2link);
       //newBook.file3name= '-';
      newBookData.append('file3name', this.file3name);
      // newBook.file3link= '-';
      newBookData.append('file3link', this.file3link);
      //newBook.file4name= '-';
      newBookData.append('file4name', this.file4name);
     // newBook.file4link= '-';
      newBookData.append('file4link', this.file4link);
      //newBook.file5name= '-';
      newBookData.append('file5name', this.file5name);
      //newBook.file5link= '-';
      newBookData.append('file5link', this.file5link);
     // newBook.file6name= '-';
      newBookData.append('file6name', this.file6name);
      //newBook.file6link= '-';
      newBookData.append('file6link', this.file6link);
      //newBook.file7name= '-';
      newBookData.append('file7name', this.file7name);
     // newBook.file7link= '-';
      newBookData.append('file7link', this.file7link);
      //newBook.file8name= '-';
      newBookData.append('file8name', this.file8name);
      //newBook.file8link= '-';
      newBookData.append('file8link', this.file8link);
      //newBook.file9name= '-';
      newBookData.append('file9name', this.file9name);
      //newBook.file9link= '-';
      newBookData.append('file9link', this.file9link);
      //newBook.file10name= '-';
      newBookData.append('file10name', this.file10name);
      // newBook.file10link= '-';
      newBookData.append('file10link', this.file10link);
      // newBook.file11name= '-';
      newBookData.append('file11name', '');
      //newBook.file11link= '-';
      newBookData.append('file11link', '');
      //newBook.file12name= '-';
      newBookData.append('file12name', '');
      // newBook.file12link= '-';
      newBookData.append('file12link', '');
      // newBook.file13name= '-';
      newBookData.append('file13name', '');
      // newBook.file13link= '-';
      newBookData.append('file13link', '');
      // newBook.file14name= '-';
      newBookData.append('file14name', '');
      //newBook.file14link= '-';
      newBookData.append('file14link', '');
      //newBook.file15name= '-';
      newBookData.append('file15name', '');
      //newBook.file15link= '-';
      newBookData.append('file15link', '');
      //newBook.file16name= '-';
      newBookData.append('file16name', '');
      //newBook.file16link= '-';
      newBookData.append('file16link', '');
      //newBook.file17name= '-';
      newBookData.append('file17name=', '');
      //newBook.file17link= '-';
      newBookData.append('file17link', '');
      //newBook.file18name= '-';
      newBookData.append('file18name', '');
      //newBook.file18link= '-';
      newBookData.append('file18link', '');
      //newBook.file19name= '-';
      newBookData.append('file19name', '');
      //newBook.file19link= '-';
      newBookData.append('file19link', '');
      //newBook.file20name= '-';
      newBookData.append('file20name', '');
      //newBook.file20link= '-';
      newBookData.append('file20link', '');
      
      this.booksService.createLecon( newBookData);

      this.booksService.getBooksFromServer();
      this.booksService.emitBooks();
      this.router.navigate(['/books'], { state: { example: this.book.authorId } });  //Retour sur la liste avec l'identifiant de l'enseignante
      //this.router.navigate(['/booksStudent']);
 

    } else {
      this.errorMessage = 'Veuillez taper votre nom au complet!';

    }



  }
}
