import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visioconf',
  templateUrl: './visioconf.component.html',
  styleUrls: ['./visioconf.component.css']
})
export class VisioconfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
