import {Component, OnInit, ViewChild, Renderer2, AfterViewInit} from '@angular/core';
import {BooksService} from '../services/books.service';
import {Router} from '@angular/router';
import {ProductsFinanceAdmin} from '../models/productsfinanceadmin';

import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';

declare var $;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public productsfinance: ProductsFinanceAdmin [];
  @ViewChild('productsTableFinance', {static: false}) Table;
  public dataTable: any;
  public dtOptions;
  public tableElement: any;

  constructor(private crudService: BooksService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loadProductsFinance();
  }

  loadProductsFinance() {
    this.crudService.getProductsFinanceAdmin().subscribe(data => {
      this.productsfinance = data;
    }, err => {}, () => {
      setTimeout(() => {
        this.dataTable = $(this.Table.nativeElement);
        this.tableElement = this.dataTable.DataTable(this.dtOptions);
      }, 1000);  
    });

  }

  getNavigation(link, id) {
    if (id === '') {
      
      this.router.navigate([link]);
    } else {
      console.log('Valeur ID : ' + id);
      this.router.navigate([link + '/' + id]);
      
    }
  }

}

