
<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/noel.png');" >  
  <p> </p>
  <p> </p>

  <ul class="breadcrumb">
    <li><a href="#" data-toggle="popover" title="Grace à votre code secret, vous pouvez récuperer vos bulletin" [routerLink]="'/authsuppbulletin'" routerLinkActive="active" > Bulletin en ligne </a></li>
    <li><a href="#" data-toggle="popover" title="Après le clic, cette page va laisser place à 'prise de rendez-vous' au bout de quelques secondes" data-content="Some content inside the popover" [routerLink]="'/calendar'" routerLinkActive="active" > Prise de R.D.V. </a></li>
    <li><a href="#" data-toggle="popover" title="Méssagérie instantanée privée de l'école. Permet de participer à un groupe dédié de CHAT" [routerLink]="'/login'" routerLinkActive="active" > Chat-Room </a></li>   
    <li><a  href="https://forms.gle/vRvE1JcMQWrddp1G9"  Target="_blank"> Académie des parents </a></li>
    <li><a href="#" data-toggle="popover" title="Commande des manuels et livres pour l'année en cours" href="http://www.hanna-sarl.com//" Target="_blank"> Commande en ligne </a></li>  
    <li><a href="#" data-toggle="popover" title="Fermeture de la session en cours" [routerLink]="'auth/signin'" routerLinkActive="active"  > Déconnexion </a></li>   
  </ul>




<div class="row">
  
    <div class="col-sm-8 col-sm-offset-2">
      <h2>Facture en ligne</h2>
      <h2>Veuillez renseigner votre numéro de famille ainsi que le code secret</h2>
      <div class="jumbotron" style="background-color:lavenderblush" >
      <form [formGroup]="signInFormSuppbulletin" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <label for="text">Uniquement les chiffres</label>
          <input type="text"
                 id="text"
                 class="form-control"
                 formControlName="text" placeholder="Numéro de famille ici">
          <input type="text"
                 id="textsecret"
                 class="form-control"
                 formControlName="textsecret" placeholder="Code secret ici">
        </div>


        <button class="btn btn-primary"
              type="submit"
              [disabled]="signInFormSuppbulletin.invalid ">Validation</button>

      </form>



      <div >
        <h4><i>La facture à télécharger en bleue</i></h4>
        <a  [href]="urlCode_lien_facture" target="_blank">{{urlCode_lien_facture}} | </a> 
      </div>
      

      <p class="text-danger">{{ errorMessage }}</p>
      <p class="text-danger">{{ idService }}</p>
      

    </div>
    </div>
    </div>
  </div>