<div class="container" [ngStyle]="{'color': 'blue'}"  style="background-image: url('assets/img/paque7.png');" >
  <p> </p>
  <p> </p>

  <ul class="breadcrumb">
    <li><a    href="https://www.quiziniere.com/" Target="_blank" > Evaluation en ligne </a></li>
    <li><a    href="https://meet.jit.si/"  Target="_blank" > Vidéoconférence </a></li>
    <li><a    href="https://login.one.com/mail" Target="_blank" > Webmail </a></li>
    <li><a    [routerLink]="'login'" routerLinkActive="active" > Chat-Room </a></li>        
  </ul>


  <div class="row" [ngStyle]="{'color': 'blue'}">
    <div class="col-sm-8 col-sm-offset-2">
      <h2>Connexion</h2>
      <h2>Veuillez renseigner votre code enseignant</h2>

      <div class="jumbotron" style="background-color:lavenderblush" >
      <form [formGroup]="signInFormSupp" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <label for="password">Mot de passe</label>
          <input type="password"
                 id="password"
                 class="form-control"
                 formControlName="password">
        </div>


        <button class="btn btn-primary"
              type="submit"
              [disabled]="signInFormSupp.invalid">Validation</button>

      </form>

                    
      

      <p class="text-danger">{{ errorMessage }}</p>
      <p class="text-danger">{{ idService }}</p>

    </div>
      



    </div>
  </div>
</div>